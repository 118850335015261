import { PortalConfigType } from '@portals/api/partners';

export enum StepId {
  CustomUrl = 'customUrl',
  PortalDesign = 'portalDesign',
}

export interface OnboardingWizardContextDataType {
  // Domain
  on_xyte_domain: PortalConfigType['name'];

  // Design
  design: PortalConfigType['design'];
}
