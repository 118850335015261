import { Button, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { InferType, object, string } from 'yup';

import { StepsFooter } from '../components/StepsFooter';
import { StepsHeader } from '../components/StepsHeader';
import { useInvitePartnerWizard } from '../hooks';

const schema = object({
  email: string().email().required(),
});

export function InviteNewPartner() {
  const { contextData, onNext, onPrev } = useInvitePartnerWizard();
  const { onSetNewPartnerEmail, newPartnerEmail, toggleInviteNewPartner } =
    contextData;

  const form = useForm<InferType<typeof schema>>({
    initialValues: {
      email: newPartnerEmail || '',
    },
    validate: yupResolver(schema),
  });

  const onBackClick = () => {
    toggleInviteNewPartner();
    onSetNewPartnerEmail('');
    onPrev();
  };

  const onSubmit = ({ email }: typeof form.values) => {
    onSetNewPartnerEmail(email);
    onNext();
  };

  useUpdateEffect(() => {
    if (newPartnerEmail) {
      onNext();
    }
  }, [newPartnerEmail, onNext]);

  return (
    <>
      <StepsHeader
        title="Invite Partner"
        description="Submit an email address of your contact to invite them to join and partner with you."
      />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          autoFocus
          placeholder="Partner's email"
          data-testid="partner-email-input"
          {...form.getInputProps('email')}
        />

        <StepsFooter>
          <Button variant="default" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" data-testid="invite-partner-continue-button">
            Continue
          </Button>
        </StepsFooter>
      </form>
    </>
  );
}
