import { Center, createStyles } from '@mantine/core';
import React from 'react';

import { useStoreListingWizard } from './hooks';
import { StoreListingProductCard } from '../../components/store-listings/StoreListingProductCard';

export function PreviewColumn() {
  const { classes } = useStyles();
  const { contextData } = useStoreListingWizard();

  return (
    <Center className={classes.container}>
      <StoreListingProductCard
        product={contextData.storeListing?.product}
        productImageUrl={contextData.productOverrides?.productImageUrl}
        productName={contextData.productOverrides?.productName}
        productCategory={contextData.productOverrides?.productCategory}
        productSubtitle={contextData.productOverrides?.productSubtitle}
        paymentSettings={contextData.paymentSettings}
        isBasePriceEnabled={contextData.isBasePriceEnabled}
      />
    </Center>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: 40,
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.25),
  },
}));
