import { Input, Stack, Switch, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { DeviceLocationWidgetFormType } from './device-location-form.types';
import { ColorSelector } from '../../common/ColorSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface DeviceLocationWidgetFormProps {
  form: UseFormReturnType<DeviceLocationWidgetFormType>;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  colors: Array<WidgetColorType> | undefined;
}

export function DeviceLocationWidgetForm({
  form,
  onAddCustomColor,
  colors,
}: DeviceLocationWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <Switch
          label="Show Location Name"
          checked={form.values.show_location_name}
          onChange={(event) =>
            form.setFieldValue('show_location_name', event.target.checked)
          }
        />
      </Stack>

      <Input.Wrapper label="Color">
        <ColorSelector
          onAddCustomColor={onAddCustomColor}
          colors={colors}
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
        />
      </Input.Wrapper>
    </Stack>
  );
}
