import { Stack, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ReactComponent as InvitePartnerIcon } from '../../../assets/img/invite-partner.svg';

interface StepsHeaderProps {
  title: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
}

export function StepsHeader({
  title,
  description,
  icon = <InvitePartnerIcon />,
}: StepsHeaderProps) {
  return (
    <Stack align="center" mb="md">
      {icon}

      <Title order={4} weight={400} data-testid="steps-header-title">
        {title}
      </Title>

      {description && (
        <Text size="sm" align="center">
          {description}
        </Text>
      )}
    </Stack>
  );
}
