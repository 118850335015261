import { Button } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import {
  useCheckStoreListingSlugAvailability,
  useCreateStoreListing,
  useStoreSettings,
  useUpdateStoreListing,
} from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { mapPaymentSettingsToStoreListingPrices } from '../../../utils/store-listings.utils';
import { DuplicateSlugModalProps } from '../../DuplicateSlugModal';
import { useStoreListingWizard, useValidateStoreListingSlug } from '../hooks';
import { isProductInformationError } from '../store-listing-wizard.utils';
import { StepId } from '../types';

export function SaveDraftButton() {
  const { contextData, setContextData, skipToStep } = useStoreListingWizard();

  const openModal = useOpenModal();
  const validateStoreListingSlug = useValidateStoreListingSlug();

  const storeSettings = useStoreSettings();
  const createStoreListing = useCreateStoreListing();
  const updateStoreListing = useUpdateStoreListing();

  const isLoading =
    createStoreListing.isLoading || updateStoreListing.isLoading;

  const checkStoreListingSlugAvailability =
    useCheckStoreListingSlugAvailability();

  const validateSlug = async () => {
    const trimmedSlug = contextData.slug?.trim() ?? '';

    if (
      isEmpty(trimmedSlug) ||
      trimmedSlug === contextData.storeListing?.slug
    ) {
      return true;
    }

    if (!validateStoreListingSlug(trimmedSlug)) {
      setContextData((prev) => ({
        ...prev,
        slugError:
          'Permalink is not a valid URL. Spaces and special characters are not allowed.',
      }));

      return false;
    }

    try {
      const foundStoreListing =
        await checkStoreListingSlugAvailability.mutateAsync(trimmedSlug);

      if (foundStoreListing) {
        openModal<DuplicateSlugModalProps['data']>('DuplicateSlugModal', {
          storeListingId: foundStoreListing.id,
          selectedStoreListingSlug: trimmedSlug,
          selectedProductName: contextData.selectedProduct?.name ?? '',
          selectedProductImageUrl: contextData.selectedProduct?.image_url ?? '',
          onChangeSelectedStoreListingSlug: (slug) => {
            setContextData((prev) => ({
              ...prev,
              slug,
            }));
          },
        });

        return false;
      }
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const onClick = async () => {
    if (!contextData.selectedProduct || !storeSettings.data) return;

    const prices = mapPaymentSettingsToStoreListingPrices({
      pricingModel: contextData.selectedProduct.pricing_model,
      currencies: storeSettings.data.currencies,
      paymentSettings: contextData.paymentSettings,
      isBasePriceEnabled: contextData.isBasePriceEnabled,
    });

    try {
      const isSlugValid = await validateSlug();

      if (!isSlugValid || isProductInformationError(contextData)) {
        await skipToStep(StepId.ProductInformation);
        return;
      }

      if (contextData.storeListing) {
        await updateStoreListing.mutateAsync({
          storeListingId: contextData.storeListing.id,
          isStoreListingEditable: contextData.storeListing.editable,
          prices,
          slug: contextData.slug?.trim() ?? null,
          requireCancellationReason: contextData.requireCancellationReason,
          productName:
            contextData.productOverrides?.productName ??
            contextData.selectedProduct.name,
          productCategory:
            contextData.productOverrides?.productCategory ?? null,
          productSubtitle:
            contextData.productOverrides?.productSubtitle ?? null,
          productImageUrl:
            contextData.productOverrides?.productImageUrl ?? null,
          productDescription: contextData.productOverrides?.productDescription,
        });
      } else {
        await createStoreListing.mutateAsync({
          productId: contextData.selectedProduct.id,
          prices,
          slug: contextData.slug?.trim() ?? null,
          requireCancellationReason: contextData.requireCancellationReason,
          productName:
            contextData.productOverrides?.productName ??
            contextData.selectedProduct.name,
          productCategory:
            contextData.productOverrides?.productCategory ?? null,
          productSubtitle:
            contextData.productOverrides?.productSubtitle ?? null,
          productImageUrl:
            contextData.productOverrides?.productImageUrl ?? null,
          productDescription: contextData.productOverrides?.productDescription,
        });
      }

      contextData.closeModal({ confirmBeforeClose: false });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      variant="default"
      loading={isLoading}
      disabled={!contextData.selectedProduct}
      onClick={onClick}
    >
      Save as Draft
    </Button>
  );
}
