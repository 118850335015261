import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Flex,
  Group,
  ScrollArea,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { find } from 'lodash/fp';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useSupportedCommands } from '@portals/api/partners';
import {
  convertWidgetFormToWidgetProps,
  GroupedSwitchesWidget,
  GroupedSwitchesWidgetForm,
  GroupedSwitchesWidgetFormType,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

export function GroupedSwitchesWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  modelId,
  colors,
  onAddCustomColor,
}: CommonWidgetFormWrapperProps<GroupedSwitchesWidgetFormType>) {
  const commands = useSupportedCommands(modelId);

  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();
  const [deviceState, setDeviceState] = useState<Record<string, any>>({});

  const form = useForm<GroupedSwitchesWidgetFormType>({
    initialValues: {
      name: '',
      display_title: false,
      color: 'red.4',
      title_telemetry: '',
      telemetry_as_title: false,
      commands: [
        {
          key: uuid(),
          label: '',
          icon_name: 'battery_full',
          telemetry: '',
          command_name: '',
          command_param_key: '',
        },
      ],

      ...(initialValues || {}),
    },
  });

  const onToggle = (
    commandName: string,
    commandParamKey: string,
    isChecked: boolean
  ) => {
    if (!commandName) return;

    const fieldParams = find(
      { command_name: commandName, command_param_key: commandParamKey },
      form.values.commands
    );

    if (!fieldParams || !fieldParams.telemetry) return;

    setTimeout(() => {
      setDeviceState((curr) => ({
        ...curr,
        [fieldParams.telemetry]: isChecked,
      }));
    }, 1000);
  };

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.config} p={30} pr={0} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9">
            Add Grouped Switches
          </Text>

          <ScrollArea.Autosize
            mah={645}
            sx={{
              '.modal-body': {
                padding: 0,
              },
            }}
          >
            <Space h="xl" />

            <GroupedSwitchesWidgetForm
              form={form}
              commands={commands.data}
              colors={colors}
              onAddCustomColor={onAddCustomColor}
            />

            <Space h="xl" />
          </ScrollArea.Autosize>
        </Stack>

        <Stack className={classes.preview} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Flex align="center" justify="center" h="100%">
            <GroupedSwitchesWidget
              {...convertWidgetFormToWidgetProps.grouped_switches({
                formValues: form.values,
                onToggle,
                deviceState,
              })}
              stackProps={{
                mah: 252,
                maw: '100%',
                w: 'fit-content',
                h: 'fit-content',
              }}
            />
          </Flex>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "config preview"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
    backgroundColor: theme.colors.gray[1],
  },
  config: {
    gridArea: 'config',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
