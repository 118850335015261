import { Radio, RadioProps } from '@mantine/core';
import React from 'react';

interface RadioCardProps extends RadioProps {}

export function RadioCard(props: RadioCardProps) {
  return <Radio styles={radioStyles} {...props} />;
}

const radioStyles: RadioProps['styles'] = (theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    transition: 'box-shadow 200ms ease',

    '&:hover': {
      boxShadow: theme.shadows.sm,
    },

    '&[data-checked]': {
      border: `1px solid ${theme.fn.primaryColor()}`,
    },

    '&:has(input:disabled)': {
      opacity: 0.75,
      backgroundColor: theme.colors.gray[0],

      '&:hover': {
        boxShadow: 'unset',
      },
    },
  },
  inner: {
    display: 'none',
  },
  labelWrapper: {
    width: '100%',
  },
  label: {
    marginBottom: 0,
    width: '100%',
    padding: theme.spacing.xl,
  },
});
