import { Button, Group, Stack } from '@mantine/core';
import React from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';

import RuleLine from './RuleLine';

const RuleArea = ({
  type,
  definition,
  addLine,
  updateLine,
  removeLine,
  readOnly,
}) => (
  <Stack p={0}>
    {definition[type].map((line, index) => (
      <RuleLine
        key={index}
        type={type}
        first={index === 0}
        line={line}
        handleUpdate={(line) => updateLine(type, index, line)}
        removeLine={() => removeLine(type, index)}
        readOnly={readOnly}
      />
    ))}

    <Group>
      <Button
        leftIcon={<Add width={14} height={14} />}
        variant="default"
        data-testid={`add-${type}-rule-button`}
        disabled={readOnly}
        onClick={() => addLine(type)}
      >
        Add Rule
      </Button>
    </Group>
  </Stack>
);

export default RuleArea;
