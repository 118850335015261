import { ActionIcon, Box, Group, Image, Text } from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React, { FC, useCallback } from 'react';
import { Cell } from 'react-table';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import { CURRENCIES, CURRENCIES_FLAGS } from '@portals/countries';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';
import { EditableNumberCell } from '@portals/table';
import { RowType, CurrencyCode } from '@portals/types';
import { convertFromMinorToMajor, formatCurrency } from '@portals/utils';

import { usePricingTableContext } from './context';

interface HeaderFormatterProps {
  currency: CurrencyCode;
}

export function HeaderFormatter({ currency }: HeaderFormatterProps) {
  const storeSettings = useStoreSettings();

  return (
    <Group>
      <Box
        sx={(theme) => ({
          borderRadius: '2.5px',
          overflow: 'hidden',
          width: 30,
          height: 20,
          border: `1px solid ${theme.colors.gray[3]}`,
        })}
      >
        <Image
          src={CURRENCIES_FLAGS[currency]}
          sx={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Group spacing={4} align="center">
        <Text className="currency-name" size="sm" weight={500}>
          {CURRENCIES[currency]}
        </Text>

        {storeSettings.data?.default_currency === currency ? (
          <Text color="dimmed" size="xs" weight={300}>
            (Default)
          </Text>
        ) : null}
      </Group>
    </Group>
  );
}

interface ProductNameFormatterProps {
  product: StoreListing['product'];
  cell: Cell<StoreListing>;
}

export const ProductNameFormatter: FC<ProductNameFormatterProps> = ({
  product,
  cell,
}) => {
  const pricingTable = usePricingTableContext();
  const row = cell.row as RowType<StoreListing>;
  const isExpandable =
    pricingTable.data[row.original.id].numOfAssignedPrices > 1;
  const isExpanded = isExpandable && row.isExpanded;

  return (
    <Group position="apart">
      <Text
        size="sm"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {product.name}
      </Text>

      {isExpandable ? (
        <ActionIcon
          sx={(theme) => ({
            svg: {
              transition: 'transform 0.15s ease-in-out',
              transform: `rotate(${isExpanded ? 180 : 0}deg)`,
              height: 15,
              color: theme.colors.blue_gray[7],
            },
          })}
        >
          <ArrowDown />
        </ActionIcon>
      ) : null}
    </Group>
  );
};

interface SinglePriceFormatterProps {
  cell: Cell<StoreListing>;
  currency: CurrencyCode;
}

export const AssignedSinglePriceFormatter: FC<SinglePriceFormatterProps> = ({
  cell,
  currency,
}) => {
  const pricingTable = usePricingTableContext();
  const storeListingId = cell.row.original.id;
  const rowMeta = pricingTable.data[storeListingId];

  // If store listing has more than 1 price assigned (monthly & yearly, for example), their
  // prices will be rendered inside the expanded rows renderer, thus leaving the main cells empty
  if (rowMeta.numOfAssignedPrices > 1) return null;

  const price = rowMeta.pricesByCurrencies[currency][0].amount_in_scu;

  return <>{formatCurrency(price, currency)}</>;
};

export const EditableSinglePriceFormatter: FC<SinglePriceFormatterProps> = ({
  cell,
  currency,
}) => {
  const pricingTable = usePricingTableContext();
  const storeListingId = cell.row.original.id;
  const rowMeta = pricingTable.data[storeListingId];
  const amountInScu = rowMeta.pricesByCurrencies[currency][0].amount_in_scu;
  const amount = isNumber(amountInScu)
    ? convertFromMinorToMajor(amountInScu, currency)
    : null;
  const storeListingKey = rowMeta.pricesByCurrencies[currency][0].key;

  const onChange = useCallback(
    (amount) => {
      pricingTable.onUpdate({
        storeListingId,
        currency,
        storeListingKey,
        amount,
      });
    },
    [currency, pricingTable, storeListingId, storeListingKey]
  );

  // If store listing has more than 1 price assigned (monthly & yearly, for example), their
  // prices will be rendered inside the expanded rows renderer, thus leaving the main cells empty
  if (rowMeta.numOfAssignedPrices > 1) return null;

  return (
    <EditableNumberCell
      value={amount || null}
      formattedValue={amountInScu ? formatCurrency(amountInScu, currency) : ''}
      onChange={onChange}
      precision={2}
      step={0.01}
      min={0}
    />
  );
};
