import { Radio, RadioGroupProps, Stack } from '@mantine/core';
import React from 'react';

interface RadioCardGroupProps extends RadioGroupProps {}

export function RadioCardGroup({
  children,
  ...radioGroupProps
}: RadioCardGroupProps) {
  return (
    <Radio.Group
      styles={(theme) => ({ error: { marginTop: theme.spacing.xs } })}
      {...radioGroupProps}
    >
      <Stack spacing="md">{children}</Stack>
    </Radio.Group>
  );
}
