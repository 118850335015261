import {
  Anchor,
  Button,
  Checkbox,
  createStyles,
  Group,
  HoverCard,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import {
  useCreateDeviceModelWidgetsSection,
  useUpdateDeviceModelWidgetsSection,
} from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

export const defaultCreateSectionInitialValues = {
  name: '',
  path: '',
  isLabOnly: false,
};

enum SectionConditionType {
  AllDevices = 'all_devices',
  Condition = 'condition',
}

export interface CreateOrEditDeviceWidgetsSectionModalProps
  extends ModalProps<{
    modelId: string;
    sectionId?: string;
    initialValues?: typeof defaultCreateSectionInitialValues;
  }> {}

export function CreateOrEditDeviceWidgetsSectionModal({
  closeMe,
  data,
}: CreateOrEditDeviceWidgetsSectionModalProps) {
  const { modelId, sectionId, initialValues } = data;

  const [selectedSectionConditionType, setSelectedSectionConditionType] =
    useState<SectionConditionType>(
      initialValues?.path
        ? SectionConditionType.Condition
        : SectionConditionType.AllDevices
    );

  const { classes, theme } = useStyles();

  const form = useForm({
    initialValues: initialValues || defaultCreateSectionInitialValues,
  });

  const createDeviceModelWidgetsSection = useCreateDeviceModelWidgetsSection({
    modelId,
    successMessage: 'Section created successfully',
  });

  const updateDeviceModelWidgetsSection = useUpdateDeviceModelWidgetsSection({
    modelId,
    sectionId,
  });

  const handleSubmit = ({ name, path, isLabOnly }: typeof form.values) => {
    const conditionPath =
      selectedSectionConditionType === SectionConditionType.Condition
        ? path
        : undefined;

    if (sectionId) {
      updateDeviceModelWidgetsSection.mutate(
        {
          name,
          condition: {
            path: conditionPath,
            isLabOnly,
          },
        },
        { onSuccess: closeMe }
      );
      return;
    }

    createDeviceModelWidgetsSection.mutate(
      {
        name,
        condition: { path: conditionPath, isLabOnly },
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      size={645}
      padding="xxl"
      title="Add new section"
    >
      <Text color="primary" my="md">
        Sections are used to group widgets, and can be set to appear under
        certain conditions. For example, you can create a section only visible
        to devices with a certain feature or command.
      </Text>

      <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
        <TextInput
          label="Section name"
          placeholder="Enter section name"
          required
          {...form.getInputProps('name')}
        />

        <Stack spacing="xs">
          <Group spacing={4}>
            <Text color="gray.6">Condition type</Text>

            <HoverCard
              width={300}
              shadow="md"
              position="top"
              withArrow
              offset={{
                crossAxis: 48,
                mainAxis: 6,
              }}
            >
              <HoverCard.Target>
                <QuestionCircle
                  width={16}
                  height={16}
                  color={theme.colors.gray[6]}
                />
              </HoverCard.Target>

              <HoverCard.Dropdown>
                Sections can be visible to all devices, or limited to devices
                with certain properties based on their configuration or details.
                To learn more about configuration or details, click{' '}
                <Anchor
                  href="https://dev.xyte.io/reference/data-types"
                  target="_blank"
                >
                  here
                </Anchor>
                .
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Stack>
            <SegmentedControl
              value={selectedSectionConditionType}
              onChange={(value) => {
                setSelectedSectionConditionType(value as SectionConditionType);
              }}
              data={[
                {
                  label: 'All devices',
                  value: SectionConditionType.AllDevices,
                },
                {
                  label: 'Configuration or Details',
                  value: SectionConditionType.Condition,
                },
              ]}
              w={340}
            />

            {selectedSectionConditionType === 'condition' && (
              <TextInput
                label="Configuration path"
                required
                {...form.getInputProps('path')}
              />
            )}
          </Stack>
        </Stack>

        <Checkbox
          label={
            <Group spacing={4}>
              <Text>Make this section seen only for lab accounts</Text>
              <Tooltip
                width={300}
                multiline
                withArrow
                label="Limit sections to Lab accounts, so you can test widgets on a published model before making it visible for all devices."
              >
                <QuestionCircle
                  width={16}
                  height={16}
                  color={theme.colors.gray[6]}
                />
              </Tooltip>
            </Group>
          }
          {...form.getInputProps('isLabOnly', { type: 'checkbox' })}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button type="submit">
            {sectionId ? 'Edit section' : 'Add section'}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },
}));
