import { DesignType } from '@portals/types';

export interface PortalConfigEmailSettingsType {
  custom_from_email?: boolean;

  primary_color?: string;

  site_url?: string;
  logo?: string;

  facebook_is_active?: boolean;
  facebook_href?: string;
  twitter_is_active?: boolean;
  twitter_href?: string;
  instagram_is_active?: boolean;
  instagram_href?: string;
  linkedin_is_active?: boolean;
  linkedin_href?: string;

  markdown?: string;
}

export interface PortalConfigType {
  hero: string | null;
  logo: string | null;
  favicon: string | null;
  files: boolean;
  users: boolean;
  design: DesignType;
  signup: boolean;
  reports: boolean;
  support: boolean;
  licenses: boolean;
  incidents: boolean;
  background: string | null;
  sample_data: boolean;
  admin_access: boolean;
  integrations: {
    email: true;
    slack: true;
    msteams: true;
    web_hook: true;
    cisco_teams: true;
  };
  partner_only: boolean;
  control_center: boolean;
  asset_management: boolean;
  digital_products: boolean;
  whitelist_devices: boolean;
  managed_service_providers: boolean;
  domain?: string;
  candidate_domain?: string;
  window_title?: string;
  name?: string;
  email_settings?: PortalConfigEmailSettingsType;
}

export enum DomainChangeType {
  OnXyte = 'onXyte',
  Custom = 'custom',
}
