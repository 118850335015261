import {
  PartnerStoreSettingsType,
  StoreListing,
  StoreListingPrice,
  StoreListingPricePeriod,
} from '@portals/api/partners';
import {
  CurrencyCode,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';

import {
  PaymentSettingItemPriceType,
  PaymentSettingsType,
} from '../types/store-listings.types';

interface MapStoreListingPricesToPaymentSettingsParams {
  defaultCurrency: PartnerStoreSettingsType['default_currency'] | undefined;
  currencies: PartnerStoreSettingsType['currencies'] | undefined;
  storeListingPrices: StoreListing['prices'] | undefined;
}
export function mapStoreListingPricesToPaymentSettings({
  defaultCurrency,
  currencies,
  storeListingPrices,
}: MapStoreListingPricesToPaymentSettingsParams) {
  if (!defaultCurrency || !currencies || !storeListingPrices) {
    return {};
  }

  const paymentSettings: Partial<PaymentSettingsType> = {};

  const oneTimePrices = getPrices({
    pricePeriod: 'one_time_price_in_scu',
    prices: storeListingPrices,
    currencies,
    defaultCurrency,
  });
  const monthlyPrices = getPrices({
    pricePeriod: 'monthly_price_in_scu',
    prices: storeListingPrices,
    currencies,
    defaultCurrency,
  });
  const yearlyPrices = getPrices({
    pricePeriod: 'yearly_price_in_scu',
    prices: storeListingPrices,
    currencies,
    defaultCurrency,
  });
  const usageBasedPrices = getPrices({
    pricePeriod: 'usage_based_price_in_scu',
    prices: storeListingPrices,
    currencies,
    defaultCurrency,
  });

  if (oneTimePrices) {
    paymentSettings.one_time = {
      period: PaymentIntervalEnum.OneTime,
      prices: oneTimePrices,
    };
  }
  if (monthlyPrices) {
    paymentSettings.monthly = {
      period: PaymentIntervalEnum.Monthly,
      prices: monthlyPrices,
    };
  }
  if (yearlyPrices) {
    paymentSettings.yearly = {
      period: PaymentIntervalEnum.Yearly,
      prices: yearlyPrices,
    };
  }
  if (usageBasedPrices) {
    paymentSettings.usage_based = {
      period: PaymentIntervalEnum.UsageBased,
      prices: usageBasedPrices,
    };
  }

  return paymentSettings;
}

interface GetPricesParams {
  pricePeriod: StoreListingPricePeriod;
  prices: StoreListing['prices'];
  currencies: PartnerStoreSettingsType['currencies'];
  defaultCurrency: PartnerStoreSettingsType['default_currency'];
}
function getPrices({
  pricePeriod,
  prices,
  defaultCurrency,
  currencies,
}: GetPricesParams): Array<PaymentSettingItemPriceType> | undefined {
  const currencyToValueMap: Partial<
    Record<CurrencyCode, PaymentSettingItemPriceType['amountInScu']>
  > = {};

  prices.forEach((price) => {
    currencyToValueMap[price.currency] = price[pricePeriod];
  });

  if (Object.values(currencyToValueMap).every((value) => value === null)) {
    return undefined;
  }

  return currencies
    .map((currency) => ({
      currency,
      error: false,
      amountInScu: currencyToValueMap[currency] ?? null,
    }))
    .sort((a) => (a.currency === defaultCurrency ? -1 : 1));
}

interface MapPaymentSettingsToStoreListingPricesParams {
  pricingModel: StoreListing['product']['pricing_model'];
  currencies: PartnerStoreSettingsType['currencies'];
  paymentSettings: Partial<PaymentSettingsType>;
  isBasePriceEnabled: boolean;
}
export function mapPaymentSettingsToStoreListingPrices({
  pricingModel,
  currencies,
  paymentSettings,
  isBasePriceEnabled,
}: MapPaymentSettingsToStoreListingPricesParams) {
  if (pricingModel === ProductPricingModel.Personalized) {
    return [];
  }

  const prices: StoreListingPrice[] = currencies.map((currency) => {
    const oneTimePrice = getPaymentAmountInSCU(
      paymentSettings,
      PaymentIntervalEnum.OneTime,
      currency
    );

    const yearlyPrice = getPaymentAmountInSCU(
      paymentSettings,
      PaymentIntervalEnum.Yearly,
      currency
    );

    const usageBasedPrice = getPaymentAmountInSCU(
      paymentSettings,
      PaymentIntervalEnum.UsageBased,
      currency
    );

    let monthlyPrice = getPaymentAmountInSCU(
      paymentSettings,
      PaymentIntervalEnum.Monthly,
      currency
    );

    // For usage based products, if the base price is not enabled, we need to clear the monthly price
    if (
      pricingModel === ProductPricingModel.UsageBased &&
      !isBasePriceEnabled
    ) {
      monthlyPrice = null;
    }

    return {
      currency,
      one_time_price_in_scu: oneTimePrice,
      monthly_price_in_scu: monthlyPrice,
      yearly_price_in_scu: yearlyPrice,
      usage_based_price_in_scu: usageBasedPrice,
    };
  });

  return prices;
}

function getPaymentAmountInSCU(
  paymentSettings: Partial<PaymentSettingsType>,
  paymentInterval: PaymentIntervalEnum,
  currencyCode: CurrencyCode
) {
  const paymentSettingsByInterval = paymentSettings[paymentInterval];

  if (!paymentSettingsByInterval) return null;

  const matchingPrice = paymentSettingsByInterval.prices.find(
    (price) => price.currency === currencyCode
  );

  if (!matchingPrice) return null;

  const amountInScu = matchingPrice.amountInScu;

  return amountInScu ?? null;
}
