import {
  RingProgress,
  Text,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

interface LimitedTextInputProps extends TextInputProps {
  value: string;
  maxLength: number;
}

export function LimitedTextInput({
  value,
  maxLength,
  ...textInputProps
}: LimitedTextInputProps) {
  const theme = useMantineTheme();

  const valueLength = value.length ?? 0;
  const valueRemainingLength = maxLength - valueLength;
  const valueLengthPercentage = (valueLength / maxLength) * 100;

  return (
    <TextInput
      value={value}
      maxLength={maxLength}
      rightSection={
        <RingProgress
          size={32}
          rootColor={theme.colors.gray[2]}
          thickness={2}
          sections={[
            {
              value: valueLengthPercentage,
              color: 'primary',
            },
          ]}
          label={
            <Text size="xs" ta="center">
              {valueRemainingLength}
            </Text>
          }
        />
      }
      {...textInputProps}
    />
  );
}
