import { Box, Center, createStyles, Stack } from '@mantine/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { PoweredByXyteIcon } from '@portals/assets';

export function AuthLayout() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Stack align="center" justify="space-between" p="xxl" bg="primary">
        <img src="logo.png" alt="xyte logo" className={classes.logo} />

        <img
          src="logo_full_white.svg"
          alt="xyte logo"
          className={classes.fullLogo}
        />

        <PoweredByXyteIcon />
      </Stack>

      <Center p="xxl">
        <Box maw={470}>
          <Outlet />
        </Box>
      </Center>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '100%',
    backgroundColor: theme.white,

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  logo: {
    alignSelf: 'flex-start',
    width: 50,
    height: 50,
  },
  fullLogo: {
    maxWidth: 305,
  },
}));
