import React, { useRef } from 'react';

import { usePricingTableContext } from './context';
import { EXPAND_ROW_HEIGHT, ExpandRowRenderer } from './ExpandRow';

export const useExpandRowConfig = () => {
  const pricingTable = usePricingTableContext();

  const expandRowConfig = useRef({
    renderer: (data, row) => <ExpandRowRenderer row={row} />,
    showExpandColumn: false,
    height: (row) => {
      // Returns height of expand row renderer based on num of assigned prices.
      // Each row is {EXPAND_ROW_HEIGHT}px
      if (pricingTable.data[row.original.id]?.numOfAssignedPrices <= 1) {
        return 0;
      }

      return (
        pricingTable.data[row.original.id]?.numOfAssignedPrices *
        EXPAND_ROW_HEIGHT
      );
    },
    expandHeaderColumnRenderer: () => null,
    // expandRow will be disabled for a specific row, if it has only one price assigned
    isEnabled: (row) =>
      pricingTable.data[row.original.id]?.numOfAssignedPrices > 1,
  });

  return expandRowConfig.current;
};
