import { getOr } from 'lodash/fp';
import * as Yup from 'yup';

export const PREDICATE_MAP = {
  boolean: [
    { id: 'equal', label: 'equals to' },
    { id: 'not_equal', label: 'not equals to' },
  ],
  string: [
    { id: 'equal', label: 'equals to' },
    { id: 'not_equal', label: 'not equals to' },
  ],
  number: [
    { id: 'equal', label: 'equals to' },
    { id: 'not_equal', label: 'not equals to' },
    { id: 'larger', label: 'larger than' },
    { id: 'larger_eq', label: 'larger or equal to' },
    { id: 'smaller', label: 'smaller than' },
    { id: 'smaller_eq', label: 'smaller or equal' },
    { id: 'between', label: 'between', double: true },
    { id: 'between_eq', label: 'between or equal to', double: true },
  ],
};

export const isDoubleValue = (predicate: string): boolean => {
  if (!predicate) {
    return false;
  }

  return getOr(
    false,
    'double',
    PREDICATE_MAP['number'].find((p) => p.id === predicate)
  );
};

const ruleValidator = Yup.object().shape({
  key: Yup.string().required(),
  type: Yup.mixed().oneOf(['string', 'number', 'boolean']).required(),
  predicate: Yup.string().required(),
  value: Yup.mixed()
    .required()
    .when('type', { is: 'string', then: Yup.string() })
    .when('type', { is: 'boolean', then: Yup.boolean() })
    .when('type', {
      is: 'number',
      then: Yup.number().when('predicate', (predicate, schema) => {
        return isDoubleValue(predicate)
          ? schema.transform((value) =>
              isNaN(value) ? undefined : Number(value)
            )
          : schema;
      }),
    }),
  value2: Yup.mixed().when('predicate', {
    is: (predicate) => isDoubleValue(predicate),
    then: Yup.number().required().moreThan(Yup.ref('value')),
  }),
});

const ruleListValidator = Yup.array().of(ruleValidator);

export const ruleSetValidator = Yup.object().shape({
  any: ruleListValidator,
  all: ruleListValidator,
});
