import { Box, createStyles, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface ProductTypeCardProps {
  title: ReactNode;
  description: string;
  icon: ReactNode;
  disabled: boolean;
  onClick: () => void;
}

export function ProductTypeCard({
  title,
  description,
  icon,
  disabled,
  onClick,
}: ProductTypeCardProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack className={cx(classes.container, { disabled })} onClick={onClick}>
      {disabled && (
        <Box className={classes.disabledLabel}>
          <Text weight={500} color="gray.7">
            Available soon
          </Text>
        </Box>
      )}

      {icon}

      <Stack spacing="xs" mb="sm">
        <Text
          data-testid={`product-type-card-title-${title}`}
          weight={600}
          align="center"
          className={classes.title}
        >
          {title}
        </Text>

        <Text color="gray.7" align="center" className={classes.description}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.spacing.xxl,
    border: `2px solid ${theme.colors.blue_gray[0]}`,
    borderRadius: theme.radius.lg,
    transition: 'all 150ms linear',
    userSelect: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative',
    [theme.fn.smallerThan(1900)]: {
      maxHeight: 280,
    },

    [theme.fn.smallerThan(1700)]: {
      maxHeight: 250,
    },

    [theme.fn.smallerThan(1520)]: {
      maxHeight: 230,
    },

    ':hover': {
      borderColor: theme.colors.blue_gray[1],
      boxShadow: '0px 11px 18px rgba(38, 50, 56, 0.1)',
    },

    '&.disabled': {
      pointerEvents: 'none',

      svg: {
        mixBlendMode: 'luminosity',
      },
    },
  },

  disabledLabel: {
    position: 'absolute',
    top: 0,
    left: 32,
    backgroundColor: theme.colors.gray[2],
    paddingInline: theme.spacing.sm,
    paddingBlock: theme.spacing.xs,
    borderRadius: '0 0 12px 12px',
  },

  title: {
    fontSize: 24,

    [theme.fn.smallerThan(1900)]: {
      fontSize: theme.fontSizes.xl,
    },

    [theme.fn.smallerThan(1600)]: {
      fontSize: theme.fontSizes.lg,
    },

    [theme.fn.smallerThan(1400)]: {
      fontSize: theme.fontSizes.md,
    },
  },

  description: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan(1600)]: {
      fontSize: theme.fontSizes.sm,
    },

    [theme.fn.smallerThan(1400)]: {
      fontSize: theme.fontSizes.xs,
    },
  },
}));
