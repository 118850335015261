import {
  Box,
  createStyles,
  Group,
  SegmentedControl,
  SegmentedControlProps,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';

import {
  AccessLevelEnum,
  UserPermissionNames,
  UserPermissions,
} from '@portals/types';

const PERMISSIONS_MAP: Array<{
  permissionKey: UserPermissionNames;
  displayName: string;
}> = [
  { permissionKey: 'customers', displayName: 'Customers' },
  { permissionKey: 'finance', displayName: 'Finance' },
  { permissionKey: 'fulfillment', displayName: 'Fulfillment' },
  { permissionKey: 'models', displayName: 'Models' },
  { permissionKey: 'store_management', displayName: 'Store management' },
];

const segmentedControlData: SegmentedControlProps['data'] = [
  {
    label: 'Restricted',
    value: String(AccessLevelEnum.None),
  },
  {
    label: 'View Only',
    value: String(AccessLevelEnum.View),
  },
  {
    label: 'Edit',
    value: String(AccessLevelEnum.Edit),
  },
];

interface PermissionsEditorProps {
  permissions: UserPermissions;
  onChange: (permissions: UserPermissions) => void;
  disabled: boolean;
}

export function PermissionsEditor({
  permissions,
  onChange,
  disabled,
}: PermissionsEditorProps) {
  const { classes } = useStyles();

  return (
    <Box c="gray.8">
      <Title order={6} weight={500} mb="xs">
        Permissions
      </Title>

      <div>
        {PERMISSIONS_MAP.map(({ permissionKey, displayName }) => {
          return (
            <Group
              key={permissionKey}
              position="apart"
              className={classes.listItem}
              grow
            >
              <Text>{displayName}</Text>

              <SegmentedControl
                disabled={disabled}
                data-testid={`permissions-${permissionKey}`}
                data={segmentedControlData}
                value={String(permissions[permissionKey])}
                onChange={(value) =>
                  onChange({ ...permissions, [permissionKey]: Number(value) })
                }
              />
            </Group>
          );
        })}
      </div>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  listItem: {
    paddingBlock: theme.spacing.md,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
