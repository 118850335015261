import { ActionIcon, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { FileTypeIcon } from '@portals/core';
import { UploadFileProps } from '@portals/framework/modals';
import { ReactComponent as Export4 } from '@portals/icons/linear/export-4.svg';
import { useOpenModal } from '@portals/redux';
import { downloadFile } from '@portals/utils';

interface AttachmentsProps {
  attachmentUrl: string | undefined;
  handelUploadAttachmentSuccess: (attachmentUrl: string) => void;
}

export function Attachments({
  attachmentUrl,
  handelUploadAttachmentSuccess,
}: AttachmentsProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  return (
    <Stack spacing="xs" p="xl" align="start">
      <Group position="apart" w="100%">
        <Text size="md" fw={500}>
          Attachment
        </Text>

        <ActionIcon
          onClick={() =>
            openModal<UploadFileProps['data']>('UploadFile', {
              title: 'Attachment',
              onSuccess: (attachmentUrls) =>
                handelUploadAttachmentSuccess(attachmentUrls[0].fileUrl),
              dropzoneProps: {
                sx: { height: 350 },
                maxSize: 20 * 1024 * 1024,
              },
            })
          }
        >
          <Export4 width={16} height={16} />
        </ActionIcon>
      </Group>

      {attachmentUrl ? (
        <Stack
          align="center"
          spacing="sm"
          className={classes.attachment}
          onClick={() => downloadFile(attachmentUrl, 'order_attachment')}
        >
          <FileTypeIcon fileUrl={attachmentUrl} />

          <Text className={classes.fileLabel}>order_attachment</Text>
        </Stack>
      ) : (
        <Text color="gray.5">No attachment</Text>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  attachment: {
    cursor: 'pointer',
  },
  fileLabel: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
  },
}));
