import { Anchor, HoverCard, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

export function DeviceEventsInfoHoverCard() {
  const theme = useMantineTheme();

  return (
    <HoverCard position="right" withArrow withinPortal width={340} shadow="md">
      <HoverCard.Target>
        <QuestionCircle color={theme.colors.gray[8]} />
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Stack spacing="xs">
          <Text size="sm" color="blue_gray.9" weight="bold">
            Device events
          </Text>

          <Text color="blue_gray.7" size="xs">
            Device Events allows devices to notify the server of low-recurring
            important events that might help the end Customers better operate
            and trouble-shoot their devices.
          </Text>

          <Anchor
            size="xs"
            color="blue_accent.4"
            href="https://dev.xyte.io/reference/device-events"
            target="_blank"
          >
            <Text inherit size="xs">
              Learn more
            </Text>
          </Anchor>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
