import { Input, SegmentedControl, Stack, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { DynamicMeasurements } from './DynamicMeasurements';
import { SplineChartWidgetFormType } from './spline-chart-form.types';
import { StaticMeasurements } from './StaticMeasurements';
import { NumberFormatSelector } from '../../common/NumberFormatSelector';
import {
  MeasurementFormType,
  OnAddCustomColorFn,
  WidgetColorType,
} from '../../widgets.types';

export interface SplineChartWidgetFormProps {
  form: UseFormReturnType<SplineChartWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function SplineChartWidgetForm({
  form,
  colors,
  onAddCustomColor,
}: SplineChartWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-testid="spline-chart-title-name-input"
          data-autofocus
          label="Title"
          required
        />

        <Input.Wrapper>
          <Stack spacing={0}>
            <Input.Label>Scale Type</Input.Label>

            <SegmentedControl
              data={[
                {
                  label: 'Linear',
                  value: 'linear',
                },
                {
                  label: 'Logarithmic',
                  value: 'log',
                },
              ]}
              value={form.values.scale_type}
              onChange={(value: 'linear' | 'log') =>
                form.setFieldValue('scale_type', value)
              }
            />
          </Stack>
        </Input.Wrapper>

        <NumberFormatSelector
          format={form.values.number_format}
          numOfDecimals={form.values.num_of_decimals}
          onFormatChange={(format) =>
            form.setFieldValue('number_format', format)
          }
          onNumOfDecimalsChange={(numOfDecimals) =>
            form.setFieldValue('num_of_decimals', numOfDecimals)
          }
        />
      </Stack>

      <Stack>
        <Text size="sm" color="gray.9">
          Measurements
        </Text>

        <SegmentedControl
          data={[
            {
              label: 'Static',
              value: 'static',
            },
            {
              label: 'Dynamic',
              value: 'dynamic',
            },
          ]}
          value={form.values.measurement_type}
          onChange={(value: MeasurementFormType) =>
            form.setFieldValue('measurement_type', value)
          }
        />
      </Stack>

      {form.values.measurement_type === 'static' ? (
        <StaticMeasurements
          form={form}
          colors={colors}
          onAddCustomColor={onAddCustomColor}
        />
      ) : (
        <DynamicMeasurements form={form} />
      )}
    </Stack>
  );
}
