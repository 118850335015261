import {
  Accordion,
  AccordionProps,
  Box,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  flow,
  isEmpty,
  keys,
  omit,
  set,
  size,
  values,
  without,
} from 'lodash/fp';
import React, { useEffect } from 'react';

import { useStoreSettings } from '@portals/api/partners';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { PaymentIntervalEnum } from '@portals/types';

import { AddPriceMenuButton } from './AddPriceMenuButton';
import { PaymentSettingPrices } from './PaymentSettingPrices';
import { PaymentSettingsGeneralError } from './PaymentSettingsGeneralError';
import { PriceRestrictionAlert } from './PriceRestrictionAlert';
import { useStoreListingPeriodPriceTitle } from '../../../hooks/store-listings.hooks';
import { PaymentSettingItemPriceType } from '../../../types/store-listings.types';
import { useStoreListingWizard } from '../hooks';
import { StoreListingWizardContext } from '../types';

export function StandardPaymentSettings() {
  const theme = useMantineTheme();

  const { contextData, setContextData } = useStoreListingWizard();

  const storeSettings = useStoreSettings();

  const calcIsAllowedToRemovePaymentPeriod = () => {
    const { storeListing } = contextData;

    // Create mode, we can remove any period
    if (!storeListing) return true;

    // Store listing is editable, we need to validate some conditions
    if (storeListing.editable) {
      // Store listing is not published, we can remove any period
      if (storeListing.status !== 'published') {
        return true;
      }

      // Store listing is published, we can remove any period if there is more than one
      if (storeListing.status === 'published') {
        return size(contextData.paymentSettings) > 1;
      }
    }

    // Store listing is not editable, we can't remove any period
    return false;
  };

  const { getPriceTitle } = useStoreListingPeriodPriceTitle();

  const onChangeAccordion = (values: PaymentIntervalEnum[]) => {
    setContextData((prev) =>
      set(['paymentSettingsAccordionValues'], values, prev)
    );
  };

  const onAddPaymentPeriod = (period: PaymentIntervalEnum) => {
    const prices: PaymentSettingItemPriceType[] = (
      storeSettings.data?.currencies ?? []
    ).map((currency) => ({
      currency,
      amountInScu: null,
      error: false,
    }));

    setContextData((prev) =>
      flow([
        set(['paymentSettings', period, 'prices'], prices),
        set('paymentSettingsAccordionValues', [period]),
        set('paymentSettingsGeneralError', undefined),
      ])(prev)
    );
  };

  const onRemovePaymentPeriod = (period: PaymentIntervalEnum) => {
    const updatedPaymentSettings: StoreListingWizardContext['paymentSettings'] =
      omit([period], contextData.paymentSettings);

    // If there is no recurring payment, we need to "falsify" the `requireCancellationReason` flag
    const updatedRequireCancellationReason =
      contextData.requireCancellationReason &&
      (!isEmpty(updatedPaymentSettings.monthly) ||
        !isEmpty(updatedPaymentSettings.yearly));

    setContextData((prev) =>
      flow([
        set('paymentSettings', updatedPaymentSettings),
        set('requireCancellationReason', updatedRequireCancellationReason),
        set(
          'paymentSettingsAccordionValues',
          without([period], prev.paymentSettingsAccordionValues)
        ),
      ])(prev)
    );
  };

  useEffect(
    function updatePaymentSettingsGeneralError() {
      if (isEmpty(contextData.paymentSettings)) return;

      const hasErrors = values(contextData.paymentSettings).some(
        (paymentInterval) => paymentInterval.prices.some((price) => price.error)
      );

      setContextData((prev) =>
        set(
          ['paymentSettingsGeneralError'],
          hasErrors ? 'Please fill in all prices' : undefined,
          prev
        )
      );
    },
    [contextData.paymentSettings, setContextData]
  );

  if (storeSettings.isLoading || !storeSettings.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack spacing={32} h="100%">
      {contextData.storeListing?.editable === false && (
        <PriceRestrictionAlert />
      )}

      <PaymentSettingsGeneralError />

      {size(contextData.paymentSettings) > 0 && (
        <VerticalScrollBar
          renderView={(props) => (
            <Box
              sx={{ zIndex: 0, borderTop: `1px solid ${theme.colors.gray[3]}` }}
              {...props}
            />
          )}
        >
          <Accordion<true>
            multiple
            styles={accordionStyles}
            value={contextData.paymentSettingsAccordionValues}
            onChange={(values: PaymentIntervalEnum[]) =>
              onChangeAccordion(values)
            }
          >
            {(keys(contextData.paymentSettings) as PaymentIntervalEnum[]).map(
              (period) => {
                const hasErrors = contextData.paymentSettings[
                  period
                ]?.prices.some((price) => price.error);

                return (
                  <Accordion.Item value={period} key={period}>
                    <Accordion.Control>
                      <Group position="apart">
                        <Text data-testid={`price-row-title-${period}`}>
                          {getPriceTitle({
                            period,
                            product: contextData.storeListing?.product,
                            paymentSettings: contextData.paymentSettings,
                          })}
                        </Text>

                        <Group>
                          {hasErrors ? (
                            <Danger
                              width={20}
                              height={20}
                              color={theme.colors.red[4]}
                            />
                          ) : null}

                          {calcIsAllowedToRemovePaymentPeriod() ? (
                            <Trash
                              width={20}
                              height={20}
                              color={theme.colors.gray[4]}
                              onClick={() => onRemovePaymentPeriod(period)}
                            />
                          ) : null}
                        </Group>
                      </Group>
                    </Accordion.Control>

                    <Accordion.Panel>
                      <PaymentSettingPrices
                        period={period}
                        defaultCurrency={storeSettings.data.default_currency}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                );
              }
            )}
          </Accordion>
        </VerticalScrollBar>
      )}

      <AddPriceMenuButton onAddPaymentPeriod={onAddPaymentPeriod} />
    </Stack>
  );
}

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  panel: {
    width: `clamp(400px, 500px, 100%)`,
    position: 'relative',
  },
  control: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.white,
  },
});
