import { Badge, BadgeProps, Box, Group } from '@mantine/core';
import React from 'react';

import { GroupIconNames, GroupId, GroupNameAndIcon } from '@portals/framework';
import { UserPermissions } from '@portals/types';

import { PermissionsEditor } from './PermissionsEditor';

interface GroupDetailsFormProps {
  groupName: string;
  groupId: string | undefined;
  setGroupName: (groupName: string) => void;
  isGroupNameError: boolean;
  iconName: GroupIconNames;
  setIconName: (iconName: GroupIconNames) => void;
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
  isNameEditable: boolean;
  isPermissionsEditable: boolean;
}

export function GroupDetailsForm({
  groupName,
  groupId,
  setGroupName,
  iconName,
  setIconName,
  permissions,
  setPermissions,
  isGroupNameError,
  isNameEditable,
  isPermissionsEditable,
}: GroupDetailsFormProps) {
  return (
    <>
      <Group mb="xl" align="flex-end" spacing="xl">
        <GroupNameAndIcon
          groupName={groupName}
          onChangeGroupName={setGroupName}
          iconName={iconName}
          onChangeIconName={setIconName}
          error={isGroupNameError}
          disabled={!isNameEditable}
        />

        {!isNameEditable ? (
          <Badge color="teal" size="lg" styles={badgeStyles}>
            Account Admin
          </Badge>
        ) : null}
        {groupId ? (
          <Box ml="auto">
            <GroupId groupId={groupId} />
          </Box>
        ) : null}
      </Group>

      <PermissionsEditor
        permissions={permissions}
        onChange={setPermissions}
        disabled={!isPermissionsEditable}
      />
    </>
  );
}

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  inner: {
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[9],
  },
});
