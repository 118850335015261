export const ANALYTICS_DASHBOARD_BASE_API_URL = 'ui/partner/analytics';
export const ANALYTICS_DASHBOARD_STATS_API_URL = `${ANALYTICS_DASHBOARD_BASE_API_URL}/stats`;
export const ANALYTICS_DASHBOARD_CUSTOMERS_API_URL = `${ANALYTICS_DASHBOARD_BASE_API_URL}/customers`;
export const ANALYTICS_DASHBOARD_CUSTOMERS_CREATION_RATE_API_URL = `${ANALYTICS_DASHBOARD_BASE_API_URL}/customer_creation_rate`;
export const ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL = `${ANALYTICS_DASHBOARD_BASE_API_URL}/device_claim_rate`;

export const analyticsDashboardQueryKeys = {
  base: [ANALYTICS_DASHBOARD_BASE_API_URL],
  stats: () => [
    ...analyticsDashboardQueryKeys.base,
    ANALYTICS_DASHBOARD_STATS_API_URL,
    'stats',
  ],
  customers: () => [
    ...analyticsDashboardQueryKeys.base,
    ANALYTICS_DASHBOARD_CUSTOMERS_API_URL,
    'customers',
  ],
  customerCreationRate: () => [
    ...analyticsDashboardQueryKeys.base,
    ANALYTICS_DASHBOARD_CUSTOMERS_CREATION_RATE_API_URL,
    'customerCreationRate',
  ],
  claimedDeviceRate: () => [
    ...analyticsDashboardQueryKeys.base,
    ANALYTICS_DASHBOARD_CLAIMED_DEVICES_RATE_API_URL,
    'claimedDeviceRate',
  ],
};
