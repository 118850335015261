import { set } from 'lodash/fp';
import React, { FC } from 'react';

import { PublishableProduct, useStoreSettings } from '@portals/api/partners';
import { CurrencyRow } from '@portals/framework';

import { usePublishProductsWizard } from '../hooks';

interface CurrencyListProps {
  product: PublishableProduct;
}

export const CurrencyList: FC<CurrencyListProps> = ({ product }) => {
  const { contextData, setContextData } = usePublishProductsWizard();

  const storeSettings = useStoreSettings();
  const defaultCurrency = storeSettings.data?.default_currency;

  const selectedPricingOptionKey =
    contextData.selectedPricingOptionMap?.[product.id];
  const productStoreListing = contextData.storeListingsMap?.[product.id];

  if (!selectedPricingOptionKey || !productStoreListing) {
    return null;
  }

  const sortedPriceList = productStoreListing.prices.sort((a) =>
    a.currency === defaultCurrency ? -1 : 1
  );

  return (
    <>
      {sortedPriceList.map((price, index) => {
        return (
          <CurrencyRow
            key={price.currency}
            currencyCode={price.currency}
            isDefaultCurrency={price.currency === defaultCurrency}
            value={price[selectedPricingOptionKey] ?? undefined}
            onChange={(value) =>
              setContextData((curr) => {
                return set(
                  [
                    'storeListingsMap',
                    product.id,
                    'prices',
                    index,
                    selectedPricingOptionKey,
                  ],
                  value,
                  curr
                );
              })
            }
          />
        );
      })}
    </>
  );
};
