import { Text } from '@mantine/core';
import React from 'react';

import { useRequestPlanUpgrade } from '@portals/api/partners';
import {
  UpgradeTierBadge,
  UpgradeTierHoverCard,
  UpgradeTierRightRibbon,
} from '@portals/framework';

interface PartnerUpdateTierBadgeProps {
  source: string;
}

export function PartnerUpdateTierBadge({
  source,
}: PartnerUpdateTierBadgeProps) {
  const requestPlanUpgrade = useRequestPlanUpgrade();

  return (
    <UpgradeTierBadge
      size="sm"
      message={
        <Text size="xs">
          This feature is not included in your current tier, please upgrade to
          activate.
        </Text>
      }
      onUpgrade={() => requestPlanUpgrade.mutate(source)}
    />
  );
}

export function PartnerUpdateTierHoverCard({
  source,
  children,
}: PartnerUpdateTierBadgeProps & { children: React.ReactNode }) {
  const requestPlanUpgrade = useRequestPlanUpgrade();

  return (
    <UpgradeTierHoverCard
      message={
        <Text size="xs">
          This feature is not included in your current tier, please upgrade to
          activate.
        </Text>
      }
      onUpgrade={() => requestPlanUpgrade.mutate(source)}
    >
      {children}
    </UpgradeTierHoverCard>
  );
}

export function PartnerUpdateTierRibbonRight({
  source,
}: PartnerUpdateTierBadgeProps) {
  const requestPlanUpgrade = useRequestPlanUpgrade();

  return (
    <UpgradeTierRightRibbon
      boxProps={{
        sx: { position: 'absolute', top: 10, right: -16, zIndex: 1 },
      }}
      onUpgrade={() => requestPlanUpgrade.mutate(source)}
      message={
        <Text size="xs">
          This feature is not included in your current tier, please upgrade to
          activate.
        </Text>
      }
    />
  );
}
