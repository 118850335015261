import {
  Group,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { SupportedCommandType } from '@portals/types';

import { ButtonWidgetFormType } from './button-form.types';
import { ColorSelector } from '../../common/ColorSelector';
import { IconSelector } from '../../common/IconSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface ButtonWidgetFormProps {
  form: UseFormReturnType<ButtonWidgetFormType>;
  commands: SupportedCommandType[];
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function ButtonWidgetForm({
  form,
  commands,
  colors,
  onAddCustomColor,
}: ButtonWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          label="Title"
          data-testid="button-widget-name-input"
          required
        />
      </Stack>

      <Input.Wrapper label="Icon">
        <IconSelector
          selectedIconName={form.values.icon_name}
          onChange={(iconName) => form.setFieldValue('icon_name', iconName)}
        />

        <Switch
          mt="lg"
          label="Show Icon"
          checked={form.values.show_icon}
          onChange={(event) =>
            form.setFieldValue('show_icon', event.target.checked)
          }
        />
      </Input.Wrapper>

      <Input.Wrapper label="Color">
        <ColorSelector
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
          colors={colors}
          onAddCustomColor={onAddCustomColor}
        />
      </Input.Wrapper>

      <Stack>
        <Text size="sm" color="gray.9">
          Settings
        </Text>

        <Group grow>
          <Select
            data={commands.map((command) => ({
              value: command.name,
              label: command.friendly_name,
            }))}
            searchable
            clearable
            required
            withinPortal
            {...form.getInputProps('command_name')}
            label="Command Name"
            data-testid="command-name-button"
          />
        </Group>
      </Stack>
    </Stack>
  );
}
