import {
  Alert,
  AlertProps,
  Button,
  Checkbox,
  createStyles,
  LoadingOverlay,
  Modal,
  NumberInput,
  RingProgress,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  TextInput,
  TextareaProps,
  Group,
  Radio,
  RadioProps,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { isEmpty, size } from 'lodash/fp';
import React, { useState } from 'react';
import { object, string } from 'yup';

import { useFeatureFlags } from '@portals/api/partners';
import { ModalFooter } from '@portals/core';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';
import { SupportedCommandType } from '@portals/types';

import { ActivateCommandWithLicense } from './ActivateCommandWithLicense';
import CustomFields from './CustomFields';

interface SupportedCommandFormProps {
  initialValues?: Partial<SupportedCommandType>;
  isEdit: boolean;
  isLoading: boolean;
  errors?: string;
  closeMe: () => void;
  handleUpdate: (data: Record<string, any>) => void;
}

const schema = object({
  friendly_name: string().required(
    'Friendly name sent to device is a required field'
  ),
  description: string().required(
    'Description sent to device is a required field'
  ),
  name: string().required('Name sent to device is a required field'),
});

const CONFIRMATION_MESSAGE_MAX_LENGTH = 1000;
const MIN_MESSAGE_LENGTH_TO_DISPLAY_PROGRESS =
  CONFIRMATION_MESSAGE_MAX_LENGTH * 0.899;
const DEFAULT_MESSAGE =
  'Are you sure you want to execute this command? \nPlease note that some actions may have significant consequences or may not be reversible.';

export function SupportedCommandForm({
  isEdit = false,
  isLoading,
  initialValues = {},
  closeMe,
  handleUpdate,
}: SupportedCommandFormProps) {
  const { classes } = useStyles();

  const featureFlags = useFeatureFlags();

  const form = useForm<Partial<SupportedCommandType>>({
    initialValues: isEmpty(initialValues)
      ? {
          is_visible_in_panel: true,
          active: true,
          file_type: '',
          with_file: false,
          allow_duplicate_commands: false,
          friendly_name: '',
          description: '',
          name: '',
          custom_fields: [],
          product_ids: [],
          products: [],
          premium: false,
          confirmation_message: DEFAULT_MESSAGE,
        }
      : {
          ...initialValues,
          confirmation_message:
            initialValues.confirmation_message ?? DEFAULT_MESSAGE,
          timeout_in_seconds: initialValues.timeout_in_seconds ?? undefined,
        },

    validate: yupResolver(schema),
  });

  const [isRequireUserApproval, setIsRequireUserApproval] = useState(
    Boolean(initialValues?.confirmation_message)
  );

  const onSubmit = (values: typeof form.values) => {
    const productIds = values.products?.map((product) => product.id) ?? [];

    handleUpdate({
      ...values,
      confirmation_message: isRequireUserApproval
        ? values.confirmation_message
        : null,
      product_ids: productIds,
    });
  };

  const convertValueToOpenTunnelArray = (isChecked: boolean) => {
    if (isChecked) {
      form.setFieldValue('command_features', ['open_tunnel']);

      return;
    }

    form.setFieldValue('command_features', []);
  };

  const onAddProtocolToTunnel = (
    protocol: 'tunnel_https_protocol' | 'tunnel_http_protocol'
  ) => {
    if (form.values.command_features === undefined) {
      return;
    }

    if (protocol === 'tunnel_http_protocol') {
      form.setFieldValue('command_features', ['open_tunnel']);

      return;
    }

    form.setFieldValue('command_features', ['open_tunnel', protocol]);
  };

  return (
    <Modal
      opened
      size="xl"
      onClose={closeMe}
      title={isEdit ? 'Edit supported command' : 'Add supported command'}
      padding="xxl"
      radius="lg"
    >
      <LoadingOverlay visible={isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <ScrollArea.Autosize mah={500} type="hover">
          <Stack>
            <Stack spacing="xs">
              <ActivateCommandWithLicense
                form={form}
                closeSupportedCommandModal={closeMe}
                initialProducts={initialValues?.products ?? []}
              />

              {!form.values.active ? (
                <Alert
                  color="gray.1"
                  radius="md"
                  icon={<InfoCircle />}
                  styles={alertStyles}
                >
                  Set this command to "Active" below in order to link it to a
                  license.
                </Alert>
              ) : null}
            </Stack>

            <TextInput
              withAsterisk
              placeholder="The name the user will see"
              label="Friendly name"
              data-testid="text-input-friendly_name"
              {...form.getInputProps('friendly_name')}
            />

            <TextInput
              withAsterisk
              placeholder="Detailed description for the user"
              label="Description"
              data-testid="text-input-description"
              {...form.getInputProps('description')}
            />

            <TextInput
              withAsterisk
              placeholder="The name the device will get when this command is sent"
              label="Name sent to device"
              data-testid="text-input-name"
              {...form.getInputProps('name')}
            />

            <Checkbox
              label="Requires file"
              {...form.getInputProps('with_file', { type: 'checkbox' })}
            />

            {form.values.with_file ? (
              <TextInput
                label="Required file type"
                placeholder="Firmware"
                {...form.getInputProps('file_type')}
              />
            ) : null}

            <NumberInput
              data-testid="number-input-timeout_in_seconds"
              label="Timeout (in seconds)"
              placeholder="Maximum possible run time for command"
              min={0}
              {...form.getInputProps('timeout_in_seconds')}
            />

            <Checkbox
              data-testid="checkbox-allow_duplicate_commands"
              label="Allow sending multiple commands with identical parameters"
              {...form.getInputProps('allow_duplicate_commands', {
                type: 'checkbox',
              })}
            />

            {isPartnerFeatureOn(featureFlags, 'tunnel_poc') ? (
              <>
                <Checkbox
                  label="Open tunnel"
                  data-testid="open-tunnel-checkbox"
                  onChange={(event) =>
                    convertValueToOpenTunnelArray(event.target.checked)
                  }
                  checked={
                    form.values.command_features === undefined
                      ? false
                      : form.values.command_features?.includes('open_tunnel')
                  }
                />

                {form.values.command_features?.includes('open_tunnel') ? (
                  <Radio.Group
                    value={
                      form.values.command_features?.includes(
                        'tunnel_https_protocol'
                      )
                        ? 'tunnel_https_protocol'
                        : 'tunnel_http_protocol'
                    }
                    onChange={(
                      value: 'tunnel_https_protocol' | 'tunnel_http_protocol'
                    ) => onAddProtocolToTunnel(value)}
                    pl="xxl"
                  >
                    <Group align="flex-start" spacing="sm">
                      <Text>Protocol:</Text>

                      <Radio
                        value="tunnel_http_protocol"
                        label="HTTP"
                        styles={radioStyles}
                      />

                      <Radio
                        value="tunnel_https_protocol"
                        label="HTTPS"
                        styles={radioStyles}
                      />
                    </Group>
                  </Radio.Group>
                ) : null}
              </>
            ) : null}

            <Checkbox
              data-testid="active-checkbox"
              label={`Active ${
                form.values.premium
                  ? `(This command is linked to a license. Please disconnect the
                    command from all licenses before deactivating it)`
                  : ''
              }`}
              disabled={form.values.premium}
              {...form.getInputProps('active', {
                type: 'checkbox',
              })}
            />

            <Checkbox
              data-testid="show-command-in-panel-checkbox"
              label="Show command in panel"
              {...form.getInputProps('is_visible_in_panel', {
                type: 'checkbox',
              })}
            />

            <Stack spacing="xs">
              <Checkbox
                data-testid="checkbox-confirmation-popup"
                label="Show a confirmation popup with a message "
                checked={isRequireUserApproval}
                onChange={(e) => {
                  setIsRequireUserApproval(e.target.checked);

                  if (!e.target.checked) {
                    form.setFieldValue('confirmation_message', null);
                  }
                }}
              />

              {isRequireUserApproval ? (
                <Textarea
                  data-testid="confirmation-message-text-area"
                  maxLength={CONFIRMATION_MESSAGE_MAX_LENGTH}
                  pl="xxl"
                  placeholder="Write a message"
                  minRows={3}
                  rightSection={
                    size(form.values.confirmation_message) >
                    MIN_MESSAGE_LENGTH_TO_DISPLAY_PROGRESS ? (
                      <RingProgress
                        size={40}
                        pr={4}
                        rootColor="gray.2"
                        thickness={2}
                        sections={[
                          {
                            value: size(form.values.confirmation_message) / 10,
                            color: 'primary',
                            max: CONFIRMATION_MESSAGE_MAX_LENGTH,
                          },
                        ]}
                        label={
                          <Text size="xs" ta="center">
                            {CONFIRMATION_MESSAGE_MAX_LENGTH -
                              size(form.values.confirmation_message)}
                          </Text>
                        }
                      />
                    ) : null
                  }
                  styles={textareaStyles}
                  {...form.getInputProps('confirmation_message')}
                />
              ) : null}
            </Stack>

            <CustomFields
              command={form.values}
              onChange={(e) => {
                form.setFieldValue('custom_fields', e.custom_fields);
              }}
            />
          </Stack>
        </ScrollArea.Autosize>

        <ModalFooter position="right" className={classes.footer}>
          <Button
            variant="default"
            data-testid="create-command-cancel-button"
            onClick={closeMe}
          >
            Cancel
          </Button>

          <Button data-testid="create-command-submit-button" type="submit">
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

const radioStyles: RadioProps['styles'] = (theme) => ({
  label: {
    marginBottom: theme.spacing.xs,
    paddingLeft: 4,
  },
});

const textareaStyles: TextareaProps['styles'] = (theme) => ({
  rightSection: {
    alignItems: 'flex-end',
    paddingBottom: theme.spacing.xs,
    paddingRight: theme.spacing.lg,
  },
  input: {
    paddingRight: 50,
  },
});

const alertStyles: AlertProps['styles'] = (theme) => ({
  icon: {
    color: theme.colors.gray[9],
    marginRight: 4,
  },
});

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
}));
