import { Button } from '@mantine/core';
import React from 'react';

interface EditModeFooterProps {
  isLoading: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export function EditModeFooter({
  isLoading,
  onCancel,
  onSave,
}: EditModeFooterProps) {
  return (
    <>
      <Button
        onClick={onCancel}
        data-testid="modal-edit-cancel-button"
        variant="default"
      >
        Cancel
      </Button>
      <Button
        loading={isLoading}
        data-testid="modal-edit-save"
        onClick={onSave}
      >
        Save
      </Button>
    </>
  );
}
