import { NumberInputProps } from '@mantine/core';

import { CurrencyCode } from '@portals/types';
import {
  convertFromMajorToMinor,
  convertFromMinorToMajor,
} from '@portals/utils';

export function useConvertedCurrencyInput(
  currencyCode: CurrencyCode,
  value: NumberInputProps['value'],
  onChange: NumberInputProps['onChange']
): [NumberInputProps['value'], NumberInputProps['onChange']] {
  let adjustedValue = undefined;

  try {
    if (value !== undefined && value !== '') {
      adjustedValue = convertFromMinorToMajor(value, currencyCode);
    }
  } catch (error) {
    console.error(error);
  }

  const onChangeHandler: NumberInputProps['onChange'] = (value) => {
    if (value === undefined || value === '') {
      onChange?.(value);
      return;
    }

    try {
      const convertedValue = convertFromMajorToMinor(value, currencyCode);
      onChange?.(convertedValue);
    } catch (error) {
      console.error(error);
    }
  };

  return [adjustedValue, onChangeHandler];
}
