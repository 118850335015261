import { ActionIcon, Divider, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { EditNoteModalProps } from '@portals/framework/modals';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { useOpenModal } from '@portals/redux';

import { Attachments } from './Attachments';
import { OrderReferenceIdModalProps } from '../../modals';

interface EditableInfoCardProps {
  referenceId: string | undefined;
  notes: string | undefined;
  attachmentUrl: string | undefined;
  setReferenceId: (referenceId: string) => void;
  setNotes: (notes: string) => void;
  setAttachmentUrl: (attachmentUrl: string) => void;
}

export function EditableInfoCard({
  referenceId,
  setReferenceId,
  notes,
  setNotes,
  attachmentUrl,
  setAttachmentUrl,
}: EditableInfoCardProps) {
  const openModal = useOpenModal();

  return (
    <Paper withBorder p={0} radius="md">
      <Stack spacing="xs" p="xl">
        <Group position="apart">
          <Text size="md" fw={500}>
            Order reference ID
          </Text>
          <ActionIcon
            onClick={() =>
              openModal<OrderReferenceIdModalProps['data']>(
                'OrderReferenceIdModal',
                {
                  handleSave: setReferenceId,
                  orderReferenceId: referenceId || '',
                }
              )
            }
            data-testid="edit-order-reference-id-button"
          >
            <Edit3 width={16} height={16} />
          </ActionIcon>
        </Group>

        {referenceId ? (
          <Text data-testid="order-reference-id">{referenceId}</Text>
        ) : (
          <Text color="gray.5">No order reference ID</Text>
        )}
      </Stack>

      <Divider />

      <Stack spacing="xs" p="xl">
        <Group position="apart">
          <Text size="md" fw={500}>
            Notes
          </Text>
          <ActionIcon
            onClick={() =>
              openModal<EditNoteModalProps['data']>('EditNoteModal', {
                note: notes || '',
                onSubmitSync: (newNote) => setNotes(newNote),
              })
            }
            data-testid="edit-order-notes-button"
          >
            <Edit3 width={16} height={16} />
          </ActionIcon>
        </Group>

        {notes ? (
          <Text data-testid="orders-notes">{notes}</Text>
        ) : (
          <Text color="gray.5">No notes</Text>
        )}
      </Stack>

      <Divider />

      <Attachments
        attachmentUrl={attachmentUrl}
        handelUploadAttachmentSuccess={setAttachmentUrl}
      />
    </Paper>
  );
}
