import { Button, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

import { ModalBody, ModalFooter } from '@portals/core';
import {
  SubscriptionCancellationForm,
  SubscriptionCancellationFormProps,
} from '@portals/framework';

interface SubscriptionCancellationFormModalProps
  extends SubscriptionCancellationFormProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function SubscriptionCancellationFormModal({
  isOpen,
  onClose,
  onConfirm,
  reason,
  onChangeReason,
  freeText,
  onChangeFreeText,
}: SubscriptionCancellationFormModalProps) {
  return (
    <Modal
      opened={isOpen}
      padding="xxl"
      onClose={onClose}
      title="Subscription Cancellation Feedback"
    >
      <ModalBody>
        <Stack spacing="xl">
          <Text weight={500}>
            In order to help you improve, please take a moment to share the
            reason for this cancellation.
          </Text>

          <SubscriptionCancellationForm
            reason={reason}
            onChangeReason={onChangeReason}
            freeText={freeText}
            onChangeFreeText={onChangeFreeText}
          />
        </Stack>
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={onClose}>
          Keep Subscription
        </Button>
        <Button onClick={onConfirm} data-testid="proceed-to-cancel-button">
          Proceed to Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
