import {
  ActionIcon,
  LoadingOverlay,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { set } from 'lodash/fp';
import React from 'react';

import { useDeviceModel } from '@portals/api/partners';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh.svg';

import { DetailsColumnContent } from './DetailsColumnContent';
import { useProductWizard } from '../use-product-wizard';

export function SkuSection() {
  const { contextData, setContextData } = useProductWizard();

  const deviceModel = useDeviceModel(
    contextData.product.device_model_id ?? undefined
  );

  const onChangeSku = (sku: string) => {
    setContextData((prev) => set(['product', 'sku'], sku, prev));
  };

  return (
    <DetailsColumnContent.Section
      title={
        <Text>
          SKU{' '}
          <Text span color="gray.5" weight={400}>
            (optional)
          </Text>
        </Text>
      }
      subtitle='A "Stock Keeping Unit" is a unique code you can create for each product you have in your store. Using SKUs helps with tracking inventory.'
    >
      <LoadingOverlay visible={deviceModel.isFetching} />

      <TextInput
        w={270}
        placeholder="Enter SKU code"
        value={contextData.product.sku ?? ''}
        onChange={(e) => onChangeSku(e.target.value)}
        data-testid="sku-input"
        rightSection={
          deviceModel.data?.sku && (
            <Tooltip label="Reset to device model SKU">
              <ActionIcon
                variant="transparent"
                color="blue_gray"
                onClick={() => onChangeSku(deviceModel.data?.sku ?? '')}
              >
                <Refresh width={16} height={16} />
              </ActionIcon>
            </Tooltip>
          )
        }
      />
    </DetailsColumnContent.Section>
  );
}
