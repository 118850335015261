import { Box, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { SelectedStoreListingDetails } from './SelectedStoreListingDetails';
import { useCreateOrderWizard } from '../create-order-wizard/hooks';

export function SelectedStoreListingsList() {
  const { classes } = useStyles();
  const { contextData } = useCreateOrderWizard();

  return (
    <Stack className={classes.container}>
      <Text size="md" fw={500}>
        Products
      </Text>

      {contextData.selectedStoreListings.map((product) => (
        <Box key={product.id} className={classes.productContainer}>
          <SelectedStoreListingDetails storeListing={product} />
        </Box>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl,
    height: 'min-content',
  },

  productContainer: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
