import { Center, createStyles, Text } from '@mantine/core';
import React from 'react';

import { ProductCard } from '@portals/framework';
import { ProductPricingModel } from '@portals/types';
import { getPricingModelDisplayName } from '@portals/utils';

import { useProductWizard } from '../use-product-wizard';

export const PreviewColumn = () => {
  const { classes } = useStyles();
  const { contextData } = useProductWizard();

  const getPricingModelText = () => {
    if (!contextData.product.pricing_model) return null;

    let text = getPricingModelDisplayName(contextData.product.pricing_model);

    if (
      contextData.product.pricing_model === ProductPricingModel.UsageBased &&
      contextData.product.usage_based_unit_name
    ) {
      text += ` (${contextData.product.usage_based_unit_name})`;
    }

    return text;
  };

  return (
    <Center className={classes.container}>
      <ProductCard className={classes.card}>
        <ProductCard.Image
          category={contextData.product?.category || 'Category'}
          imageUrl={contextData.product?.image_url}
        />
        <ProductCard.Details
          name={contextData.product?.name || 'Product Name'}
          subtitle={contextData.product?.subtitle || 'Subtitle'}
        >
          <Text size="xl" weight={500} color="gray.9">
            {getPricingModelText()}
          </Text>
        </ProductCard.Details>
      </ProductCard>
    </Center>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: 80,
    paddingInline: 40,
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.25),
  },
  card: {
    width: '100%',
    maxWidth: 370,
    filter: 'drop-shadow(0px 11px 18px rgba(6, 72, 105, 0.05))',
  },
}));
