import { EditWithWizard } from '@portals/framework';
import {
  PartnerFeatureFlagsType,
  PartnerPortalCapabilities,
  TabType,
} from '@portals/types';

import Zoho from './Zoho/mapping';

export const DESK_TABS: Array<
  TabType<PartnerFeatureFlagsType, PartnerPortalCapabilities, any>
> = [
  {
    title: 'Zoho',
    id: 'zoho',
    Component: EditWithWizard,
    props: { sections: Zoho },
  },
];

const TABS: Array<
  TabType<PartnerFeatureFlagsType, PartnerPortalCapabilities, any>
> = [{ title: 'Desk Systems', id: 'desk', tabs: DESK_TABS }];

export default TABS;
