import { pick } from 'lodash/fp';
import React from 'react';

import { useWizard } from '@portals/framework';

import PortalDesignForm from './PortalDesignForm';
import { BROWSER_MOCK_WIDTH } from '../BrowserMock';
import { LayoutContainer } from '../common';
import DetailsArea from '../DetailsArea';
import { usePreviewScaleRatio } from '../hooks';
import PreviewArea from '../PreviewArea';
import { SignInBrowserMock } from '../SignInBrowserMock';
import { OnboardingWizardContextDataType, StepId } from '../types';

function PortalDesignStep() {
  const { contextData } = useWizard<OnboardingWizardContextDataType, StepId>();
  const previewRatio = usePreviewScaleRatio();

  return (
    <LayoutContainer>
      <DetailsArea>
        <PortalDesignForm />
      </DetailsArea>

      <PreviewArea
        motionProps={{
          initial: { scale: previewRatio, left: 0, transformOrigin: 'left' },
          animate: {
            scale: previewRatio,
            position: 'relative',
            left: '50%',
            marginLeft: `calc(-${BROWSER_MOCK_WIDTH}px * ${previewRatio} / 2)`,
          },
          transition: { type: 'linear' },
        }}
      >
        <SignInBrowserMock portalConfig={pick(['design'], contextData)} />
      </PreviewArea>
    </LayoutContainer>
  );
}

export default PortalDesignStep;
