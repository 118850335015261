import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Group,
  Slider,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  BarIndicatorWidget,
  BarIndicatorWidgetForm,
  BarIndicatorWidgetFormType,
  convertWidgetFormToWidgetProps,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

export function BarIndicatorWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  colors,
  onAddCustomColor,
}: CommonWidgetFormWrapperProps<BarIndicatorWidgetFormType>) {
  const { classes } = useStyles();
  const [value, setValue] = useState(0);
  const asyncConfirmationCheck = useConfirmationModal();
  const form = useForm<BarIndicatorWidgetFormType>({
    initialValues: {
      name: '',
      telemetry: '',
      unit: '',
      scale_type: 'linear',
      number_format: 'none',
      num_of_decimals: 1,
      telemetry_as_title: false,
      title_telemetry: '',
      segments: [
        {
          id: uuid(),
          max: 25,
          color: 'red.4',
        },
        {
          id: uuid(),
          max: 70,
          color: 'orange.6',
        },
        {
          id: uuid(),
          max: 100,
          color: 'teal_accent.3',
        },
      ],
      min: 0,
      max: 100,
      value,
      ...(initialValues || {}),
    },
    validateInputOnBlur: true,
    validate: {
      min: (value, values) => {
        if (isNaN(value)) return 'Min value must be a number';

        return value >= values.max ? 'Min value must be less than max' : null;
      },
      max: (value, values) => {
        if (isNaN(value)) return 'Max value must be a number';

        return value <= values.min
          ? 'Max value must be greater than min'
          : null;
      },
    },
  });

  useEffect(
    function updateMinValueOnLogScaleSelection() {
      if (form.values.scale_type === 'log' && form.values.min < 1) {
        form.setFieldValue('min', 1);
      }
    },
    [form]
  );

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} pt={30} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9" px={30}>
            Add Bar Indicator
          </Text>

          <VerticalScrollBar>
            <Space h="xl" />

            <BarIndicatorWidgetForm
              form={form}
              colors={colors}
              onAddCustomColor={onAddCustomColor}
            />

            <Space h="xl" />
          </VerticalScrollBar>
        </Stack>

        <Stack className={classes.config} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Stack justify="center" spacing="xl" h="100%">
            <BarIndicatorWidget
              {...convertWidgetFormToWidgetProps.bar_indicator({
                formValues: form.values,
                value,
              })}
              stackProps={{ h: 167, w: '100%' }}
            />

            <Slider
              pt="xl"
              min={form.values.min}
              max={form.values.max}
              value={form.values.value}
              onChange={(value) => {
                form.setFieldValue('value', value);
                setValue(value);
              }}
              labelAlwaysOn
              w="100%"
            />
          </Stack>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
  },
  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
