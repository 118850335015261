import {
  Checkbox,
  CheckboxProps,
  createStyles,
  Group,
  Text,
} from '@mantine/core';
import React from 'react';

export interface CurrencyRowProps {
  currencyName: string;
  flagSrc: string;
  isSelected: boolean;
  onSelect: () => void;
}

export function CurrencyRow({
  flagSrc,
  currencyName,
  isSelected,
  onSelect,
}: CurrencyRowProps) {
  const { classes } = useStyles();

  return (
    <Checkbox
      checked={isSelected}
      onChange={onSelect}
      styles={checkboxStyles}
      label={
        <Group>
          <img
            src={flagSrc}
            width={30}
            height={20}
            loading="lazy"
            className={classes.flagImage}
          />

          <Text size="sm" color="blue_gray">
            {currencyName}
          </Text>
        </Group>
      }
    />
  );
}

const checkboxStyles: CheckboxProps['styles'] = (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  body: {
    alignItems: 'center',
  },
  labelWrapper: {
    flexGrow: 1,
  },
  label: {
    paddingBlock: theme.spacing.lg,
  },
});

const useStyles = createStyles((theme) => ({
  flagImage: {
    borderRadius: theme.radius.xs,
    border: `1px solid ${theme.colors.gray[3]}`,
  },
}));
