import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { RouteModalLink } from '@portals/framework/route-modals';
import { AuditLogType } from '@portals/types';

interface PurchaseOrderApprovedProps {
  auditLog: AuditLogType;
}

export function PurchaseOrderApproved({
  auditLog,
}: PurchaseOrderApprovedProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const orderParam = paramsMap['order'];

  return (
    <Text size="sm">
      Purchase order approved for {orderParam.model}:{' '}
      <RouteModalLink modalId="order" pathParams={[orderParam.id as string]}>
        {orderParam.display_name}
      </RouteModalLink>
    </Text>
  );
}
