import { createStyles, LoadingOverlay } from '@mantine/core';
import React from 'react';

import { useCheckStoreListingSlugAvailability } from '@portals/api/partners';

import { EditModeFooter } from './EditModeFooter';
import { SteppingModeFooter } from './SteppingModeFooter';
import { useStoreListingWizard } from '../hooks';

export function WizardFooter() {
  const { classes } = useStyles();
  const { contextData } = useStoreListingWizard();

  const isEdit = contextData.intent === 'edit';

  const checkStoreListingSlugAvailability =
    useCheckStoreListingSlugAvailability();

  if (checkStoreListingSlugAvailability.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <footer className={classes.footer}>
      {isEdit ? <EditModeFooter /> : <SteppingModeFooter />}
    </footer>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    paddingInline: theme.spacing.xl,
    paddingBlock: theme.spacing.lg,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
