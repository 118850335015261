import { Stepper, StepperProps } from '@mantine/core';
import { omit } from 'lodash/fp';
import React from 'react';

import { useFeatureFlags } from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import { ProductTypeEnum } from '@portals/types';

import { StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export function CreateModeNavigation() {
  const featureFlags = useFeatureFlags();
  const { steps, currentStep, contextData } = useProductWizard();

  const isPlatformLicenseType =
    contextData.product.product_type === ProductTypeEnum.PlatformLicense;

  const showProductDetailsStep =
    !!contextData.product.device_model_id || isPlatformLicenseType;

  const stepIds = showProductDetailsStep
    ? (Object.keys(omit([StepId.ProductType], steps)) as StepId[])
    : (Object.keys(
        omit([StepId.ProductType, StepId.ProductDetails], steps)
      ) as StepId[]);

  return (
    <Stepper
      active={stepIds.findIndex((stepId) => stepId === currentStep.id)}
      orientation="vertical"
      styles={stepperStyles}
    >
      {!isPlatformLicenseType ? <Stepper.Step label="Product Type" /> : null}
      <Stepper.Step label="General Information" />

      {showProductDetailsStep && <Stepper.Step label="Product Details" />}

      <Stepper.Step label="Pricing Model" />
      <Stepper.Step label="Tax Details" />

      {isPartnerFeatureOn(featureFlags, 'ppp') &&
      contextData.product.product_type === ProductTypeEnum.Physical ? (
        <Stepper.Step label="Post Purchase Data" />
      ) : null}
    </Stepper>
  );
}

const stepperStyles: StepperProps['styles'] = (theme) => {
  return {
    step: {
      position: 'relative',
      width: '100%',
      paddingLeft: 4,
      paddingTop: 4,
      cursor: 'default !important',

      '&:not(:first-of-type)': {
        marginTop: -4,
      },

      '&[data-progress]': {
        ':before': {
          content: '""',
          zIndex: -1,
          position: 'absolute',
          top: 1,
          left: 0,
          display: 'block',
          width: '100%',
          height: 41,
          borderRadius: theme.radius.xl,
          backgroundColor: theme.colors.primary[0],
        },
      },
    },
    stepBody: {
      marginTop: theme.spacing.sm,
    },
    verticalSeparator: {
      marginTop: -4,
    },
  };
};
