import { UsePaginatedTableApiQuery } from '@portals/types';

import { SHIPMENTS_API_URL, shipmentsQueryKeys } from './shipments.constants';
import { OrderShipmentType } from './shipments.types';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export function useNewShipments(
  tableState: UsePaginatedTableApiQuery<OrderShipmentType>['tableState'],
  columns: UsePaginatedTableApiQuery<OrderShipmentType>['columns'],
  baseUrl: string = SHIPMENTS_API_URL,
  queryKey?: Array<string>
) {
  return usePaginatedTableApiQuery<OrderShipmentType>({
    baseUrl,
    tableState,
    columns,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...shipmentsQueryKeys.list(), baseUrl, tableState],
  });
}
