export const PURCHASED_PRODUCTS_API_URL = 'ui/partner/purchased_products';

export const purchasedProductsQueryKeys = {
  all: [PURCHASED_PRODUCTS_API_URL],
  details: () => [...purchasedProductsQueryKeys.all, 'details'],
  detail: (purchasedProductId: string) => [
    ...purchasedProductsQueryKeys.details(),
    purchasedProductId,
  ],
};
