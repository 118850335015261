import { PartnerConfigurationType, RoutesMap } from '@portals/types';
import { lazyNamedImport } from '@portals/utils';

const CreditProvidersRouteModal = lazyNamedImport(
  () => import('./route-modals/CreditProviders'),
  'CreditProvidersRouteModal'
);
const CustomerRouteModal = lazyNamedImport(
  () => import('./route-modals/Customer'),
  'CustomerRouteModal'
);
const OfflineOrderRouteModal = lazyNamedImport(
  () => import('./route-modals/offline-order/OfflineOrder'),
  'OfflineOrderRouteModal'
);
const OrderRouteModal = lazyNamedImport(
  () => import('./route-modals/Order/Order'),
  'OrderRouteModal'
);
const PurchasedProductRoutePanel = lazyNamedImport(
  () => import('./route-panels/product/PurchasedProductRoutePanel'),
  'PurchasedProductRoutePanel'
);
const StoreListingRoutePanel = lazyNamedImport(
  () => import('./route-panels/store-listings/StoreListingRoutePanel'),
  'StoreListingRoutePanel'
);
const SubscriptionRoutePanel = lazyNamedImport(
  () => import('./route-panels/subscriptions/SubscriptionRoutePanel'),
  'SubscriptionRoutePanel'
);

const routeModals: RoutesMap<PartnerConfigurationType>['routeModals'] = [
  {
    id: 'customer',
    path: '/m/customers/:customer_id',
    component: CustomerRouteModal,
    backgroundPath: '/customers',
    generatePath: ([customerId]: [string]) => `/m/customers/${customerId}`,
  },
  {
    id: 'order',
    path: '/m/order/:order_id',
    component: OrderRouteModal,
    backgroundPath: '/store-management/orders',
    generatePath: ([orderId]: [string]) => `/m/order/${orderId}`,
  },
  {
    id: 'offline-order',
    path: '/m/offline-order/:order_id',
    component: OfflineOrderRouteModal,
    backgroundPath: '/store-management/orders',
    generatePath: ([orderId]: [string]) => `/m/offline-order/${orderId}`,
  },
  {
    id: 'credit-providers',
    path: '/m/credit-providers',
    component: CreditProvidersRouteModal,
    backgroundPath: '/',
    generatePath: () => `/m/credit-providers`,
  },
];

const routePanels: RoutesMap<PartnerConfigurationType>['routePanels'] = [
  {
    id: 'store_listing',
    path: '/p/products/:store_listing_slug',
    component: StoreListingRoutePanel,
    backgroundPath: '/products',
    generatePath: ([store_listing_slug]: string[]) =>
      `/p/products/${store_listing_slug}`,
  },
  {
    id: 'subscription',
    path: '/p/subscription/:subscription_id',
    component: SubscriptionRoutePanel,
    backgroundPath: '/store-management/subscriptions',
    generatePath: ([subscriptionId]: string[]) =>
      `/p/subscription/${subscriptionId}`,
  },
  {
    id: 'purchased_product',
    path: '/p/purchased_product/:purchased_product_id',
    component: PurchasedProductRoutePanel,
    backgroundPath: '/store-management/purchased-products',
    generatePath: ([purchasedProductId]: string[]) =>
      `/p/purchased_product/${purchasedProductId}`,
  },
];

export const routes: RoutesMap<PartnerConfigurationType> = {
  dashboard: [],
  routeModals,
  routePanels,
};
