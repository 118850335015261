import { Box, createStyles, Group, Menu, Text, Tooltip } from '@mantine/core';
import React from 'react';

import { useSignOut } from '@portals/api/auth';
import { useCurrentUser } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as DocumentTextIcon } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as Logout1Icon } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as MessageQuestionIcon } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as SecurityUserIcon } from '@portals/icons/linear/security-user.svg';
import { ReactComponent as PersonalSettings } from '@portals/icons/linear/setting-4.svg';
import { useOpenModal } from '@portals/redux';

import {
  HUBS_HEADER_TOOLTIP_OFFSET,
  hubsHeaderMenuStyles,
  hubsHeaderTooltipStyles,
} from './common-styles';
import { useCanAccessPersonalSettings } from '../../hooks/personal-settings';

export function UserMenu() {
  const { classes } = useStyles();

  const signOut = useSignOut();
  const currentUser = useCurrentUser();

  const canAccessPersonalSettings = useCanAccessPersonalSettings();

  const openModal = useOpenModal();

  return (
    <Menu
      withinPortal
      width="fit-content"
      offset={HUBS_HEADER_TOOLTIP_OFFSET}
      shadow="lg"
      radius="lg"
      position="bottom-end"
      styles={hubsHeaderMenuStyles}
    >
      <Menu.Target>
        <Tooltip
          offset={HUBS_HEADER_TOOLTIP_OFFSET}
          label="User settings"
          styles={hubsHeaderTooltipStyles}
        >
          <Box sx={{ cursor: 'pointer' }}>
            <NameAbbreviationAvatar
              name={currentUser.data?.name}
              radius="xl"
              withToolTip={false}
              src={currentUser.data?.settings.profile_image}
              color="indigo_accent.3"
              variant="filled"
              data-testid="user-settings"
            />
          </Box>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown p="md">
        <Group noWrap mb="md" px="xs" className={classes.userDetails}>
          <NameAbbreviationAvatar
            name={currentUser.data?.name}
            radius="xl"
            withToolTip={false}
            src={currentUser.data?.settings.profile_image}
            color="indigo_accent.3"
            variant="filled"
          />
          <Text weight={500} sx={{ overflow: 'hidden' }}>
            <Text size="md" color="gray.1" truncate>
              {currentUser.data?.name}
            </Text>
            <Text size="xs" color="gray.5" truncate>
              {currentUser.data?.email}
            </Text>
          </Text>
        </Group>

        {canAccessPersonalSettings ? (
          <Menu.Item
            icon={<PersonalSettings />}
            data-testid="user-settings-personal-settings"
            onClick={() => openModal('PersonalSettingsModal')}
          >
            Personal settings
          </Menu.Item>
        ) : null}

        <Menu.Item
          component="a"
          href="https://dev.xyte.io/"
          target="_blank"
          icon={<MessageQuestionIcon />}
          data-testid="user-settings-help"
        >
          Help
        </Menu.Item>

        <Menu.Item
          component="a"
          href="https://xyte.io/terms-of-service"
          target="_blank"
          data-testid="user-settings-terms-of-service"
          icon={<DocumentTextIcon />}
        >
          Platform Terms of service
        </Menu.Item>

        <Menu.Item
          component="a"
          href="https://xyte.io/privacy-policy"
          target="_blank"
          data-testid="user-settings-privacy-policy"
          icon={<SecurityUserIcon />}
        >
          Platform Privacy Policy
        </Menu.Item>

        <Menu.Item
          icon={<Logout1Icon />}
          data-testid="user-settings-sign-out"
          onClick={() => signOut.mutate()}
        >
          Sign out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  userDetails: {
    paddingInline: theme.spacing.xs,
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[8]}`,
  },
}));
