import { Button, Center, Divider, Group, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

import { ReactComponent as SearchNormal5 } from '@portals/icons/linear/search-normal-5.svg';

import {
  HUBS_HEADER_TOOLTIP_OFFSET,
  hubsHeaderTooltipStyles,
} from './common-styles';

export function FeedbackButton() {
  const isLargerThan1500 = useMediaQuery(`(min-width: 1500px)`);

  return (
    <Group spacing={0}>
      <Tooltip
        label="Submit Feedback"
        styles={hubsHeaderTooltipStyles}
        offset={HUBS_HEADER_TOOLTIP_OFFSET}
        disabled={isLargerThan1500}
      >
        <Button
          variant="transparent"
          data-analyticsid="pendo-feedback-button"
          c="gray.4"
          pr="md"
          pl={0}
        >
          {isLargerThan1500 ? 'Submit Feedback' : <SearchNormal5 />}
        </Button>
      </Tooltip>

      <Center h={30}>
        <Divider orientation="vertical" color="gray.7" />
      </Center>
    </Group>
  );
}
