import {
  Button,
  createStyles,
  Footer as MantineFooter,
  Group,
  Stepper,
  StepperProps,
} from '@mantine/core';
import React from 'react';

import { IntegrationStatus } from './IntegrationStatus';

interface FooterProps {
  isDeviceRegistered: boolean;
  isTelemetrySent: boolean;
  closeMe: () => void;
  moveToNextStep: () => void;
  activeStep: number;
}

export function Footer({
  isDeviceRegistered,
  isTelemetrySent,
  closeMe,
  moveToNextStep,
  activeStep,
}: FooterProps) {
  const { classes } = useStyles();

  return (
    <MantineFooter className={classes.footer} height={70}>
      <Group>
        <Button onClick={closeMe} color="gray" variant="default" size="sm">
          Cancel
        </Button>
      </Group>

      <Stepper active={activeStep} styles={stepperStyles}>
        <Stepper.Step label="Register" />
        <Stepper.Step label="Send Telemetry" />
      </Stepper>

      <IntegrationStatus
        activeStep={activeStep}
        closeMe={closeMe}
        isDeviceRegistered={isDeviceRegistered}
        isTelemetrySent={isTelemetrySent}
        moveToNextStep={moveToNextStep}
      />
    </MantineFooter>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    padding: '12px 32px 0',
  },
}));

const stepperStyles: StepperProps['styles'] = (theme) => {
  return {
    root: {
      width: '200px',
      marginBottom: theme.spacing.xs,
      justifySelf: 'center',
    },
    step: {
      position: 'relative',
      flexDirection: 'column',
      wordBreak: 'normal',
    },
    stepIcon: {
      marginBottom: theme.spacing.xs,
    },
    stepBody: {
      position: 'absolute',
      bottom: 4,
      margin: 0,
      transform: 'translateY(100%)',
    },
    stepLabel: {
      width: 'max-content',
    },
  };
};
