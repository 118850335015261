import { Box, Button, Group } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import React, { useState } from 'react';

import { PartnerStateDumpType } from '@portals/api/partners';
import { ReactComponent as Book } from '@portals/icons/linear/book.svg';
import { ReactComponent as Copy } from '@portals/icons/linear/copy.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { SmartTable } from '@portals/table';
import {
  SmartTableProps,
  StateDumpType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { prettyTime } from '@portals/utils';

const getFileUrl = (accessToken: string) =>
  `${process.env.NX_SERVER_URL}external/dump_view/${accessToken}`;

interface StateDumpsViewProps
  extends Omit<
    SmartTableProps<StateDumpType | PartnerStateDumpType, 'id'>,
    'columns' | 'keyField'
  > {}

export function StateDumpsView({ ...tableProps }: StateDumpsViewProps) {
  const [copiedDumpId, setCopiedDumpId] = useState<string>();
  const clipboard = useClipboard();

  const columns: Array<TableColumn<StateDumpType | PartnerStateDumpType>> = [
    {
      dataField: 'filename',
      text: 'Filename',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
      isSticky: true,
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      formatter: (_, dump) => prettyTime(dump.created_at),
      sort: true,
      filter: { type: TableFilterTypeEnum.Date },
    },
    {
      dataField: 'access_count',
      text: 'Access Count',
      sort: true,
      filter: { type: TableFilterTypeEnum.Number },
    },
    {
      dataField: 'access_token',
      text: 'Access Code',
      formatter: (_, dump) => dump.access_token.slice(0, 8),
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
    },
    {
      dataField: 'mime_type',
      text: 'MIME type',
      sort: true,
      filter: { type: TableFilterTypeEnum.Text },
    },
    {
      dataField: 'link',
      text: 'Shareable Link',
      minWidth: 280,
      formatter: (_, dump) => {
        const showCopiedState = clipboard.copied && copiedDumpId === dump.id;

        return (
          <Group>
            <Button
              size="xs"
              variant="outline"
              leftIcon={<Book width={16} height={16} />}
              onClick={() =>
                window.open(
                  getFileUrl(dump.access_token),
                  '_blank',
                  'noopener noreferrer'
                )
              }
            >
              Preview
            </Button>

            <Button
              onClick={() => {
                setCopiedDumpId(dump.id);
                clipboard.copy(getFileUrl(dump.access_token));
              }}
              color={showCopiedState ? 'green' : 'blue_accent'}
              size="xs"
              variant="outline"
              leftIcon={
                showCopiedState ? (
                  <TickSquare width={16} height={16} />
                ) : (
                  <Copy width={16} height={16} />
                )
              }
            >
              {showCopiedState ? 'Copied!' : 'Copy link'}
            </Button>
          </Group>
        );
      },
    },
  ];

  return (
    <Box h="100%" p="md">
      <SmartTable<StateDumpType | PartnerStateDumpType>
        keyField="id"
        exportParams={{ isEnabled: false }}
        noColumnsSelection
        columns={columns}
        defaultSorted={[{ id: 'created_at', desc: true }]}
        noDataIndication={{ title: 'No state dumps' }}
        {...tableProps}
      />
    </Box>
  );
}
