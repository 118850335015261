import { Anchor, Text } from '@mantine/core';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { AuditLogType } from '@portals/types';

interface ClaimDeviceProps {
  auditLog: AuditLogType;
}

export const ClaimDevice: FC<ClaimDeviceProps> = ({ auditLog }) => {
  return (
    <Text size="sm">
      Claimed {auditLog?.params[0]?.model}:{' '}
      <Anchor
        size="sm"
        component={Link}
        to={`/models/${auditLog?.params[0]?.id}`}
      >
        {auditLog?.params[0]?.display_name}
      </Anchor>
    </Text>
  );
};
