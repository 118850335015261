import { StoreListing } from '@portals/api/partners';
import { PaymentIntervalEnum } from '@portals/types';

import { PaymentSettingsType } from '../../types/store-listings.types';

export enum StepId {
  ChooseProduct = 'chooseProduct',
  ProductInformation = 'productInformation',
  PaymentSettings = 'paymentSettings',
  SubscriptionCancellation = 'subscriptionCancellation',
}

export interface StoreListingWizardContext {
  intent: 'create' | 'edit' | 'publish';
  storeListing: StoreListing | undefined;
  productOverrides:
    | {
        productName: string;
        productDescription: string;
        productCategory: string | null;
        productImageUrl: string | null;
        productSubtitle: string | null;
      }
    | undefined;
  productNameError: string;
  productDescriptionError: string;
  selectedProduct: StoreListing['product'] | undefined;
  paymentSettings: Partial<PaymentSettingsType>;
  paymentSettingsAccordionValues: Array<PaymentIntervalEnum>;
  paymentSettingsGeneralError: string | undefined;
  isBasePriceEnabled: boolean;
  requireCancellationReason: boolean;
  slug: string | undefined | null;
  slugError: string;
  closeModal: OnCloseCallback;
}

export type OnCloseCallback = (params: { confirmBeforeClose: boolean }) => void;
