import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { AuditLogType } from '@portals/types';

interface InvitedNewPartnerProps {
  auditLog: AuditLogType;
}

export function InvitedNewPartner({ auditLog }: InvitedNewPartnerProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const emailParam = paramsMap['email'];

  return (
    <Text size="sm">
      Invited new partner via email:{' '}
      <Text span weight={700}>
        {emailParam.value}
      </Text>
    </Text>
  );
}
