import {
  statsQueryKeys,
  STORE_MANAGEMENT_STATS_BASE_URL,
} from './stats.constants';
import { StoreManagementStats } from './stats.types';
import { useApiQuery } from '../../hooks';

export function useStoreManagementStats() {
  return useApiQuery<StoreManagementStats>(
    STORE_MANAGEMENT_STATS_BASE_URL,
    statsQueryKeys.storeManagement,
    {
      staleTime: 0,
    }
  );
}
