import { createStyles } from '@mantine/core';
import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { ProductCard } from '@portals/framework';

import { StoreListingProductCardPrice } from './StoreListingProductCardPrice';
import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

interface StoreListingProductCardProps {
  product: StoreListing['product'] | undefined;
  productImageUrl: string | null | undefined;
  productCategory: string | null | undefined;
  productName: string | undefined;
  productSubtitle: string | null | undefined;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  isBasePriceEnabled?: boolean;
  className?: string;
}

export function StoreListingProductCard({
  product,
  productImageUrl,
  productName,
  productCategory,
  productSubtitle,
  paymentSettings,
  isBasePriceEnabled = true,
  className,
}: StoreListingProductCardProps) {
  const { classes, cx } = useStyles();

  return (
    <ProductCard className={cx(classes.card, className)}>
      <ProductCard.Image
        imageUrl={productImageUrl}
        category={productCategory}
      />

      <ProductCard.Details name={productName ?? ''} subtitle={productSubtitle}>
        <StoreListingProductCardPrice
          product={product}
          paymentSettings={paymentSettings}
          showBasePrice={isBasePriceEnabled}
        />
      </ProductCard.Details>
    </ProductCard>
  );
}

const useStyles = createStyles(() => ({
  card: {
    width: '100%',
    maxWidth: 370,
    filter: 'drop-shadow(0px 11px 18px rgba(6, 72, 105, 0.05))',
  },
}));
