import { Button, createStyles, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useCreateDeviceModelWidgetsSection } from '@portals/api/partners';
import { ModalProps } from '@portals/framework';

import { defaultCreateSectionInitialValues } from './CreateOrEditDeviceWidgetsSectionModal';

export interface DuplicateDeviceWidgetsSectionModalProps
  extends ModalProps<{
    modelId: string;
    initialValues: typeof defaultCreateSectionInitialValues;
  }> {}

export function DuplicateDeviceWidgetsSectionModal({
  closeMe,
  data,
}: DuplicateDeviceWidgetsSectionModalProps) {
  const { modelId, initialValues } = data;

  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      ...initialValues,
      name: `${initialValues.name} (copy)`,
    },
  });

  const createDeviceModelWidgetsSection = useCreateDeviceModelWidgetsSection({
    modelId,
    successMessage: 'Section duplicated successfully',
  });

  const handleSubmit = ({ name, path, isLabOnly }: typeof form.values) => {
    if (name === initialValues.name) {
      form.setFieldError(
        'name',
        'Name is already taken, please provide a different name'
      );
      return;
    }

    createDeviceModelWidgetsSection.mutate(
      {
        name,
        condition: { path, isLabOnly },
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      size={645}
      padding="xxl"
      title={`Duplicate ${initialValues.name}`}
    >
      <form onSubmit={form.onSubmit(handleSubmit)} className={classes.form}>
        <TextInput
          label="Section name"
          placeholder="Enter section name"
          required
          {...form.getInputProps('name')}
        />

        <Group position="right" mt="xl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button type="submit">Duplicate section</Button>
        </Group>
      </form>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xl,
  },
}));
