import {
  Group,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { ToggleWidgetFormType } from './toggle-form.types';
import { ColorSelector } from '../../common/ColorSelector';
import { IconSelector } from '../../common/IconSelector';
import {
  InputLabelWithTooltip,
  SelectItemComponent,
} from '../../common/input-helpers';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface ToggleWidgetFormProps {
  form: UseFormReturnType<ToggleWidgetFormType>;
  commands: Array<{ label: string; value: string; disabled: boolean }>;
  params: Array<{ label: string; value: string; disabled: boolean }>;
  onAddCustomColor: OnAddCustomColorFn | undefined;
  colors: Array<WidgetColorType> | undefined;
}

export function ToggleWidgetForm({
  form,
  commands,
  params,
  colors,
  onAddCustomColor,
}: ToggleWidgetFormProps) {
  return (
    <Stack spacing="xl" pr={30}>
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          data-testid="toggle-title-input"
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}
      </Stack>

      <Input.Wrapper label="Icon">
        <IconSelector
          selectedIconName={form.values.icon_name}
          onChange={(iconName) => form.setFieldValue('icon_name', iconName)}
        />
      </Input.Wrapper>

      <Input.Wrapper label="Color">
        <ColorSelector
          onAddCustomColor={onAddCustomColor}
          colors={colors}
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
        />
      </Input.Wrapper>

      <Stack>
        <Text size="sm" color="gray.9">
          Settings
        </Text>

        <TextInput
          {...form.getInputProps('telemetry')}
          withAsterisk={false}
          data-testid="toggle-telemetry-input"
          label={
            <InputLabelWithTooltip
              tooltipLabel="The key to obtain boolean telemetry data used to determine the toggle's state"
              label="Boolean Telemetry Key"
              data-testid="boolean-telemetry-key-input"
              Icon={QuestionCircle}
            />
          }
          required
        />

        <Group grow>
          <Select
            data={commands}
            searchable
            clearable
            required
            withinPortal
            data-testid="toggle-command-select"
            labelProps={{
              w: '100%',
            }}
            withAsterisk={false}
            label={
              <InputLabelWithTooltip
                tooltipLabel="The command sent to the device when the widget is toggled"
                label="Toggle Command"
                Icon={QuestionCircle}
              />
            }
            {...form.getInputProps('command_name')}
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                disabledLabel="Command must have a boolean field"
                Icon={InfoCircle}
              />
            )}
          />

          <Select
            data={params}
            searchable
            clearable
            required
            withinPortal
            disabled={!form.values.command_name}
            withAsterisk={false}
            data-testid="toggle-command-param-select"
            placeholder={
              !form.values.command_name
                ? 'Select command'
                : 'Select command param'
            }
            label={
              <InputLabelWithTooltip
                tooltipLabel="The key of the boolean parameter sent with the toggle command"
                label="Boolean Parameter"
                Icon={QuestionCircle}
              />
            }
            {...form.getInputProps('command_param_key')}
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                Icon={InfoCircle}
                disabledLabel="Only boolean paramaters are supported"
              />
            )}
          />
        </Group>
      </Stack>
    </Stack>
  );
}
