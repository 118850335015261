import { useApiQuery } from '../../hooks/query';
import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
} from './device-models.constants';
import { AvailableApiVersionsType } from './device-models.types';

export const useDeviceModelApiVersions = () =>
  useApiQuery<AvailableApiVersionsType>(
    `${DEVICE_MODELS_API_URL}/available_api_versions`,
    deviceModelsQueryKeys.apiVersions.all()
  );
