import { Group } from '@mantine/core';
import { useElementSize, useListState } from '@mantine/hooks';
import { map } from 'lodash/fp';
import React from 'react';
import { useUpdateEffect } from 'react-use';

import { SegmentHandle } from './segment-handle/SegmentHandle';
import { SegmentedRangeBars } from './SegmentedRangeBars';
import { BarIndicatorSegmentType } from '../../bar-indicator';
import {
  NumberFormatType,
  OnAddCustomColorFn,
  WidgetColorType,
} from '../../widgets.types';

interface SegmentedRangeSelectorProps {
  range: { min: number; max: number };
  segments: Array<BarIndicatorSegmentType & { disabled?: boolean }>;
  onRemoveSegment: (segmentIndex: number) => void;
  onUpdateSegmentColor: (segmentIndex: number, color: WidgetColorType) => void;
  onUpdateSegmentMax: (segmentIndex: number, max: number) => void;
  formatter?: (value: number) => string | undefined;
  numberFormat: NumberFormatType;
  numOfDecimals: number;
  colors?: Array<WidgetColorType> | undefined;
  onAddCustomColor?: OnAddCustomColorFn | undefined;
}

export function SegmentedRangeSelector({
  range,
  segments,
  onRemoveSegment,
  onUpdateSegmentColor,
  onUpdateSegmentMax,
  numberFormat,
  numOfDecimals,
  colors,
  onAddCustomColor,
}: SegmentedRangeSelectorProps) {
  const { ref, width } = useElementSize<HTMLDivElement>();

  const [localSegmentsMax, localSegmentsMaxHandler] = useListState(
    map('max', segments)
  );

  useUpdateEffect(
    function syncLocalSegments() {
      localSegmentsMaxHandler.setState(map('max', segments));
    },
    [segments]
  );

  return (
    <Group pos="relative" w="100%" h={165} align="center" ref={ref}>
      {width ? (
        <>
          {segments.map((segment, index) => (
            <SegmentHandle
              colors={colors}
              onAddCustomColor={onAddCustomColor}
              key={segment.id}
              containerRef={ref}
              index={index}
              isDisabled={Boolean(segment.disabled)}
              color={segment.color}
              segmentMax={localSegmentsMax[index]}
              leftSideSegmentMax={localSegmentsMax?.[index - 1]}
              rightSideSegmentMax={localSegmentsMax?.[index + 1]}
              onRemoveSegment={onRemoveSegment}
              onChangeSegmentColor={onUpdateSegmentColor}
              onUpdateSegmentMax={onUpdateSegmentMax}
              onUpdateLocalSegmentMax={localSegmentsMaxHandler.setItem}
              containerWidth={width}
              globalRange={range}
              numberFormat={numberFormat}
              numOfDecimals={numOfDecimals}
            />
          ))}

          <SegmentedRangeBars
            segmentsMax={localSegmentsMax}
            segmentsColors={map('color', segments)}
            globalRange={range}
          />
        </>
      ) : null}
    </Group>
  );
}
