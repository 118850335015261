import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  useCreatePartner,
  UseCreatePartnerRequestPayload,
} from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { switchTenant } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

interface CreatePartnerProps extends ModalProps {}

type FormValues = Omit<UseCreatePartnerRequestPayload, 'is_internal_tenant'> & {
  tenant_type: UseCreatePartnerRequestPayload['tenant_type'] | null;
  tenantVisibility: 'demo' | 'customer' | null;
};

export function CreatePartner({ closeMe }: CreatePartnerProps) {
  const dispatch = useDispatch();
  const createPartner = useCreatePartner();

  const form = useForm<FormValues>({
    initialValues: {
      display_name: '',
      name: '',
      emails: '',
      admin_contact_email: '',
      admin_contact_name: '',
      finance_contact_email: '',
      finance_contact_name: '',
      tenantVisibility: null,
      tenant_type: null,
    },
  });

  const onSubmit = (values: typeof form.values) => {
    const { tenantVisibility, ...formValues } = values;

    createPartner.mutate(
      {
        ...formValues,
        is_internal_tenant: tenantVisibility === 'demo',
      },
      {
        onSuccess: (newTenant) => {
          dispatch(
            switchTenant(
              { id: newTenant.id, name: newTenant.name },
              TenantType.Partner
            )
          );
        },
      }
    );
  };

  return (
    <Modal opened onClose={closeMe} centered title="Create a new Partner">
      <LoadingOverlay visible={createPartner.isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="md">
          <TextInput
            {...form.getInputProps('name')}
            required
            label="Name"
            placeholder="Lower case name, without spaces"
            data-testid="name-input"
          />

          <TextInput
            {...form.getInputProps('display_name')}
            required
            label="Display Name"
            placeholder="User friendly display name"
            data-testid="display-name-input"
          />

          <Textarea
            {...form.getInputProps('emails')}
            required
            label="Admins Emails"
            placeholder="Comma-seperated list of emails"
            rows={6}
            data-testid="admin-emails-input"
          />

          <Divider color="gray.2" />

          <TextInput
            {...form.getInputProps('admin_contact_email')}
            required
            label="Admin Contact Email"
            type="email"
            data-testid="admin-contact-email-input"
          />

          <TextInput
            {...form.getInputProps('admin_contact_name')}
            required
            label="Admin Contact Name"
            data-testid="admin-contact-name-input"
          />

          <Divider color="gray.2" />

          <TextInput
            {...form.getInputProps('finance_contact_email')}
            required
            label="Finance Contact Email"
            type="email"
            data-testid="finance-contact-email-input"
          />

          <TextInput
            {...form.getInputProps('finance_contact_name')}
            required
            label="Finance Contact Name"
            data-testid="finance-contact-name-input"
          />

          <Divider color="gray.2" />

          <Select
            {...form.getInputProps('tenant_type')}
            required
            label="Tenant Type"
            data-testid="tenant-type-dropdown-input"
            placeholder="Select tenant type"
            data={[
              { label: 'Private cloud', value: 'private_cloud' },
              { label: 'Hybrid Connect+', value: 'hybrid_cec' },
              { label: 'Connect+ / CP with models', value: 'cec' },
              { label: 'Channel partner', value: 'channel_partner' },
            ]}
          />

          <Select
            {...form.getInputProps('tenantVisibility')}
            required
            label="Tenant Visibility"
            data-testid="tenant-visibility-dropdown-input"
            placeholder="Select tenant visibility"
            data={[
              { label: 'Demo', value: 'demo' },
              { label: 'Customer', value: 'customer' },
            ]}
          />

          <Group py="lg" position="right">
            <Button
              variant="default"
              onClick={closeMe}
              data-testid="cancel-button"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              loading={createPartner.isLoading}
              data-testid="submit-button"
            >
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
