import {
  Button,
  createStyles,
  getStylesRef,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { filter, includes } from 'lodash/fp';
import React from 'react';

import {
  useDeviceModels,
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import { ModalProps, PolicyKey, useOpenPolicyModal } from '@portals/framework';
import { ReactComponent as BagCrossIcon } from '@portals/icons/bulk/bag-cross.svg';
import { ReactComponent as TermsOfServiceIcon } from '@portals/icons/bulk/book-1.svg';
import { ReactComponent as DeviceModelIcon } from '@portals/icons/bulk/box-1.svg';
import { ReactComponent as StoreAddressIcon } from '@portals/icons/bulk/building-4.svg';
import { ReactComponent as PrivacyPolicyIcon } from '@portals/icons/bulk/shield-security.svg';
import { ReactComponent as ArrowRight1 } from '@portals/icons/linear/arrow-right-1.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';

import { PublishDeviceModelsSummarizedModalProps } from './PublishDeviceModelsSummarized';

export interface AddRequiredStoreSettingsModalData {
  unpublishedDeviceModelsIds: Array<string> | undefined;
  onFulfillment: () => void;
}

interface AddRequiredStoreSettingsModalProps
  extends ModalProps<AddRequiredStoreSettingsModalData> {}

export function AddRequiredStoreSettings({
  closeMe,
  data: { unpublishedDeviceModelsIds, onFulfillment },
}: AddRequiredStoreSettingsModalProps) {
  const { classes, theme } = useStyles();
  const openModal = useOpenModal();
  const openPolicyModal = useOpenPolicyModal();
  const storeSettings = useStoreSettings();
  const updateStoreSettings = useUpdateStoreSettings();
  const allDeviceModels = useDeviceModels();

  const unpublishedDeviceModels = filter(
    (deviceModel) =>
      includes(deviceModel.id, unpublishedDeviceModelsIds) &&
      !deviceModel.active,
    allDeviceModels?.data
  );

  if (!allDeviceModels.isFetched) {
    return <LoadingOverlay visible />;
  }

  const onSubmitPolicy = async (
    contentToSave: string,
    policyKey: PolicyKey
  ) => {
    await updateStoreSettings.mutateAsync({
      [policyKey]: contentToSave,
    });
  };

  const onOpenPolicyModal = (policyKey: PolicyKey) => {
    openPolicyModal({
      policyKey,
      editorContent: storeSettings.data?.[policyKey],
      isEditable: true,
      onSubmit: (contentToSave) => onSubmitPolicy(contentToSave, policyKey),
    });
  };

  const isUnpublishedModels = unpublishedDeviceModels.length > 0;
  const isMissingTermsOfService = !storeSettings.data?.terms_of_service;
  const isMissingPrivacyPolicy = !storeSettings.data?.privacy_policy;
  const isMissingCancellationsAndReturnsPolicy =
    !storeSettings.data?.cancellations_and_returns_policy;
  // Current way to check if there's an existing tax address,
  // simply check one of the required fields
  const isMissingAddress = !storeSettings.data?.address_1;

  const rows: Array<{
    key: string;
    label: string;
    isMissingPreCondition: boolean;
    CategoryIcon: typeof DeviceModelIcon;
    handleOnClick: () => void;
  }> = [];

  if (unpublishedDeviceModelsIds !== undefined) {
    rows.push({
      key: 'device_models',
      label: 'Publish Model',
      isMissingPreCondition: isUnpublishedModels,
      CategoryIcon: DeviceModelIcon,
      handleOnClick: () => {
        openModal<PublishDeviceModelsSummarizedModalProps['data']>(
          'PublishDeviceModelsSummarized',
          {
            deviceModels: unpublishedDeviceModels,
          }
        );
      },
    });
  }

  rows.push(
    {
      key: 'terms_of_service',
      label: 'Set Terms of service',
      isMissingPreCondition: isMissingTermsOfService,
      CategoryIcon: TermsOfServiceIcon,
      handleOnClick: () => onOpenPolicyModal('terms_of_service'),
    },
    {
      key: 'privacy_policy',
      label: 'Set Privacy policy',
      isMissingPreCondition: isMissingPrivacyPolicy,
      CategoryIcon: PrivacyPolicyIcon,
      handleOnClick: () => onOpenPolicyModal('privacy_policy'),
    },
    {
      key: 'cancellations_and_returns_policy',
      label: 'Set Cancellations and Returns Policy',
      isMissingPreCondition: isMissingCancellationsAndReturnsPolicy,
      CategoryIcon: BagCrossIcon,
      handleOnClick: () =>
        onOpenPolicyModal('cancellations_and_returns_policy'),
    },
    {
      key: 'address_1',
      label: 'Set Store Address',
      isMissingPreCondition: isMissingAddress,
      CategoryIcon: StoreAddressIcon,
      handleOnClick: () =>
        openModal('AddTaxAddress', {
          description: 'Please fill out your tax address',
        }),
    }
  );

  return (
    <Modal opened onClose={closeMe} title="Publish Product">
      <Stack spacing="md">
        {rows.map(
          ({
            key,
            label,
            isMissingPreCondition,
            CategoryIcon,
            handleOnClick,
          }) => (
            <Group key={key} className={classes.row} onClick={handleOnClick}>
              <CategoryIcon
                width={24}
                height={24}
                color={theme.fn.primaryColor()}
              />

              <Text
                className={classes.label}
                data-testid={`add-required-store-settings-label-${key}`}
              >
                {label}
              </Text>

              {isMissingPreCondition ? (
                <ArrowRight1 className={classes.statusIcon} />
              ) : (
                <>
                  <TickCircle color={theme.colors.teal[4]} />
                  <Text
                    color={theme.colors.teal[4]}
                    data-testid="completed-tag"
                  >
                    Completed!
                  </Text>
                </>
              )}
            </Group>
          )
        )}

        <Group grow spacing="md">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            onClick={() => {
              onFulfillment();
              closeMe();
            }}
            disabled={
              isUnpublishedModels ||
              isMissingTermsOfService ||
              isMissingPrivacyPolicy ||
              isMissingAddress
            }
            data-testid="add-required-store-settings-modal-publish-button"
          >
            Save & Publish
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  label: {
    flexGrow: 2,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[9],
  },

  statusIcon: {
    ref: getStylesRef('statusIcon'),
    width: 20,
    height: 20,
    color: theme.colors.blue_gray[5],
    transition: '0.2s ease-in',
  },

  row: {
    justifyContent: 'space-between',
    padding: theme.spacing.lg,
    cursor: 'pointer',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },

    [`&:hover .${getStylesRef('statusIcon')}`]: {
      transform: 'translateX(50%)',
    },
  },
}));
