import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { AuditLogType } from '@portals/types';

interface InvitedExistingPartnerProps {
  auditLog: AuditLogType;
}

export function InvitedExistingPartner({
  auditLog,
}: InvitedExistingPartnerProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const partnerParam = paramsMap['partner'];

  return (
    <Text size="sm">
      Invited partner:{' '}
      <Text span weight={700}>
        {partnerParam.display_name}
      </Text>{' '}
      to connect
    </Text>
  );
}
