import { LoadingOverlay } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import {
  configurationQueryKeys,
  usePartnerConfig,
  usePortalConfig,
  useUpdatePortalConfig,
} from '@portals/api/partners';
import {
  useUpdateOnboardingStep,
  WizardOnTransition,
  WizardProvider,
  WizardTransitionType,
} from '@portals/framework';
import { PartnerConfigurationType, TenantType } from '@portals/types';

import { STEPS } from './constants';
import OnboardingLayout from './OnboardingLayout';
import { OnboardingWizardContextDataType, StepId } from './types';

export function OnboardingWizard() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const partnerConfig = usePartnerConfig();

  const updatePortalConfig = useUpdatePortalConfig();
  const updateOnboardingStep = useUpdateOnboardingStep<StepId>();
  const portalConfig = usePortalConfig();

  const { design } = portalConfig.data || {};

  const [contextData, setContextData] =
    useState<OnboardingWizardContextDataType>({
      // Domain
      on_xyte_domain: partnerConfig?.name,

      // Design
      design,
    });

  const onTransition: WizardOnTransition<
    OnboardingWizardContextDataType,
    StepId
  > = async ({ transitionType, to }) => {
    if (transitionType === 'prev') {
      return true;
    }

    try {
      await updatePortalConfig.mutateAsync({
        updatedConfig: {
          design: contextData.design,
        },
      });

      const updatedStepId =
        transitionType === WizardTransitionType.Finish
          ? 'complete'
          : (to as StepId);

      await updateOnboardingStep.mutateAsync({ stepId: updatedStepId });

      queryClient.setQueryData<PartnerConfigurationType>(
        configurationQueryKeys.config,
        (config) => ({
          ...config,
          [TenantType.Partner]: {
            ...config[TenantType.Partner],
            onboarding_step: updatedStepId,
          },
        })
      );

      if (transitionType === WizardTransitionType.Finish) {
        navigate('/');
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  if (!partnerConfig) {
    return <LoadingOverlay visible />;
  }

  if (partnerConfig.onboarding_step === 'complete') {
    return <Navigate to={{ pathname: '/', search: location.search }} replace />;
  }

  return (
    <WizardProvider<OnboardingWizardContextDataType, StepId>
      initialStep={
        (partnerConfig.onboarding_step as StepId) || StepId.CustomUrl
      }
      steps={STEPS}
      contextData={contextData}
      setContextData={setContextData}
      onTransition={onTransition}
    >
      <OnboardingLayout />
    </WizardProvider>
  );
}
