import { filter } from 'lodash/fp';
import { useEffect, useState } from 'react';

import { ItemsIDs, TransferListItems } from './transfer-list.types';

interface UseTransferList<TItem extends object> {
  transferableItems: TransferListItems<TItem>;
  onTransferableItemsMove: () => void;
  onTransferableItemCheck: (itemID: string) => void;
  transferableCheckedItems: ItemsIDs;

  transferredItems: TransferListItems<TItem>;
  onTransferredItemsMove: () => void;
  onTransferredItemCheck: (itemID: string) => void;
  transferredCheckedItems: ItemsIDs;

  getIsChecked: (
    type: 'transferable' | 'transferred',
    itemId: string
  ) => boolean;
}

export function useTransferList<TItem extends object>(
  initialTransferableItems: TransferListItems<TItem> | undefined,
  initialTransferredItems: TransferListItems<TItem> | undefined
): UseTransferList<TItem> {
  const [transferableItems, setTransferableItems] = useState<
    TransferListItems<TItem>
  >(initialTransferableItems || []);

  const [transferredItems, setTransferredItems] = useState<
    TransferListItems<TItem>
  >(initialTransferredItems || []);

  const [transferableCheckedItems, setTransferableCheckedItems] =
    useState<ItemsIDs>([]);
  const [transferredCheckedItems, setTransferredCheckedItems] =
    useState<ItemsIDs>([]);

  useEffect(() => {
    if (initialTransferableItems) {
      setTransferableItems(initialTransferableItems);
    }
  }, [initialTransferableItems]);

  const onTransferableItemsMove = () => {
    const itemsToMove: TransferListItems<TItem> = filter(
      ({ id }) => transferableCheckedItems.includes(id),
      transferableItems
    );

    setTransferableItems((currItems) =>
      filter(({ id }) => !transferableCheckedItems.includes(id), currItems)
    );
    setTransferredItems((currItems) => [...currItems, ...itemsToMove]);

    setTransferableCheckedItems([]);
    setTransferredCheckedItems([]);
  };

  const onTransferredItemsMove = () => {
    const itemsToMove: TransferListItems<TItem> = filter(
      ({ id }) => transferredCheckedItems.includes(id),
      transferredItems
    );

    setTransferredItems((currItems) =>
      filter(({ id }) => !transferredCheckedItems.includes(id), currItems)
    );
    setTransferableItems((currItems) => [...currItems, ...itemsToMove]);

    setTransferableCheckedItems([]);
    setTransferredCheckedItems([]);
  };

  const onTransferableItemCheck = (itemID: string) => {
    setTransferredCheckedItems([]);
    setTransferableCheckedItems((currItems) =>
      currItems.includes(itemID)
        ? filter((id) => id !== itemID, currItems)
        : [...currItems, itemID]
    );
  };

  const onTransferredItemCheck = (itemID: string) => {
    setTransferableCheckedItems([]);
    setTransferredCheckedItems((currItems) =>
      currItems.includes(itemID)
        ? filter((id) => id !== itemID, currItems)
        : [...currItems, itemID]
    );
  };

  const getIsChecked = (
    type: 'transferable' | 'transferred',
    itemId: string
  ) => {
    if (type === 'transferable') {
      return transferableCheckedItems.includes(itemId);
    }

    return transferredCheckedItems.includes(itemId);
  };

  return {
    // Transferable items
    transferableItems,
    onTransferableItemsMove,
    onTransferableItemCheck,
    transferableCheckedItems,

    // Transferred items
    transferredItems,
    onTransferredItemsMove,
    onTransferredItemCheck,
    transferredCheckedItems,

    // Utils
    getIsChecked,
  };
}
