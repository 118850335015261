import { Button, Modal, NumberInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { useCreateDeviceModelHardwareKey } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface AddHardwareKeyProps extends ModalProps<{ modelId: string }> {}

interface FormValues {
  name: string;
  maxDevicesNumber: number | undefined;
}

export function AddHardwareKey({
  closeMe,
  data: { modelId },
}: AddHardwareKeyProps) {
  const createHardwareKey = useCreateDeviceModelHardwareKey(modelId);

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      maxDevicesNumber: undefined,
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    if (!values.maxDevicesNumber) return;

    try {
      await createHardwareKey.mutateAsync({
        friendly_name: values.name,
        expected_device_count: values.maxDevicesNumber,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Add Hardware Key">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalBody>
          <Stack>
            <TextInput
              required
              label="Display name"
              {...form.getInputProps('name')}
            />

            <NumberInput
              required
              label="Max devices number"
              min={1}
              max={2_147_483_647}
              {...form.getInputProps('maxDevicesNumber')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit" loading={createHardwareKey.isLoading}>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
