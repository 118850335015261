import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  STORE_SETTINGS_API_URL,
  storeSettingsQueryKeys,
} from './store-settings.constants';
import {
  CurrencyStoreListing,
  PartnerStoreSettingsType,
} from './store-settings.types';
import { useApiQuery } from '../../hooks/query';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { storeListingsQueryKeys } from '../store-listings/store-listings.constants';

export function useStoreSettings() {
  return useApiQuery<PartnerStoreSettingsType>(
    STORE_SETTINGS_API_URL,
    storeSettingsQueryKeys.all
  );
}

export function useUpdateStoreSettings() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: STORE_SETTINGS_API_URL,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: (updatedStoreSettings: Partial<PartnerStoreSettingsType>) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(updatedStoreSettings),
      });
    },
    onSuccess: (data: PartnerStoreSettingsType) => {
      queryClient.setQueryData(storeSettingsQueryKeys.all, data);
      queryClient.invalidateQueries(storeListingsQueryKeys.all);

      dispatch(toastrSuccess('Updated store settings'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('Update store settings failed', error));
    },
    meta: {
      mutationName: 'useUpdateStoreSettings',
      baseUrl: STORE_SETTINGS_API_URL,
      method: 'PATCH',
    },
  });
}

export function useRemoveCurrency() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${STORE_SETTINGS_API_URL}/remove_currency`,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: ({ currency }: { currency: string }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ currency }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(storeSettingsQueryKeys.all);
      queryClient.invalidateQueries(storeListingsQueryKeys.all);

      dispatch(toastrSuccess('Updated store settings'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('Update store settings failed', error));
    },
    meta: {
      mutationName: 'useRemoveCurrency',
      baseUrl: `${STORE_SETTINGS_API_URL}/remove_currency`,
      method: 'PATCH',
    },
  });
}

export function useAddCurrency() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${STORE_SETTINGS_API_URL}/add_currency`,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: (params: {
      currency: string;
      store_listings: Array<CurrencyStoreListing>;
    }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(storeSettingsQueryKeys.all);
      queryClient.invalidateQueries(storeListingsQueryKeys.all);

      dispatch(toastrSuccess('Updated store settings'));
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('Update store settings failed', error));
    },
    meta: {
      mutationName: 'useAddCurrency',
      baseUrl: `${STORE_SETTINGS_API_URL}/add_currency`,
      method: 'PATCH',
    },
  });
}
