import {
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { TextEditor, TextEditorProps } from '@portals/core';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';

import { usePublishProductsWizard } from '../hooks';
import { ProductHeader } from '../product-header/ProductHeader';

export function ProductDetailsStep() {
  const { classes, theme } = useStyles();
  const openModal = useOpenModal();
  const { contextData } = usePublishProductsWizard();

  const product = contextData.products?.find(
    (product) => product.id === contextData.activeProductId
  );

  if (!product) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack p={32}>
      <ProductHeader product={product} />

      <Divider />

      <Text
        className={classes.editProductLink}
        onClick={() =>
          openModal('CreateProductWizard', { productToEdit: product })
        }
      >
        <Group spacing="xs">
          <Edit width={14} height={14} />
          <span>Edit Product</span>
        </Group>
      </Text>

      <VerticalScrollBar>
        {product.description ? (
          <TextEditor
            styles={rteStyles}
            editorParams={{ content: product?.description, editable: false }}
          />
        ) : (
          <Text sx={{ color: theme.colors.gray[5] }}>
            No description (required)
          </Text>
        )}
      </VerticalScrollBar>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  editProductLink: {
    fontSize: theme.fontSizes.xs,
    color: theme.other.primaryColor,
    cursor: 'pointer',
  },
  description: {
    flex: 1,
    border: 'none',
  },
}));

const rteStyles: TextEditorProps['styles'] = {
  root: {
    border: 'none',

    '.ProseMirror': {
      padding: 0,
    },
  },
};
