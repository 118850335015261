import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import {
  storeSettingsQueryKeys,
  TAX_GROUPS_API_URL,
} from './store-settings.constants';
import { TaxGroupType } from './store-settings.types';
import { useApiQuery } from '../../hooks/query';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

interface TaxGroupDataType {
  groupName: string;
  iconName: string;
  taxCode: string;
}

export function useTaxGroups() {
  return useApiQuery<Array<TaxGroupType>>(
    TAX_GROUPS_API_URL,
    storeSettingsQueryKeys.taxGroups.all
  );
}

export function useCreateTaxGroup() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: TAX_GROUPS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: async ({
      groupName,
      iconName,
      taxCode,
    }: TaxGroupDataType): Promise<TaxGroupType> => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name: groupName,
          icon_name: iconName,
          tax_code: taxCode,
        }),
      });
    },
    onSuccess: async () => {
      // Re-fetch tax groups data
      await queryClient.invalidateQueries(storeSettingsQueryKeys.taxGroups.all);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateTaxGroup',
      baseUrl: TAX_GROUPS_API_URL,
      method: 'POST',
    },
  });
}
