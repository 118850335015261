import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Divider,
  Group,
  NumberInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { FC } from 'react';

import { SortableDndItem } from '@portals/core';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import {
  NumberFieldType,
  PostPurchaseParamsStepFieldType,
  PostPurchaseParamsStepType,
  SelectFieldType,
} from '@portals/types';

interface FieldSpecificInputsProps<
  TField extends PostPurchaseParamsStepFieldType
> {
  form: UseFormReturnType<TField>;
}

function NumberFieldInputs({
  form,
}: FieldSpecificInputsProps<NumberFieldType>) {
  return (
    <Group align="center" spacing="xl" grow>
      <NumberInput
        label="Min (optional)"
        data-testid="min-value-input"
        {...form.getInputProps('configuration.min')}
      />
      <NumberInput
        label="Max (optional)"
        data-testid="max-value-input"
        {...form.getInputProps('configuration.max')}
      />
    </Group>
  );
}

function SelectFieldInputs({
  form,
}: FieldSpecificInputsProps<SelectFieldType>) {
  const { classes } = useSelectFieldInputsStyles();
  const onMove = (dragIndex: number, hoverIndex: number) => {
    form.reorderListItem('configuration.options', {
      from: dragIndex,
      to: hoverIndex,
    });
  };

  return (
    <Stack spacing="md">
      <Divider variant="dashed" label="OPTIONS" />

      {(form.values.configuration?.options || []).map(
        ({ value, label }, index) => (
          <SortableDndItem
            key={index}
            dndTypeIdentifier="option-field"
            itemIndex={index}
            itemUniqueId={index}
            onMove={onMove}
          >
            <Box p={4} className={classes.rowWrapper}>
              <Group h="100%" align="center" pt="lg">
                <ActionIcon
                  c="gray.3"
                  sx={{
                    transform: 'rotate(-90deg) !important',
                  }}
                >
                  <Drag />
                </ActionIcon>
              </Group>

              <TextInput
                required
                data-testid="value-input"
                label="Value"
                sx={{ flexGrow: 1 }}
                {...form.getInputProps(`configuration.options.${index}.value`)}
              />

              <TextInput
                label="Label"
                data-testid="label-input"
                required
                sx={{ flexGrow: 1 }}
                {...form.getInputProps(`configuration.options.${index}.label`)}
              />

              {form.values.configuration?.options?.length > 1 ? (
                <Group h="100%" align="center" pt="lg">
                  <ActionIcon
                    onClick={() =>
                      form.removeListItem('configuration.options', index)
                    }
                    sx={{
                      svg: { width: 16, height: 16 },
                    }}
                  >
                    <Trash />
                  </ActionIcon>
                </Group>
              ) : null}
            </Box>
          </SortableDndItem>
        )
      )}

      <Group position="center" mt="md">
        <Button
          variant="default"
          color="blue_gray"
          leftIcon={<AddSquare />}
          onClick={() =>
            form.insertListItem('configuration.options', {
              value: '',
              label: '',
            })
          }
          sx={{
            svg: {
              height: 16,
              width: 16,
            },
          }}
        >
          Add option
        </Button>
      </Group>
    </Stack>
  );
}

const useSelectFieldInputsStyles = createStyles((theme) => ({
  rowWrapper: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr 1fr max-content',
    gap: theme.spacing.md,
  },
}));

export const FIELD_SPECIFIC_INPUTS: Record<
  PostPurchaseParamsStepType,
  FC<FieldSpecificInputsProps<any>> | null
> = {
  number: ({ form }: FieldSpecificInputsProps<NumberFieldType>) => (
    <NumberFieldInputs form={form} />
  ),
  select: ({ form }: FieldSpecificInputsProps<SelectFieldType>) => (
    <SelectFieldInputs form={form} />
  ),
  text: null,
  textarea: null,
  file: null,
};
