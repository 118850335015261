import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { SupportedCommandType } from '@portals/types';

import { deviceModelsQueryKeys } from './device-models.constants';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { productsQueryKeys } from '../products';
import { supportedCommandsQueryKeys } from '../supported-commands';

const getSupportedCommandsApiUrl = (modelId: string) =>
  `ui/partner/device_models/${modelId}/supported_commands`;

export const useSupportedCommands = (modelId: string) => {
  return useApiQuery<SupportedCommandType[]>(
    getSupportedCommandsApiUrl(modelId),
    deviceModelsQueryKeys.supportedCommands.all(modelId),
    {
      staleTime: 0,
      enabled: Boolean(modelId),
    }
  );
};

export const useDeleteSupportedCommand = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `${getSupportedCommandsApiUrl(modelId)}`,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (commandId) => fetchApiRequest(`${url}/${commandId}`, options),
    onSuccess: () => {
      dispatch(toastrSuccess('Command successfully removed'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.detail(modelId));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Command removal failed', error));
    },
    meta: {
      mutationName: 'useDeleteSupportedCommand',
      baseUrl: `ui/partner/device_models/:id/supported_commands`,
      method: 'DELETE',
    },
  });
};

export const useAddSupportedCommand = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getSupportedCommandsApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<void, ServerError, Partial<SupportedCommandType>>({
    mutationFn: (supportedCommand) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(supportedCommand),
      }),
    onSuccess: async () => {
      dispatch(toastrSuccess('Custom command added'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.detail(modelId));
      queryClient.invalidateQueries(supportedCommandsQueryKeys.published());
      queryClient.invalidateQueries(productsQueryKeys.all);
    },
    onError: ({ error }) => {
      return dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAddSupportedCommand',
      baseUrl: `ui/partner/device_models/:id/supported_commands`,
      method: 'POST',
    },
  });
};

export const useUpdateSupportedCommand = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getSupportedCommandsApiUrl(modelId),
    method: 'PUT',
  });

  return useMutation<void, ServerError, Partial<SupportedCommandType>>({
    mutationFn: (supportedCommand) =>
      fetchApiRequest(`${url}/${supportedCommand.id}`, {
        ...options,
        body: JSON.stringify(supportedCommand),
      }),
    onSuccess: () => {
      dispatch(toastrSuccess('Custom command updated'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.detail(modelId));
      queryClient.invalidateQueries(supportedCommandsQueryKeys.published());
      queryClient.invalidateQueries(productsQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateSupportedCommand',
      baseUrl: `ui/partner/device_models/:id/supported_commands/:id`,
      method: 'PUT',
    },
  });
};

export interface UseMoveSupportedCommandParams {
  commandId: string;
  isUp: boolean;
}

export const useMoveSupportedCommand = (modelId: string) => {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getSupportedCommandsApiUrl(modelId),
    method: 'PUT',
  });

  return useMutation<void, ServerError, UseMoveSupportedCommandParams>({
    mutationFn: ({ commandId, isUp }) =>
      fetchApiRequest(
        `${url}/${commandId}/move_${isUp ? 'up' : 'down'}`,
        options
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.detail(modelId));
    },
    meta: {
      mutationName: 'useMoveSupportedCommand',
      baseUrl: `ui/partner/device_models/:id/supported_commands/:id/move_[up/down]`,
      method: 'PUT',
    },
  });
};
