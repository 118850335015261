import { Box, Button, Menu, Text } from '@mantine/core';
import { drop, forEach, size } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';
import { SupportedCommandType } from '@portals/types';

interface MoreCommands {
  hiddenIndexes: Array<number>;
  supportedCommands: Array<SupportedCommandType>;
  onOpenCommandModal: (command: SupportedCommandType) => void;
  maxNumOfCommands: number;
}

const MoreCommands: FC<MoreCommands> = ({
  onOpenCommandModal,
  hiddenIndexes,
  supportedCommands,
  maxNumOfCommands,
}) => {
  const commandsList = useMemo(() => {
    const nonDisplayedCommands = drop(maxNumOfCommands, supportedCommands);
    const actions: Array<SupportedCommandType> = [];

    forEach<number>((index) => {
      const command = supportedCommands[index];

      if (!command) return;

      actions.push(command);
    }, hiddenIndexes);

    return [...actions, ...nonDisplayedCommands];
  }, [hiddenIndexes, maxNumOfCommands, supportedCommands]);

  if (
    (size(supportedCommands) < maxNumOfCommands && !size(hiddenIndexes)) ||
    !size(commandsList)
  )
    return <Box sx={{ width: 150 }} />;

  return (
    <Menu withinPortal>
      <Menu.Target>
        <Box sx={{ width: 150 }}>
          <Button
            size="xs"
            variant="default"
            leftIcon={<ArrowDown />}
            sx={(theme) => ({
              height: 30,
              color: theme.colors.blue_gray[7],

              svg: {
                width: 16,
                height: 16,
              },
            })}
          >
            <Text size="xs">
              {size(hiddenIndexes) === maxNumOfCommands
                ? 'Commands'
                : 'More Commands'}
            </Text>
          </Button>
        </Box>
      </Menu.Target>

      <Menu.Dropdown>
        {commandsList.map((command) => (
          <Menu.Item
            key={command.id}
            onClick={() => onOpenCommandModal(command)}
          >
            {command.friendly_name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default MoreCommands;
