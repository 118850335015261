import { useViewportSize } from '@mantine/hooks';

const DEFAULT_SCREEN_SIZE = 2880;

export const usePreviewScaleRatio = (
  defaultScreenSize: number = DEFAULT_SCREEN_SIZE
) => {
  const { width } = useViewportSize();

  return width / defaultScreenSize;
};
