import { createStyles, getStylesRef, Group, Image, Text } from '@mantine/core';
import React, { FC } from 'react';

import { PublishableProduct } from '@portals/api/partners';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';

interface ProductListSelectItemProps {
  product: PublishableProduct;
  selectedProductId: string | undefined;
  hasError: boolean;
  statusText: string;
  onClick: (productId: string) => void;
}

export const ProductListSelectItem: FC<ProductListSelectItemProps> = ({
  product,
  onClick,
  selectedProductId,
  hasError,
  statusText,
}) => {
  const { classes, theme, cx } = useStyles();

  return (
    <Group
      spacing="xs"
      className={cx(classes.productRow, {
        selected: selectedProductId === product.id,
      })}
      onClick={() => onClick(product.id)}
    >
      <Image
        width={48}
        height={48}
        radius="md"
        src={product.image_url ?? undefined}
        withPlaceholder
        placeholder={
          <Gallery width={48} height={48} color={theme.colors.blue_gray[2]} />
        }
      />
      <div>
        <Text className={classes.productName}>
          {product.name ? product.name : '--'}
        </Text>
        <Group spacing={6}>
          <div className={cx(classes.dot, { error: hasError })} />
          <Text size="xs" color={theme.colors.blue_gray[4]}>
            {statusText}
          </Text>
        </Group>
      </div>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  productRow: {
    marginBottom: 4,
    paddingInline: theme.spacing.md,
    paddingBlock: 10,
    borderRadius: theme.radius.sm,
    cursor: 'pointer',

    ':hover, &.selected': {
      backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),

      [`& .${getStylesRef('productName')}`]: {
        color: theme.other.primaryColor,
      },
    },
  },
  productName: {
    ref: getStylesRef('productName'),

    color: theme.colors.blue_gray[9],
    fontSize: theme.fontSizes.sm,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors.teal_accent[4],

    '&.error': {
      backgroundColor: theme.colors.red_accent[4],
    },
  },
}));
