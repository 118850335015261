import { Stack, Stepper, StepperProps, Text } from '@mantine/core';
import React from 'react';

import { useCreateOrderWizard } from './hooks';

export function CreateOrderNavigation() {
  const { currentStep, steps } = useCreateOrderWizard();

  const stepIds = Object.keys(steps);

  return (
    <Stack spacing="xl">
      <Text weight={500} size={28} data-testid="new-order-modal-title">
        New order
      </Text>

      <Stepper
        active={stepIds.findIndex((stepId) => stepId === currentStep.id)}
        orientation="vertical"
        styles={stepperStyles}
      >
        <Stepper.Step
          label="Select products"
          data-testid="select-product-step"
        />
        <Stepper.Step label="Order details" data-testid="order-details-step" />
      </Stepper>
    </Stack>
  );
}

const stepperStyles: StepperProps['styles'] = (theme) => {
  return {
    step: {
      position: 'relative',
      width: '100%',
      paddingLeft: 4,
      paddingTop: 4,
      cursor: 'default !important',

      '&:not(:first-of-type)': {
        marginTop: -4,
      },

      '&[data-progress]': {
        ':before': {
          content: '""',
          zIndex: -1,
          position: 'absolute',
          top: 1,
          left: 0,
          display: 'block',
          width: '100%',
          height: 41,
          borderRadius: theme.radius.xl,
          backgroundColor: theme.colors.primary[1],
        },
      },
    },

    stepBody: {
      marginTop: 10,
    },

    verticalSeparator: {
      marginTop: -4,
    },
  };
};
