import {
  ActionIcon,
  Avatar,
  Box,
  Code,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { Identifier } from 'dnd-core';
import { isNumber } from 'lodash/fp';
import React, { FC, MutableRefObject, useMemo } from 'react';

import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { ToggleCell } from '@portals/table';
import { FieldTypeEnum, SupportedCommandFieldType } from '@portals/types';

import { Column, OptionsColumn } from './common';
import { TYPES_ICONS, COMMAND_TYPES } from '../../constants';

export interface FieldPreviewBaseProps {
  field: SupportedCommandFieldType;
  onRemove: (name: string) => void;
  onOpenEditModal: () => void;
  onFieldValueUpdate: (
    fieldName: string,
    valuePath: Array<string>,
    value: any
  ) => void;
}

interface FieldPreviewProps {
  handlerId: Identifier;
  innerRef: MutableRefObject<HTMLDivElement>;
}

const FieldPreview: FC<FieldPreviewBaseProps & FieldPreviewProps> = ({
  field,
  onRemove,
  onFieldValueUpdate,
  onOpenEditModal,
  handlerId,
  innerRef,
}) => {
  const columns = useMemo(() => {
    const columns = [
      <Box
        key="drag-icon"
        c="gray.3"
        sx={{ transform: 'rotate(-90deg) translateY(25%)' }}
      >
        <Drag />
      </Box>,

      <Avatar
        key="avatar"
        color={TYPES_ICONS[field.typeName]?.color}
        sx={{ flexGrow: 0, flexShrink: 0 }}
      >
        {TYPES_ICONS[field.typeName]?.icon}
      </Avatar>,

      <Column
        key="key"
        label="Field key"
        content={
          <Group>
            <Code>{field.name}</Code>
          </Group>
        }
      />,

      <Column
        key="label"
        label="Field label"
        content={<Text size="sm">{field.title}</Text>}
      />,
    ];

    if (field.type === FieldTypeEnum.Number) {
      columns.push(
        ...[
          <Column
            key="min"
            label="Min"
            content={
              <Text size="sm">
                {isNumber(field.inputProps?.min)
                  ? field.inputProps?.min
                  : 'N/A'}
              </Text>
            }
          />,
          <Column
            key="max"
            label="Max"
            content={
              <Text size="sm">
                {isNumber(field.inputProps?.max)
                  ? field.inputProps?.max
                  : 'N/A'}
              </Text>
            }
          />,
        ]
      );
    } else if (
      field.type === FieldTypeEnum.Multiselect ||
      field.type === FieldTypeEnum.Select
    ) {
      columns.push(
        ...[
          <OptionsColumn field={field} key="options" />,

          <div key="placeholder" />,
        ]
      );
    } else {
      // to keep required toggle at the end
      columns.push(
        ...[<div key="placeholder_1" />, <div key="placeholder_2" />]
      );
    }

    columns.push(
      field.type === FieldTypeEnum.Checkbox ? (
        <Column key="required" label="" content={null} />
      ) : (
        <Column
          key="required"
          label="Required"
          content={
            <ToggleCell
              isChecked={field.required as boolean}
              onChange={(isChecked) =>
                onFieldValueUpdate(field.name, ['required'], isChecked)
              }
            />
          }
        />
      )
    );

    return columns;
  }, [field, onFieldValueUpdate]);

  return (
    <Stack spacing="xs" sx={{ position: 'relative' }}>
      <Text size="xs" color="blue_gray">
        {COMMAND_TYPES[field.type]?.value}
      </Text>

      <Group
        p="sm"
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.sm,
        })}
        position="apart"
        data-handler-id={handlerId}
        ref={innerRef}
      >
        <Box
          sx={(theme) => ({
            flex: 1,
            display: 'grid',
            gridTemplateColumns:
              'max-content max-content 2fr 2fr 1fr 0.8fr 0.8fr',
            columnGap: theme.spacing.md,
          })}
        >
          {columns}
        </Box>

        <Group>
          <ActionIcon
            onClick={onOpenEditModal}
            sx={{
              svg: { width: 16, height: 16 },
            }}
          >
            <Edit />
          </ActionIcon>

          <ActionIcon
            onClick={() => onRemove(field.name)}
            sx={{
              svg: { width: 16, height: 16 },
            }}
          >
            <Trash />
          </ActionIcon>
        </Group>
      </Group>
    </Stack>
  );
};

export default FieldPreview;
