import { get } from 'lodash/fp';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { ReactComponent as Refresh2 } from '@portals/icons/linear/refresh-2.svg';
import { FieldTypeEnum } from '@portals/types';
import { NetworkButton } from '@portals/ui';

import {
  getIntegrationParam,
  updateIntegration,
} from '../../../redux/actions/integrations';

const INTEGRATION = 'zoho';
const buildFields = ({ departments }) => [
  {
    name: 'department',
    title: 'Department',
    required: true,
    type: FieldTypeEnum.Select,
    options: departments,
  },
];

const EditDefaults = ({
  integration,
  inProgress,
  updateIntegration,
  getIntegrationParam,
}) => {
  const [, setValues] = useState(integration.params);
  const fields = buildFields(integration.extra);
  const handleSubmit = (values) => {
    updateIntegration(INTEGRATION, values);
  };

  const refresh = () => (
    <NetworkButton
      request={`getIntegration_${INTEGRATION}_all_`}
      className="mr-4 ml-0"
      onClick={() => getIntegrationParam(INTEGRATION, 'all', null, false)}
    >
      <Refresh2 width={15} height={15} />
      <span className="pl-2">Reload Zoho options</span>
    </NetworkButton>
  );

  return (
    <div>
      <AutoFormik
        fields={fields}
        initialValues={integration.params}
        handleSubmit={handleSubmit}
        inProgress={inProgress}
        wrapped={refresh}
        onChange={setValues}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  integration: state.data.integrations[INTEGRATION],
  inProgress: get(['ui', 'network', `integration_${INTEGRATION}`], state),
});

export default connect(mapStateToProps, {
  updateIntegration,
  getIntegrationParam,
})(EditDefaults);
