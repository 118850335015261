import {
  Alert,
  createStyles,
  Group,
  Paper,
  Stack,
  Switch,
  SwitchProps,
  Text,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef } from 'react';
import { useUpdateEffect } from 'react-use';

import { useStoreSettings } from '@portals/api/partners';
import {
  SubscriptionCancellationForm,
  useOpenPolicyModal,
} from '@portals/framework';
import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';

import { DetailsColumn } from '../DetailsColumn';
import { useStoreListingWizard } from '../hooks';

export function SubscriptionCancellationStep() {
  const { classes } = useStyles();

  const { contextData, setContextData } = useStoreListingWizard();

  const openPolicyModal = useOpenPolicyModal();

  const storeSettings = useStoreSettings();

  const ref = useRef<HTMLDivElement | null>(null);

  useUpdateEffect(() => {
    if (contextData.requireCancellationReason) {
      ref.current?.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [contextData.requireCancellationReason]);

  return (
    <DetailsColumn title="Cancellations & Returns">
      <Stack spacing={42}>
        <Paper withBorder radius="lg" py="xxl" px="xl">
          <Text size="md" mb="md" weight={600} color="gray.8">
            Policies
          </Text>

          <Text mb="xxl" color="gray.8">
            Here you can review your store policies regarding cancellations and
            returns. The details you provide here will be vital for your
            customers whenever they consider cancelling a purchase, a
            subscription, or when they wish to return a product. They will also
            be included in subsequent communication emails pertaining to
            cancellations and returns.
            <br />
            <br />
            Please ensure the information provided is clear, factual, and fair
            to safeguard your business and consumers' interests. You can always
            edit your store policies through the store settings.
          </Text>

          <Group
            position="apart"
            className={classes.policyAction}
            onClick={() =>
              openPolicyModal({
                policyKey: 'cancellations_and_returns_policy',
                isEditable: false,
                editorContent:
                  storeSettings.data?.cancellations_and_returns_policy,
              })
            }
          >
            <Text>Store Cancellations Policy</Text>
            <ChevronDownIcon className={classes.chevron} />
          </Group>
        </Paper>

        <Paper withBorder radius="lg" py="xxl" px="xl" ref={ref}>
          <Stack spacing="xxl">
            <Switch
              label="Ask customers for cancellation reason"
              data-testid="require-cancellation-reason-switch"
              labelPosition="left"
              styles={switchStyles}
              checked={contextData.requireCancellationReason}
              onChange={(e) =>
                setContextData((prev) => ({
                  ...prev,
                  requireCancellationReason: e.currentTarget.checked,
                }))
              }
            />

            <AnimatePresence initial={false}>
              {contextData.requireCancellationReason && (
                <motion.div
                  initial={{ y: -100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 50, opacity: 0 }}
                >
                  <Alert icon={<InfoCircleIcon />} radius="md" mb="xxl">
                    This form will automatically prompt customers for feedback
                    when they initiate a subscription cancellation.
                  </Alert>

                  <SubscriptionCancellationForm readOnly />
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
        </Paper>
      </Stack>
    </DetailsColumn>
  );
}

const useStyles = createStyles((theme) => ({
  policyAction: {
    padding: theme.spacing.xl,
    borderRadius: theme.radius.lg,
    backgroundColor: theme.colors.gray[0],
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  chevron: {
    transform: 'rotate(-90deg)',
  },
}));

const switchStyles: SwitchProps['styles'] = (theme) => ({
  body: {
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 600,
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[8],
  },
});
