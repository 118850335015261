import { createStyles, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import { useIsSidebarOpen } from '@portals/redux';

import { CollapsibleSidebarItem } from './CollapsibleSidebarItem';
import { HubSidebarItem, HubSidebarItemWithSubItems } from '../hubs.types';

// @todo: create a shared styles/components for all the common styles (hover, active, bg, color etc)

interface SidebarItemProps {
  sidebarItem: HubSidebarItem;
}

export function SidebarItem({ sidebarItem }: SidebarItemProps) {
  const { classes, cx } = useStyles();

  const isSidebarOpen = useIsSidebarOpen();

  if (sidebarItem.subItems) {
    return (
      <CollapsibleSidebarItem
        sidebarItem={sidebarItem as HubSidebarItemWithSubItems}
      />
    );
  }

  return (
    <Tooltip
      withinPortal
      key={sidebarItem.id}
      label={sidebarItem.label}
      disabled={isSidebarOpen}
      position="right"
      offset={20}
    >
      <NavLink
        data-testid={`sidebar-item-${sidebarItem.id}`}
        to={generatePath(sidebarItem.path)}
        className={cx(classes.sidebarItem, {
          [classes.collapsedSidebarItem]: !isSidebarOpen,
        })}
      >
        <span>{sidebarItem.icon}</span>

        {isSidebarOpen && <Text>{sidebarItem.label}</Text>}
      </NavLink>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.md,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[6],

    '&:hover, &.active': {
      backgroundColor: theme.colors.gray[2],
      color: theme.colors.gray[9],
      textDecoration: 'none',
    },
  },
  collapsedSidebarItem: {
    padding: 10,
  },
}));
