import { Button, Modal } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { ModalBody, ModalFooter } from '@portals/core';

interface EditModeConfirmationModalProps {
  opened: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onConfirm: () => void;
}

export function EditModeConfirmationModal({
  opened,
  onCancel,
  onConfirm,
  onDiscard,
}: EditModeConfirmationModalProps) {
  return (
    <Modal
      opened={opened}
      title="Save Changes?"
      onClose={onCancel}
      styles={modalStyles}
    >
      <ModalBody>
        You have unsaved changes. Would you like to save them?
      </ModalBody>
      <ModalFooter position="right">
        <Button variant="white" color="dark" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="default" onClick={onDiscard}>
          Discard Changes
        </Button>
        <Button onClick={onConfirm}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `0 0 ${theme.spacing.md}`,
  },
  body: {
    padding: 0,
  },
  content: {
    padding: theme.spacing.xxl,
  },
});
