import { find } from 'lodash/fp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AvailablePartnersToInvite,
  useCreatePartnerInvitation,
} from '@portals/api/partners';
import { useWizard } from '@portals/framework';

import {
  InvitePartnerWizardContextDataType,
  PartnerOptionType,
  RelationshipTypeEnum,
  StepIdEnum,
} from './types';

function getOptionFromValue(
  value: PartnerOptionType['value'] | null,
  options: Array<PartnerOptionType>
) {
  if (value) {
    return find({ value }, options) as PartnerOptionType;
  }

  return null;
}

export function useInvitePartnerWizardContext(
  partners: AvailablePartnersToInvite[],
  closeMe: () => void
): InvitePartnerWizardContextDataType {
  const navigate = useNavigate();
  const createPartnerInvitation = useCreatePartnerInvitation();

  const [selectedPartner, setSelectedPartner] =
    useState<PartnerOptionType | null>(null);

  const [isInvitingNewPartner, setIsInvitingNewPartner] = useState(false);
  const [newPartnerEmail, setNewPartnerEmail] = useState('');

  const [selectedRelationshipType, setSelectedRelationshipType] =
    useState<RelationshipTypeEnum | null>(null);
  const [otherRelationshipType, setOtherRelationshipType] = useState('');

  const partnersOptions: Array<PartnerOptionType> = partners.map(
    ({ id, name, display_name, logo }) => ({
      value: id,
      label: display_name,
      name,
      logo,
    })
  );

  const toggleInviteNewPartner = () => {
    setSelectedPartner(null);
    setIsInvitingNewPartner((prev) => !prev);
  };

  const onChangeRelationshipType = (relationshipType: RelationshipTypeEnum) => {
    if (relationshipType !== RelationshipTypeEnum.Other) {
      setOtherRelationshipType('');
    }

    setSelectedRelationshipType(relationshipType);
  };

  const onInviteExisting = async (certified: boolean) => {
    if (!selectedPartner) return;

    try {
      await createPartnerInvitation.mutateAsync({
        partner_id: selectedPartner.value,
        relationship_type:
          selectedRelationshipType === RelationshipTypeEnum.Other
            ? otherRelationshipType
            : (selectedRelationshipType as string),
        certified,
      });

      closeMe();
      navigate('/partners/invitations');
    } catch (e) {
      console.error(e);
    }
  };

  const onInviteNewPartner = async (certified: boolean) => {
    try {
      await createPartnerInvitation.mutateAsync({
        email: newPartnerEmail,
        relationship_type:
          selectedRelationshipType === RelationshipTypeEnum.Other
            ? otherRelationshipType
            : (selectedRelationshipType as string),
        certified,
      });

      closeMe();
      navigate('/partners/invitations');
    } catch (e) {
      console.error(e);
    }
  };

  const onSelectExistingPartner = (
    selectedPartner: PartnerOptionType['value'] | null
  ) => {
    const selectedPartnerOption = getOptionFromValue(
      selectedPartner,
      partnersOptions
    );

    setIsInvitingNewPartner(false);
    setSelectedPartner(selectedPartnerOption);
  };

  return {
    // Select existing or new partner step
    partners: partnersOptions,
    selectedPartner,
    onSelectExistingPartner,
    isInvitingNewPartner,
    toggleInviteNewPartner,

    // New partner step
    newPartnerEmail,
    onSetNewPartnerEmail: setNewPartnerEmail,

    // Partner type step (new/existing)
    selectedRelationshipType,
    onChangeRelationshipType,
    otherRelationshipType,
    setOtherRelationshipType,
    onInviteExisting,
    onInviteNewPartner,

    closeMe,
    isLoading: createPartnerInvitation.isLoading,
  };
}

export function useInvitePartnerWizard() {
  return useWizard<InvitePartnerWizardContextDataType, StepIdEnum>();
}
