import { Box, Group } from '@mantine/core';
import React from 'react';

import { GradientHandle } from './GradientHandle';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

interface GradientType {
  from: WidgetColorType;
  to: WidgetColorType;
}

interface GradientSelectorProps {
  gradient: GradientType;
  onChangeGradient: (gradient: GradientType) => void;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function GradientSelector({
  gradient,
  onChangeGradient,
  colors,
  onAddCustomColor,
}: GradientSelectorProps) {
  return (
    <Box pos="relative" px="md">
      <Group position="apart" w="100%">
        <GradientHandle
          color={gradient.from}
          onChange={(from) => onChangeGradient({ ...gradient, from })}
          horizontalOffset="-20px"
          colors={colors}
          onAddCustomColor={onAddCustomColor}
        />

        <GradientHandle
          color={gradient.to}
          onChange={(to) => onChangeGradient({ ...gradient, to })}
          horizontalOffset="20px"
          colors={colors}
          onAddCustomColor={onAddCustomColor}
        />
      </Group>

      <Box
        w="100%"
        h="8px"
        sx={(theme) => ({
          background: theme.fn.linearGradient(
            90,
            theme.fn.themeColor(gradient.from),
            theme.fn.themeColor(gradient.to)
          ),
        })}
      />
    </Box>
  );
}
