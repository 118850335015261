import { Button, createStyles, Group, Modal, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';

import { useOrganizations } from '@portals/api/partners';
import { ModalProps } from '@portals/framework';

import { ReactComponent as OrbitCloudImage } from '../assets/img/orbit-cloud.svg';
import { useRedirectToCustomerPortal } from '../hooks/customers.hooks';

interface DeviceConnectedProps extends ModalProps {}

export function DeviceConnected({ closeMe }: DeviceConnectedProps) {
  const { classes } = useStyles();

  const redirectToCustomerPortal = useRedirectToCustomerPortal();
  const organizations = useOrganizations();

  const labOrganization = find({ lab: true }, organizations.data);

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      styles={(theme) => ({
        content: {
          padding: '30px 34px 32px !important',
          borderRadius: theme.radius.md,
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        header: {
          margin: 0,
        },
      })}
    >
      <OrbitCloudImage />
      <Text weight={500} className={classes.title}>
        Your first device is now connected!
      </Text>
      <Text size="sm" weight={500} className={classes.text}>
        Use your lab environment to test it before inviting your customers
      </Text>
      <Group noWrap grow w="100%">
        <Button
          variant="default"
          onClick={() => {
            if (labOrganization) {
              redirectToCustomerPortal.redirect({
                customerId: labOrganization.id,
              });
            }
          }}
        >
          Go to Lab
        </Button>
        <Button onClick={closeMe} fullWidth>
          Done
        </Button>
      </Group>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '24px',
    color: `${theme.colors.blue_gray[9]}`,
    margin: `${theme.spacing.md} 0`,
    textAlign: 'center',
  },
  text: {
    color: `${theme.colors.blue_gray[9]}`,
    marginBottom: 57,
  },
}));
