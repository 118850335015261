import { any, find, forEach, isUndefined, keys } from 'lodash/fp';
import { MutableRefObject, useMemo } from 'react';

import { WidgetMetaType } from '../../components/ModelWidgets/types';

export type UseIsWidgetFormValidParams = {
  ref: MutableRefObject<any>;
  widgetMeta: WidgetMetaType;
  form: Record<string, any>;
};

export const useIsWidgetFormValid = ({
  ref,
  widgetMeta,
  form,
}: UseIsWidgetFormValidParams) => {
  const flatWidgetFields = useMemo(() => {
    const fields = [];

    forEach((field) => {
      if (!field.children) fields.push(field);
      else {
        forEach((child) => fields.push(child), field.children);
      }
    }, widgetMeta.fields);

    return fields;
  }, [widgetMeta]);

  return useMemo(() => {
    if (!ref?.current || !form) return false;

    return !any((key) => {
      const fieldMeta = find({ name: key }, flatWidgetFields);

      return fieldMeta.required && (form[key] === '' || isUndefined(form[key]));
    }, keys(form));
  }, [ref, form, flatWidgetFields]);
};
