import { ActionIcon, createStyles, Group, Text, Tooltip } from '@mantine/core';
import { useOs } from '@mantine/hooks';
import { spotlight } from '@mantine/spotlight';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { useIsChannelPartner } from '@portals/api/partners';
import { LaunchNotes } from '@portals/framework';
import { ReactComponent as CommandSquare } from '@portals/icons/linear/command-square.svg';
import { ReactComponent as LifebuoyIcon } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as NewIcon } from '@portals/icons/linear/new.svg';
import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';
import { ReactComponent as Setting2Icon } from '@portals/icons/linear/setting-2.svg';

import {
  HUBS_HEADER_TOOLTIP_OFFSET,
  hubsHeaderTooltipStyles,
} from './common-styles';
import { FeedbackButton } from './FeedbackButton';
import { SuccessCenter } from './SuccessCenter';
import { SystemNotifications } from './SystemNotifications';
import { DASHBOARD_PATHS } from '../../routes/dashboard/dashboard-paths.constants';
import { useDashboardRoutes } from '../../routes/dashboard/DashboardRoutesProvider';

export function HubsHeaderActions() {
  const { classes } = useStyles();

  const os = useOs();

  const { canAccessPath } = useDashboardRoutes();

  const isChannelPartner = useIsChannelPartner();

  const canAccessSettings = canAccessPath(DASHBOARD_PATHS.staticPaths.settings);

  return (
    <Group spacing="xs" noWrap>
      <FeedbackButton />

      {canAccessSettings && (
        <Tooltip
          label="Settings"
          styles={hubsHeaderTooltipStyles}
          offset={HUBS_HEADER_TOOLTIP_OFFSET}
        >
          <ActionIcon
            component={Link}
            size="lg"
            data-testid="settings-button"
            variant="transparent"
            className={classes.actionIcon}
            to={generatePath(DASHBOARD_PATHS.staticPaths.settings)}
          >
            <Setting2Icon />
          </ActionIcon>
        </Tooltip>
      )}

      <Tooltip
        offset={HUBS_HEADER_TOOLTIP_OFFSET}
        styles={hubsHeaderTooltipStyles}
        label={
          <Group>
            <Text>Search...</Text>
            <Group spacing={4}>
              {os === 'macos' ? (
                <CommandSquare width={16} height={16} />
              ) : (
                <Text>Ctrl</Text>
              )}
              <Text>+</Text>
              <Text>K</Text>
            </Group>
          </Group>
        }
      >
        <ActionIcon
          size="lg"
          variant="transparent"
          className={classes.actionIcon}
          onClick={() => spotlight.open()}
        >
          <SearchNormal2 />
        </ActionIcon>
      </Tooltip>

      {!isChannelPartner && (
        <Tooltip
          label="Contact support"
          offset={HUBS_HEADER_TOOLTIP_OFFSET}
          styles={hubsHeaderTooltipStyles}
        >
          <ActionIcon
            component="a"
            href="mailto:support@xyte.io"
            variant="transparent"
            size="lg"
            target="_blank"
            className={classes.actionIcon}
          >
            <LifebuoyIcon />
          </ActionIcon>
        </Tooltip>
      )}

      <LaunchNotes>
        {({ isActive, setIsActive }) => (
          <Tooltip
            label="What's new"
            offset={HUBS_HEADER_TOOLTIP_OFFSET}
            styles={hubsHeaderTooltipStyles}
          >
            <ActionIcon
              onClick={() => setIsActive(true)}
              variant="transparent"
              size="lg"
              id="launch-notes-toggle"
              color={isActive ? 'indigo_accent.3' : 'gray.4'}
            >
              <NewIcon />
            </ActionIcon>
          </Tooltip>
        )}
      </LaunchNotes>

      <Tooltip
        label="Notifications"
        offset={HUBS_HEADER_TOOLTIP_OFFSET}
        styles={hubsHeaderTooltipStyles}
      >
        <SystemNotifications />
      </Tooltip>

      {isChannelPartner && <SuccessCenter />}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  actionIcon: {
    color: theme.colors.gray[4],

    '&:hover': {
      color: theme.colors.gray[4],
    },
  },
}));
