import { Stack, Switch, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { NumericMetricWidgetFormType } from './numeris-metric-form.types';
import { InputLabelWithTooltip } from '../../common/input-helpers';
import { NumberFormatSelector } from '../../common/NumberFormatSelector';

export interface NumericMetricWidgetFormProps {
  form: UseFormReturnType<NumericMetricWidgetFormType>;
}

export function NumericMetricWidgetForm({
  form,
}: NumericMetricWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          data-testid="numeric-metric-title-name-input"
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}

        <motion.div layout="position">
          <NumberFormatSelector
            format={form.values.number_format}
            numOfDecimals={form.values.num_of_decimals}
            onFormatChange={(format) =>
              form.setFieldValue('number_format', format)
            }
            onNumOfDecimalsChange={(numOfDecimals) =>
              form.setFieldValue('num_of_decimals', numOfDecimals)
            }
          />
        </motion.div>

        <motion.div layout="position">
          <TextInput
            {...form.getInputProps('telemetry')}
            withAsterisk={false}
            data-testid="numeric-telemetry-key"
            label={
              <InputLabelWithTooltip
                label="Numeric Telemetry Keys"
                Icon={QuestionCircle}
                tooltipLabel="The key to obtain telemetry data for the metric value"
              />
            }
            required
          />
        </motion.div>
      </Stack>

      <motion.div layout="position">
        <Stack>
          <Switch
            label="Track Measurement Unit"
            data-testid="track-measurement-unit-radio-button"
            {...form.getInputProps('with_measurement_unit', {
              type: 'checkbox',
            })}
          />

          {form.values.with_measurement_unit ? (
            <TextInput
              {...form.getInputProps('measurement_unit')}
              data-testid="measurement-unit-input"
              required
              width={50}
            />
          ) : null}
        </Stack>
      </motion.div>
    </Stack>
  );
}
