import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import {
  PaginatedQueryFilterType,
  PaginationResponse,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import {
  CONNECTED_PARTNERS_API_URL,
  connectedPartnersQueryKeys,
} from './connected-partners.constants';
import {
  ActiveConnectedPartnerType,
  CanceledConnectedPartnerType,
  ConnectedPartner,
} from './connected-partners.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

interface UseActiveConnectionsParams {
  queryOptions?: Partial<
    QueryOptions<PaginationResponse<ActiveConnectedPartnerType>>
  >;
}
export function useActiveConnectionsResponse({
  queryOptions = {},
}: UseActiveConnectionsParams = {}) {
  return useApiQuery<PaginationResponse<ActiveConnectedPartnerType>>(
    `${CONNECTED_PARTNERS_API_URL}/?q[status_eq]=active`,
    connectedPartnersQueryKeys.active(),
    queryOptions
  );
}

interface UseActiveConnectionsResponseWithFilters {
  filters: Array<PaginatedQueryFilterType<ActiveConnectedPartnerType>>;
}

export function useActiveConnectionsResponseWithFilters({
  filters,
}: UseActiveConnectionsResponseWithFilters) {
  const { url, options } = useRequestOptions({
    url: `${CONNECTED_PARTNERS_API_URL}/?q[status_eq]=active`,
  });

  const requestUrl = buildUrlFromFilters({
    url,
    filters,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<ActiveConnectedPartnerType>,
    ServerError,
    ActiveConnectedPartnerType[]
  >({
    queryKey: [connectedPartnersQueryKeys.active(), JSON.stringify(filters)],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
  });
}

export function useCanceledConnectionsResponse() {
  return useApiQuery<PaginationResponse<CanceledConnectedPartnerType>>(
    `${CONNECTED_PARTNERS_API_URL}/?q[status_eq]=canceled`,
    connectedPartnersQueryKeys.canceled()
  );
}

export function useConnectedRelationshipPartnerTypes() {
  return useApiQuery<string[]>(
    `${CONNECTED_PARTNERS_API_URL}/relationship_types`,
    connectedPartnersQueryKeys.relationshipType()
  );
}

export function useConnectedPartners(
  tableState: UsePaginatedTableApiQuery<ConnectedPartner>['tableState'],
  columns: UsePaginatedTableApiQuery<ConnectedPartner>['columns'],
  baseUrl = CONNECTED_PARTNERS_API_URL,
  queryKey = connectedPartnersQueryKeys.all()
) {
  return usePaginatedTableApiQuery<ConnectedPartner>({
    queryKey: [...queryKey, tableState],
    baseUrl,
    columns,
    tableState,
  });
}

export function useUpdateConnectedPartner() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: CONNECTED_PARTNERS_API_URL,
    method: 'POST',
  });

  return useMutation<
    void,
    ServerError,
    { ids: Array<string>; relationship_type: string }
  >({
    mutationFn: (partner: { ids: Array<string>; relationship_type: string }) =>
      fetchApiRequest(`${url}/bulk_update`, {
        ...options,
        body: JSON.stringify(partner),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(connectedPartnersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateConnectedPartner',
      baseUrl: `${CONNECTED_PARTNERS_API_URL}/bulk_update`,
      method: 'POST',
    },
  });
}

interface DisconnectPartnersParams {
  ids: Array<string>;
}

export function useDisconnectPartners() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: CONNECTED_PARTNERS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, DisconnectPartnersParams>({
    mutationFn: (ids) =>
      fetchApiRequest(`${url}/bulk_cancel`, {
        ...options,
        body: JSON.stringify(ids),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(connectedPartnersQueryKeys.all());
    },
    onError: ({ error }) => {
      dispatch(toastrError('Could not update partner', error));
    },
    meta: {
      mutationName: 'useDisconnectPartners',
      baseUrl: `${CONNECTED_PARTNERS_API_URL}/bulk_cancel`,
      method: 'POST',
    },
  });
}

export function useBulkCreateCustomers() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${CONNECTED_PARTNERS_API_URL}/bulk_create`,
    method: 'POST',
  });

  return useMutation<void, ServerError>({
    mutationFn: () => fetchApiRequest(url, options),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useBulkCreateCustomers',
      baseUrl: `${CONNECTED_PARTNERS_API_URL}/bulk_create`,
      method: 'POST',
    },
  });
}

export function useBulkInvitePartners() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${CONNECTED_PARTNERS_API_URL}/bulk_invite`,
    method: 'POST',
  });

  return useMutation<void, ServerError>({
    mutationFn: () => fetchApiRequest(url, options),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useBulkInvitePartners',
      baseUrl: `${CONNECTED_PARTNERS_API_URL}/bulk_invite`,
      method: 'POST',
    },
  });
}
