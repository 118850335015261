import { Button } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import {
  StoreListing,
  useCheckStoreListingSlugAvailability,
  useCreateStoreListing,
  usePublishStoreListing,
  useStoreSettings,
  useUpdateStoreListing,
} from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { mapPaymentSettingsToStoreListingPrices } from '../../../utils/store-listings.utils';
import { DuplicateSlugModalProps } from '../../DuplicateSlugModal';
import {
  useStoreListingWizard,
  useValidateBeforePublish,
  useValidateStoreListingSlug,
} from '../hooks';
import { isProductInformationError } from '../store-listing-wizard.utils';
import { StepId } from '../types';

export function PublishButton() {
  const { contextData, setContextData, skipToStep } = useStoreListingWizard();
  const { validatePaymentSettings, validatePublishPrerequisites } =
    useValidateBeforePublish();

  const openModal = useOpenModal();
  const validateStoreListingSlug = useValidateStoreListingSlug();

  const storeSettings = useStoreSettings();
  const createStoreListing = useCreateStoreListing();
  const publishStoreListing = usePublishStoreListing();

  const updateStoreListing = useUpdateStoreListing();

  const checkStoreListingSlugAvailability =
    useCheckStoreListingSlugAvailability();

  const isLoading =
    publishStoreListing.isLoading ||
    updateStoreListing.isLoading ||
    createStoreListing.isLoading;

  const validateSlug = async () => {
    const trimmedSlug = contextData.slug?.trim() ?? '';

    if (trimmedSlug === contextData.storeListing?.slug) {
      return true;
    }

    if (isEmpty(trimmedSlug)) {
      return true;
    }

    if (!validateStoreListingSlug(trimmedSlug)) {
      setContextData((prev) => ({
        ...prev,
        slugError:
          'Permalink is not a valid URL. Spaces and special characters are not allowed.',
      }));

      return false;
    }

    try {
      const foundStoreListing =
        await checkStoreListingSlugAvailability.mutateAsync(trimmedSlug);

      if (foundStoreListing) {
        openModal<DuplicateSlugModalProps['data']>('DuplicateSlugModal', {
          storeListingId: foundStoreListing.id,
          selectedStoreListingSlug: trimmedSlug,
          selectedProductName: contextData.selectedProduct?.name ?? '',
          selectedProductImageUrl: contextData.selectedProduct?.image_url ?? '',
          onChangeSelectedStoreListingSlug: (slug) => {
            setContextData((prev) => ({
              ...prev,
              slug,
            }));
          },
        });

        return false;
      }
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const getButtonLabel = () => {
    if (contextData.storeListing?.status === 'published') {
      return 'Update';
    }

    return 'Publish to Store';
  };

  const createOrUpdateStoreListing = async () => {
    if (!storeSettings.data || !contextData.selectedProduct) {
      return;
    }

    const prices = mapPaymentSettingsToStoreListingPrices({
      pricingModel: contextData.selectedProduct.pricing_model,
      currencies: storeSettings.data.currencies,
      paymentSettings: contextData.paymentSettings,
      isBasePriceEnabled: contextData.isBasePriceEnabled,
    });

    // determine if we need to create or update the store listing
    if (contextData.storeListing) {
      // update the store listing
      return await updateStoreListing.mutateAsync({
        isStoreListingEditable: contextData.storeListing.editable,
        prices,
        slug: contextData.slug?.trim() ?? null,
        storeListingId: contextData.storeListing?.id,
        requireCancellationReason: contextData.requireCancellationReason,
        productName:
          contextData.productOverrides?.productName ??
          contextData.selectedProduct.name,
        productCategory: contextData.productOverrides?.productCategory ?? null,
        productSubtitle: contextData.productOverrides?.productSubtitle ?? null,
        productImageUrl: contextData.productOverrides?.productImageUrl ?? null,
        productDescription: contextData.productOverrides?.productDescription,
      });
    }

    // create the store listing
    return await createStoreListing.mutateAsync({
      productId: contextData.selectedProduct.id,
      prices,
      slug: contextData.slug?.trim() ?? null,
      requireCancellationReason: contextData.requireCancellationReason,
      productName:
        contextData.productOverrides?.productName ??
        contextData.selectedProduct.name,
      productCategory: contextData.productOverrides?.productCategory ?? null,
      productSubtitle: contextData.productOverrides?.productSubtitle ?? null,
      productImageUrl: contextData.productOverrides?.productImageUrl ?? null,
      productDescription: contextData.productOverrides?.productDescription,
    });
  };

  const publish = (storeListing: StoreListing) => {
    publishStoreListing.mutate(
      { storeListingId: storeListing.id },
      { onSuccess: () => contextData.closeModal({ confirmBeforeClose: false }) }
    );
  };

  const onClick = async () => {
    if (!contextData.selectedProduct || !storeSettings.data) return;

    const isSlugValid = await validateSlug();
    const hasProductInformationErrors =
      isProductInformationError(contextData) || !isSlugValid;
    const hasPaymentSettingsErrors = !validatePaymentSettings();

    if (hasProductInformationErrors) {
      await skipToStep(StepId.ProductInformation);
      return;
    }

    if (hasPaymentSettingsErrors) {
      await skipToStep(StepId.PaymentSettings);
      return;
    }

    try {
      const storeListing = await createOrUpdateStoreListing();

      if (!storeListing) return;

      setContextData((prev) => ({
        ...prev,
        storeListing,
        selectedProduct: storeListing.product,
      }));

      validatePublishPrerequisites({
        storeListing,
        onFulfillment: () => publish(storeListing),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      loading={isLoading}
      onClick={onClick}
      data-testid="modal-publish-button"
    >
      {getButtonLabel()}
    </Button>
  );
}
