import { LoadingOverlay } from '@mantine/core';
import React, { FC } from 'react';

import {
  StoreListingPrice,
  PublishableProduct,
  useStoreSettings,
} from '@portals/api/partners';

import { usePublishProductsWizard } from '../hooks';
import { Price } from './Price';
import { RecurringPrices } from './RecurringPrices';

interface ProductHeaderPricesProps {
  product: PublishableProduct;
}

export const ProductHeaderPrices: FC<ProductHeaderPricesProps> = ({
  product,
}) => {
  const storeSettings = useStoreSettings();

  const { contextData } = usePublishProductsWizard();

  if (
    !contextData.storeListingsMap ||
    !contextData.activeProductId ||
    storeSettings.isFetching ||
    !storeSettings.data
  ) {
    return <LoadingOverlay visible />;
  }

  const { prices } = contextData.storeListingsMap[contextData.activeProductId];
  const defaultPriceObj = prices.find(
    (price) => price.currency === storeSettings.data.default_currency
  ) as StoreListingPrice;

  const selectedPriceKey = contextData.selectedPricingOptionMap?.[product.id];

  return (
    <div>
      {product.allow_one_time_price && (
        <Price
          isActive={selectedPriceKey === 'one_time_price_in_scu'}
          value={defaultPriceObj.one_time_price_in_scu}
          currencyCode={defaultPriceObj.currency}
        />
      )}

      {(product.allow_monthly_price || product.allow_yearly_price) && (
        <RecurringPrices
          price={defaultPriceObj}
          product={product}
          selectedPriceKey={selectedPriceKey ?? undefined}
        />
      )}
    </div>
  );
};
