import {
  Grid,
  Group,
  Input,
  Select,
  SelectProps,
  Space,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { compact, concat, flow, set, uniq } from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import { useProductCategories } from '@portals/api/partners';
import { ImageSelectorField } from '@portals/autoformik';
import { LimitedTextInput, TextEditor, TextEditorProps } from '@portals/core';
import { ReactComponent as AddCircle } from '@portals/icons/bulk/add-circle.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';

import { DetailsColumnContent } from './common/DetailsColumnContent';
import { SkuSection } from './common/SkuSection';
import { TransferableLicenseSection } from './common/TransferableLicenseSection';
import { StepId } from './types';
import { useProductWizard } from './use-product-wizard';

const CATEGORY_MAX_LENGTH = 25;

export function GeneralInformationStep() {
  const theme = useMantineTheme();
  const { contextData, setContextData } = useProductWizard();
  const categories = useProductCategories();

  const [localCategories, setLocalCategories] = useState<string[]>([]);

  useEffect(
    function initCategories() {
      if (!categories.isFetched || !categories.data) return;

      const newCategoryList = flow([
        // add the current selected category to the list of categories because it might not have been saved yet,
        // if the user has moved between steps without saving.
        concat(contextData.product.category || ''),
        // we might concatenate existing categories, thus we must unique-fy the list
        uniq,
        compact,
      ])(categories.data);

      setLocalCategories(newCategoryList);
    },
    [categories.data, categories.isFetched, contextData.product.category]
  );

  const onCreateCategory: SelectProps['onCreate'] = (query) => {
    setLocalCategories((prev) => [...prev, query]);
    return query;
  };

  return (
    <DetailsColumnContent title="General Information">
      <DetailsColumnContent.Section title="General Information">
        <Stack>
          <Grid>
            <Grid.Col span={7}>
              <TextInput
                required
                label="Name"
                placeholder="Product name"
                data-testid="general-information-step-product-name"
                error={contextData.errors[StepId.GeneralInformation].name}
                value={contextData.product?.name ?? ''}
                onChange={({ target }) =>
                  setContextData((curr) =>
                    set(['product', 'name'], target.value, curr)
                  )
                }
                onBlur={() => {
                  if (contextData.product.name?.trim()) {
                    setContextData((curr) =>
                      set(
                        ['errors', StepId.GeneralInformation, 'name'],
                        '',
                        curr
                      )
                    );
                  }
                }}
                rightSection={
                  contextData.errors[StepId.GeneralInformation].name && (
                    <Danger
                      width={16}
                      height={16}
                      color={theme.colors.red[4]}
                    />
                  )
                }
              />
            </Grid.Col>

            <Grid.Col span={5}>
              <Select
                searchable
                clearable
                creatable
                maxLength={CATEGORY_MAX_LENGTH}
                placeholder="tv, speakers, etc..."
                data-testid="general-information-step-product-category"
                styles={{ label: { width: '100%' } }}
                label={
                  <Group position="apart">
                    <Text>Category</Text>
                    <Text size="xs" weight={600} color="blue_gray.3">
                      {contextData.product?.category?.length || 0}/
                      {CATEGORY_MAX_LENGTH}
                    </Text>
                  </Group>
                }
                data={localCategories}
                value={contextData.product?.category}
                onChange={(value) =>
                  setContextData((curr) =>
                    set(['product', 'category'], value, curr)
                  )
                }
                getCreateLabel={(query) => (
                  <Group spacing="xs" noWrap>
                    <div>
                      <AddCircle color={theme.colors.blue[5]} />
                    </div>
                    <Text size="sm">Add "{query}"</Text>
                  </Group>
                )}
                onCreate={onCreateCategory}
              />
            </Grid.Col>
          </Grid>

          <LimitedTextInput
            label="Subtitle"
            placeholder="This will appear in the product preview card"
            value={contextData.product?.subtitle ?? ''}
            maxLength={40}
            data-testid="general-information-step-product-subtitle"
            onChange={({ target }) =>
              setContextData((curr) =>
                set(['product', 'subtitle'], target.value, curr)
              )
            }
          />

          <Input.Wrapper
            required
            styles={{
              error: { marginTop: 4 },
            }}
            label="Description"
            error={contextData.errors[StepId.GeneralInformation].description}
          >
            <TextEditor
              styles={rteStyles(
                contextData.errors[StepId.GeneralInformation].description
              )}
              data-testid="general-information-step-product-description"
              editorParams={{
                content: contextData.product?.description,
                onBlur: ({ editor }) => {
                  let descriptionToSave: string | null;

                  if (editor.isEmpty || editor.getText().trim() === '') {
                    descriptionToSave = null;
                  } else {
                    descriptionToSave = editor.getHTML();
                  }

                  const error = !descriptionToSave ? 'Required' : '';
                  setContextData((curr) => {
                    return flow([
                      set(['product', 'description'], descriptionToSave),
                      set(
                        ['errors', StepId.GeneralInformation, 'description'],
                        error
                      ),
                    ])(curr);
                  });
                },
              }}
            />
          </Input.Wrapper>

          <TransferableLicenseSection />
        </Stack>
      </DetailsColumnContent.Section>

      <DetailsColumnContent.Section title="Media">
        <ImageSelectorField
          horizontal={false}
          value={contextData.product?.image_url ?? ''}
          setFieldValue={(_, value: string) =>
            setContextData((curr) => set(['product', 'image_url'], value, curr))
          }
          field={{
            title: '',
            name: 'image',
            height: 372,
            width: 350,
            cropConfig: {
              aspectRatio: 372 / 350,
              height: 1000,
              width: 940,
            },
          }}
        />
      </DetailsColumnContent.Section>

      <DetailsColumnContent.Section title="">
        <SkuSection />
        <Space h="xl" />
      </DetailsColumnContent.Section>
    </DetailsColumnContent>
  );
}

const rteStyles =
  (error: string): TextEditorProps['styles'] =>
  (theme) => ({
    root: {
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
      minHeight: '230px',
      borderColor: error ? theme.colors.red[7] : theme.colors.gray[4],
    },
    content: {
      height: '100%',

      '.ProseMirror': {
        height: '100%',
      },
    },
  });
