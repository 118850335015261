import {
  Box,
  Button,
  createStyles,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { compact, size } from 'lodash/fp';
import React, { ReactNode, useState } from 'react';

import { useConfig } from '@portals/api/partners';
import { ReactComponent as Book } from '@portals/icons/linear/book-1.svg';
import { ReactComponent as Glossary } from '@portals/icons/linear/book-a.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as LifeBuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as VideoSquare } from '@portals/icons/linear/video-square.svg';

import { SuccessCenterPartnerManuals } from './SuccessCenterPartnerManuals';

interface SuccessCenterMenuItemType {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

export function SuccessCenterItems() {
  const { classes } = useStyles();

  const config = useConfig();

  const [showPartnersManuals, setShowPartnersManuals] = useState(false);

  const successCenterMenuItems: Array<SuccessCenterMenuItemType> = compact([
    {
      icon: <DocumentText />,
      onClick: () => window.open('https://docs.xyte.io/', '_blank'),
      label: 'Documentation',
    },
    {
      icon: <VideoSquare />,
      onClick: () =>
        window.open(
          'https://www.youtube.com/playlist?list=PLqmvZivrF9W77RL6DZTfrKtgtd-o1K5Ok',
          '_blank'
        ),
      label: 'Videos',
    },
    {
      icon: <Glossary />,
      onClick: () => window.open('https://www.xyte.io/glossary', '_blank'),
      label: 'Glossary',
    },
    size(config.data?.manuals) > 0 && {
      icon: <Book />,
      label: 'Manufacturer manuals',
      onClick: () => setShowPartnersManuals(true),
    },
  ]);

  if (showPartnersManuals) {
    return (
      <SuccessCenterPartnerManuals
        onClose={() => setShowPartnersManuals(false)}
      />
    );
  }

  return (
    <Stack spacing={4} h="100%">
      <Box pt="md" pb="xs">
        <Text color="gray.8" weight={600} ta="center">
          Success Center
        </Text>
      </Box>

      <Stack spacing="xl" h="100%" justify="space-between">
        <SimpleGrid cols={3} spacing="xs">
          {successCenterMenuItems.map(({ icon, label, onClick }) => (
            <Stack
              key={label}
              spacing="xs"
              align="center"
              className={classes.menuItem}
              onClick={onClick}
              data-testid={`success-center-${label}`}
            >
              <Box className={classes.icon}>{icon}</Box>

              <Text size="xs" color="gray.8" ta="center">
                {label}
              </Text>
            </Stack>
          ))}
        </SimpleGrid>

        <Button
          size="md"
          variant="default"
          leftIcon={<LifeBuoy width={18} height={18} />}
          onClick={() => window.open('mailto:support@xyte.io', '_blank')}
        >
          Contact technical support
        </Button>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  menuItem: {
    width: 120,
    height: 120,
    paddingInline: theme.spacing.md,
    paddingTop: theme.spacing.xxl,
    paddingBottom: theme.spacing.md,
    borderRadius: theme.radius.lg,
    cursor: 'pointer',

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[0],
    }),
  },

  icon: {
    svg: {
      height: 32,
      width: 32,
      color: theme.colors.indigo_accent[2],
    },
  },
}));
