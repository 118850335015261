import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PaginationResponse, PartnerFileResponseType } from '@portals/types';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { CreateDeviceModelFileParams } from './device-models.types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';

function getApiUrl(deviceModelId: string) {
  return `${getDeviceModelApiUrl(deviceModelId)}/file_infos`;
}

export function useDeviceModelFiles(deviceModelId: string) {
  const { url, options } = useRequestOptions({ url: getApiUrl(deviceModelId) });

  const requestUrl = buildUrlFromFilters<PartnerFileResponseType>({
    url,
    pagination: {
      page: 0,
      pageSize: 500,
    },
  });

  return useQuery<
    PaginationResponse<PartnerFileResponseType>,
    { error: string },
    PartnerFileResponseType[]
  >({
    queryKey: deviceModelsQueryKeys.files.all(deviceModelId),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    meta: {
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/file_infos`,
    },
  });
}

export function useUpdateDeviceModelFile(deviceModelId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceModelId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (
      file: PartnerFileResponseType
    ): Promise<PartnerFileResponseType> => {
      return fetchApiRequest(`${url}/${file.id}`, {
        ...options,
        body: JSON.stringify(file),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Update file successfully'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('Update file failed', error));
    },
    meta: {
      mutationName: 'useUpdateDeviceModelFile',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/file_infos/:id`,
      method: 'PUT',
    },
  });
}

export function useCreateDeviceModelFile(deviceModelId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceModelId),
    method: 'POST',
  });

  return useMutation({
    mutationFn: (file: CreateDeviceModelFileParams) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(file),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File created successfully'));

      queryClient.invalidateQueries(deviceModelsQueryKeys.files.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError('File creation failed', error));
    },
    meta: {
      mutationName: 'useCreateDeviceModelFile',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/file_infos`,
      method: 'POST',
    },
  });
}
