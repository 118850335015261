import {
  Button,
  createStyles,
  Group,
  Stepper,
  StepperProps,
  Tooltip,
} from '@mantine/core';
import { find, isEqual, values } from 'lodash/fp';
import React, { FC } from 'react';

import {
  StoreListing,
  useBulkPublishProducts,
  useNonePaginatedStoreListings,
  // useUpdateStoreListingPrices,
} from '@portals/api/partners';

import { usePublishProductsWizard } from './hooks';

interface WizardFooterProps {
  isMultipleProducts: boolean;
  onClose: () => void;
}

export const WizardFooter: FC<WizardFooterProps> = ({
  isMultipleProducts,
  onClose,
}) => {
  const { classes } = useStyles();

  const storeListings = useNonePaginatedStoreListings();
  // const updateStoreListingPrices = useUpdateStoreListingPrices();
  const bulkPublishProducts = useBulkPublishProducts();

  const {
    contextData,
    currentStep,
    isFinalStep,
    onPrev,
    onNext,
    steps,
    isNextDisabled,
  } = usePublishProductsWizard();

  const stepIds = Object.keys(steps);

  const getChangedStoreListings = (): StoreListing[] => {
    const localStoreListings = values(contextData.storeListingsMap);

    return localStoreListings.filter((storeListing) => {
      const originalStoreListing = find(
        { id: storeListing.id },
        storeListings.data
      );

      return !isEqual(storeListing, originalStoreListing);
    });
  };

  const onPrevClick = () => {
    if (isFinalStep) {
      onPrev();
    } else {
      onClose();
    }
  };

  const onNextClick = async () => {
    if (!contextData.products) return;

    if (isFinalStep) {
      try {
        await bulkPublishProducts.mutateAsync(
          contextData.products.map((product) => product.id)
        );

        onClose();
        return;
      } catch (e) {
        console.error(e);
      }
    } else {
      const changedStoreListings = getChangedStoreListings();

      if (changedStoreListings.length === 0) {
        onNext();
        return;
      }

      try {
        // Only save store listings that have changed
        // const promises = changedStoreListings.map((storeListing) => {
        // return updateStoreListingPrices.mutateAsync({
        //   storeListingId: storeListing.id,
        //   prices: storeListing.prices,
        // });
        // });

        // await Promise.all(promises);

        onNext();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <footer className={classes.footer}>
      <Group position="apart">
        <Button variant="default" onClick={onPrevClick}>
          {isFinalStep ? 'Back' : 'Cancel'}
        </Button>
        <Stepper
          styles={stepperStyles}
          active={stepIds.findIndex((stepId) => stepId === currentStep.id)}
        >
          <Stepper.Step label="Review Prices" />
          <Stepper.Step
            label={isMultipleProducts ? 'Review Products' : 'Review Product'}
          />
        </Stepper>
        <Tooltip
          label={
            isFinalStep
              ? 'Some fields are missing'
              : 'All prices must be set in order to continue'
          }
          disabled={!isNextDisabled}
          withArrow
        >
          <Button
            disabled={isNextDisabled}
            onClick={onNextClick}
            // loading={
            //   updateStoreListingPrices.isLoading ||
            //   bulkPublishProducts.isLoading
            // }
          >
            {isFinalStep ? 'Publish' : 'Save & Continue'}
          </Button>
        </Tooltip>
      </Group>
    </footer>
  );
};

const stepperStyles: StepperProps['styles'] = (theme) => {
  return {
    root: {
      width: 170,
    },
    step: {
      position: 'relative',
      flexDirection: 'column',
      wordBreak: 'normal',
    },
    separator: {
      height: '1px',
    },
    stepIcon: {
      marginBottom: theme.spacing.xs,
    },
    stepBody: {
      position: 'absolute',
      bottom: 4,
      margin: 0,
      transform: 'translateY(100%)',
    },
    stepLabel: {
      width: 'max-content',
    },
  };
};

const useStyles = createStyles((theme) => ({
  footer: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
