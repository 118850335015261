import { Switch, SwitchProps } from '@mantine/core';
import React from 'react';

import { ProductTypeEnum } from '@portals/types';

import { useProductWizard } from '../use-product-wizard';

export function TransferableLicenseSection() {
  const { contextData, setContextData } = useProductWizard();

  if (contextData.product.product_type === ProductTypeEnum.Physical) {
    return null;
  }

  return (
    <Switch
      label="Transferable License"
      data-testid="transferable-license-toggle"
      labelPosition="left"
      description="Allow this license to be transferred between devices after activation."
      checked={contextData.product.transferable}
      onChange={(event) =>
        setContextData((prev) => ({
          ...prev,
          product: { ...prev.product, transferable: event.target.checked },
        }))
      }
      styles={switchStyles}
    />
  );
}

const switchStyles: SwitchProps['styles'] = (theme) => ({
  body: {
    justifyContent: 'space-between',
  },

  labelWrapper: {
    gap: theme.spacing.xs,
  },

  description: {
    color: theme.colors.gray[5],
    marginTop: 0,
  },
});
