import {
  Group,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { StateControllerWidgetFormType } from './state-controller-form.types';
import { StateControllerOptionFormDndWrapper } from './state-controller-option-form/StateControllerOptionFormDndWrapper';
import { ColorSelector } from '../../common/ColorSelector';
import {
  InputLabelWithTooltip,
  SelectItemComponent,
} from '../../common/input-helpers';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface StateControllerWidgetFormProps {
  form: UseFormReturnType<StateControllerWidgetFormType>;
  commands: Array<{ value: string; label: string }>;
  params: Array<{ value: string; label: string }>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function StateControllerWidgetForm({
  form,
  commands,
  params,
  colors,
  onAddCustomColor,
}: StateControllerWidgetFormProps) {
  const onUpdateFieldPosition = (dragIndex: number, hoverIndex: number) => {
    const fieldsList = [...form.values.options];

    [fieldsList[dragIndex], fieldsList[hoverIndex]] = [
      fieldsList[hoverIndex],
      fieldsList[dragIndex],
    ];

    form.setValues({ ...form.values, options: fieldsList });
  };

  return (
    <Stack spacing="xl" pr={30}>
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          data-testid="state-controller-title-input"
          autoFocus
          data-autofocus
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          {...form.getInputProps('display_title', { type: 'checkbox' })}
          label="Show title"
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}
      </Stack>

      <Input.Wrapper label="Color">
        <ColorSelector
          colors={colors}
          onAddCustomColor={onAddCustomColor}
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
        />
      </Input.Wrapper>

      <Switch
        {...form.getInputProps('display_icons', { type: 'checkbox' })}
        label="Show icons"
      />

      <Stack w="100%">
        <Text size="sm" color="gray.9">
          Commands
        </Text>

        <Group grow>
          <Select
            data={commands}
            searchable
            clearable
            required
            withinPortal
            labelProps={{
              w: '100%',
            }}
            {...form.getInputProps('command_name')}
            styles={{
              itemsWrapper: {
                width: 210,
              },
            }}
            withAsterisk={false}
            data-testid="command-selecting"
            label={
              <InputLabelWithTooltip
                label="Command"
                Icon={QuestionCircle}
                tooltipLabel="The command sent to the device when option is selected"
              />
            }
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                Icon={InfoCircle}
                disabledLabel="Command must have an options field"
              />
            )}
          />

          <Select
            data={params}
            searchable
            clearable
            required
            withinPortal
            disabled={!form.values.command_name}
            {...form.getInputProps('command_param_key')}
            styles={{
              itemsWrapper: {
                width: 210,
              },
            }}
            placeholder={
              !form.values.command_name
                ? 'Select command'
                : 'Select options parameter'
            }
            data-testid="option-parameter-input"
            withAsterisk={false}
            label={
              <InputLabelWithTooltip
                data-testid="option-parameter-selecting"
                label="Options Parameter"
                Icon={QuestionCircle}
                tooltipLabel="The key of the options parameter sent with the command"
              />
            }
            itemComponent={(props) => (
              <SelectItemComponent
                {...props}
                Icon={InfoCircle}
                disabledLabel="Only option parameters are supported"
              />
            )}
          />
        </Group>

        <TextInput
          {...form.getInputProps('telemetry')}
          withAsterisk={false}
          required
          data-testid="telemetry-key-input"
          label={
            <InputLabelWithTooltip
              label="Telemetry Key"
              Icon={QuestionCircle}
              tooltipLabel="The telemetry key that the widget will use to understand the state of the device. Choose a telemetry key whose value changes after receiving the command."
            />
          }
        />

        {form.values.options.map((option, index) => (
          <StateControllerOptionFormDndWrapper
            key={option.key}
            option={option}
            onMove={onUpdateFieldPosition}
            index={index}
            form={form}
          />
        ))}
      </Stack>
    </Stack>
  );
}
