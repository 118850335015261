import { createStyles, Group, Stack, Text, Tooltip } from '@mantine/core';
import { without } from 'lodash/fp';
import React, { useMemo } from 'react';

import { StoreListing } from '@portals/api/partners';
import { QuantityInput } from '@portals/core';
import { ProductCard } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { formatCurrency } from '@portals/utils';

import { useCreateOrderWizard } from '../create-order-wizard/hooks';
import { CreateOrderWizardContextDataType } from '../create-order-wizard/types';
import { getStoreListingPrice } from '../utils';

interface OrderStoreListingCardProps {
  storeListing: StoreListing;
}

export function OrderStoreListingCard({
  storeListing,
}: OrderStoreListingCardProps) {
  const { classes, theme } = useStyles();
  const { contextData, setContextData } = useCreateOrderWizard();

  const getSelectedStoreListings = (
    prevContextData: CreateOrderWizardContextDataType,
    quantity: number
  ) => {
    let updatedSelectedStoreListings = prevContextData.storeListings.filter(
      (storeListing: StoreListing) =>
        prevContextData.storeListingsQuantities[storeListing.id] > 0
    );

    if (quantity === 0) {
      const foundStoreListing = updatedSelectedStoreListings.find(
        (product: StoreListing) => product.id === storeListing.id
      );

      if (foundStoreListing) {
        updatedSelectedStoreListings = without(
          [foundStoreListing],
          updatedSelectedStoreListings
        );
      }
    } else {
      const isStoreListingAlreadySelected = updatedSelectedStoreListings.some(
        (currStoreListing) => currStoreListing.id === storeListing.id
      );

      if (!isStoreListingAlreadySelected) {
        updatedSelectedStoreListings.push(storeListing);
      }
    }

    return updatedSelectedStoreListings || [];
  };

  const getSelectedStoreListingsTotalPrice = (
    prevContextData: CreateOrderWizardContextDataType,
    newQuantity: number
  ) => {
    return prevContextData.storeListings.reduce((acc, currStoreListing) => {
      const currStoreListingQuantity =
        prevContextData.storeListingsQuantities[currStoreListing.id] || 0;
      const quantity =
        storeListing.id === currStoreListing.id
          ? newQuantity
          : currStoreListingQuantity;

      const price = getStoreListingPrice(
        currStoreListing,
        prevContextData.currency
      );

      return acc + quantity * price;
    }, 0);
  };

  const onQuantityChange = (quantity: number) => {
    setContextData((prev) => ({
      ...prev,
      storeListingsQuantities: {
        ...prev.storeListingsQuantities,
        [storeListing.id]: quantity,
      },
      selectedStoreListings: getSelectedStoreListings(prev, quantity),
      selectedStoreListingsTotalPrice: getSelectedStoreListingsTotalPrice(
        prev,
        quantity
      ),
    }));
  };

  const storeListingPrice = useMemo(() => {
    const price = storeListing.prices?.find(
      (price) => price.currency === contextData.currency
    );

    return price?.one_time_price_in_scu || 0;
  }, [contextData.currency, storeListing.prices]);

  return (
    <ProductCard
      className={classes.container}
      data-testid={`order-product-information-${storeListing.product_name}`}
    >
      <ProductCard.Image
        imageUrl={storeListing.product_image_url}
        category={storeListing.product_category}
        data-testid={`order-product-card-name-${storeListing.product_name}`}
      />

      <ProductCard.Details
        name={storeListing.product_name}
        subtitle={storeListing.product_subtitle}
      >
        <Stack spacing="xs" mt="xs">
          <Group spacing={4}>
            <Text color="gray.7">Store price</Text>

            <Tooltip
              w={300}
              multiline
              label="The store price is used to calculate Xyte fees for this order. You will not be charged anything when placing this order."
            >
              <InfoCircle width={18} height={18} color={theme.colors.gray[5]} />
            </Tooltip>
          </Group>

          <Group position="apart">
            <Text
              size="xl"
              data-testid={`order-price-${storeListing.product_name}`}
            >
              {formatCurrency(storeListingPrice, contextData.currency)}
            </Text>

            <QuantityInput
              min={0}
              disabled={false}
              value={contextData.storeListingsQuantities[storeListing.id] ?? 0}
              onChange={onQuantityChange}
              data-testid="quantity-input"
            />
          </Group>
        </Stack>
      </ProductCard.Details>
    </ProductCard>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderColor: theme.colors.gray[3],
    borderRadius: theme.radius.lg,
  },
}));
