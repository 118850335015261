import {
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { DeviceModelTestDevice } from './device-models.types';
import { useApiQuery } from '../../hooks/query';
import { QueryOptions } from '../../types/common';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/test_device`;
}

export const useDeviceModelTestDevice = (
  modelId: string,
  queryOptions: QueryOptions<{ device: DeviceModelTestDevice | null }>
) =>
  useApiQuery<{ device: DeviceModelTestDevice | null }>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.testDevices.all(modelId),
    queryOptions
  );
