import { useQuery } from '@tanstack/react-query';

import { PaginationResponse, SupportedCommandType } from '@portals/types';

import {
  SUPPORTED_COMMANDS_API_URL,
  supportedCommandsQueryKeys,
} from './supported-commands.constants';
import { ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  useRequestOptions,
} from '../../utils';

export function usePublishedSupportedCommand() {
  const { url, options } = useRequestOptions({
    url: `${SUPPORTED_COMMANDS_API_URL}/all?q[device_model_active_eq]=true`,
    method: 'GET',
  });

  const requestUrl = buildUrlFromFilters({
    url,
    pagination: {
      page: 0,
      pageSize: 1000,
    },
  });

  return useQuery<
    PaginationResponse<SupportedCommandType>,
    ServerError,
    SupportedCommandType[]
  >({
    queryKey: supportedCommandsQueryKeys.published(),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    cacheTime: 0,
    meta: {
      baseUrl: `${SUPPORTED_COMMANDS_API_URL}/all?q[device_model_active_eq]=true`,
      method: 'GET',
    },
  });
}
