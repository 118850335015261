import { createStyles, Group } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import React, { FC } from 'react';

import { ReactComponent as CopiedIcon } from '../../assets/img/icons/copied.svg';
import { ReactComponent as CopyIcon } from '../../assets/img/icons/copy.svg';

const useStyles = createStyles((theme) => ({
  copied: {
    color: theme.colors.blue_accent[7],
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
  },
  copyIcon: {
    cursor: 'pointer',
  },
}));

interface CopyProps {
  textToCopy: string;
}

const CopyToClipboard: FC<CopyProps> = ({ textToCopy }) => {
  const clipboard = useClipboard({ timeout: 1000 });
  const { classes } = useStyles();

  return (
    <Group position="right" align="center">
      {clipboard.copied ? (
        <Group spacing={5}>
          <div className={classes.copied}>Copied!</div>
          <CopiedIcon />
        </Group>
      ) : (
        <CopyIcon
          className={classes.copyIcon}
          onClick={() => clipboard.copy(textToCopy)}
        />
      )}
    </Group>
  );
};

export default CopyToClipboard;
