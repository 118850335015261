import { Modal, Stack, Text } from '@mantine/core';
import { get } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum } from '@portals/types';
import { PRIORITY_NAMES } from '@portals/utils';

import RulesEditor from '../../components/RulesEditor';
import { ruleSetValidator } from '../../components/RulesEditor/mapping';
import { addRule } from '../../redux/actions/rules';

const FIELDS: Array<FieldType> = [
  {
    name: 'incident_settings',
    title: (
      <Text size="md" weight={300} color="gray.8">
        Incident Settings
      </Text>
    ),
    type: FieldTypeEnum.Group,
    children: [
      {
        name: 'name',
        title: 'Name',
        required: true,
        default: '',
        inputProps: {
          sx: {
            maxWidth: 400,
          },
        },
      },
      {
        name: 'description',
        title: 'Description',
        required: true,
        default: '',
        type: FieldTypeEnum.Textarea,
        inputProps: {
          minRows: 3,
          maxRows: 3,
          sx: {
            maxWidth: 400,
          },
        },
      },
      {
        name: 'priority',
        title: 'Priority',
        required: true,
        default: 3,
        type: FieldTypeEnum.Select,
        options: PRIORITY_NAMES,
        parse: (v) => parseInt(v),
        inputProps: {
          sx: {
            maxWidth: 400,
          },
        },
      },
      {
        name: 'active',
        title: 'Active',
        required: true,
        default: true,
        type: FieldTypeEnum.Checkbox,
      },
    ],
  },
  {
    name: 'definition',
    title: (
      <Text size="md" weight={300} color="gray.8">
        Rule Definitions
      </Text>
    ),
    type: FieldTypeEnum.Group,
    children: [
      {
        name: 'definition',
        title: 'Definition rules',
        required: true,
        default: {},
        type: FieldTypeEnum.Custom,
        component: RulesEditor,
        validations: ruleSetValidator,
      },
    ],
  },
];

const AddRule = ({ data, inProgress, closeMe, addRule }) => {
  const model = data;
  const navigate = useNavigate();

  const handleSubmit = (data) =>
    addRule(model.id, data, [
      closeMe,
      (data) => navigate(`/rules/${data.result}`),
    ]);

  return (
    <Modal
      opened
      onClose={closeMe}
      size="1150px"
      title={
        <Stack spacing="xs">
          <Text>Add Rule for {model.model}</Text>
        </Stack>
      }
      sx={{
        '.modal-body': {
          padding: 0,
          marginBottom: 20,
        },
      }}
    >
      <AutoFormik
        fields={FIELDS}
        inProgress={inProgress}
        handleSubmit={handleSubmit}
        modal={closeMe}
        submitTitle="Add Rule"
        dismissTitle="Cancel"
      />
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
  inProgress: get(['ui', 'network', `addRule_${ownProps.data.id}`], state),
});

export default connect(mapStateToProps, { addRule })(AddRule);
