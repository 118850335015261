import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { AuditLogType } from '@portals/types';

interface CreateProductProps {
  auditLog: AuditLogType;
}

export const ProductCreated = ({ auditLog }: CreateProductProps) => {
  const paramsMap = keyBy('name', auditLog.params);
  const productParam = paramsMap.product;

  return (
    <Text size="sm">
      Created {productParam.model}: {productParam.display_name}
    </Text>
  );
};
