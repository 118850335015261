import { flow, isEmpty, keys, set } from 'lodash/fp';
import { useCallback } from 'react';
import { string } from 'yup';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import { usePortalProtocolAndDomain, useWizard } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { PaymentIntervalEnum, ProductPricingModel } from '@portals/types';

import { StepId, StoreListingWizardContext } from './types';
import { AddRequiredStoreSettingsModalData } from '../publish-product-missing-requirements/AddRequiredStoreSettings';

export function useStoreListingWizard() {
  return useWizard<StoreListingWizardContext, StepId>();
}

export function useValidateBeforePublish() {
  const openModal = useOpenModal();
  const storeSettings = useStoreSettings();
  const { contextData, setContextData } = useStoreListingWizard();

  const validateUsageBasedPaymentSetting = () => {
    let isValid = true;

    const { paymentSettings, isBasePriceEnabled } = contextData;
    const newContextData: StoreListingWizardContext = { ...contextData };

    if (isEmpty(paymentSettings)) {
      isValid = false;
    }

    const usageBasedPrices = paymentSettings.usage_based?.prices?.map(
      (price) => ({
        ...price,
        error: price.amountInScu === null,
      })
    );

    const basePrices = paymentSettings.monthly?.prices?.map((price) => ({
      ...price,
      error: price.amountInScu === null,
    }));

    if (usageBasedPrices && usageBasedPrices.some((price) => price.error)) {
      newContextData.paymentSettings.usage_based = {
        ...newContextData.paymentSettings.usage_based,
        period: PaymentIntervalEnum.UsageBased,
        prices: usageBasedPrices,
      };

      isValid = false;
    }

    if (
      isBasePriceEnabled &&
      basePrices &&
      basePrices.some((price) => price.error)
    ) {
      newContextData.paymentSettings.monthly = {
        ...newContextData.paymentSettings.monthly,
        period: PaymentIntervalEnum.Monthly,
        prices: basePrices,
      };

      isValid = false;
    }

    if (!isValid) {
      setContextData({
        ...newContextData,
        paymentSettingsGeneralError: 'Please fill in all prices',
      });
    } else {
      setContextData((prev) => ({
        ...prev,
        paymentSettingsGeneralError: undefined,
      }));
    }

    return isValid;
  };

  const validateStandardPaymentSettings = () => {
    const { paymentSettings } = contextData;

    if (isEmpty(paymentSettings)) {
      setContextData((prev) => ({
        ...prev,
        paymentSettingsGeneralError: 'Please add at least one price',
      }));

      return false;
    }

    const newPaymentSettingsAccordionValuesSet = new Set<PaymentIntervalEnum>();
    const newPaymentSettings: StoreListingWizardContext['paymentSettings'] =
      contextData.paymentSettings;
    const paymentIntervals = keys(paymentSettings) as PaymentIntervalEnum[];

    paymentIntervals.forEach((paymentInterval) => {
      const prices = paymentSettings[paymentInterval]?.prices?.map((price) => ({
        ...price,
        error: price.amountInScu === null,
      }));

      if (prices?.some((price) => price.error)) {
        newPaymentSettingsAccordionValuesSet.add(paymentInterval);
      }

      newPaymentSettings[paymentInterval] = {
        ...newPaymentSettings[paymentInterval],
        prices,
      };
    });

    const hasErrors = newPaymentSettingsAccordionValuesSet.size !== 0;

    setContextData((prev) =>
      flow([
        set('paymentSettings', newPaymentSettings),
        set(
          'paymentSettingsAccordionValues',
          Array.from(newPaymentSettingsAccordionValuesSet)
        ),
        set(
          ['paymentSettingsGeneralError'],
          hasErrors ? 'Please fill in all prices' : undefined
        ),
      ])(prev)
    );

    return !hasErrors;
  };

  const validatePaymentSettings = () => {
    if (
      contextData.selectedProduct?.pricing_model ===
      ProductPricingModel.Personalized
    ) {
      return true;
    }

    if (
      contextData.selectedProduct?.pricing_model ===
      ProductPricingModel.UsageBased
    ) {
      return validateUsageBasedPaymentSetting();
    }

    if (
      contextData.selectedProduct?.pricing_model ===
      ProductPricingModel.Standard
    ) {
      return validateStandardPaymentSettings();
    }

    return false;
  };

  const validatePublishPrerequisites = ({
    storeListing,
    onFulfillment,
  }: {
    storeListing: StoreListing;
    onFulfillment: () => void;
  }) => {
    const isMissingSettings =
      !storeSettings.data?.privacy_policy ||
      !storeSettings.data?.terms_of_service ||
      !storeSettings.data?.cancellations_and_returns_policy ||
      !storeSettings.data?.address_1;

    const isUnpublishedModel =
      storeListing.product.device_model &&
      !storeListing.product.device_model.active;

    if (!isMissingSettings && !isUnpublishedModel) {
      onFulfillment();
      return;
    }

    const unpublishedDeviceModelsIds =
      storeListing.product.device_model &&
      !storeListing.product.device_model.active
        ? [storeListing.product.device_model.id]
        : undefined;

    if (isMissingSettings || isUnpublishedModel) {
      openModal<AddRequiredStoreSettingsModalData>('AddRequiredStoreSettings', {
        unpublishedDeviceModelsIds,
        onFulfillment,
      });
    }
  };

  return { validatePaymentSettings, validatePublishPrerequisites };
}

const SlugSchema = string().url();
export function useValidateStoreListingSlug() {
  const { protocolAndDomain } = usePortalProtocolAndDomain();

  return useCallback(
    (slug: string | null | undefined) => {
      if (slug === null || slug === undefined) {
        return true;
      }

      return SlugSchema.isValidSync(`${protocolAndDomain}/store/${slug}`);
    },
    [protocolAndDomain]
  );
}
