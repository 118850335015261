import { Stack } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { FC } from 'react';

import { useOpenModal } from '@portals/redux';
import {
  SupportedCommandFieldType,
  SupportedCommandType,
} from '@portals/types';

import FieldPreview from './FieldPreview';

interface CustomFieldsListProps {
  command: Partial<SupportedCommandType>;
  onRemove: (fieldName: string) => void;
  onFieldUpdate: (field: SupportedCommandFieldType, fieldName: string) => void;
  onUpdateFieldPosition: (dragIndex: number, hoverIndex: number) => void;
  onFieldValueUpdate: (
    fieldName: string,
    valuePath: Array<string>,
    value: any
  ) => void;
}

const CustomFieldsList: FC<CustomFieldsListProps> = ({
  command,
  onFieldValueUpdate,
  onUpdateFieldPosition,
  onFieldUpdate,
  onRemove,
}) => {
  const openModal = useOpenModal();

  const onOpenEditModal = (field: SupportedCommandFieldType) => {
    openModal('CustomFieldForm', {
      onSubmit: onFieldUpdate,
      custom_fields: command.custom_fields,
      field,
    });
  };

  if (isEmpty(command.custom_fields)) {
    return null;
  }

  return (
    <Stack spacing="xs">
      {command.custom_fields?.map((field, index) => (
        <FieldPreview
          index={index}
          key={field.name}
          field={field}
          onOpenEditModal={() => onOpenEditModal(field)}
          onRemove={onRemove}
          onMove={onUpdateFieldPosition}
          onFieldValueUpdate={onFieldValueUpdate}
        />
      ))}
    </Stack>
  );
};

export default CustomFieldsList;
