export enum PartnerInvitationStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
  Canceled = 'canceled',
}

export interface PartnerInvitationsResponse {
  id: string;
  relationship_type: string;
  status: PartnerInvitationStatus;
  certified: boolean;
  created_at: string;
  email: null | string;
  partner: null | {
    display_name: string;
    logo: string | null;
  };
}

export interface IncomingInvitation {
  id: string;
  created_at: string;
  partner: {
    display_name: string;
    logo: string | null;
  };
}

export type IncomingInvitationsResponse = Array<IncomingInvitation>;

type PartnerIdOrEmail =
  | { partner_id: string; email?: never }
  | { email: string; partner_id?: never };

export type PartnerInvitationRequest = {
  relationship_type: string;
  certified: boolean;
} & PartnerIdOrEmail;

export interface AvailableInvitedPartnerResponse {
  id: string;
  name: string;
  display_name: string;
  logo: string | null;
}

export interface AvailablePartnersToInvite {
  id: string;
  name: string;
  display_name: string;
  logo: string | null;
}
