import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { PartnerContacts } from './contacts.types';
import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';

const CONTACTS_API_URL = 'ui/partner/configuration/contacts';
const contactsQueryKeys = {
  contacts: [CONTACTS_API_URL, 'contacts'],
};

export function useContacts() {
  return useApiQuery<PartnerContacts>(
    CONTACTS_API_URL,
    contactsQueryKeys.contacts
  );
}

/*
 * Mutations
 * */

export function useUpdateContacts() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONTACTS_API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (contacts: Partial<PartnerContacts>) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(contacts),
      }),
    onSuccess: () => {
      dispatch(toastrSuccess('Updated contacts successfully'));
      queryClient.invalidateQueries(contactsQueryKeys.contacts);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateContacts',
      baseUrl: CONTACTS_API_URL,
      method: 'PUT',
    },
  });
}
