import { createStyles, LoadingOverlay } from '@mantine/core';
import React, { FC } from 'react';

import { PublishableProduct } from '@portals/api/partners';

import { usePublishProductsWizard } from './hooks';
import { ProductListSelectItem } from './ProductListSelectItem';
import { PublishProductsStepIds } from './types';
import { checkIsMissingFields, checkIsMissingPrices } from './utils';

interface ProductListSelectProps {
  products: PublishableProduct[] | undefined;
  selectedProductId: string | undefined;
  onSelectProduct: (productId: string) => void;
}

export const ProductListSelect: FC<ProductListSelectProps> = ({
  products,
  selectedProductId,
  onSelectProduct,
}) => {
  const { classes } = useStyles();
  const { contextData, currentStep } = usePublishProductsWizard();

  return (
    <div className={classes.listContainer}>
      <LoadingOverlay visible={!products} />

      {products?.map((product) => {
        let statusText, hasError;

        if (currentStep.id === PublishProductsStepIds.PricesDetails) {
          hasError = checkIsMissingPrices(
            product,
            contextData.storeListingsMap?.[product.id]?.prices
          );
          statusText = hasError ? 'Missing prices' : 'Prices set';
        } else {
          hasError = checkIsMissingFields(product);
          statusText = hasError ? 'Missing fields' : 'Fields set';
        }

        return (
          <ProductListSelectItem
            key={product.id}
            product={product}
            selectedProductId={selectedProductId}
            hasError={hasError}
            statusText={statusText}
            onClick={onSelectProduct}
          />
        );
      })}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  listContainer: {
    position: 'relative',
    padding: 4,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
}));
