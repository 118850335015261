import { Button, Group, LoadingOverlay } from '@mantine/core';
import React, { useState } from 'react';

import { GroupsDetailsType } from '@portals/api/partners';
import { UserResponseType, useUsers } from '@portals/api/ui';
import { MembersSVG } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps, UsersMultiSelect } from '@portals/framework';

export interface AddGroupMembersModalProps
  extends ModalProps<{
    usersList?: GroupsDetailsType['users'];
    onSubmit: (usersToAdd: GroupsDetailsType['users']) => void;
  }> {}

export function AddGroupMembersModal({
  closeMe,
  data: { usersList, onSubmit },
}: AddGroupMembersModalProps) {
  const users = useUsers();

  const [userIdsToAdd, setUserIdsToAdd] = useState<string[]>([]);

  const availableUsersToAdd = getAvailableUsersToAdd(users.data, usersList);
  const options = composeOptions(availableUsersToAdd);

  const handleSubmit = () => {
    const groupUsersToAdd = userIdsToAdd.map((userId) => {
      const currUser = availableUsersToAdd.find(
        (user) => user.id === userId
      ) as UserResponseType;

      return {
        id: currUser.id,
        name: currUser.name,
        email: currUser.email,
        settings: currUser.settings,
      };
    });

    onSubmit(groupUsersToAdd);
    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Members"
      media={<MembersSVG />}
      footer={
        <Group grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} data-testid="modal-add-members-button">
            Add members
          </Button>
        </Group>
      }
    >
      <LoadingOverlay visible={users.isFetching} />

      <UsersMultiSelect
        data={options}
        value={userIdsToAdd}
        onChange={setUserIdsToAdd}
      />
    </ModalCenteredMediaLayout>
  );
}

function getAvailableUsersToAdd(
  allUsers: UserResponseType[] | undefined = [],
  groupUsers: GroupsDetailsType['users'] | undefined = []
) {
  const groupUserIds = groupUsers.map((user) => user.id);

  return allUsers.filter(
    (user) => !groupUserIds.includes(user.id) && !user.deactivated_at
  );
}

function composeOptions(availableUsersToAdd: UserResponseType[]) {
  return availableUsersToAdd?.map((user) => ({
    label: user.name,
    value: user.id,
    email: user.email,
  }));
}
