import {
  Input,
  InputLabelProps,
  LoadingOverlay,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useIsFetching } from '@tanstack/react-query';
import { flow, set } from 'lodash/fp';
import React from 'react';

import {
  DeviceModelDetailsType,
  deviceModelsQueryKeys,
  DeviceModelSummaryType,
} from '@portals/api/partners';
import { ReactComponent as CloudLightning } from '@portals/icons/bulk/cloud-lightning.svg';

import { CreatableDeviceModelSelect } from '../common/CreatableDeviceModelSelect';
import { DetailsColumnContent } from '../common/DetailsColumnContent';
import { StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export const DigitalProductIntegration = () => {
  const theme = useMantineTheme();

  const { contextData, setContextData } = useProductWizard();
  const isFetching = useIsFetching(deviceModelsQueryKeys.base) > 0;

  const onDeviceModelChange = (
    deviceModel: DeviceModelSummaryType | DeviceModelDetailsType
  ) => {
    setContextData((curr) =>
      flow([
        set(['product', 'device_model_id'], deviceModel.id),
        set(['product', 'sku'], deviceModel.sku || curr.product.sku),
        set(['errors', StepId.Integration], ''),
      ])(curr)
    );
  };

  return (
    <DetailsColumnContent title="Product Type">
      <DetailsColumnContent.Section title="Please select the device model to which your product should be attached">
        <LoadingOverlay visible={isFetching} />

        <CreatableDeviceModelSelect
          label={
            <Input.Label required styles={inputLabelStyles}>
              <CloudLightning
                color={theme.colors.primary[1]}
                width={34}
                height={34}
              />
              <Text>Device Model</Text>
            </Input.Label>
          }
          error={contextData.errors[StepId.Integration]}
          value={contextData.product.device_model_id}
          onChange={onDeviceModelChange}
          onAfterCreate={onDeviceModelChange}
        />
      </DetailsColumnContent.Section>
    </DetailsColumnContent>
  );
};

const inputLabelStyles: InputLabelProps['styles'] = (theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.xs,
  },
});
