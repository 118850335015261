import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Tabs,
  TabsProps,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import { isEqual } from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import {
  GroupsDetailsType,
  useCreateGroup,
  useGroup,
  useUpdateGroup,
} from '@portals/api/partners';
import {
  GroupIconNames,
  GroupMembersList,
  GroupMembersListProps,
  ModalProps,
  useGroupMembersList,
  useGroupNameAndIcon,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { AccessLevelEnum } from '@portals/types';

import { AddGroupMembersModalProps } from './AddGroupMembersModal';
import { GroupDetailsForm } from './GroupDetailsForm';
import { usePricingPlanFeature } from '../../hooks/partner-config';

type ManageGroupModalTabs = 'general' | 'members';

export interface ManageGroupModalProps
  extends ModalProps<{ groupId?: string; tabToOpen: ManageGroupModalTabs }> {}

export function ManageGroupModal({
  closeMe,
  data: { groupId, tabToOpen },
}: ManageGroupModalProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const group = useGroup(groupId || '');
  const createGroup = useCreateGroup();
  const updateGroup = useUpdateGroup();
  const roleBasedPermissions = usePricingPlanFeature('role_based_permissions');

  const isEditMode = groupId !== undefined;
  const isSubmitButtonLoading = createGroup.isLoading || updateGroup.isLoading;

  const isPermissionsEditable =
    roleBasedPermissions === 1 &&
    (!isEditMode || group.data?.is_name_editable === true);

  const [activeTab, setActiveTab] = useState<ManageGroupModalTabs>(tabToOpen);

  const {
    usersList,
    setUsersList,
    onChangeCheckAllUsers,
    onChangeCheckedUser,
  } = useGroupMembersList();

  const {
    groupName,
    setGroupName,
    iconName,
    setIconName,
    isGroupNameError,
    setIsGroupNameError,
  } = useGroupNameAndIcon();
  const [permissions, setPermissions] = useState<
    GroupsDetailsType['permissions']
  >({
    models: AccessLevelEnum.Edit,
    finance: AccessLevelEnum.Edit,
    fulfillment: AccessLevelEnum.Edit,
    customers: AccessLevelEnum.Edit,
    store_management: AccessLevelEnum.Edit,
  });

  useEffect(
    function initializeDataAfterFetch() {
      if (!isEditMode || group.isFetching) return;

      if (group.isFetched && group.data) {
        setGroupName(group.data.name);
        setIconName(group.data.icon_name as GroupIconNames);
        setPermissions(group.data.permissions);
        setUsersList(
          group.data.users.map((user) => ({
            ...user,
            isChecked: false,
            is_external: false,
          }))
        );
      }
    },
    [
      group.data,
      group.isFetched,
      group.isFetching,
      isEditMode,
      setGroupName,
      setIconName,
      setUsersList,
    ]
  );

  const isPermissionsChanged = !isEqual(permissions, group.data?.permissions);
  const isNameChanged = groupName !== group.data?.name;
  const isIconChanged = iconName !== group.data?.icon_name;
  const isUsersListChanged = !isEqual(
    usersList.map((user) => user.id),
    group.data?.users.map((user) => user.id)
  );

  const hasChangesToSave = () => {
    if (!isEditMode) return true;

    return (
      isPermissionsChanged ||
      isUsersListChanged ||
      isNameChanged ||
      isIconChanged
    );
  };

  const onAddUsers = (usersToAdd: GroupMembersListProps['usersList']) => {
    setUsersList((prev) => [...prev, ...usersToAdd]);
  };

  const onRemoveUsers = (userIdsToRemove: string[]) => {
    setUsersList((prev) =>
      prev.filter((user) => !userIdsToRemove.includes(user.id))
    );
  };

  const openAddMembersModal = () => {
    openModal<AddGroupMembersModalProps['data']>('AddGroupMembersModal', {
      usersList,
      onSubmit: (usersToAdd) =>
        onAddUsers(
          usersToAdd.map((userToAdd) => ({
            ...userToAdd,
            isChecked: false,
            is_external: false,
          }))
        ),
    });
  };

  const onCreateNewGroup = async () => {
    try {
      await createGroup.mutateAsync({
        name: groupName,
        iconName,
        permissions,
        userIds: usersList.map((user) => user.id),
      });

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  const onEditGroup = async () => {
    try {
      if (hasChangesToSave()) {
        await updateGroup.mutateAsync({
          groupId: groupId as string,
          iconName,
          name: groupName,
          permissions: isPermissionsChanged ? permissions : undefined,
          userIds: usersList.map((user) => user.id),
        });
      }

      closeMe();
      return;
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = () => {
    if (groupName.length === 0) {
      setIsGroupNameError(true);
      setActiveTab('general');

      return;
    }

    setIsGroupNameError(false);

    if (isEditMode) {
      onEditGroup();
    } else {
      onCreateNewGroup();
    }
  };

  return (
    <Modal
      opened
      title={isEditMode ? 'Manage Group' : 'Create New Group'}
      onClose={closeMe}
      padding={0}
      size="clamp(800px, 55%, 1030px)"
      styles={modalStyles}
    >
      <div className={classes.body}>
        <Tabs
          keepMounted={false}
          value={activeTab}
          onTabChange={(tab: ManageGroupModalTabs) => setActiveTab(tab)}
          styles={tabsStyles}
        >
          <Tabs.List grow>
            <Tabs.Tab value="general" data-testid="manage-groups-general-tab">
              General
            </Tabs.Tab>
            <Tabs.Tab value="members" data-testid="manage-groups-members-tab">
              Members
            </Tabs.Tab>
          </Tabs.List>

          {group.isFetching ? (
            <LoadingOverlay visible />
          ) : (
            <>
              <Tabs.Panel value="general">
                <GroupDetailsForm
                  groupId={groupId}
                  groupName={groupName}
                  setGroupName={setGroupName}
                  isGroupNameError={isGroupNameError}
                  iconName={iconName}
                  setIconName={setIconName}
                  permissions={permissions}
                  setPermissions={setPermissions}
                  isNameEditable={
                    !isEditMode || group.data?.is_name_editable === true
                  }
                  isPermissionsEditable={isPermissionsEditable}
                />
              </Tabs.Panel>

              <Tabs.Panel value="members">
                <GroupMembersList
                  usersList={usersList}
                  onRemoveUsers={onRemoveUsers}
                  onAddUsersActionClick={openAddMembersModal}
                  onChangeCheckAllUsers={onChangeCheckAllUsers}
                  onChangeCheckedUser={onChangeCheckedUser}
                />
              </Tabs.Panel>
            </>
          )}
        </Tabs>

        <Group className={classes.footer} position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!hasChangesToSave()}
            loading={isSubmitButtonLoading}
            data-testid={
              isEditMode ? 'save-changes-button' : 'create-group-button'
            }
          >
            {isEditMode ? 'Save Changes' : 'Create Group'}
          </Button>
        </Group>
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    minHeight: '85%',
  },
  header: {
    padding: 30,
    marginBottom: 0,
  },
});

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gap: 32,
    paddingInline: 30,
    paddingBottom: theme.spacing.md,
  },
  panel: {
    height: '100%',
  },
});

const useStyles = createStyles((theme) => ({
  body: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  footer: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
