import {
  ActionIcon,
  Box,
  createStyles,
  Menu,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import React from 'react';

import { SortableDndItem } from '@portals/core';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-2.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { PostPurchaseParamsStepFieldType } from '@portals/types';

import {
  FIELD_ICONS,
  FIELD_LABELS,
} from './post-purchase-params-step.constants';

interface PostPurchaseParamsFieldsProps {
  fields: PostPurchaseParamsStepFieldType[];
  onChange: (index: number, field: PostPurchaseParamsStepFieldType) => void;
  onRemove: (index: number) => void;
  onEdit: (index: number) => void;
  onReorder: (dragIndex: number, hoverIndex: number) => void;
}

export function PostPurchaseParamsFields({
  fields,
  onChange,
  onRemove,
  onEdit,
  onReorder,
}: PostPurchaseParamsFieldsProps) {
  const { classes } = useStyles();

  if (!fields?.length) return null;

  const onMove = (dragIndex: number, hoverIndex: number) => {
    onReorder(dragIndex, hoverIndex);
  };

  return (
    <Stack>
      {fields.map((field, index) => {
        const Icon = FIELD_ICONS[field.type];

        return (
          <SortableDndItem
            key={`${field.type}-${field.label}`}
            dndTypeIdentifier="post-purchase-field"
            itemIndex={index}
            itemUniqueId={index}
            onMove={onMove}
          >
            <Box className={classes.fieldContainer}>
              <ActionIcon
                sx={{ transform: 'rotate(90deg) !important' }}
                color="gray.3"
                py={2}
              >
                <Drag />
              </ActionIcon>

              <Box className={classes.fieldContentWrapper}>
                <Box className={classes.fieldContent}>
                  <Icon width={40} height={40} />

                  <Stack
                    justify="space-between"
                    h={40}
                    spacing={0}
                    sx={{ overflow: 'hidden' }}
                  >
                    <Text
                      size="md"
                      weight={500}
                      color="gray.8"
                      truncate
                      title={field.label}
                    >
                      {field.label}
                    </Text>

                    <Text size="xs" color="gray.5">
                      {FIELD_LABELS[field.type]}
                    </Text>
                  </Stack>
                </Box>

                <Switch
                  label="Required"
                  labelPosition="left"
                  checked={field.required}
                  onChange={(event) =>
                    onChange(index, {
                      ...field,
                      required: event.target.checked,
                    })
                  }
                />
              </Box>

              <Box>
                <Menu withinPortal>
                  <Menu.Target>
                    <ActionIcon color="gray.9">
                      <More />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      icon={<Edit width={16} height={16} />}
                      onClick={() => onEdit(index)}
                    >
                      Edit
                    </Menu.Item>

                    <Menu.Item
                      icon={<Trash width={16} height={16} />}
                      color="red"
                      onClick={() => onRemove(index)}
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Box>
            </Box>
          </SortableDndItem>
        );
      })}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  fieldContainer: {
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.xl,
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    gap: theme.spacing.lg,
    alignItems: 'center',
  },
  fieldContentWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gap: theme.spacing.md,
    alignItems: 'center',
  },
  fieldContent: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: theme.spacing.md,
    overflow: 'hidden',
  },
}));
