import {
  Box,
  TextInput,
  Text,
  TextInputProps,
  createStyles,
} from '@mantine/core';
import React, { FC } from 'react';

interface RightSectionProps {
  label: string;
  isRightSection?: boolean;
}

const useStyles = createStyles((theme) => ({
  container: {
    width: 'calc(100% - 1px)',
    height: 'calc(100% - 2px)',
    backgroundColor: theme.colors.gray[1],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftSection: {
    borderTopLeftRadius: theme.radius.sm,
    borderBottomLeftRadius: theme.radius.sm,
    transform: 'translateX(1px)',
  },
  rightSection: {
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    transform: 'translateX(-1px)',
  },
}));

const InputSection: FC<RightSectionProps> = ({ label, isRightSection }) => {
  const { cx, classes } = useStyles();

  return (
    <Box
      className={cx(
        classes.container,
        isRightSection ? classes.rightSection : classes.leftSection
      )}
    >
      <Text weight={400} color="gray" size="sm">
        {label}
      </Text>
    </Box>
  );
};

export const OnXyteDomainInput = (props: TextInputProps) => (
  <TextInput
    rightSectionWidth={95}
    rightSection={<InputSection label=".on-xyte.com" isRightSection />}
    styles={(theme) => ({
      input: {
        borderColor: theme.colors.gray[3],
      },
    })}
    {...props}
  />
);

export const CustomDomainInput = (props: TextInputProps) => (
  <TextInput
    icon={<InputSection label="https://" />}
    iconWidth={60}
    styles={(theme) => ({
      input: {
        borderColor: theme.colors.gray[3],
        paddingLeft: '65px !important',
      },
    })}
    {...props}
  />
);
