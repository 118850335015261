import { Box, BoxProps } from '@mantine/core';
import React from 'react';

import { UpgradeRibbonRightIcon } from '@portals/assets';

import {
  UpgradeTierHoverCard,
  UpgradeTierHoverCardProps,
} from './UpgradeTierHoverCard';

export interface UpgradeTierRibbonProps extends UpgradeTierHoverCardProps {
  boxProps: Partial<BoxProps>;
}

export function UpgradeTierRightRibbon({
  boxProps,
  ...upgradeTierHoverCardProps
}: UpgradeTierRibbonProps) {
  return (
    <UpgradeTierHoverCard {...upgradeTierHoverCardProps}>
      <Box {...boxProps}>
        <UpgradeRibbonRightIcon />
      </Box>
    </UpgradeTierHoverCard>
  );
}
