import { Button, Group, Modal, Stack } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { map } from 'lodash/fp';
import React from 'react';
import { useUpdateEffect } from 'react-use';

import { PostPurchaseParamsStepFieldType } from '@portals/types';

import { PostPurchaseParamForm } from './common/PostPurchaseParamForm';
import { getFormSchema } from './post-purchase-params-step.constants';

interface AddPostPurchaseParamModalProps {
  opened: boolean;
  onCancel: () => void;
  onConfirm: (field: PostPurchaseParamsStepFieldType) => void;
  postPurchaseParams: Array<PostPurchaseParamsStepFieldType>;
}

export function AddPostPurchaseParamModal({
  opened,
  onCancel,
  onConfirm,
  postPurchaseParams,
}: AddPostPurchaseParamModalProps) {
  const existingFieldsLabels = map('label', postPurchaseParams);
  const form = useForm<PostPurchaseParamsStepFieldType>({
    validate: yupResolver(getFormSchema({ usedLabels: existingFieldsLabels })),
  });

  useUpdateEffect(() => {
    if (!opened) {
      form.reset();
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      title="Add Parameter"
      onClose={onCancel}
      withinPortal
    >
      <Stack>
        <form onSubmit={form.onSubmit(onConfirm)}>
          <PostPurchaseParamForm form={form} mode="add" />

          <Group position="right" p="md" pb={0} pr={0} spacing="sm">
            <Button variant="white" color="dark" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              variant="default"
              type="submit"
              data-testid="add-parameter-add-button"
            >
              Add
            </Button>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
}
