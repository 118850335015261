import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  CommonWidgetFormType,
  NewWidgetConfigType,
  NewWidgetType,
  UpdateLayoutPayload,
} from '@portals/device-widgets';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/new_device_widgets`;
}

interface CreateDeviceModelWidgetParams {
  name: string;
  config: NewWidgetConfigType;
  sectionId?: string;
  withNotification?: boolean;
}

export const useNewCreateDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<NewWidgetType, ServerError, CreateDeviceModelWidgetParams>(
    {
      mutationFn: ({ name, config, sectionId }) =>
        fetchApiRequest(url, {
          ...options,
          body: JSON.stringify({ name, config, device_section_id: sectionId }),
        }),
      onSuccess: (data, { withNotification }) => {
        if (withNotification) {
          dispatch(toastrSuccess(`Widget ${data.name} created`));
        }

        queryClient.invalidateQueries(
          deviceModelsQueryKeys.sections.all(modelId)
        );
      },
      onError: (error, { withNotification }) => {
        if (withNotification) {
          dispatch(toastrError(error.error));
        }
      },
      meta: {
        mutationName: 'useCreateDeviceModelWidget',
        baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets`,
        method: 'POST',
      },
    }
  );
};

interface NewUpdateDeviceModelWidgetParams<TForm = CommonWidgetFormType> {
  widget: NewWidgetType<TForm>;
  widgetId: string;
  withNotification?: boolean;
}

export function useNewUpdateDeviceModelWidget<TForm = CommonWidgetFormType>(
  modelId: string
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'PUT',
  });

  return useMutation<
    NewWidgetType<TForm>,
    ServerError,
    NewUpdateDeviceModelWidgetParams<TForm>
  >({
    mutationFn: ({ widget, widgetId }) =>
      fetchApiRequest(`${url}/${widgetId}`, {
        ...options,
        body: JSON.stringify(widget),
      }),
    onSuccess: (data, { withNotification }) => {
      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );

      if (withNotification) {
        dispatch(toastrSuccess(`Widget ${data.name} updated`));
      }
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/:id`,
      method: 'PUT',
    },
  });
}

export const useNewUpdateDeviceModelWidgetsLayout = (modelId = '') => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: `${getApiUrl(modelId)}/update_layout`,
    method: 'POST',
  });

  return useMutation<
    Array<UpdateLayoutPayload>,
    ServerError,
    Array<UpdateLayoutPayload>
  >({
    mutationFn: (widgetsToUpdate) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(widgetsToUpdate),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useUpdateDeviceModelWidgetsLayout',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/update_layout`,
      method: 'POST',
    },
  });
};

export const useNewRemoveDeviceModelWidget = (modelId: string) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'DELETE',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (widgetId) => fetchApiRequest(`${url}/${widgetId}`, options),
    onSuccess: () => {
      dispatch(toastrSuccess(`Widget removed`));

      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );
    },
    onError: ({ error }) => dispatch(toastrError(error)),
    meta: {
      mutationName: 'useRemoveDeviceModelWidget',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/new_device_widgets/:id`,
      method: 'DELETE',
    },
  });
};
