import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { MANUFACTURER_MANUALS_API_URL } from './manufacturer-manuals.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { configurationQueryKeys } from '../configuration';

interface UseUploadPartnerManualParams {
  name: string;
  url: string;
}

interface PartnerManualResponse {
  created_at: string;
  id: string;
  name: string;
  partner_id: string;
  updated_at: string;
  url: string;
}

export function useUploadPartnerManual() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url: requestUrl, options } = useRequestOptions({
    url: MANUFACTURER_MANUALS_API_URL,
    method: 'POST',
  });

  return useMutation<
    PartnerManualResponse,
    ServerError,
    UseUploadPartnerManualParams
  >({
    mutationFn: ({ name, url }) => {
      return fetchApiRequest(requestUrl, {
        ...options,
        body: JSON.stringify({ name, url }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Manual uploaded successfully'));

      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useDeletePartnerManual() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: MANUFACTURER_MANUALS_API_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, { fileId: string }>({
    mutationFn: ({ fileId }) => {
      return fetchApiRequest(`${url}/${fileId}`, {
        ...options,
        body: JSON.stringify({ id: fileId }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Manual deleted successfully'));

      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
