export const DEVICES_API_URL = 'ui/partner/devices';

export const devicesQueryKeys = {
  list: [DEVICES_API_URL, 'devices', 'list'],
  detail: (deviceId: string) => [...devicesQueryKeys.list, 'details', deviceId],
  usageBasedStateHistories: (deviceId: string) => [
    ...devicesQueryKeys.detail(deviceId),
    'usage_based_state_histories',
  ],
};
