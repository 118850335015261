import { Input, TextInput } from '@mantine/core';
import React, { FC } from 'react';

import CopyToClipboard from './CopyToClipboard';

interface CopyTextProps {
  title: string;
  textToCopy: string;
}

const CopyText: FC<CopyTextProps> = ({ title, textToCopy }) => (
  <Input.Wrapper label={title} sx={{ flex: 1 }}>
    <TextInput
      disabled
      value={textToCopy}
      rightSectionWidth={70}
      rightSection={<CopyToClipboard textToCopy={textToCopy} />}
      styles={(theme) => ({
        rightSection: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginRight: theme.spacing.xs,
        },
      })}
    />
  </Input.Wrapper>
);

export default CopyText;
