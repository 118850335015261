import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import {
  PartnerConfigurationType,
  PartnerFeatureFlagsType,
} from '@portals/types';

import {
  configurationQueryKeys,
  FEATURE_FLAGS_API_URL,
} from './configuration.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

interface UseUpdateFeatureFlagsParams {
  feature_flags: PartnerFeatureFlagsType;
}
export function useUpdateFeatureFlags() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: FEATURE_FLAGS_API_URL,
    method: 'POST',
  });

  return useMutation<
    PartnerConfigurationType,
    ServerError,
    UseUpdateFeatureFlagsParams
  >({
    mutationFn: (featureFlags) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(featureFlags),
      }),
    onSuccess: (response) => {
      queryClient.setQueriesData(configurationQueryKeys.config, response);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
