import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { castArray, includes } from 'lodash/fp';
import React, { FC, useState } from 'react';

import {
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import { ModalCenteredMediaLayout } from '@portals/core';
import { getCountryName, getCountryCodes } from '@portals/countries';
import {
  ModalProps,
  useValidateTaxAddress,
  ValidateTaxAddressParams,
} from '@portals/framework';

import locationSrc from '../assets/img/icons/location.svg';

const COUNTRIES_TO_VALIDATE = ['US', 'CA'];

export const AddTaxAddress: FC<ModalProps<{ description?: string }>> = ({
  closeMe,
  data: modalData,
}) => {
  const storeSettings = useStoreSettings();

  const isAdd = !storeSettings.data?.zip;
  const updateStoreSettings = useUpdateStoreSettings();
  const validateTaxAddress = useValidateTaxAddress();
  const [errors, setErrors] = useState(null);

  const form = useForm<ValidateTaxAddressParams>({
    initialValues: {
      address_1: storeSettings.data?.address_1 ?? '',
      address_2: storeSettings.data?.address_2 ?? '',
      address_3: storeSettings.data?.address_3 ?? '',
      country: storeSettings.data?.country ?? '',
      city: storeSettings.data?.city ?? '',
      zip: storeSettings.data?.zip ?? '',
      state: storeSettings.data?.state ?? '',
      note_to_shipper: storeSettings.data?.note_to_shipper ?? '',
    },
  });

  const onSubmit = async () => {
    const shouldValidate = includes(form.values.country, COUNTRIES_TO_VALIDATE);

    if (shouldValidate) {
      try {
        await validateTaxAddress.mutateAsync(form.values);
      } catch (err) {
        setErrors(err?.errors);

        return;
      }
    }

    await updateStoreSettings.mutateAsync(form.values);

    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={locationSrc} />}
      title={isAdd ? 'Add Address' : 'Edit Address'}
      description={modalData.description}
    >
      <LoadingOverlay
        visible={validateTaxAddress.isLoading || updateStoreSettings.isLoading}
      />

      <form onSubmit={form.onSubmit(onSubmit)} onChange={() => setErrors(null)}>
        <Stack spacing="xl">
          <Stack mb="lg">
            <Group spacing="md" grow>
              <Select
                required
                searchable
                label="Country"
                data-testid="country-select"
                {...form.getInputProps('country')}
                data={getCountryCodes().map((countryCode) => ({
                  value: countryCode,
                  label: getCountryName(countryCode),
                }))}
                styles={(theme) => ({
                  item: {
                    height: 'fit-content',
                    padding: `${theme.spacing.md} ${theme.spacing.xs}`,
                  },
                })}
              />

              <TextInput
                required
                label="State"
                placeholder=""
                data-testid="state-input"
                {...form.getInputProps('state')}
              />
            </Group>

            <TextInput
              required
              label="Address #1"
              placeholder=""
              data-testid="address-1-input"
              {...form.getInputProps('address_1')}
            />

            <TextInput
              label="Address #2"
              placeholder=""
              data-testid="address-2-input"
              {...form.getInputProps('address_2')}
            />

            <TextInput
              label="Address #3"
              placeholder=""
              data-testid="address-3-input"
              {...form.getInputProps('address_3')}
            />

            <Group grow>
              <TextInput
                required
                label="City"
                placeholder=""
                data-testid="city-input"
                {...form.getInputProps('city')}
              />

              <TextInput
                required
                label="Zip"
                placeholder=""
                data-testid="zip-input"
                {...form.getInputProps('zip')}
              />
            </Group>
          </Stack>

          {errors ? (
            <Alert color="red">
              <Stack spacing="xs">
                {castArray(errors).map((error, index) => (
                  <Text key={index} size="sm">
                    - {error}
                  </Text>
                ))}
              </Stack>
            </Alert>
          ) : null}

          <Group grow spacing="md">
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={!form.isValid || !!errors}
              data-testid="add-tax-address-submit-button"
            >
              {isAdd ? 'Add Address' : 'Update Address'}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
};
