import React from 'react';

import { ReactComponent as Calendar } from '@portals/icons/linear/calendar.svg';
import { ReactComponent as Code } from '@portals/icons/linear/code.svg';
import { ReactComponent as Computing } from '@portals/icons/linear/computing.svg';
import { ReactComponent as DocumentText1 } from '@portals/icons/linear/document-text-1.svg';
import { ReactComponent as List } from '@portals/icons/linear/list.svg';
import { ReactComponent as MobileProgramming } from '@portals/icons/linear/mobile-programming.svg';
import { ReactComponent as TickSquare } from '@portals/icons/linear/tick-square.svg';
import { FieldTypeEnum } from '@portals/types';

export enum CommandsTypesEnum {
  StaticListSingle = 'staticListSingle',
  StaticListMulti = 'staticListMulti',
  String = 'string',
  Number = 'number',
  Date = 'date',
  Boolean = 'boolean',
  JSON = 'json',
  DynamicListSingle = 'dynamicListSingle',
  DynamicListMulti = 'dynamicListMulti',
}

export const COMMAND_TYPES = {
  [CommandsTypesEnum.StaticListSingle]: {
    value: FieldTypeEnum.Select,
    label: 'Static list (single-select)',
  },
  [CommandsTypesEnum.StaticListMulti]: {
    value: FieldTypeEnum.Multiselect,
    label: 'Static list (multi-select)',
  },
  [CommandsTypesEnum.String]: {
    value: FieldTypeEnum.Textarea,
    label: 'String',
  },
  [CommandsTypesEnum.Number]: {
    value: FieldTypeEnum.Number,
    label: 'Number',
  },
  [CommandsTypesEnum.Date]: { value: FieldTypeEnum.Datetime, label: 'Date' },
  [CommandsTypesEnum.Boolean]: {
    value: FieldTypeEnum.Checkbox,
    label: 'Boolean',
  },
  [CommandsTypesEnum.JSON]: { value: FieldTypeEnum.JSON, label: 'JSON' },
  [CommandsTypesEnum.DynamicListSingle]: {
    value: FieldTypeEnum.Select,
    label: 'Dynamic list (single-select)',
  },
  [CommandsTypesEnum.DynamicListMulti]: {
    value: FieldTypeEnum.Multiselect,
    label: 'Dynamic list (multi-select)',
  },
};

export const COMMAND_TYPES_DATA = [
  {
    value: CommandsTypesEnum.StaticListSingle,
    label: COMMAND_TYPES.staticListSingle.label,
    type: COMMAND_TYPES.staticListSingle.value,
  },
  {
    value: CommandsTypesEnum.StaticListMulti,
    type: COMMAND_TYPES.staticListMulti.value,
    label: COMMAND_TYPES.staticListMulti.label,
  },
  {
    value: CommandsTypesEnum.DynamicListSingle,
    type: COMMAND_TYPES.dynamicListSingle.value,
    label: COMMAND_TYPES.dynamicListSingle.label,
  },
  {
    value: CommandsTypesEnum.DynamicListMulti,
    type: COMMAND_TYPES.dynamicListMulti.value,
    label: COMMAND_TYPES.dynamicListMulti.label,
  },
  {
    value: CommandsTypesEnum.String,
    type: COMMAND_TYPES.string.value,
    label: COMMAND_TYPES.string.label,
  },
  {
    value: CommandsTypesEnum.Number,
    type: COMMAND_TYPES.number.value,
    label: COMMAND_TYPES.number.label,
  },
  {
    value: CommandsTypesEnum.Date,
    type: COMMAND_TYPES.date.value,
    label: COMMAND_TYPES.date.label,
  },
  {
    value: CommandsTypesEnum.Boolean,
    type: COMMAND_TYPES.boolean.value,
    label: COMMAND_TYPES.boolean.label,
  },
  {
    value: CommandsTypesEnum.JSON,
    type: COMMAND_TYPES.json.value,
    label: COMMAND_TYPES.json.label,
  },
];

export const TYPES_ICONS = {
  [CommandsTypesEnum.StaticListSingle]: {
    icon: <List />,
    color: 'cyan',
  },
  [CommandsTypesEnum.StaticListMulti]: {
    icon: <List />,
    color: 'cyan',
  },
  [CommandsTypesEnum.DynamicListSingle]: {
    icon: <Code />,
    color: 'orange',
  },
  [CommandsTypesEnum.DynamicListMulti]: {
    icon: <Code />,
    color: 'orange',
  },
  [CommandsTypesEnum.Number]: {
    icon: <Computing />,
    color: 'blue_accent',
  },
  [CommandsTypesEnum.String]: {
    icon: <DocumentText1 />,
    color: 'orange',
  },
  [CommandsTypesEnum.Date]: {
    icon: <Calendar />,
    color: 'green',
  },
  [CommandsTypesEnum.Boolean]: {
    icon: <TickSquare />,
    color: 'red',
  },
  [CommandsTypesEnum.JSON]: {
    icon: <MobileProgramming />,
    color: 'blue_gray',
  },
};
