import { Box, createStyles, Grid, Modal, Text } from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  useDeviceModel,
  useDeviceModelTestDevice,
} from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import { Aside } from '../components/ConnectFlow/Aside';
import { Footer } from '../components/ConnectFlow/Footer';
import { RegisterDevice } from '../components/ConnectFlow/steps/RegisterDevice';
import { SendTelemetry } from '../components/ConnectFlow/steps/SendTelemetry';

const useStyles = createStyles((theme) => ({
  pageTitle: {
    fontSize: 24,
    color: theme.colors.blue_gray[9],
    marginBottom: 32,

    [theme.fn.largerThan('xl')]: {
      marginBottom: 45,
    },
  },
}));

const ConnectFlow: FC<ModalProps<{ modelId: string }>> = ({
  closeMe,
  data,
}) => {
  const { classes } = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [isFetchingEnabled, setIsFetchingEnabled] = useState(true);

  const model = useDeviceModel(data.modelId);

  const testDevice = useDeviceModelTestDevice(data.modelId, {
    refetchInterval: 5000,
    enabled: isFetchingEnabled,
  });

  const registeredDevice = testDevice?.data?.device;

  useEffectOnce(function fetchOnMount() {
    testDevice.refetch();
  });

  useEffect(
    function disableRefetchOnFinish() {
      if (registeredDevice?.has_telemetries) {
        setIsFetchingEnabled(false);
      }
    },
    [registeredDevice?.has_telemetries]
  );

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      withCloseButton={false}
      size="100%"
      padding="0 0 12px"
      styles={(theme) => ({
        content: {
          maxWidth: theme.breakpoints.lg,
          maxHeight: '850px !important',
          height: '100%',

          [theme.fn.largerThan('xl')]: {
            maxWidth: theme.breakpoints.xl,
          },
        },

        body: {
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr max-content',
        },
      })}
    >
      <Box>
        {activeStep === 0 ? (
          <>
            <VerticalScrollBar>
              <Grid sx={{ margin: 0, height: '100%' }}>
                <Grid.Col
                  span={7}
                  sx={{
                    padding: '32px 0 70px 48px',
                    display: 'grid',
                    gridTemplateRows: 'max-content 1fr',
                  }}
                >
                  <Text className={classes.pageTitle}>Register</Text>

                  <RegisterDevice
                    modelId={model.data?.id}
                    modelName={model.data?.model}
                    authMethod={model.data?.auth_method}
                  />
                </Grid.Col>

                <Grid.Col span={5} p={0}>
                  <Aside closeModal={closeMe} />
                </Grid.Col>
              </Grid>
            </VerticalScrollBar>
          </>
        ) : (
          <>
            <VerticalScrollBar>
              <Grid sx={{ margin: 0, height: '100%' }}>
                <Grid.Col
                  span={7}
                  sx={{
                    padding: '32px 0 70px 48px',
                    display: 'grid',
                    gridTemplateRows: 'max-content 1fr',
                  }}
                >
                  <Text className={classes.pageTitle}>Send Telemetry</Text>

                  <SendTelemetry
                    modelName={model.data?.model}
                    device={registeredDevice}
                  />
                </Grid.Col>

                <Grid.Col span={5} p={0}>
                  <Aside closeModal={closeMe} />
                </Grid.Col>
              </Grid>
            </VerticalScrollBar>
          </>
        )}
      </Box>

      <Footer
        isDeviceRegistered={!!registeredDevice}
        isTelemetrySent={registeredDevice?.has_telemetries}
        moveToNextStep={() => setActiveStep(1)}
        activeStep={activeStep}
        closeMe={closeMe}
      />
    </Modal>
  );
};

export default ConnectFlow;
