import { Checkbox, Select, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { useUpdateEffect } from 'react-use';

import { PostPurchaseParamsStepFieldType } from '@portals/types';

import { FIELD_SPECIFIC_INPUTS } from './FieldSpecificInputs';
import { FIELD_TYPES_OPTIONS } from '../post-purchase-params-step.constants';

interface PostPurchaseParamFormProps {
  form: UseFormReturnType<PostPurchaseParamsStepFieldType>;
  mode: 'edit' | 'add';
}

export function PostPurchaseParamForm({
  form,
  mode,
}: PostPurchaseParamFormProps) {
  const fieldType = form.values.type;
  const FieldSpecificInputs = FIELD_SPECIFIC_INPUTS[fieldType] ?? null;

  useUpdateEffect(
    function initFieldConfiguration() {
      if (mode === 'edit') return;

      if (fieldType === 'select') {
        form.setFieldValue('configuration', {
          options: [
            {
              value: '',
              label: '',
            },
          ],
        });
      } else if (fieldType === 'number') {
        form.setFieldValue('configuration', {
          min: undefined,
          max: undefined,
        });
      } else {
        form.setFieldValue('configuration', {});
      }
    },
    [fieldType, mode]
  );

  return (
    <Stack py="lg" spacing="lg">
      <TextInput
        label="Label"
        data-testid="add-parameter-label-input"
        autoFocus
        data-autofocus
        required
        {...form.getInputProps('label')}
      />

      <TextInput
        label="Description (optional)"
        data-testid="add-parameter-description-input"
        {...form.getInputProps('description')}
      />

      <Select
        label="Field type"
        data-testid="add-parameter-field-type-selection"
        required
        data={FIELD_TYPES_OPTIONS}
        {...form.getInputProps('type')}
      />

      <Checkbox
        label="This field is required"
        data-testid="required-field-checkbox"
        {...form.getInputProps('required', { type: 'checkbox' })}
      />

      {FieldSpecificInputs ? <FieldSpecificInputs form={form} /> : null}
    </Stack>
  );
}
