import { createStyles, getStylesRef, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as Lock1Icon } from '@portals/icons/bold/lock-1.svg';

interface StoreListingCategoriesProps {
  categories: Array<string>;
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
}

export function StoreListingCategories({
  categories,
  selectedCategory,
  onSelectCategory,
}: StoreListingCategoriesProps) {
  const { classes, cx } = useStyles();

  return (
    <Stack spacing="xs">
      <Group
        position="apart"
        onClick={() => onSelectCategory(null)}
        className={cx(classes.categoryRow, {
          [classes.selected]: selectedCategory === null,
        })}
      >
        <Text className={classes.categoryName}>All products</Text>
      </Group>

      {categories.map((category, index) => (
        <Group
          key={index}
          position="apart"
          onClick={() => onSelectCategory(category)}
          className={cx(classes.categoryRow, {
            [classes.selected]: category === selectedCategory,
          })}
        >
          <Text className={classes.categoryName}>{category}</Text>
          <Lock1Icon className={classes.lockIcon} />
        </Group>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  categoryRow: {
    ref: getStylesRef('categoryRow'),
    paddingInline: theme.spacing.md,
    paddingBlock: theme.spacing.sm,
    cursor: 'pointer',
  },
  categoryName: {
    ref: getStylesRef('categoryName'),
    color: theme.colors.blue_gray[7],
    textTransform: 'capitalize',
  },
  lockIcon: {
    ref: getStylesRef('lockIcon'),
    width: 18,
    height: 18,
    color: theme.colors.gray[4],
  },
  selected: {
    [`&.${getStylesRef('categoryRow')}`]: {
      borderRadius: theme.radius.xl,
      backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),
    },

    [`.${getStylesRef('categoryName')}`]: {
      color: theme.fn.primaryColor(),
    },

    [`.${getStylesRef('lockIcon')}`]: {
      color: theme.colors.primary[1],
    },
  },
}));
