import { Button, Menu, MenuProps } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ReactComponent as Calendar } from '@portals/icons/bulk/calendar.svg';
import { ReactComponent as CardPos } from '@portals/icons/bulk/card-pos.svg';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { PaymentIntervalEnum } from '@portals/types';

import { useStoreListingWizard } from '../hooks';

interface AddPriceMenuButtonProps {
  onAddPaymentPeriod: (period: PaymentIntervalEnum) => void;
}

export function AddPriceMenuButton({
  onAddPaymentPeriod,
}: AddPriceMenuButtonProps) {
  const { contextData } = useStoreListingWizard();

  if (contextData.storeListing?.editable === false) {
    return null;
  }

  const menuItems: Array<{
    period: PaymentIntervalEnum;
    label: string;
    icon: ReactNode;
    hasDivider?: boolean;
  }> = [];

  if (!contextData.paymentSettings.one_time) {
    menuItems.push({
      period: PaymentIntervalEnum.OneTime,
      label: 'One Time',
      icon: <CardPos />,
      hasDivider: true,
    });
  }
  if (!contextData.paymentSettings.monthly) {
    menuItems.push({
      period: PaymentIntervalEnum.Monthly,
      label: 'Monthly',
      icon: <Calendar />,
    });
  }
  if (!contextData.paymentSettings.yearly) {
    menuItems.push({
      period: PaymentIntervalEnum.Yearly,
      label: 'Yearly',
      icon: <Calendar />,
    });
  }

  const buttonLabel =
    menuItems.length === 3 ? 'Add Price' : 'Add Another Price';

  return (
    <Menu styles={menuStyles} width="target">
      <Menu.Target>
        <Button
          w={200}
          variant="default"
          disabled={menuItems.length === 0}
          leftIcon={<Add width={16} height={16} />}
          sx={{ alignSelf: 'flex-start' }}
          data-testid="add-price-button"
        >
          {buttonLabel}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {menuItems.map((item) => (
          <React.Fragment key={item.period}>
            <Menu.Item
              icon={item.icon}
              onClick={() => onAddPaymentPeriod(item.period)}
              data-testid={`add-price-${item.period}`}
            >
              {item.label}
            </Menu.Item>

            {item.hasDivider && <Menu.Divider />}
          </React.Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

const menuStyles: MenuProps['styles'] = (theme) => ({
  itemIcon: {
    width: 24,
    height: 24,
    color: theme.fn.primaryColor(),
  },
  divider: {
    marginInline: 0,
  },
});
