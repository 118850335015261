import {
  Box,
  Button,
  Group,
  Input,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import { SupportedCommandType } from '@portals/types';

import { GroupedSwitchFormDndWrapper } from './grouped-switch-form/GroupedSwitchFormDndWrapper';
import { GroupedSwitchesWidgetFormType } from './grouped-switches.types';
import { ColorSelector } from '../../common/ColorSelector';
import { InputLabelWithTooltip } from '../../common/input-helpers';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface GroupedSwitchesWidgetFormProps {
  form: UseFormReturnType<GroupedSwitchesWidgetFormType>;
  commands: SupportedCommandType[] | undefined;
  colors: WidgetColorType[] | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function GroupedSwitchesWidgetForm({
  form,
  commands,
  colors,
  onAddCustomColor,
}: GroupedSwitchesWidgetFormProps) {
  const onUpdateFieldPosition = (dragIndex: number, hoverIndex: number) => {
    const fieldsList = [...form.values.commands];

    [fieldsList[dragIndex], fieldsList[hoverIndex]] = [
      fieldsList[hoverIndex],
      fieldsList[dragIndex],
    ];

    form.setValues({ ...form.values, commands: fieldsList });
  };

  return (
    <Stack spacing="xl" pr={30} data-testid="widget-hex-color-selector">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          data-testid="group-switches-command-title-input"
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}
      </Stack>

      <Input.Wrapper label="Color">
        <ColorSelector
          selectedColor={form.values.color}
          onPresetColorSelect={(color) => form.setFieldValue('color', color)}
          onAddCustomColor={onAddCustomColor}
          colors={colors}
        />
      </Input.Wrapper>

      <Stack w="100%">
        <Text size="sm" color="gray.9">
          Commands
        </Text>

        <Box data-testid="display-title-switcher">
          <Switch
            label="Display titles"
            {...form.getInputProps('display_title', { type: 'checkbox' })}
          />
        </Box>

        {form.values.commands.map((commandField, index) => (
          <GroupedSwitchFormDndWrapper
            onMove={onUpdateFieldPosition}
            key={commandField.key}
            commands={commands}
            commandField={commandField}
            index={index}
            form={form}
          />
        ))}
      </Stack>

      <Group grow>
        <Button
          variant="default"
          onClick={() =>
            form.insertListItem('commands', {
              label: '',
              command_name: '',
              telemetry: '',
              icon_name: 'battery_full',
              key: uuid(),
            })
          }
        >
          Add Command
        </Button>
      </Group>
    </Stack>
  );
}
