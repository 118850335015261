import {
  ActionIcon,
  Group,
  LoadingOverlay,
  ScrollArea,
  Tabs,
  TabsProps,
  Tooltip,
} from '@mantine/core';
import React, { useState } from 'react';

import { useProductCategories } from '@portals/api/partners';
import { ReactComponent as Element3 } from '@portals/icons/linear/element-3.svg';
import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { TableViewModeType } from '@portals/types';

import { ProductsTable } from './ProductsTable';
import { DetailsColumn } from '../DetailsColumn';
import { useStoreListingWizard } from '../hooks';

export function ChooseProductStep() {
  const { setContextData } = useStoreListingWizard();
  const [viewType, setViewType] = useState<TableViewModeType>('grid');

  const productCategories = useProductCategories();

  if (productCategories.isLoading) {
    return <LoadingOverlay visible />;
  }

  const categories = ['all'].concat(productCategories.data || []);

  return (
    <DetailsColumn title="Choose Product">
      <Tabs
        defaultValue="all"
        styles={tabsStyles}
        keepMounted={false}
        onTabChange={() =>
          setContextData((prev) => ({ ...prev, selectedProduct: undefined }))
        }
      >
        <ScrollArea type="auto">
          <Tabs.List>
            {categories.map((category) => (
              <Tabs.Tab key={category} value={category}>
                {category}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </ScrollArea>

        <Group position="right" spacing="xs">
          <Tooltip label="List view">
            <ActionIcon
              color="blue_gray"
              variant={viewType === 'list' ? 'light' : 'subtle'}
              size="lg"
              onClick={() => setViewType('list')}
            >
              <ListIcon width={18} height={18} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Grid view">
            <ActionIcon
              color="blue_gray"
              variant={viewType === 'grid' ? 'light' : 'subtle'}
              size="lg"
              onClick={() => setViewType('grid')}
            >
              <Element3 width={18} height={18} />
            </ActionIcon>
          </Tooltip>
        </Group>

        {categories.map((category) => (
          <Tabs.Panel key={category} value={category}>
            <ProductsTable viewType={viewType} category={category} />
          </Tabs.Panel>
        ))}
      </Tabs>
    </DetailsColumn>
  );
}

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing.xl,
  },
  tabsList: {
    width: 'fit-content',
    flexWrap: 'nowrap',
  },
  tabLabel: {
    textTransform: 'capitalize',
  },
  panel: {
    gridColumn: '1 / -1',
  },
});
