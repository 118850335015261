import { Code, createStyles } from '@mantine/core';
import React, { FC } from 'react';

import CopyToClipboard from './CopyToClipboard';

const useStyles = createStyles((theme) => ({
  code: {
    padding: `${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.md}`,
    backgroundColor: theme.colors.gray[1],
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '0 4px 4px 4px',
    display: 'grid',
    gridTemplateRows: '1fr 18px',
    height: '100%',
    maxHeight: 330,
  },
  codeText: {
    whiteSpace: 'pre-wrap',
    background: 'none',
    lineHeight: '20px',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[8],
    padding: 0,
  },
}));

interface CodeSnippetProps {
  code: string;
}

const CodeSnippet: FC<CodeSnippetProps> = ({ code }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.code}>
      <Code className={classes.codeText}>{code}</Code>
      <CopyToClipboard textToCopy={code} />
    </div>
  );
};

export default CodeSnippet;
