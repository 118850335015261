import { ActionIcon, Button, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useHubspotConversations,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as MessageQuestion } from '@portals/icons/linear/message-question.svg';
import { ReactComponent as Send2 } from '@portals/icons/linear/send-2.svg';
import { useOpenModal } from '@portals/redux';

import Video from './Video';

const useStyles = createStyles((theme) => ({
  button: {
    borderColor: theme.colors.gray[3],
    fontWeight: 400,
    color: theme.colors.blue_gray[8],

    svg: {
      width: '16px',
      height: '16px',
    },
  },

  video: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.sm,
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
  },

  closeIcon: {
    color: theme.colors.blue_gray[3],
    display: 'flex',
    alignSelf: 'self-end',
    margin: '32px 0 38px 0',
    cursor: 'pointer',
  },
}));

interface AsideProps {
  closeModal: () => void;
}

export function Aside({ closeModal }: AsideProps) {
  const { classes } = useStyles();
  const { isAdmin } = usePermissionAccess();
  const { toggleWidget } = useHubspotConversations();
  const openModal = useOpenModal();
  const navigate = useNavigate();

  const onShareClick = () => {
    closeModal();
    navigate('/settings/users_and_groups/users');
    openModal('AddUserModal');
  };

  const openHubspotConversationsWidget = () => {
    toggleWidget();
  };

  return (
    <Stack
      sx={(theme) => ({
        padding: '0 44px 20px',
        backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.25),
        height: '100%',
      })}
      spacing="xl"
    >
      <ActionIcon onClick={closeModal} className={classes.closeIcon}>
        <CloseX />
      </ActionIcon>

      <Stack className={classes.video} p={32}>
        <Text mb="lg" sx={(theme) => ({ color: theme.colors.gray[8] })}>
          Learn how to register your first device
        </Text>

        <Video />
      </Stack>

      <Stack>
        {isAdmin && (
          <Button
            onClick={onShareClick}
            leftIcon={<Send2 />}
            className={classes.button}
            variant="default"
            styles={(theme) => ({
              leftIcon: {
                marginRight: theme.spacing.xs,
              },
            })}
          >
            Share With a Developer
          </Button>
        )}

        <Button
          onClick={openHubspotConversationsWidget}
          leftIcon={<MessageQuestion />}
          className={classes.button}
          variant="default"
          styles={(theme) => ({
            leftIcon: {
              marginRight: theme.spacing.xs,
            },
          })}
        >
          Need Help?
        </Button>
      </Stack>
    </Stack>
  );
}
