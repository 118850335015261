import { Anchor, createStyles, Text } from '@mantine/core';
import { flow, set } from 'lodash/fp';
import React from 'react';

import { useOpenModal } from '@portals/redux';

import { DetailsColumnContent } from './common/DetailsColumnContent';
import { StepId } from './types';
import { useProductWizard } from './use-product-wizard';
import { TaxGroupsSelect } from '../../components/tax-groups/tax-groups-select/TaxGroupsSelect';

export function TaxDetailsStep() {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const { contextData, setContextData } = useProductWizard();

  const onSelectTaxGroup = (taxGroupId: string) => {
    setContextData((curr) =>
      flow([
        set(['product', 'tax_group_id'], taxGroupId),
        set(['errors', StepId.TaxDetails], ''),
      ])(curr)
    );
  };

  return (
    <DetailsColumnContent title="Tax Details">
      <Text className={classes.taxText}>
        Select a tax group that best matches your product,&nbsp;
        <Anchor
          onClick={() =>
            openModal('CreateTaxGroup', {
              onAfterCreate: onSelectTaxGroup,
            })
          }
        >
          or create a new tax group
        </Anchor>
      </Text>

      <TaxGroupsSelect
        error={contextData.errors[StepId.TaxDetails]}
        styles={{ root: { maxWidth: 330 } }}
        value={contextData.product.tax_group_id}
        onChange={onSelectTaxGroup}
      />
    </DetailsColumnContent>
  );
}

const useStyles = createStyles((theme) => ({
  taxText: {
    marginBottom: theme.spacing.md,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
  },
}));
