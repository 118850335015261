import {
  Modal,
  ModalProps as MantineModalProps,
  createStyles,
  CloseButton,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';

import { useNonePaginatedPartnerSelfPurchasableStoreListings } from '@portals/api/partners';
import { ModalProps, WizardProvider } from '@portals/framework';

import { STEPS_MAP } from './create-order-wizard/steps-map';
import {
  CreateOrderWizardContextDataType,
  StepIdEnum,
} from './create-order-wizard/types';
import { WizardContent } from './create-order-wizard/WizardContent';

interface CreateOrderWizardModalProps extends ModalProps {}

export function CreateOrderWizardModal({
  closeMe,
}: CreateOrderWizardModalProps) {
  const { classes } = useStyles();
  const storeListings = useNonePaginatedPartnerSelfPurchasableStoreListings();

  const [contextData, setContextData] =
    useState<CreateOrderWizardContextDataType>({
      closeMe,
      selectedStoreListings: [],
      storeListingsQuantities: {},
      storeListings: [],
      currency: 'USD',
      selectedStoreListingsTotalPrice: 0,
      orderReferenceId: '',
      orderNotes: '',
      attachmentUrl: '',
    });

  useEffect(
    function updateCreateOrderContextData() {
      if (!storeListings.isFetched || !storeListings.data) {
        return;
      }

      setContextData((prev) => ({
        ...prev,
        storeListings: storeListings.data,
      }));
    },
    [storeListings.data, storeListings.isFetched]
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      styles={modalStyles}
      withCloseButton={false}
      padding={0}
    >
      <CloseButton
        size="md"
        onClick={closeMe}
        className={classes.closeButton}
        data-testid="close-modal-buton"
      />

      <WizardProvider<CreateOrderWizardContextDataType, StepIdEnum>
        steps={STEPS_MAP}
        initialStep={StepIdEnum.SelectProducts}
        contextData={contextData}
        setContextData={setContextData}
      >
        <WizardContent />
      </WizardProvider>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    height: '90%',
    overflow: 'hidden !important',
    paddingInline: theme.spacing.xxl,
    paddingTop: theme.spacing.xxl,
    minWidth: '100%',
    display: 'flex',
  },
  header: { margin: 0 },
  body: {
    padding: 0,
    display: 'flex',
    flexGrow: 1,
  },
});

const useStyles = createStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing.md,
    right: theme.spacing.md,
  },
}));
