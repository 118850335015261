import {
  useMutation,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { CurrencyCode, TableColumn, TableState } from '@portals/types';

import { ORDERS_API_URL, ordersQueryKeys } from './orders.constants';
import {
  OfflinePaymentOrderItemType,
  OrderSummaryType,
  OrderType,
} from './orders.types';
import { useApiQuery } from '../../hooks';
import { ServerError, ServerSuccess } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function useOrders(
  tableState: Pick<TableState<OrderSummaryType>, 'sortBy'>,
  columns: Array<TableColumn<OrderSummaryType>>,
  baseUrl: string = ORDERS_API_URL,
  queryKey: Array<string>
) {
  return usePaginatedTableApiQuery<OrderSummaryType>({
    baseUrl,
    tableState,
    columns,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...ordersQueryKeys.list(), baseUrl, tableState],
  });
}

export function useOrderById(orderId: string): UseQueryResult<OrderType> {
  return useApiQuery<OrderType>(
    `${ORDERS_API_URL}/${orderId}`,
    ordersQueryKeys.detail(orderId),
    { enabled: !!orderId }
  );
}

interface UseCreateShipmentParams {
  orderId: string;
  trackingNumber: string;
  serialNumbers: Record<string, Array<string>>;
}

export function useCreateOrderShipment() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, UseCreateShipmentParams>({
    mutationFn: ({ orderId, trackingNumber, serialNumbers }) =>
      fetchApiRequest(`${baseUrl}/${orderId}/shipment`, {
        ...options,
        body: JSON.stringify({
          tracking_number: trackingNumber,
          serial_numbers: serialNumbers,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateOrderShipment',
      baseUrl: `${ORDERS_API_URL}/:id/shipment`,
      method: 'POST',
    },
  });
}

interface UseUpdateShipmentStatusParams {
  orderId: string;
  newStatus: 'ready' | 'shipped' | 'delivered';
}

export function useUpdateOrderShipmentStatus() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'PUT',
  });

  return useMutation<ServerSuccess, ServerError, UseUpdateShipmentStatusParams>(
    {
      mutationFn: ({ newStatus, orderId }) =>
        fetchApiRequest(`${baseUrl}/${orderId}/shipment`, {
          ...options,
          body: JSON.stringify({
            status: newStatus,
          }),
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(ordersQueryKeys.all);
      },
      onError: ({ error }) => {
        dispatch(toastrError(error));
      },
      meta: {
        mutationName: 'useUpdateOrderShipmentStatus',
        baseUrl: `${ORDERS_API_URL}/:id/shipment`,
        method: 'PUT',
      },
    }
  );
}

interface UseApprovePurchaseOrderParams {
  orderId: string;
  note: string;
}

export function useApprovePurchaseOrder() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, UseApprovePurchaseOrderParams>(
    {
      mutationFn: ({ orderId, note }) =>
        fetchApiRequest(`${baseUrl}/${orderId}/approve_purchase_order`, {
          ...options,
          body: JSON.stringify({ note }),
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(ordersQueryKeys.all);
      },
      onError: ({ error }) => {
        dispatch(toastrError(error));
      },
      meta: {
        mutationName: 'useApprovePurchaseOrder',
        baseUrl: `${ORDERS_API_URL}/:id/approve_purchase_order`,
        method: 'POST',
      },
    }
  );
}

interface UseCreateOrderParams {
  items: Array<OfflinePaymentOrderItemType>;
  currency: CurrencyCode;
  referenceId?: string;
  attachment?: string;
  note?: string;
}

export function useCreateOrder() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<OrderType, ServerError, UseCreateOrderParams>({
    mutationFn: ({ items, referenceId, note, currency, attachment }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          items,
          reference_id: referenceId,
          note,
          currency,
          attachments: [attachment],
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateOrder',
      baseUrl: ORDERS_API_URL,
      method: 'POST',
    },
  });
}

interface UseUpdateReferenceIdParams {
  id: string;
  referenceId: string;
}

export function useUpdateReferenceId() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'PUT',
  });

  return useMutation<OrderType, ServerError, UseUpdateReferenceIdParams>({
    mutationFn: ({ id, referenceId }) =>
      fetchApiRequest(`${url}/${id}`, {
        ...options,
        body: JSON.stringify({
          reference_id: referenceId,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateReferenceId',
      baseUrl: `${ORDERS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

interface UseUpdateNoteParams {
  id: string;
  note: string;
}

export function useUpdateNote() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'PUT',
  });

  return useMutation<OrderType, ServerError, UseUpdateNoteParams>({
    mutationFn: ({ id, note }) =>
      fetchApiRequest(`${url}/${id}`, {
        ...options,
        body: JSON.stringify({
          note,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateNote',
      baseUrl: `${ORDERS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

interface UseUpdateAttachmentParams {
  id: string;
  attachment: string;
}

export function useUpdateAttachment() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'PUT',
  });

  return useMutation<OrderType, ServerError, UseUpdateAttachmentParams>({
    mutationFn: ({ id, attachment }) =>
      fetchApiRequest(`${url}/${id}`, {
        ...options,
        body: JSON.stringify({
          attachments: [attachment],
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateAttachment',
      baseUrl: `${ORDERS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
