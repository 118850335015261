import {
  createStyles,
  Divider,
  Grid,
  Group,
  HoverCard,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { flow, set } from 'lodash/fp';
import React from 'react';

import { ProductUsageBasedFieldsNames } from '@portals/api/partners';
import { LimitedTextInput } from '@portals/core';
import { ReactComponent as QuestionCircleIcon } from '@portals/icons/linear/question-circle.svg';
import { ProductPricingModel } from '@portals/types';

import displayNameSrc from './field-media-display-name.png';
import telemetrySrc from './field-media-telemetry.png';
import unitNameSrc from './field-media-unit-name.png';
import { ProductWizardContext, StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

type PricingModelUsageBasedErrorKeys = keyof Pick<
  ProductWizardContext['errors'][StepId.PricingModel],
  'usageBasedDisplayName' | 'usageBasedTelemetryKey' | 'usageBasedUnitName'
>;

export function UsageBasedFields() {
  const { contextData, setContextData } = useProductWizard();

  const onChange = (params: {
    fieldKey: ProductUsageBasedFieldsNames;
    errorKey: PricingModelUsageBasedErrorKeys;
    value: string;
  }) => {
    const { fieldKey, errorKey, value } = params;

    setContextData((prev) =>
      flow([
        set(['product', fieldKey], value),
        set(['errors', StepId.PricingModel, errorKey], ''),
      ])(prev)
    );
  };

  return (
    <AnimatePresence initial={false}>
      {contextData.product.pricing_model === ProductPricingModel.UsageBased && (
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
        >
          <Divider mb="md" />

          <Grid columns={12} gutter="xs">
            <Grid.Col xs={12} xl={5}>
              <TextInput
                required
                autoFocus
                withAsterisk={false}
                data-testid="usage-based-pricing-display-name"
                placeholder="Distance"
                label={
                  <FieldLabel
                    mediaSrc={displayNameSrc}
                    title="Display name"
                    description={
                      'Enter a descriptive name for your product\'s usage method. This name will be displayed on the product page and will appear on the invoices as the title of the usage method. Choose a name that clearly represents the type of usage the customer will be charged for, such as "Distance Traveled" for vehicles or "Operating Hours" for TV\'s.'
                    }
                  />
                }
                error={
                  contextData.errors[StepId.PricingModel]?.usageBasedDisplayName
                }
                value={contextData.product.usage_based_display_name ?? ''}
                onChange={({ target }) =>
                  onChange({
                    fieldKey: 'usage_based_display_name',
                    errorKey: 'usageBasedDisplayName',
                    value: target.value,
                  })
                }
              />
            </Grid.Col>

            <Grid.Col xs={7} xl={4}>
              <TextInput
                required
                withAsterisk={false}
                data-testid="usage-based-pricing-telemetry"
                placeholder="distance_traveled"
                label={
                  <FieldLabel
                    title="Telemetry"
                    mediaSrc={telemetrySrc}
                    description="Enter the telemetry parameter that will provide the server with information about the current operating conditions of your product."
                  />
                }
                error={
                  contextData.errors[StepId.PricingModel]
                    ?.usageBasedTelemetryKey
                }
                value={contextData.product.usage_based_telemetry_key ?? ''}
                onChange={({ target }) =>
                  onChange({
                    fieldKey: 'usage_based_telemetry_key',
                    errorKey: 'usageBasedTelemetryKey',
                    value: target.value,
                  })
                }
              />
            </Grid.Col>

            <Grid.Col xs={5} xl={3}>
              <LimitedTextInput
                required
                placeholder="km"
                maxLength={10}
                data-testid="usage-based-pricing-unit"
                withAsterisk={false}
                label={
                  <FieldLabel
                    title="Unit name"
                    mediaSrc={unitNameSrc}
                    description={
                      'Define the appropriate unit of measurement. Enter the unit name that corresponds to the usage parameter. For example, "km" to measure distance traveled or "hrs" for to measure operating hours. Select a unit that aligns with the nature of your product, ensuring clarity and consistency.'
                    }
                  />
                }
                error={
                  contextData.errors[StepId.PricingModel]?.usageBasedUnitName
                }
                value={contextData.product.usage_based_unit_name ?? ''}
                onChange={({ target }) =>
                  onChange({
                    fieldKey: 'usage_based_unit_name',
                    errorKey: 'usageBasedUnitName',
                    value: target.value,
                  })
                }
              />
            </Grid.Col>
          </Grid>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

interface FieldLabelProps {
  mediaSrc: string;
  title: string;
  description: string;
}
function FieldLabel({ mediaSrc, title, description }: FieldLabelProps) {
  const { classes } = useStyles();

  return (
    <Group spacing="xs">
      <Text>{title}</Text>

      <HoverCard
        width={390}
        withinPortal
        position="top"
        withArrow
        arrowSize={16}
        radius="md"
        classNames={{ dropdown: classes.dropdown }}
      >
        <HoverCard.Target>
          <QuestionCircleIcon />
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <img width="100%" src={mediaSrc} alt="" loading="lazy" />

          <Stack p="xl" spacing="xs">
            <Text color="blue_gray.9" weight={600}>
              {title}
            </Text>
            <Text color="gray.6" size="sm">
              {description}
            </Text>
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>
    </Group>
  );
}

const useStyles = createStyles(() => ({
  dropdown: {
    padding: 0,
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
  },
}));
