import { ActionIcon, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { useCurrentUser } from '@portals/api/ui';
import { ProductImage } from '@portals/framework';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { SupportedCommandProductType } from '@portals/types';

interface SelectedProductsProps {
  product: SupportedCommandProductType;
  onDeleteProduct: (productId: string) => void;
}

export function SelectedProducts({
  product,
  onDeleteProduct,
}: SelectedProductsProps) {
  const user = useCurrentUser();

  return (
    <Paper withBorder radius="md" p="md">
      <Group position="apart">
        <Group spacing="sm">
          <ProductImage
            src={product.image_url}
            radius="md"
            height={40}
            width={45}
          />

          <Stack spacing={4}>
            <Text fw={500}>{product.name}</Text>

            <Text fz="xs" color="gray.7">
              by {user.data?.partner_display_name}
            </Text>
          </Stack>
        </Group>

        <ActionIcon
          onClick={() => onDeleteProduct(product.id)}
          color="gray.7"
          variant="transparent"
          size={40}
        >
          <Trash />
        </ActionIcon>
      </Group>
    </Paper>
  );
}
