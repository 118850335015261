import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { isEmpty, noop } from 'lodash/fp';
import React from 'react';

import {
  DeviceModelDetailsType,
  useCreateDeviceModel,
  useDeviceModels,
} from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { AuthMethodType } from '@portals/types';

export interface AddModelProps
  extends ModalProps<{
    title: string;
    onSubmit: (model: DeviceModelDetailsType) => void;
  }> {}

export function AddModel({ closeMe, data }: AddModelProps) {
  const deviceModels = useDeviceModels();
  const createDeviceModel = useCreateDeviceModel();

  const form = useForm({
    initialValues: {
      modelName: '',
    },
  });

  const text = isEmpty(deviceModels?.data)
    ? "Before moving on, let's start with naming your very first model"
    : "Before moving on, let's start with naming your model";

  const handleSubmit = async (values: typeof form.values) => {
    try {
      const newDeviceModel = await createDeviceModel.mutateAsync({
        name: values.modelName,
        auth_method: AuthMethodType.CloudId,
      });

      closeMe();

      data.onSubmit(newDeviceModel);
    } catch (error) {
      noop();
    }
  };

  return (
    <Modal opened onClose={closeMe} title={data.title}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalBody>
          <Stack>
            <Text>{text}</Text>

            <TextInput
              required
              label="Model name"
              placeholder="Model name"
              data-autofocus
              maxLength={60}
              {...form.getInputProps('modelName')}
            />
          </Stack>
        </ModalBody>

        <ModalFooter position="right">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button type="submit" loading={createDeviceModel.isLoading}>
            Create model
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
