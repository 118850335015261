import { createStyles, Group, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { set } from 'lodash/fp';
import React from 'react';

import { CURRENCIES_FLAGS } from '@portals/countries';
import { CurrencyInput, CurrencyInputProps } from '@portals/framework';
import { CurrencyCode, PaymentIntervalEnum } from '@portals/types';

import { useStoreListingWizard } from '../hooks';

interface UsageBasedPaymentSettingPricesProps {
  defaultCurrency: CurrencyCode;
}

export function UsageBasedPaymentSettingPrices({
  defaultCurrency,
}: UsageBasedPaymentSettingPricesProps) {
  const { classes } = useStyles();

  const { contextData, setContextData } = useStoreListingWizard();

  const usageBasedPrices =
    contextData.paymentSettings.usage_based?.prices ?? [];

  const basePrices = contextData.paymentSettings.monthly?.prices ?? [];

  const onChangePrice = (
    paymentInterval: PaymentIntervalEnum,
    newValue: CurrencyInputProps['value'],
    currency: CurrencyCode
  ) => {
    const prices = contextData.paymentSettings[paymentInterval]?.prices ?? [];

    const newPrices = prices.map((price) => {
      if (price.currency === currency) {
        return {
          ...price,
          amountInScu: newValue || null,
          error: price.error ? newValue === undefined : price.error,
        };
      }

      return price;
    });

    setContextData((prev) =>
      set(['paymentSettings', paymentInterval, 'prices'], newPrices, prev)
    );
  };

  return (
    <div>
      {usageBasedPrices.map((price) => {
        const basePrice = basePrices.find(
          (basePrice) => basePrice.currency === price.currency
        );

        return (
          <Group key={price.currency} className={classes.currencyRow}>
            <Group spacing="xs">
              <img
                width={30}
                height="auto"
                src={CURRENCIES_FLAGS[price.currency]}
                alt="country flag"
                className={classes.flag}
              />

              <Text size="sm">
                <span>{price.currency}</span>
                {defaultCurrency === price.currency && (
                  <Text component="span" color="blue_gray.3">
                    &nbsp;(default)
                  </Text>
                )}
              </Text>
            </Group>

            <Group spacing="xs" ml="auto">
              <AnimatePresence initial={false}>
                {contextData.isBasePriceEnabled && (
                  <motion.div
                    initial={{ x: '100%', opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: '100%', opacity: 0 }}
                  >
                    <Group spacing="xs">
                      <CurrencyInput
                        currencyCode={price.currency}
                        placeholder="Base"
                        readOnly={contextData.storeListing?.editable === false}
                        error={basePrice?.error}
                        value={basePrice?.amountInScu ?? undefined}
                        onChange={(value) =>
                          onChangePrice(
                            PaymentIntervalEnum.Monthly,
                            value,
                            price.currency
                          )
                        }
                      />
                      <Text color="gray.6">+</Text>
                    </Group>
                  </motion.div>
                )}
              </AnimatePresence>

              <Group spacing="xs" className={classes.usageInputWrapper}>
                <CurrencyInput
                  readOnly={contextData.storeListing?.editable === false}
                  currencyCode={price.currency}
                  error={price.error}
                  value={price.amountInScu ?? undefined}
                  onChange={(value) =>
                    onChangePrice(
                      PaymentIntervalEnum.UsageBased,
                      value,
                      price.currency
                    )
                  }
                />
                <Text color="gray.6">
                  / {contextData.selectedProduct?.usage_based_unit_name}
                </Text>
              </Group>
            </Group>
          </Group>
        );
      })}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  currencyRow: {
    paddingBlock: theme.spacing.xl,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
  flag: {
    borderRadius: theme.radius.xs,
  },
  usageInputWrapper: {
    zIndex: 1,
    backgroundColor: theme.white,
  },
}));
