import isValidDomain from 'is-valid-domain';
import React from 'react';
import * as Yup from 'yup';

import { WizardStep } from '@portals/framework';

import CustomUrlStep from './CustomUrlStep/CustomUrlStep';
import PortalDesignStep from './PortalDesignStep/PortalDesignStep';
import { OnboardingWizardContextDataType, StepId } from './types';

export const onXyteDomainValidation = Yup.string().min(
  2,
  'Domain should have at least 2 characters'
);

export const customDomainValidation = (domain: string) =>
  isValidDomain(domain, { subdomain: true });

export const STEPS: Record<
  StepId,
  WizardStep<OnboardingWizardContextDataType, StepId>
> = {
  [StepId.CustomUrl]: {
    id: StepId.CustomUrl,
    component: <CustomUrlStep />,
    isFinalStep: false,
    next: {
      transition: { id: StepId.PortalDesign },
      isDisabled: ({ contextData }) => {
        return !onXyteDomainValidation.isValidSync(contextData.on_xyte_domain);
      },
    },
  },
  [StepId.PortalDesign]: {
    id: StepId.PortalDesign,
    component: <PortalDesignStep />,
    isFinalStep: true,
    prev: {
      transition: { id: StepId.CustomUrl },
    },
    next: {},
  },
};
