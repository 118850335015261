import { get } from 'lodash/fp';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { useConfig } from '@portals/api/partners';
import { Integrations } from '@portals/framework';
import { PartnerConfigurationType, StateType } from '@portals/types';

import TABS from './tab_map';
import * as actions from '../../redux/actions/integrations';

type EditIntegrationsConnectedProps = {
  integrations: StateType['data']['integrations'];
};

type EditIntegrationsConnectedActions = {
  fetchIntegrations: () => void;
  testConnection: (type: string, params: Record<string, any>) => void;
  updateIntegration: (
    type: string,
    params: Record<string, any>,
    extraAction?: Array<any>,
    onError?: () => void
  ) => void;
  reloadIntegration: (type: string, silent?: boolean) => void;
  getIntegrationParam: (
    type: string,
    key: string,
    subKey: null | string,
    quiet: boolean
  ) => void;
  removeIntegration: (type: string) => void;
};

const EditIntegrations: FC<
  EditIntegrationsConnectedProps & EditIntegrationsConnectedActions
> = (props) => {
  const { fetchIntegrations } = props;
  const config = useConfig();

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  return (
    <Integrations<PartnerConfigurationType>
      {...props}
      config={config.data}
      tabs={TABS}
    />
  );
};

const mapStateToProps = (state: StateType) => ({
  integrations: get('data.integrations', state),
});

export default connect<
  EditIntegrationsConnectedProps,
  EditIntegrationsConnectedActions
>(
  mapStateToProps,
  actions
)(EditIntegrations);
