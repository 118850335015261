import {
  Tooltip,
  ActionIcon,
  Popover,
  PopoverProps,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';

import { ReactComponent as QuestionCircleIcon } from '@portals/icons/linear/question-circle.svg';

import { SuccessCenterItems } from './SuccessCenterItems';

export function SuccessCenter() {
  const [isSuccessCenterMenuOpen, setIsSuccessCenterMenuOpen] = useState(false);

  const theme = useMantineTheme();

  return (
    <Popover
      position="bottom-end"
      offset={20}
      radius="lg"
      shadow="md"
      width={400}
      onChange={(isOpen) => setIsSuccessCenterMenuOpen(isOpen)}
      opened={isSuccessCenterMenuOpen}
      styles={popoverStyles}
    >
      <Popover.Target>
        <Tooltip label="Success center">
          <ActionIcon
            size="lg"
            variant="transparent"
            onClick={() => setIsSuccessCenterMenuOpen((isOpened) => !isOpened)}
          >
            <QuestionCircleIcon
              width={24}
              height={24}
              color={
                isSuccessCenterMenuOpen
                  ? theme.colors.indigo_accent[2]
                  : theme.colors.gray[4]
              }
            />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown px="xl" h={400}>
        <SuccessCenterItems />
      </Popover.Dropdown>
    </Popover>
  );
}

const popoverStyles: PopoverProps['styles'] = (theme) => ({
  dropdown: {
    overflow: 'hidden',
    paddingBottom: theme.spacing.xl,
  },
});
