import { map } from 'lodash/fp';
import React from 'react';
import styled, { css } from 'styled-components';

import { useWizard } from '@portals/framework';

import { OnboardingWizardContextDataType, StepId } from './types';

function PaginationDots() {
  const { steps, currentStep } = useWizard<
    OnboardingWizardContextDataType,
    StepId
  >();
  return (
    <Container>
      {map(
        (step) => (
          <Dot key={step.id} $isActive={step === currentStep} />
        ),
        steps
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const Dot = styled.div<{ $isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${({ $isActive }) => css`
    background-color: ${$isActive ? '#1C1C1A' : '#dee5e5'};
  `};
`;

export default PaginationDots;
