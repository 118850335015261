import {
  Alert,
  AlertProps,
  Box,
  Button,
  createStyles,
  Divider,
  Modal,
  Text,
} from '@mantine/core';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useState } from 'react';

import { ModalFooter } from '@portals/core';
import { FilePreview, ModalProps } from '@portals/framework';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import { useUploadReceipt } from '../hooks/Receipt/receipt.hooks';

export interface ReviewReceiptModalProps
  extends ModalProps<{
    invoiceId: string;
    onSuccess: (fileUrl: string) => void;
    receiptUrl: string;
  }> {}

export function ReviewReceiptModal({ closeMe, data }: ReviewReceiptModalProps) {
  const { invoiceId, onSuccess, receiptUrl } = data;
  const { classes, theme } = useStyles();

  const uploadReceiptFile = useUploadReceipt(invoiceId);

  const [PDFDocumentProxy, setPDFDocumentProxy] = useState<PDFDocumentProxy>();
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const onChangeReceipt = () => {
    closeMe();
    uploadReceiptFile();
  };

  const onUploadReceipt = () => {
    closeMe();
    onSuccess(receiptUrl);
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      withCloseButton={false}
      classNames={{
        body: classes.modalBody,
        content: classes.modalContent,
      }}
    >
      <VerticalScrollBar>
        <Box className={classes.content}>
          <Alert
            icon={
              <InfoCircleIcon
                width={18}
                height={18}
                color={theme.colors.gray[9]}
              />
            }
            title={
              <Text weight={600} size="md" color="gray.9">
                Review receipt before upload
              </Text>
            }
            color="primary"
            radius="md"
            mb={30}
            styles={alertStyles}
          >
            Please take a moment to carefully review your receipt before
            uploading it to our platform. Once the receipt is uploaded, it
            cannot be altered or deleted as it will be immediately sent to the
            buyer. Make sure all details on your receipt are accurate and clear.
            By proceeding with the upload, you acknowledge your understanding of
            this one-time action.
          </Alert>

          <FilePreview
            fileUrl={receiptUrl}
            fileName="receipt"
            PDFDocumentProxy={PDFDocumentProxy}
            setPDFDocumentProxy={setPDFDocumentProxy}
            setIsDownloaded={setIsDownloaded}
            isDownloaded={isDownloaded}
            isFailedToLoad={isFailedToLoad}
            setIsFailedToLoad={setIsFailedToLoad}
          />
        </Box>
      </VerticalScrollBar>

      <Divider />

      <ModalFooter position="right" mt="xxl" p={0}>
        <Button variant="subtle" color="blue_gray.8" onClick={closeMe}>
          Cancel
        </Button>

        <Button variant="default" onClick={onChangeReceipt}>
          Change Receipt
        </Button>

        <Button onClick={onUploadReceipt}>Upload Receipt</Button>
      </ModalFooter>
    </Modal>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  message: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[8],
  },
});

const useStyles = createStyles((theme) => ({
  modalContent: {
    maxWidth: 1077,
    width: '100%',
  },

  modalBody: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '90vh',
    display: 'grid',
    gridTemplateRows: '1fr min-content',
    padding: theme.spacing.xxl,
  },

  note: {
    backgroundColor: theme.colors.primary[0],
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl,
  },

  fileWrapper: {
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
  },

  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    height: '100%',
  },
}));
