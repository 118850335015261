import { SimpleGrid, Stack, Text } from '@mantine/core';
import React from 'react';

import { DeviceModelTestDevice } from '@portals/api/partners';

import CodeSnippet from '../CodeSnippet';
import CopyText from '../CopyText';

const getCodeSnippet = (device: DeviceModelTestDevice) => {
  return `curl --request POST \\
     --url ${device?.hub_url}/v1/devices/${device?.id}/telemetry \\
     --header 'Accept: application/json' \\
     --header 'Content-Type: application/json' \\
     --header 'Authorization: Bearer ${device?.access_token}' \\
     --data '{"status":"online"}'`;
};

interface SendTelemetryProps {
  modelName: string;
  device?: DeviceModelTestDevice;
}

export function SendTelemetry({ modelName, device }: SendTelemetryProps) {
  const codeSnippet = getCodeSnippet(device);

  return (
    <Stack
      sx={() => ({
        maxWidth: '588px',
      })}
    >
      <Stack mb={36}>
        <Text
          size="md"
          sx={(theme) => ({
            color: theme.colors.gray[8],
          })}
        >
          Send telemetry from your first {modelName} device
        </Text>

        <Text
          size="sm"
          sx={(theme) => ({
            color: theme.colors.gray[6],
          })}
        >
          Once registered, devices can start sending telemetry messages to the
          cloud
        </Text>
      </Stack>

      <Text
        size="md"
        sx={(theme) => ({
          color: theme.colors.gray[8],
        })}
      >
        Please use the code sample below to send telemetry from your device
      </Text>

      <CodeSnippet code={codeSnippet} />

      <SimpleGrid cols={2}>
        <CopyText title="Device ID" textToCopy={device?.id} />
        <CopyText title="Hub URL" textToCopy={device?.hub_url} />
        <CopyText title="Access token" textToCopy={device?.access_token} />
      </SimpleGrid>
    </Stack>
  );
}
