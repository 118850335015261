import { Center, createStyles, Divider, Group, Header } from '@mantine/core';
import { size } from 'lodash/fp';
import React from 'react';

import {
  useCurrentUserAccessibleTenants,
  usePermissionAccess,
} from '@portals/framework';

import { HubsHeaderActions } from './HubsHeaderActions';
import { HubsNavbar } from './HubsNavbar';
import { TenantDetails } from './TenantDetails';
import { TenantMenu } from './TenantMenu';
import { UserMenu } from './UserMenu';

export function HubsHeader() {
  const { classes, theme } = useStyles();

  const shouldDisplayTenantMenu = useShouldDisplayTenantMenu();

  return (
    <Header
      zIndex={10}
      height={theme.other.navbarHeight}
      className={classes.header}
    >
      <div className={classes.tenantSection}>
        {shouldDisplayTenantMenu ? <TenantMenu /> : <TenantDetails />}
      </div>

      <HubsNavbar />

      <Group pr="md" noWrap>
        <HubsHeaderActions />

        <Center h={30}>
          <Divider orientation="vertical" color="gray.7" />
        </Center>

        <UserMenu />
      </Group>
    </Header>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: 'none',
    background: theme.colors.gray[9],
  },
  tenantSection: {
    width: theme.other.sidebarFullWidth,
    padding: theme.spacing.xs,

    [theme.fn.smallerThan('xl')]: {
      width: theme.other.sidebarCompactWidth,
    },
  },
}));

function useShouldDisplayTenantMenu() {
  const { isSuperAdmin } = usePermissionAccess();
  const accessibleTenants = useCurrentUserAccessibleTenants();

  const hasMultipleTenants = size(accessibleTenants) > 1;

  return isSuperAdmin || hasMultipleTenants;
}
