import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { ShipmentStatusType } from '@portals/types';

import {
  PRODUCT_SHIPMENTS_API_URL,
  productShipmentsQueryKeys,
} from './product-shipments.constants';
import {
  PartnerShipmentType,
  PartnerUnshippedRequestByOrgType,
  PartnerUnshippedRequestType,
} from './product-shipments.types';
import { useClearDataCache } from '../../hooks/common';
import { useApiQuery } from '../../hooks/query';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

export const useUnshippedRequests = () =>
  useApiQuery<Array<PartnerUnshippedRequestType>>(
    `${PRODUCT_SHIPMENTS_API_URL}/unshipped`,
    productShipmentsQueryKeys.unshipped.all
  );

export const useUnshippedRequestsByOrg = (orgId: string) =>
  useApiQuery<Array<PartnerUnshippedRequestByOrgType>>(
    `${PRODUCT_SHIPMENTS_API_URL}/unshipped/${orgId}`,
    productShipmentsQueryKeys.unshipped.byOrganizationId(orgId)
  );

export const useShipments = () =>
  useApiQuery<Array<PartnerShipmentType>>(
    PRODUCT_SHIPMENTS_API_URL,
    productShipmentsQueryKeys.all
  );

export const useCreateShipment = () => {
  const dispatch = useDispatch();
  const clearDataCache = useClearDataCache();
  const { url, options } = useRequestOptions({
    url: PRODUCT_SHIPMENTS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({
      tracking_number,
      products,
    }: {
      tracking_number: string;
      products: Array<{
        id: string;
        name: string;
        sn?: string;
      }>;
    }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          tracking_number,
          products,
        }),
      }),
    onSuccess: () => {
      clearDataCache([productShipmentsQueryKeys.all]);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateShipment',
      baseUrl: PRODUCT_SHIPMENTS_API_URL,
      method: 'POST',
    },
  });
};

export const useShipmentMutation = () => {
  const clearDataCache = useClearDataCache();
  const { url, options } = useRequestOptions({
    url: PRODUCT_SHIPMENTS_API_URL,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: (
      shipments: Array<{
        tracking_number: string;
        status: ShipmentStatusType;
      }>
    ) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          shipments,
        }),
      }),
    onSuccess: () => {
      clearDataCache([productShipmentsQueryKeys.all]);
    },
    meta: {
      mutationName: 'useShipmentMutation',
      baseUrl: PRODUCT_SHIPMENTS_API_URL,
      method: 'PATCH',
    },
  });
};
