import {
  createStyles,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import { set } from 'lodash/fp';
import React, { FC } from 'react';

import { usePublishProductsWizard } from './hooks';
import { ProductListSelect } from './ProductListSelect';
import { PublishProductsStepIds } from './types';
import { WizardFooter } from './WizardFooter';

interface PublishProductsWizardContentProps {
  isMultipleProducts: boolean;
  onClose: () => void;
}

export const WizardContent: FC<PublishProductsWizardContentProps> = ({
  isMultipleProducts,
  onClose,
}) => {
  const { classes } = useStyles({
    isTwoColumnsLayout: isMultipleProducts,
  });

  const { currentStep, contextData, setContextData } =
    usePublishProductsWizard();

  const getModalTitle = () => {
    if (currentStep.id === PublishProductsStepIds.PricesDetails) {
      return 'Review Prices';
    } else {
      return isMultipleProducts ? 'Review Products' : 'Review Product';
    }
  };

  return (
    <Modal
      opened
      padding={0}
      styles={modalStyles(isMultipleProducts)}
      onClose={onClose}
      title={getModalTitle()}
    >
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          {isMultipleProducts && (
            <ProductListSelect
              products={contextData.products}
              selectedProductId={contextData.activeProductId}
              onSelectProduct={(productId) =>
                setContextData((curr) =>
                  set(['activeProductId'], productId, curr)
                )
              }
            />
          )}
          {currentStep.component}
        </div>

        <WizardFooter
          isMultipleProducts={isMultipleProducts}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};

const modalStyles =
  (isTwoColumnsLayout: boolean): MantineModalProps['styles'] =>
  (theme) => ({
    content: {
      width: isTwoColumnsLayout ? 1000 : 700,
      minHeight: 700,
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      margin: 0,
      padding: `${theme.spacing.xl} 32px`,
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
    body: { flexGrow: 1, display: 'flex', flexDirection: 'column' },
  });

const useStyles = createStyles(
  (theme, { isTwoColumnsLayout }: { isTwoColumnsLayout: boolean }) => ({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      flexGrow: 1,
      display: 'grid',
      gridTemplateColumns: isTwoColumnsLayout ? '4fr 6fr' : '1fr',
    },
  })
);
