import { LoadingOverlay, Modal } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { FC } from 'react';

import { useAvailablePartnersToInvite } from '@portals/api/partners';
import { ModalProps, useWizard, WizardProvider } from '@portals/framework';

import { STEPS } from './constants';
import { useInvitePartnerWizardContext } from './hooks';
import { InvitePartnerWizardContextDataType, StepIdEnum } from './types';

export interface InvitePartnerModalProps
  extends ModalProps<{
    onChange: (type: string) => Promise<void>;
  }> {}

const InvitePartner: FC<ModalProps> = ({ closeMe }) => {
  const partners = useAvailablePartnersToInvite();
  const contextData = useInvitePartnerWizardContext(
    partners?.data || [],
    closeMe
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={32}
      styles={{ header: { paddingBottom: 0 } }}
    >
      {partners.isFetching ? <LoadingOverlay visible /> : null}

      <WizardProvider<InvitePartnerWizardContextDataType, StepIdEnum>
        steps={STEPS}
        initialStep={StepIdEnum.SelectPartner}
        contextData={contextData}
        setContextData={noop}
      >
        <WizardStep />
      </WizardProvider>
    </Modal>
  );
};

const WizardStep = () => {
  const { currentStep } = useWizard();

  return <>{currentStep.component}</>;
};

export default InvitePartner;
