import { Button, Modal, Text } from '@mantine/core';
import React from 'react';

import { useRequestCecEnrollment } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

import imageSrc from './request-cec-enrollment-bg.svg';

interface RequestCecEnrollmentModalProps extends ModalProps {}

export function RequestCecEnrollmentModal({
  closeMe,
}: RequestCecEnrollmentModalProps) {
  const requestCecEnrollment = useRequestCecEnrollment();

  return (
    <Modal
      opened
      onClose={closeMe}
      withCloseButton={false}
      padding={0}
      size={714}
    >
      <img width="100%" height="auto" src={imageSrc} alt="connect" />

      <ModalBody p="xxl" ta="center">
        <Text fz={24} mb="xl">
          Access exclusive branding and expand your reach
        </Text>

        <Text size="md">
          Opting into Xyte Connect unlocks premium branding placements, extends
          your customer reach, and offers a seamless, single-pane-of-glass
          experience for your customers. Interested? Click "I'm Interested", and
          we’ll get in touch with more details.
        </Text>
      </ModalBody>

      <ModalFooter grow p="xl">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          onClick={() =>
            requestCecEnrollment.mutate(undefined, { onSuccess: closeMe })
          }
        >
          I'm interested
        </Button>
      </ModalFooter>
    </Modal>
  );
}
