import { Button, createStyles, Group, Loader, Text } from '@mantine/core';
import React from 'react';

import { useDeviceModels } from '@portals/api/partners';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { useOpenModal } from '@portals/redux';

const useStyles = createStyles((theme) => ({
  tickCircleIcon: {
    width: '16px',
    color: theme.colors.teal_accent[7],
  },
}));

interface IntegrationStatusProps {
  activeStep: number;
  closeMe: () => void;
  isDeviceRegistered: boolean;
  isTelemetrySent: boolean;
  moveToNextStep: () => void;
}

export function IntegrationStatus({
  closeMe,
  activeStep,
  isDeviceRegistered,
  isTelemetrySent,
  moveToNextStep,
}: IntegrationStatusProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const deviceModels = useDeviceModels();

  const isWaitingToIntegration =
    !isDeviceRegistered || (activeStep === 1 && !isTelemetrySent);

  const onFinishButtonClick = () => {
    closeMe();

    openModal('DeviceConnected');

    deviceModels.refetch();
  };

  return (
    <Group position="right" noWrap={true} spacing="md">
      {isWaitingToIntegration ? (
        <Group spacing="xs" noWrap>
          <Loader size={14} />
          <Text size="sm" weight={600} color="blue_gray">
            {activeStep === 0
              ? 'Waiting for you to register your first device'
              : 'We’re waiting for you to send telemetry'}
          </Text>
        </Group>
      ) : (
        <Group spacing="sm">
          <TickCircle className={classes.tickCircleIcon} />
          <Text size="sm" weight={600} color="teal_accent">
            Integrated successfully!
          </Text>
        </Group>
      )}
      {activeStep === 0 ? (
        <Button disabled={!isDeviceRegistered} onClick={moveToNextStep}>
          Continue
        </Button>
      ) : (
        <Button disabled={!isTelemetrySent} onClick={onFinishButtonClick}>
          Finish
        </Button>
      )}
    </Group>
  );
}
