import React, { useMemo } from 'react';

import { ProductPricingModel } from '@portals/types';

import { PersonalizedPaymentSettings } from './PersonalizedPaymentSettings';
import { StandardPaymentSettings } from './StandardPaymentSettings';
import { UsageBasedPaymentSettings } from './UsageBasedPaymentSettings';
import { DetailsColumn } from '../DetailsColumn';
import { useStoreListingWizard } from '../hooks';

export function PaymentSettingsStep() {
  const { contextData } = useStoreListingWizard();
  const { selectedProduct } = contextData;

  const content = useMemo(() => {
    if (selectedProduct?.pricing_model === ProductPricingModel.Standard) {
      return <StandardPaymentSettings />;
    } else if (
      selectedProduct?.pricing_model === ProductPricingModel.Personalized
    ) {
      return <PersonalizedPaymentSettings />;
    } else if (
      selectedProduct?.pricing_model === ProductPricingModel.UsageBased
    ) {
      return <UsageBasedPaymentSettings />;
    }
  }, [selectedProduct?.pricing_model]);

  return <DetailsColumn title="Payment Settings">{content}</DetailsColumn>;
}
