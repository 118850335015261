import {
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  Radio,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC } from 'react';

import { ModalProps } from '@portals/framework';

const ENTITY_TYPES = [
  'Corporation',
  'S-Corporation',
  'Sole Proprietor',
  'LLC',
  'LLP',
  'LLLP',
  'PC',
  'PLLC',
  'Joint Venture Corporation',
  'Foreign',
  'Government Agency',
  'US Federal Government Agency',
  'State',
  'Government Agency',
  'Federally Chartered',
  'Nonprofit',
];

export const CreditProviderApplication: FC<ModalProps> = ({ closeMe }) => {
  const form = useForm({
    initialValues: {
      entity: {},
      contact_information: {},
      credit_request_detail: {},
      financial_and_credit_information: {},
      insurance_information: {},
    },
  });

  const onSubmit = async () => {
    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Credit Provider Application"
      size={900}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <Stack>
            <Title order={5}>Entity Information</Title>

            <Group grow>
              <TextInput
                label="Entity Name"
                {...form.getInputProps('entity.entity_name')}
              />

              <Select
                searchable
                label="Entity Type"
                {...form.getInputProps('entity.entity_type')}
                data={ENTITY_TYPES}
                styles={(theme) => ({
                  item: {
                    height: 'fit-content',
                    padding: `${theme.spacing.md} ${theme.spacing.xs}`,
                  },
                })}
              />
            </Group>

            <TextInput
              label="Corporate Address"
              placeholder="Street, City, Zip"
              {...form.getInputProps('entity.corporate_address')}
            />

            <Group grow>
              <Radio.Group
                label="Ownership Type"
                {...form.getInputProps('entity.ownership_type')}
              >
                <Group>
                  <Radio value="public" label="Public" />
                  <Radio value="private" label="Private" />
                </Group>
              </Radio.Group>

              <Radio.Group
                label="Tax Exempt"
                {...form.getInputProps('entity.tax_exempt')}
              >
                <Group>
                  <Radio value="yes" label="Yes" />
                  <Radio value="no" label="No" />
                </Group>
              </Radio.Group>

              <TextInput
                label="State of Incorporation"
                {...form.getInputProps('entity.state_of_incorporation')}
              />
            </Group>

            <Group grow>
              <TextInput
                label="Phone"
                {...form.getInputProps('entity.phone')}
              />
              <TextInput
                label="Website"
                {...form.getInputProps('entity.website')}
              />
              <TextInput
                label="Tax Identification Number (TIN)"
                {...form.getInputProps('entity.tin')}
              />
            </Group>

            <Group grow>
              <TextInput
                label="Employer Identification Number (EIN)"
                {...form.getInputProps('entity.ein')}
              />
              <NumberInput
                label="Year Founded"
                hideControls
                {...form.getInputProps('entity.year_founded')}
              />
              <NumberInput
                label="Number of Employees"
                {...form.getInputProps('entity.num_of_employees')}
              />
            </Group>
          </Stack>

          <Stack>
            <Title order={5}>
              Contact Information (Needed for Agreement Documentation)
            </Title>

            <Stack>
              <Text size="sm" color="gray" weight={600}>
                Primary Contact
              </Text>
              <Group grow>
                <TextInput
                  label="Contact Name/Title"
                  {...form.getInputProps(
                    'contact_information.primary_contact_name'
                  )}
                />
                <TextInput
                  label="Phone"
                  {...form.getInputProps('entity.primary_contact_phone')}
                />
                <TextInput
                  label="Email"
                  {...form.getInputProps('entity.primary_contact_email')}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Mailing Address"
                  {...form.getInputProps(
                    'entity.primary_contact_mailing_address'
                  )}
                />
              </Group>
            </Stack>

            <Stack>
              <Text size="sm" color="gray" weight={600}>
                Authorized Signor
              </Text>
              <Group grow>
                <TextInput
                  label="Contact Name/Title"
                  {...form.getInputProps(
                    'contact_information.authorized_signor_name'
                  )}
                />
                <TextInput
                  label="Phone"
                  {...form.getInputProps('entity.authorized_signor_phone')}
                />
                <TextInput
                  label="Email"
                  {...form.getInputProps('entity.authorized_signor_email')}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Mailing Address"
                  {...form.getInputProps(
                    'entity.authorized_signor_mailing_address'
                  )}
                />
              </Group>
            </Stack>

            <Stack>
              <Text size="sm" color="gray" weight={600}>
                Witness to Signor
              </Text>
              <Group grow>
                <TextInput
                  label="Contact Name/Title"
                  {...form.getInputProps(
                    'contact_information.witness_to_signor_name'
                  )}
                />
                <TextInput
                  label="Phone"
                  {...form.getInputProps('entity.witness_to_signor_phone')}
                />
                <TextInput
                  label="Email"
                  {...form.getInputProps('entity.witness_to_signor_email')}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Mailing Address"
                  {...form.getInputProps(
                    'entity.witness_to_signor_mailing_address'
                  )}
                />
              </Group>
            </Stack>

            <Stack>
              <Text size="sm" color="gray" weight={600}>
                Billing Contact
              </Text>
              <Group grow>
                <TextInput
                  label="Contact Name/Title"
                  {...form.getInputProps(
                    'contact_information.billing_contact_name'
                  )}
                />
                <TextInput
                  label="Phone"
                  {...form.getInputProps('entity.billing_contact_phone')}
                />
                <TextInput
                  label="Email"
                  {...form.getInputProps('entity.billing_contact_email')}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Mailing Address"
                  {...form.getInputProps(
                    'entity.billing_contact_mailing_address'
                  )}
                />
              </Group>
            </Stack>
          </Stack>

          <Stack>
            <Title order={5}>Credit Request Details</Title>

            <Group grow>
              <NumberInput
                label="Immediate Order Amount ($)"
                hideControls
                {...form.getInputProps(
                  'credit_request_detail.immediate_order_amount'
                )}
              />

              <NumberInput
                label="Estimated Monthly Order Amount ($)"
                hideControls
                {...form.getInputProps(
                  'credit_request_detail.estimated_monthly_order_amount'
                )}
              />
            </Group>

            <Stack>
              <Group grow>
                <TextInput
                  label="Equip Location 1 (Street)"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_1_street'
                  )}
                />
                <TextInput
                  label="City"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_1_city'
                  )}
                />
                <TextInput
                  label="State"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_1_state'
                  )}
                />
                <NumberInput
                  label="9 Digit ZIP"
                  hideControls
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_1_zip'
                  )}
                />
                <TextInput
                  label="County"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_1_county'
                  )}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Equip Location 2 (Street)"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_2_street'
                  )}
                />
                <TextInput
                  label="City"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_2_city'
                  )}
                />
                <TextInput
                  label="State"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_2_state'
                  )}
                />
                <NumberInput
                  label="9 Digit ZIP"
                  hideControls
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_2_zip'
                  )}
                />
                <TextInput
                  label="County"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_2_county'
                  )}
                />
              </Group>

              <Group grow>
                <TextInput
                  label="Equip Location 3 (Street)"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_3_street'
                  )}
                />
                <TextInput
                  label="City"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_3_city'
                  )}
                />
                <TextInput
                  label="State"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_3_state'
                  )}
                />
                <NumberInput
                  label="9 Digit ZIP"
                  hideControls
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_3_zip'
                  )}
                />
                <TextInput
                  label="County"
                  {...form.getInputProps(
                    'credit_request_detail.equip_location_3_county'
                  )}
                />
              </Group>
            </Stack>
          </Stack>

          <Stack>
            <Title order={5}>Financial & Credit Information</Title>

            <Checkbox.Group
              label="Financial Documents Provided"
              {...form.getInputProps(
                'financial_and_credit_information.financial_documents_provided'
              )}
            >
              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="2019 Full Year Financials"
                label="2019 Full Year Financials"
              />
              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="2020 Full Year Financials"
                label="2020 Full Year Financials"
              />
              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="2021 Interim Financials"
                label="2021 Interim Financials"
              />
              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="2019 Tax Return"
                label="2019 Tax Return"
              />
              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="2020 Tax Return"
                label="2020 Tax Return"
              />

              <Checkbox
                sx={{
                  '.mantine-CheckboxGroup-label': {
                    marginBottom: 0,
                  },
                }}
                value="other"
                label={
                  <Group>
                    <Text size="sm">Other: </Text>
                    <TextInput
                      size="xs"
                      {...form.getInputProps(
                        'financial_and_credit_information.financial_documents_provided_other_input'
                      )}
                    />
                  </Group>
                }
              />
            </Checkbox.Group>

            <Stack>
              <Text size="lg" color="gray">
                Primary Lending Institution
              </Text>

              <Radio.Group
                label="Type of Account"
                {...form.getInputProps(
                  'financial_and_credit_information.primary_lending_institution'
                )}
              >
                <Group>
                  <Radio value="Depository" label="Depository" />
                  <Radio value="Credit Card" label="Credit Card" />
                  <Radio value="Credit Line" label="Credit Line" />

                  <Radio
                    value="other"
                    label={
                      <Group>
                        <Text size="sm">Other (Please Describe): </Text>
                        <TextInput
                          size="xs"
                          {...form.getInputProps(
                            'financial_and_credit_information.primary_lending_institution_other_input'
                          )}
                        />
                      </Group>
                    }
                  />
                </Group>
              </Radio.Group>
            </Stack>

            <Stack>
              <Text size="lg" color="gray">
                Other Lending Institution
              </Text>

              <Radio.Group
                label="Type of Account"
                {...form.getInputProps(
                  'financial_and_credit_information.other_lending_institution'
                )}
              >
                <Group>
                  <Radio value="Depository" label="Depository" />
                  <Radio value="Credit Card" label="Credit Card" />
                  <Radio value="Credit Line" label="Credit Line" />

                  <Radio
                    value="other"
                    label={
                      <Group>
                        <Text size="sm">Other (Please Describe): </Text>
                        <TextInput
                          size="xs"
                          {...form.getInputProps(
                            'financial_and_credit_information.other_lending_institution_other_input'
                          )}
                        />
                      </Group>
                    }
                  />
                </Group>
              </Radio.Group>
            </Stack>
          </Stack>

          <Stack>
            <Title order={5}>
              Insurance Information (Needed for Agreement Documentation)
            </Title>

            <Group>
              <TextInput
                label="Name of Insurance Provider"
                {...form.getInputProps(
                  'insurance_information.name_of_insurance_provider'
                )}
              />
              <TextInput
                label="Policy Number"
                {...form.getInputProps('insurance_information.policy_number')}
              />
            </Group>

            <Group>
              <TextInput
                label="Provider Contact Name"
                {...form.getInputProps(
                  'insurance_information.provider_contact_name'
                )}
              />
              <TextInput
                label="Phone"
                {...form.getInputProps('insurance_information.phone')}
              />
              <TextInput
                label="Email"
                {...form.getInputProps('insurance_information.email')}
              />
            </Group>
          </Stack>

          <Stack>
            <Title order={5}>Additional Comments</Title>

            <Textarea
              title=""
              description="** Please provide detailed equipment quotations with your application. Thank you!"
              {...form.getInputProps('additional_comments')}
            />
          </Stack>
        </Stack>

        <Group position="right" pt="xl">
          <Button onClick={closeMe} variant="default">
            Cancel
          </Button>
          <Button onClick={closeMe} type="submit">
            Submit Application
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
