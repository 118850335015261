import React, { useState } from 'react';

import {
  SubscriptionDetailsType,
  useCancelSubscription,
} from '@portals/api/partners';
import {
  ModalProps,
  useSubscriptionCancellationForm,
} from '@portals/framework';
import { ProductPricingModel } from '@portals/types';

import { SubscriptionCancellationConfirmModal } from './SubscriptionCancellationConfirmModal';
import { SubscriptionCancellationFormModal } from './SubscriptionCancellationFormModal';
import { SubscriptionCancellationSuccessModal } from './SubscriptionCancellationSuccessModal';

export interface SubscriptionCancellationFlowModalProps
  extends ModalProps<{ subscription: SubscriptionDetailsType }> {}

export function SubscriptionCancellationFlowModal({
  closeMe,
  data: { subscription },
}: SubscriptionCancellationFlowModalProps) {
  const [openedModal, setOpenedModal] = useState<
    'reason' | 'confirm' | 'success' | undefined
  >('reason');

  const [isCancelImmediately, setIsCancelImmediately] = useState(
    subscription.product.pricing_model === ProductPricingModel.UsageBased
  );

  const { reason, setReason, freeText, setFreeText } =
    useSubscriptionCancellationForm();

  const cancelSubscription = useCancelSubscription();

  const onConfirmCancellation = () => {
    cancelSubscription.mutate(
      {
        subscriptionId: subscription.id,
        reason,
        freeText,
        cancelImmediately: isCancelImmediately,
      },
      { onSuccess: () => setOpenedModal('success') }
    );
  };

  return (
    <>
      <SubscriptionCancellationFormModal
        isOpen={openedModal === 'reason'}
        onConfirm={() => setOpenedModal('confirm')}
        onClose={closeMe}
        reason={reason}
        onChangeReason={setReason}
        freeText={freeText}
        onChangeFreeText={setFreeText}
      />

      <SubscriptionCancellationConfirmModal
        isOpen={openedModal === 'confirm'}
        subscription={subscription}
        onCancel={closeMe}
        isCancelImmediately={isCancelImmediately}
        setIsCancelImmediately={setIsCancelImmediately}
        onConfirm={onConfirmCancellation}
      />

      <SubscriptionCancellationSuccessModal
        isOpen={openedModal === 'success'}
        onClose={() => {
          setOpenedModal(undefined);
          closeMe();
        }}
        nextChargeAt={subscription.next_charge_at}
        isCancelledImmediately={isCancelImmediately}
      />
    </>
  );
}
