import { ProductTypeEnum } from '@portals/types';

import { ProductWizardContext } from '../types';

export function calcPhysicalIntegrationType(
  productType: ProductTypeEnum | undefined,
  deviceModelId: string | undefined
): ProductWizardContext['physicalIntegrationType'] {
  if (productType === undefined) return null;
  if (productType !== ProductTypeEnum.Physical) return null;
  if (deviceModelId) return 'device';

  return 'none';
}
