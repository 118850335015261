import { Box, LoadingOverlay, Modal } from '@mantine/core';
import { forEach, size } from 'lodash/fp';
import React, { FC, useMemo, useRef } from 'react';

import {
  PartnerStoreSettingsType,
  StoreListing,
  useAddCurrency,
  useNonePaginatedStoreListings,
  useStoreSettings,
} from '@portals/api/partners';
import { CURRENCIES } from '@portals/countries';
import { ModalProps } from '@portals/framework';
import { SmartTable } from '@portals/table';
import {
  CurrencyCode,
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { PricingTableProvider } from './context';
import { Footer } from './Footer';
import {
  AssignedSinglePriceFormatter,
  EditableSinglePriceFormatter,
  HeaderFormatter,
  ProductNameFormatter,
} from './formatters';
import { useExpandRowConfig } from './hooks';
import { useStyles } from './styles';

interface AddProductsPricingProps {
  selectedCurrencies: Array<CurrencyCode>;
  onClose: () => void;
}

const AddProductsPricing: FC<
  ModalProps<AddProductsPricingProps> & {
    storeSettings: PartnerStoreSettingsType;
    storeListings: Array<StoreListing>;
  }
> = ({ closeMe, data, storeSettings, storeListings }) => {
  const addCurrency = useAddCurrency();
  const initialStoreSettings = useRef(storeSettings);

  const columns = useMemo(() => {
    // First column is products names, and `isSticky`
    const columns: Array<TableColumn<StoreListing>> = [
      {
        text: 'Name',
        dataField: 'product.name',
        isSticky: true,
        minWidth: 400,
        maxWidth: 400,
        formatter: (cell, { product }) => (
          <ProductNameFormatter cell={cell} product={product} />
        ),
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
    ];

    // Columns of existing & assigned currencies. Not editable, only for view
    forEach((currency) => {
      columns.push({
        text: CURRENCIES[currency],
        minWidth: 350,
        maxWidth: 350,
        dataField: currency,
        isEditable: false,
        headerFormatter: () => <HeaderFormatter currency={currency} />,
        formatter: (cell) => (
          <AssignedSinglePriceFormatter currency={currency} cell={cell} />
        ),
      });
    }, initialStoreSettings.current.currencies);

    // Columns of currencies user wants to add, are editable
    forEach((currency) => {
      columns.push({
        text: CURRENCIES[currency],
        minWidth: 350,
        maxWidth: 350,
        dataField: currency,
        isEditable: true,
        headerFormatter: () => <HeaderFormatter currency={currency} />,
        formatter: (cell) => (
          <EditableSinglePriceFormatter cell={cell} currency={currency} />
        ),
      });
    }, data.selectedCurrencies);

    return columns;
  }, [data.selectedCurrencies]);

  const expandRow = useExpandRowConfig();
  // Setting all rows expanded by default
  const defaultExpanded = useMemo(() => {
    const expanded = {};

    storeListings.forEach((_, index) => {
      expanded[index] = true;
    });

    return expanded;
  }, [storeListings]);

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr max-content',
        gap: theme.spacing.md,
      })}
    >
      <LoadingOverlay visible={addCurrency.isLoading} />

      <SmartTable<StoreListing>
        name="storeSettings.addProductsPricing"
        keyField="id"
        data={storeListings}
        columns={columns}
        isUrlSyncEnabled={false}
        expandRow={expandRow}
        noColumnsSelection
        expanded={defaultExpanded}
        pageSize={size(storeListings)}
      />

      <Footer
        onClose={() => {
          data.onClose();
          closeMe();
        }}
        onAddCurrency={addCurrency.mutateAsync}
        selectedCurrencies={data.selectedCurrencies}
      />
    </Box>
  );
};

const AddProductsPricingWrapper: FC<ModalProps<AddProductsPricingProps>> = (
  props
) => {
  const { classes } = useStyles();
  const storeListings = useNonePaginatedStoreListings({
    filters: [
      { id: 'status', value: 'published', type: PaginatedFilterTypeEnum.Eq },
    ],
  });
  const storeSettings = useStoreSettings();

  return (
    <Modal
      title="Products Pricing"
      opened
      onClose={props.closeMe}
      size="95%"
      className={classes.tableOverwrites}
      classNames={{
        content: classes.modalContent,
        overlay: classes.overlay,
      }}
    >
      {storeListings.isFetching || storeSettings.isFetching ? (
        <LoadingOverlay visible />
      ) : (
        <PricingTableProvider
          selectedCurrencies={props.data.selectedCurrencies}
        >
          <AddProductsPricing
            {...props}
            storeSettings={storeSettings.data}
            storeListings={storeListings.data}
          />
        </PricingTableProvider>
      )}
    </Modal>
  );
};

export default AddProductsPricingWrapper;
