import { createStyles, Text } from '@mantine/core';
import React from 'react';

import { Product } from '@portals/api/partners';
import { ProductCard } from '@portals/framework';
import { getPricingModelDisplayName } from '@portals/utils';

interface ProductGridItemProps {
  product: Product;
  isSelected: boolean;
}

export function ProductGridItem({ product, isSelected }: ProductGridItemProps) {
  const { classes } = useStyles(isSelected);

  return (
    <ProductCard className={classes.container}>
      <ProductCard.Image
        imageUrl={product.image_url}
        category={product.category}
      />

      <ProductCard.Details name={product.name} subtitle={product.subtitle}>
        <Text weight={700} size="xl">
          {getPricingModelDisplayName(product.pricing_model)}
        </Text>
      </ProductCard.Details>
    </ProductCard>
  );
}

const useStyles = createStyles((theme, isSelected: boolean) => ({
  container: {
    borderColor: isSelected ? theme.fn.primaryColor() : theme.colors.gray[3],
    boxShadow: isSelected ? `0 0 0 1px ${theme.fn.primaryColor()}` : 'none',
    cursor: 'pointer',
  },
}));
