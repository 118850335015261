import { Badge, createStyles, Group, Loader, Stack, Text } from '@mantine/core';
import { size } from 'lodash';
import { first, keys } from 'lodash/fp';
import React from 'react';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import {
  CurrencyCode,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';
import { formatPrice, getPricingModelDisplayName } from '@portals/utils';

import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

interface StoreListingProductCardPriceProps {
  product: StoreListing['product'] | undefined;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  showBasePrice: boolean;
}

export function StoreListingProductCardPrice({
  product,
  paymentSettings,
  showBasePrice,
}: StoreListingProductCardPriceProps) {
  const storeSettings = useStoreSettings();

  if (!product) return null;

  if (storeSettings.isLoading) {
    return <Loader size="sm" />;
  }

  if (product.pricing_model === ProductPricingModel.Personalized) {
    return (
      <Text weight={600} size="xl">
        {getPricingModelDisplayName(product.pricing_model)}
      </Text>
    );
  }

  if (!storeSettings.data) return null;

  if (product.pricing_model === ProductPricingModel.UsageBased) {
    return (
      <UsageBasedPriceDetails
        currencyCode={storeSettings.data.default_currency}
        paymentSettings={paymentSettings}
        usageBasedUnitName={product.usage_based_unit_name ?? undefined}
        showBasePrice={showBasePrice}
      />
    );
  }

  return (
    <StandardPriceDetails
      currencyCode={storeSettings.data.default_currency}
      paymentSettings={paymentSettings}
    />
  );
}

interface UsageBasedPriceDetailsProps {
  currencyCode: CurrencyCode;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  usageBasedUnitName: string | undefined;
  showBasePrice: boolean;
}

function UsageBasedPriceDetails({
  currencyCode,
  paymentSettings,
  usageBasedUnitName,
  showBasePrice,
}: UsageBasedPriceDetailsProps) {
  const usageBasedPrice = paymentSettings.usage_based?.prices?.find?.(
    (price) => price.currency === currencyCode
  )?.amountInScu;

  const basePrice = paymentSettings.monthly?.prices?.find?.(
    (price) => price.currency === currencyCode
  )?.amountInScu;

  const formattedUsageBasedPrice = formatPrice({
    value: usageBasedPrice,
    currencyCode,
  });

  const formattedBasePrice = formatPrice({ value: basePrice, currencyCode });

  return (
    <Stack spacing="xs">
      <Group spacing="0.5ch" fz="xl" fw={600}>
        {showBasePrice && basePrice ? (
          <Group spacing="0.5ch" noWrap>
            <Text>{formattedBasePrice}</Text>
            <Text color="gray.4">+</Text>
          </Group>
        ) : null}
        <Group spacing="0.5ch" noWrap>
          <Text>{formattedUsageBasedPrice}</Text>
          <Text color="gray.5">/</Text>
          <Text color="gray.5">{usageBasedUnitName}</Text>
        </Group>
      </Group>

      <Text weight={600} size="md" color="gray.5">
        Billed monthly
      </Text>
    </Stack>
  );
}

interface StandardPriceDetailsProps {
  currencyCode: CurrencyCode;
  paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
}
function StandardPriceDetails({
  currencyCode,
  paymentSettings,
}: StandardPriceDetailsProps) {
  const firstPricePeriod = first(keys(paymentSettings)) as
    | PaymentIntervalEnum
    | undefined;

  if (!firstPricePeriod) return null;

  const periodLabel = () => {
    switch (firstPricePeriod) {
      case PaymentIntervalEnum.OneTime:
        return null;
      case PaymentIntervalEnum.Monthly:
        return ' / month';
      case PaymentIntervalEnum.Yearly:
        return ' / year';
    }
  };

  const priceByCurrency = paymentSettings[firstPricePeriod]?.prices?.find?.(
    (price) => price.currency === currencyCode
  )?.amountInScu;

  const formattedPrice = formatPrice({ value: priceByCurrency, currencyCode });

  return (
    <Stack spacing="xs">
      <Group spacing="0.5ch" fz="xl" fw={600}>
        <Text>{formattedPrice}</Text>
        <Text color="gray.5">{periodLabel()}</Text>
      </Group>

      <ExtraPricesBadge numOfPricingPeriods={size(paymentSettings)} />
    </Stack>
  );
}

interface ExtraPricesBadgeProps {
  numOfPricingPeriods: number;
}
function ExtraPricesBadge({ numOfPricingPeriods }: ExtraPricesBadgeProps) {
  const { classes } = useExtraPricesBadgeStyles();
  if (numOfPricingPeriods <= 1) {
    return null;
  }

  const getBadgeText = () => {
    let text = `${numOfPricingPeriods - 1} more`;
    text += numOfPricingPeriods > 2 ? ' prices' : ' price';

    return text;
  };

  return (
    <Badge color="blue_gray" size="md" className={classes.badge}>
      {getBadgeText()}
    </Badge>
  );
}

const useExtraPricesBadgeStyles = createStyles((theme) => ({
  badge: {
    color: theme.colors.gray[9],
    fontWeight: 500,
    alignSelf: 'flex-end',
  },
}));
