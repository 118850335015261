import { Box, LoadingOverlay, Modal } from '@mantine/core';
import { forEach, size, sortBy } from 'lodash/fp';
import React, { FC, useMemo, useRef } from 'react';

import {
  PartnerStoreSettingsType,
  StoreListing,
  useNonePaginatedStoreListings,
  useStoreSettings,
} from '@portals/api/partners';
import { CURRENCIES } from '@portals/countries';
import { ModalProps } from '@portals/framework';
import { SmartTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { PricingTableProvider } from './context';
import {
  AssignedSinglePriceFormatter,
  HeaderFormatter,
  ProductNameFormatter,
} from './formatters';
import { useExpandRowConfig } from './hooks';
import { useStyles } from './styles';

interface ProductsPricingProps {
  onClose: () => void;
}

const ProductsPricing: FC<
  ModalProps<ProductsPricingProps> & {
    storeSettings: PartnerStoreSettingsType;
    storeListings: Array<StoreListing>;
  }
> = ({ storeSettings, storeListings }) => {
  const initialStoreSettings = useRef(storeSettings);

  const columns = useMemo(() => {
    const columns: Array<TableColumn<StoreListing>> = [
      {
        text: 'Name',
        dataField: 'product.name',
        isSticky: true,
        minWidth: 400,
        maxWidth: 400,
        formatter: (cell, { product }) => (
          <ProductNameFormatter cell={cell} product={product} />
        ),
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
    ];

    const sortedCurrencies = sortBy(
      (currency) => currency !== storeSettings.default_currency,
      initialStoreSettings.current.currencies
    );

    forEach((currency) => {
      columns.push({
        text: CURRENCIES[currency],
        minWidth: 350,
        maxWidth: 350,
        dataField: currency,
        isEditable: false,
        headerFormatter: () => <HeaderFormatter currency={currency} />,
        formatter: (cell) => (
          <AssignedSinglePriceFormatter currency={currency} cell={cell} />
        ),
      });
    }, sortedCurrencies);

    return columns;
  }, [storeSettings.default_currency]);

  const expandRow = useExpandRowConfig();
  const defaultExpanded = useMemo(() => {
    const expanded = {};

    storeListings.forEach((_, index) => {
      expanded[index] = true;
    });

    return expanded;
  }, [storeListings]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr max-content',
      }}
    >
      <SmartTable<StoreListing>
        name="storeSettings.addProductsPricing"
        keyField="id"
        data={storeListings}
        columns={columns}
        isUrlSyncEnabled={false}
        expandRow={expandRow}
        noColumnsSelection
        expanded={defaultExpanded}
        pageSize={size(storeListings)}
      />
    </Box>
  );
};

const ProductsPricingWrapper: FC<ModalProps<ProductsPricingProps>> = (
  props
) => {
  const { classes } = useStyles();
  const storeListings = useNonePaginatedStoreListings({
    filters: [
      { id: 'status', value: 'published', type: PaginatedFilterTypeEnum.Eq },
    ],
  });
  const storeSettings = useStoreSettings();

  return (
    <Modal
      title="Products Pricing"
      opened
      onClose={props.closeMe}
      size="95%"
      className={classes.tableOverwrites}
      classNames={{
        content: classes.modalContent,
      }}
    >
      {storeListings.isFetching || storeSettings.isFetching ? (
        <LoadingOverlay visible />
      ) : (
        <PricingTableProvider selectedCurrencies={[]}>
          <ProductsPricing
            {...props}
            storeSettings={storeSettings.data}
            storeListings={storeListings.data}
          />
        </PricingTableProvider>
      )}
    </Modal>
  );
};

export default ProductsPricingWrapper;
