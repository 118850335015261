import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { useCurrentUser } from '@portals/api/ui';
import { useHasSupportSeat } from '@portals/framework';
import { RouteModalLink } from '@portals/framework/route-modals';
import { AuditLogType } from '@portals/types';

interface CreateOrganizationProps {
  auditLog: AuditLogType;
}

export function CreateOrganization({ auditLog }: CreateOrganizationProps) {
  const currentUser = useCurrentUser();
  const controlled = currentUser?.data?.accessible_tenants?.organizations;
  const hasSupportSeat = useHasSupportSeat();

  const paramsMap = keyBy('name', auditLog.params);
  const customerParam = paramsMap['customer'];

  const canOpenCustomerRouteModal =
    hasSupportSeat &&
    controlled?.find((c) => c.id === customerParam?.id) !== undefined;

  return (
    <Text size="sm">
      Created {customerParam?.model}:{' '}
      {canOpenCustomerRouteModal ? (
        <RouteModalLink
          modalId="customer"
          pathParams={[customerParam?.id as string]}
        >
          {customerParam?.display_name}
        </RouteModalLink>
      ) : (
        <Text span>{customerParam?.display_name}</Text>
      )}
    </Text>
  );
}
