import { Box, createStyles, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface DetailsColumnProps {
  title: ReactNode;
  children: ReactNode;
}

export function DetailsColumn({ title, children }: DetailsColumnProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.detailsColumn}>
      <Text
        className={classes.detailsColumnTitle}
        data-testid="wizard-step-title"
      >
        {title}
      </Text>

      <div className={classes.componentWrapper}>{children}</div>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  detailsColumn: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  detailsColumnTitle: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    paddingTop: 36,
    paddingInline: theme.spacing.xxl,
    paddingBottom: 42,
    fontWeight: 400,
    fontSize: 24,
    backgroundColor: theme.white,
  },
  componentWrapper: {
    overflow: 'auto',
    height: '100%',
    paddingInline: theme.spacing.xxl,
    paddingBottom: theme.spacing.xxl,

    '& > *': {
      minHeight: '100%',
    },
  },
}));
