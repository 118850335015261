import {
  createStyles,
  LoadingOverlay,
  Select,
  SelectProps,
  Stack,
  Text,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';

import {
  DeviceModelDetailsType,
  DeviceModelSummaryType,
  useDeviceModels,
} from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { CreateDeviceModelModalProps } from '../../device-models/CreateDeviceModelModal';

interface CreatableDeviceModelSelectProps
  extends Omit<SelectProps, 'data' | 'onChange'> {
  onChange: (deviceModel: DeviceModelSummaryType) => void;
  onAfterCreate: (deviceModel: DeviceModelDetailsType) => void;
}

export function CreatableDeviceModelSelect({
  onChange,
  onAfterCreate,
  ...selectProps
}: CreatableDeviceModelSelectProps) {
  const { classes } = useStyles();

  const openModal = useOpenModal();

  const deviceModels = useDeviceModels();

  const deviceModelOptions = composeOptions(deviceModels);

  if (deviceModels.isLoading || !deviceModels.data) {
    return (
      <Stack pos="relative">
        <LoadingOverlay visible />
      </Stack>
    );
  }

  return (
    <Stack>
      <Select
        searchable
        placeholder="Select device model..."
        data-testid="select-device-model"
        data={deviceModelOptions}
        onChange={(deviceModelId) => {
          const deviceModel = deviceModels.data.find(
            (deviceModel) => deviceModel.id === deviceModelId
          ) as DeviceModelSummaryType;

          onChange(deviceModel);
        }}
        {...selectProps}
      />

      <Text>
        or{' '}
        <Text
          span
          color="primary"
          className={classes.createModelLink}
          data-testid="create-model-link"
          onClick={() =>
            openModal<CreateDeviceModelModalProps['data']>(
              'CreateDeviceModelModal',
              { onCreationSuccess: onAfterCreate }
            )
          }
        >
          create a new model
        </Text>
      </Text>
    </Stack>
  );
}

const useStyles = createStyles(() => ({
  createModelLink: {
    cursor: 'pointer',
  },
}));

function composeOptions(deviceModels: ReturnType<typeof useDeviceModels>) {
  return map(
    ({ id, model }) => ({ label: model, value: id }),
    deviceModels?.data
  );
}
