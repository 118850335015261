import React from 'react';

import { WizardSteps } from '@portals/framework';

import { ExistingPartnerType } from './steps/ExistingPartnerType';
import { InviteNewPartner } from './steps/InviteNewPartner';
import { NewPartnerType } from './steps/NewPartnerType';
import { SelectPartner } from './steps/SelectPartner';
import {
  InvitePartnerWizardContextDataType,
  RelationshipTypeEnum,
  StepIdEnum,
} from './types';

export const STEPS: WizardSteps<
  InvitePartnerWizardContextDataType,
  StepIdEnum
> = {
  [StepIdEnum.SelectPartner]: {
    id: StepIdEnum.SelectPartner,
    component: <SelectPartner />,
    isFinalStep: false,
    next: {
      isDisabled: ({ contextData }) =>
        !contextData.selectedPartner && !contextData.isInvitingNewPartner,
      transition: [
        {
          id: StepIdEnum.ExistingPartnerType,
          condition: ({ contextData }) => contextData?.selectedPartner !== null,
        },
        {
          id: StepIdEnum.InviteNewPartner,
          condition: ({ contextData }) => contextData?.isInvitingNewPartner,
        },
      ],
    },
  },
  [StepIdEnum.ExistingPartnerType]: {
    id: StepIdEnum.ExistingPartnerType,
    component: <ExistingPartnerType />,
    isFinalStep: true,
    next: {
      isDisabled: ({ contextData }) => {
        const { selectedRelationshipType, otherRelationshipType } = contextData;
        if (!selectedRelationshipType) return true;

        if (selectedRelationshipType === RelationshipTypeEnum.Other) {
          return !otherRelationshipType;
        }

        return false;
      },
    },
    prev: {
      transition: { id: StepIdEnum.SelectPartner },
    },
  },
  [StepIdEnum.InviteNewPartner]: {
    id: StepIdEnum.InviteNewPartner,
    component: <InviteNewPartner />,
    isFinalStep: false,
    next: {
      transition: { id: StepIdEnum.NewPartnerType },
    },
    prev: {
      transition: { id: StepIdEnum.SelectPartner },
    },
  },
  [StepIdEnum.NewPartnerType]: {
    id: StepIdEnum.NewPartnerType,
    component: <NewPartnerType />,
    isFinalStep: true,
    next: {
      isDisabled: ({ contextData }) => {
        const { selectedRelationshipType, otherRelationshipType } = contextData;
        if (!selectedRelationshipType) return true;

        if (selectedRelationshipType === RelationshipTypeEnum.Other) {
          return !otherRelationshipType;
        }

        return false;
      },
    },
    prev: {
      transition: { id: StepIdEnum.InviteNewPartner },
    },
  },
};
