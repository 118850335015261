import React, { ChangeEvent, FC, useState } from 'react';

import { DomainChangeType, useUpdateDomain } from '@portals/api/partners';
import { useWizard } from '@portals/framework';

import CustomUrl from '../../ChangeDomain';
import { DetailsForm } from '../DetailsForm';
import { OnboardingWizardContextDataType, StepId } from '../types';

interface CustomUrlFormProps {
  onFocusToggle: (isFocused: boolean) => void;
}

const CustomUrlForm: FC<CustomUrlFormProps> = ({ onFocusToggle }) => {
  const [error, setError] = useState(null);
  const { contextData, setContextData, onNext } = useWizard<
    OnboardingWizardContextDataType,
    StepId
  >();

  const { onUpdate, isLoading } = useUpdateDomain({
    onOnXyteDomainChanged: () => {
      setContextData((curr) => ({
        ...curr,
      }));

      onNext();
    },
    onOnXyteDomainChangeError: setError,
  });

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: 'custom_domain' | 'on_xyte_domain'
  ) => {
    if (error) {
      setError(null);
    }

    setContextData((currData) => ({
      ...currData,
      [key]: e.target.value,
    }));
  };

  return (
    <DetailsForm
      title="Custom URL"
      description={
        <div>
          <div>Enter your preferred URL to create a seamless</div>
          <div>white-labeled experience</div>
        </div>
      }
      onNextClick={() =>
        onUpdate({
          onXyteDomain: contextData.on_xyte_domain,
          domainChangeType: DomainChangeType.OnXyte,
        })
      }
      isLoading={isLoading}
    >
      <CustomUrl
        withCustomDomain={false}
        domainChangeType={DomainChangeType.OnXyte}
        onChange={onChange}
        onFocusToggle={onFocusToggle}
        onXyteDomain={contextData.on_xyte_domain}
      />
    </DetailsForm>
  );
};

export default CustomUrlForm;
