import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  filter,
  includes,
  isEmpty,
  keys,
  map,
  size,
  toLower,
  xor,
} from 'lodash/fp';
import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  useAddCurrency,
  useNonePaginatedStoreListings,
  useStoreSettings,
  useUpdateStoreSettings,
} from '@portals/api/partners';
import { SearchInput } from '@portals/core';
import { CURRENCIES, CURRENCIES_FLAGS } from '@portals/countries';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';
import { CurrencyCode } from '@portals/types';

import { ReactComponent as CurrencyIcon } from '../../assets/img/currency.svg';
import { CurrencyRow } from './CurrencyRow';

const ADJUSTED_CURRENCIES_LIST: Array<
  {
    key: CurrencyCode;
    name: string;
  } & Record<string, string>
> = map(
  (currencyKey: CurrencyCode) => ({
    key: currencyKey,
    lowerKey: toLower(currencyKey),
    name: CURRENCIES[currencyKey],
    lowerName: toLower(CURRENCIES[currencyKey]),
    flagSrc: CURRENCIES_FLAGS[currencyKey],
  }),
  keys(CURRENCIES)
);

const AddCurrency: FC<ModalProps> = ({ closeMe }) => {
  const storeSettings = useStoreSettings();
  const storeListings = useNonePaginatedStoreListings();
  const updateStoreSettings = useUpdateStoreSettings();
  const addCurrency = useAddCurrency();
  const openModal = useOpenModal();

  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredList = useMemo(() => {
    if (!storeSettings.data) return [];

    const currenciesWithoutSelected = filter(
      ({ key }) => !includes(key, storeSettings.data.currencies),
      ADJUSTED_CURRENCIES_LIST
    );

    if (!searchTerm) return currenciesWithoutSelected;

    const lowerSearchTerm = toLower(searchTerm);

    return filter((currencyItem) => {
      return (
        includes(lowerSearchTerm, currencyItem.lowerKey) ||
        includes(lowerSearchTerm, currencyItem.lowerName)
      );
    }, currenciesWithoutSelected);
  }, [searchTerm, storeSettings.data]);

  const onSubmit = useCallback(async () => {
    if (isEmpty(storeListings.data)) {
      for (let i = 0; i < selected.length; i++) {
        await addCurrency.mutateAsync({
          currency: selected[i],
          store_listings: [],
        });
      }

      closeMe();

      return;
    }

    openModal('AddProductsPricing', {
      selectedCurrencies: selected,
      onClose: closeMe,
    });
  }, [addCurrency, closeMe, openModal, selected, storeListings.data]);

  const submitTitle = useMemo(() => {
    if (size(selected) < 2) return 'Add currency';

    return `Add ${size(selected)} currencies`;
  }, [selected]);

  return (
    <Modal
      opened
      onClose={closeMe}
      padding={32}
      title={null}
      styles={{
        body: {
          marginTop: -40,
        },
        close: {
          zIndex: 1,
        },
      }}
    >
      <LoadingOverlay
        visible={updateStoreSettings.isLoading || storeListings.isLoading}
      />

      <Stack align="center">
        <CurrencyIcon />

        <Title order={4} sx={{ fontWeight: 400 }}>
          Add Currencies
        </Title>

        <Text size="sm" align="center">
          Add currencies to your store so customers can pay in their local
          currency
        </Text>

        <SearchInput
          data-autofocus={true}
          sx={{ width: '100%' }}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onClear={() => setSearchTerm('')}
          size="lg"
          placeholder="Search currency..."
        />

        <Stack sx={{ height: 300, width: '100%' }}>
          <VerticalScrollBar>
            {map(
              ({ flagSrc, key, name }) => (
                <CurrencyRow
                  key={key}
                  currencyName={name}
                  flagSrc={flagSrc}
                  isSelected={includes(key, selected)}
                  onSelect={() => setSelected((curr) => xor([key], curr))}
                />
              ),
              filteredList
            )}
          </VerticalScrollBar>
        </Stack>

        <Group pt="lg" spacing="md" grow sx={{ width: '100%' }}>
          <Button variant="default" sx={{ width: '100%' }} onClick={closeMe}>
            Cancel
          </Button>

          <Button
            sx={{ width: '100%' }}
            disabled={!size(selected) || storeListings.isLoading}
            onClick={onSubmit}
          >
            {submitTitle}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default AddCurrency;
