import { createStyles } from '@mantine/core';
import React from 'react';

import { useStoreListingWizard } from './hooks';
import { PreviewColumn } from './PreviewColumn';
import { StepId } from './types';
import { WizardFooter } from './wizard-footer/WizardFooter';
import { WizardNavigation } from './wizard-navigation/WizardNavigation';

export function WizardContent() {
  const { classes } = useStyles();
  const { currentStep } = useStoreListingWizard();

  const showPreviewColumn = currentStep.id !== StepId.ChooseProduct;

  return (
    <div className={classes.container}>
      <div className={classes.mainContent}>
        <WizardNavigation />

        {currentStep.component}

        {showPreviewColumn ? <PreviewColumn /> : null}
      </div>

      <WizardFooter />
    </div>
  );
}

const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  mainContent: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '280px 4fr',
    gridAutoFlow: 'column',
    gridAutoColumns: '3fr',
  },
}));
