import {
  CloseButton,
  createStyles,
  Flex,
  Group,
  ScrollArea,
  SimpleGrid,
  Space,
  Stack,
  Tabs,
  Text,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { SearchInput } from '@portals/core';
import { CommonWidgetFormType } from '@portals/device-widgets';

import { DeviceWidgetMetaType, WidgetsTabType } from './device-widgets.types';

interface WidgetsListProps {
  setSelectedWidget: Dispatch<
    SetStateAction<DeviceWidgetMetaType<CommonWidgetFormType> | null>
  >;
  widgets: Array<DeviceWidgetMetaType<CommonWidgetFormType>>;

  selectedTab: WidgetsTabType;
  setSelectedTab: Dispatch<SetStateAction<WidgetsTabType>>;

  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;

  onClose: () => void;
}

export function WidgetsList({
  setSelectedWidget,
  widgets,
  selectedTab,
  setSelectedTab,
  searchTerm,
  setSearchTerm,
  onClose,
}: WidgetsListProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing={32} p={30} pb={0}>
      <Group position="apart">
        <Text data-testid="connect-widget-title" size={24}>
          Add Widget
        </Text>

        <CloseButton size="xl" onClick={onClose} />
      </Group>

      <Tabs
        value={selectedTab}
        onTabChange={(tab: WidgetsTabType) => setSelectedTab(tab)}
        classNames={{
          root: classes.tabsRoot,
          tabsList: classes.tabsList,
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="all">All</Tabs.Tab>
          <Tabs.Tab value="monitoring">Monitoring</Tabs.Tab>
          <Tabs.Tab value="commands">Commands</Tabs.Tab>
          <Tabs.Tab value="UI">UI</Tabs.Tab>
        </Tabs.List>
      </Tabs>

      <Group grow>
        <SearchInput
          onClear={() => setSearchTerm('')}
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          size="md"
          width="100%"
          placeholder="Search widget..."
        />
      </Group>

      <ScrollArea.Autosize mah={530}>
        <Stack spacing="md">
          <SimpleGrid cols={4} spacing="md">
            {widgets.map((widget, index) => (
              <Stack
                className={classes.widgetContainer}
                key={widget.id}
                p="xl"
                justify="space-between"
                data-testid={'widget-' + widget.id}
                onClick={() => setSelectedWidget(widget)}
              >
                <Flex
                  align="center"
                  justify="center"
                  className={classes.widgetPreviewImage}
                >
                  <img
                    src={widget.widgetPreviewSrc}
                    alt={widget.name}
                    width="100%"
                  />
                </Flex>

                <Stack spacing="sm">
                  <Text weight={600} size="sm" color="gray.8">
                    {widget.name}
                  </Text>
                  <Text size="xs" color="gray.7">
                    {widget?.description || 'N/A'}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
          <Space h="lg" />
        </Stack>
      </ScrollArea.Autosize>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  widgetContainer: {
    height: 336,
    width: 230,
    background: theme.fn.rgba(theme.colors.blue_gray[0], 0.5),
    borderRadius: theme.radius.lg,
    boxShadow: '0px 11px 18px rgba(38, 50, 56, 0)',
    transition: 'box-shadow 0.15s ease-in-out',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 11px 18px rgba(38, 50, 56, 0.1)',
    },
  },
  widgetPreviewImage: {
    width: 186,
    height: 192,
  },
  tabsRoot: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  tabsList: {
    height: 44,
    flexWrap: 'nowrap',
    width: 'fit-content',
  },
}));
