import { Box, createStyles } from '@mantine/core';
import { includes, isNumber, map } from 'lodash/fp';
import React, { FC, useCallback, useMemo } from 'react';
import { Cell } from 'react-table';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import { EditableNumberCell } from '@portals/table';
import { CurrencyCode, ProductPricingModel, RowType } from '@portals/types';
import { convertFromMinorToMajor, formatCurrency } from '@portals/utils';

import { usePricingTableContext } from './context';

export const EXPAND_ROW_HEIGHT = 48;

interface ExpandRowRendererProps {
  row: RowType<StoreListing>;
}

export const ExpandRowRenderer: FC<ExpandRowRendererProps> = ({ row }) => {
  const rowProps = row.getRowProps();

  return (
    <Box {...rowProps} key={`${rowProps.key}-expand_row`}>
      {row.cells.map((cell, index) => (
        <ExpandRowCell
          key={cell.getCellProps().key}
          cell={cell}
          index={index}
        />
      ))}
    </Box>
  );
};

interface ExpandRowCellProps {
  index: number;
  cell: Cell<StoreListing>;
}

const LABELS = {
  currency: '',
  one_time_price_in_scu: 'Price',
  monthly_price_in_scu: 'Monthly',
  yearly_price_in_scu: 'Yearly',
};

const ExpandRowCell: FC<ExpandRowCellProps> = ({ cell, index }) => {
  const pricingTable = usePricingTableContext();
  const storeListingId = cell.row.original.id;
  const rowMeta = pricingTable.data[cell.row.original.id];
  const storeSettings = useStoreSettings();

  const { cx, classes } = useStyles();

  const cellProps = cell.getCellProps();

  const onChange = useCallback(
    ({ amount, currency, storeListingKey }) => {
      pricingTable.onUpdate({
        storeListingId,
        currency,
        storeListingKey,
        amount,
      });
    },
    [pricingTable, storeListingId]
  );

  const content = useMemo(() => {
    const columnId = cell.column.id;
    const isNameColumn = columnId === 'product.name';

    const storeListing = pricingTable.storeListings?.find?.(
      (sl) => sl.id === storeListingId
    );

    if (isNameColumn) {
      return map(({ key }) => {
        let label;

        if (
          storeListing?.product?.pricing_model ===
          ProductPricingModel.UsageBased
        ) {
          if (key === 'monthly_price_in_scu') {
            label = 'Base fee per month';
          } else if (key === 'usage_based_price_in_scu') {
            label = `Price per ${storeListing.product.usage_based_unit_name}`;
          }
        } else {
          label = LABELS[key];
        }

        return (
          <Box
            key={key}
            className={cx('currency-sub-cell', classes.subCell)}
            pl="xl"
          >
            {label}
          </Box>
        );
      }, rowMeta.pricesByCurrencies[rowMeta.defaultCurrency]);
    }

    const cellRows = [];

    const currency = columnId as CurrencyCode;
    const columnValues = rowMeta.pricesByCurrencies[currency];

    for (let i = 0; i < rowMeta.numOfAssignedPrices; i++) {
      const isEditable = !includes(currency, storeSettings.data.currencies);

      if (isEditable) {
        const amountInScu = columnValues[i].amount_in_scu;
        const amount = isNumber(amountInScu)
          ? convertFromMinorToMajor(amountInScu, currency)
          : null;

        cellRows.push(
          <Box
            key={i}
            className={cx('currency-sub-cell', classes.subCell)}
            p={0}
          >
            <EditableNumberCell
              value={amount || null}
              formattedValue={
                amountInScu ? formatCurrency(amountInScu, currency) : ''
              }
              precision={2}
              step={0.01}
              onChange={(amount) =>
                onChange({
                  amount,
                  currency,
                  storeListingKey: columnValues[i].key,
                })
              }
            />
          </Box>
        );
      } else {
        cellRows.push(
          <Box key={i} className={cx('currency-sub-cell', classes.subCell)}>
            {formatCurrency(columnValues[i].amount_in_scu, currency)}
          </Box>
        );
      }
    }

    return cellRows;
  }, [
    cell.column.id,
    pricingTable.storeListings,
    rowMeta.pricesByCurrencies,
    rowMeta.defaultCurrency,
    rowMeta.numOfAssignedPrices,
    storeListingId,
    cx,
    classes.subCell,
    storeSettings.data.currencies,
    onChange,
  ]);

  return (
    <Box
      {...cellProps}
      key={cellProps.key}
      className={cx(classes.cell, {
        sticky: index === 0,
      })}
      sx={{
        height: rowMeta.numOfAssignedPrices * EXPAND_ROW_HEIGHT,
      }}
    >
      {content}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  cell: {
    backgroundColor: 'white',

    '&.sticky': {
      position: 'sticky',
      left: 0,
      backgroundColor: theme.colors.gray[1],
      zIndex: 1,
      clipPath: 'inset(0px -15px 0px 0px)',
    },

    '&:not(:last-of-type)': {
      '.currency-sub-cell': {
        borderRight: `1px solid ${theme.colors.gray[3]}`,
      },
    },
  },

  subCell: {
    height: EXPAND_ROW_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing.md}`,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    color: theme.colors.gray[7],
    fontWeight: 400,
    width: '100%',
  },
}));
