import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { TableColumn, TableState, TicketType } from '@portals/types';

import {
  getTicketApiUrl,
  TICKETS_API_URL,
  ticketsQueryKeys,
} from './tickets.constants';
import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export const useTickets = (
  tableState: Pick<TableState<TicketType>, 'sortBy'>,
  columns: Array<TableColumn>,
  baseUrl: string = TICKETS_API_URL,
  queryKey: Array<string>
) =>
  usePaginatedTableApiQuery<TicketType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...ticketsQueryKeys.list, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: { staleTime: 0 },
  });

export function useTicket(ticketId: string) {
  return useApiQuery<TicketType>(
    getTicketApiUrl(ticketId),
    ticketsQueryKeys.byId(ticketId),
    {
      enabled: Boolean(ticketId),
      staleTime: 0,
    }
  );
}

export function useUnresolvedTicketsCount() {
  return useApiQuery<number>(
    `${TICKETS_API_URL}/unresolved_count`,
    ticketsQueryKeys.unresolvedCount,
    {
      staleTime: 0,
    }
  );
}

export function useSendMessage(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${getTicketApiUrl(ticketId)}/chat`,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (message: string): Promise<TicketType> => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ message }),
      });
    },
    onSuccess: (updatedTicket) => {
      queryClient.setQueryData<TicketType>(
        ticketsQueryKeys.byId(ticketId),
        updatedTicket
      );
    },
    onError: () => {
      dispatch(toastrError('Failed to send message'));
    },
    meta: {
      mutationName: 'useSendMessage',
      baseUrl: `${TICKETS_API_URL}/:id/chat`,
      method: 'POST',
    },
  });
}

export function useUpdateSeen(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: getTicketApiUrl(ticketId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (seen: boolean) => {
      return fetchApiRequest(`${url}/${seen ? 'seen' : 'unseen'}`, {
        ...options,
      });
    },
    onSuccess: async (updatedTicket: TicketType) => {
      queryClient.setQueryData<TicketType>(
        ticketsQueryKeys.byId(ticketId),
        updatedTicket
      );

      await queryClient.invalidateQueries(ticketsQueryKeys.unresolvedCount);

      dispatch(toastrSuccess('Update ticket successfully'));
    },
    onError: () => {
      dispatch(toastrError('Update ticket failed'));
    },
    meta: {
      mutationName: 'useUpdateSeen',
      baseUrl: `${TICKETS_API_URL}/:id/:[seen/unseen]`,
      method: 'PUT',
    },
  });
}

export function useUpdateTicket(ticketId: string) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: getTicketApiUrl(ticketId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (ticket: Partial<TicketType>) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(ticket),
      });
    },
    onSuccess: (updatedTicket: TicketType) => {
      queryClient.setQueryData<TicketType>(
        ticketsQueryKeys.byId(ticketId),
        updatedTicket
      );

      dispatch(toastrSuccess('Update ticket successfully'));
    },
    onError: () => {
      dispatch(toastrError('Update ticket failed'));
    },
    meta: {
      mutationName: 'useUpdateTicket',
      baseUrl: `${TICKETS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
