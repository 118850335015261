import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { map, reduce } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { useCreateOrderShipment, useOrderById } from '@portals/api/partners';
import { boxOpenSrc, keySrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps, useConfirmationModal } from '@portals/framework';

import { ShipmentFormType } from './create-shipment.types';
import { ProductsSerialNumbersList } from './ProductsSerialNumbersList';

export interface CreateShipmentModalProps
  extends ModalProps<{
    orderId: string;
  }> {}

export function CreateShipmentModal({
  closeMe,
  data,
}: CreateShipmentModalProps) {
  const confirmationModal = useConfirmationModal();
  const [currentStep, setCurrentStep] = useState<
    'trackingNumber' | 'serialNumbers'
  >('trackingNumber');
  const isTrackingNumberStep = currentStep === 'trackingNumber';
  const createOrderShipment = useCreateOrderShipment();

  const order = useOrderById(data.orderId);
  const { classes } = useStyles();

  const form = useForm<ShipmentFormType>({
    initialValues: {
      trackingNumber: '',
      items: [],
    },
  });

  const content = useMemo(() => {
    if (!order.isFetched) return <LoadingOverlay visible />;

    if (isTrackingNumberStep) {
      return (
        <TextInput
          {...form.getInputProps('trackingNumber')}
          required
          label="Tracking Number (required)"
          data-testid="order-shipment-tracking-number-input"
        />
      );
    } else {
      return <ProductsSerialNumbersList form={form} order={order.data} />;
    }
  }, [order.data, order.isFetched, isTrackingNumberStep, form]);

  const onClose = async () => {
    if (!form.values.trackingNumber) closeMe();
    else {
      const isConfirmed = await confirmationModal({
        title: 'Are you sure?',
        description: 'All your changes will be lost',
        confirmationLabel: 'Confirm',
        cancelLabel: 'Cancel',
      });

      if (isConfirmed) {
        closeMe();
      }
    }
  };

  const onSubmit = async (values: ShipmentFormType) => {
    try {
      const adjustedSerialNumbers = map(
        ({ orderItemId, serialNumber }) => ({
          id: orderItemId,
          serialNumber: serialNumber,
        }),
        values.items
      );

      const serialNumbersGroupedByProductId = reduce(
        (acc, { id, serialNumber }) => {
          if (!acc[id]) acc[id] = [];

          if (serialNumber) {
            acc[id].push(serialNumber);
          }

          return acc;
        },
        {} as Record<string, string[]>,
        adjustedSerialNumbers
      );

      await createOrderShipment.mutateAsync({
        orderId: data.orderId,
        trackingNumber: values.trackingNumber,
        serialNumbers: serialNumbersGroupedByProductId,
      });

      closeMe();
    } catch (err) {
      console.error(err);
    }
  };

  const onNext = () => {
    if (isTrackingNumberStep) {
      setCurrentStep('serialNumbers');
    } else {
      onSubmit(form.values);
    }
  };

  const onPrevious = () => {
    if (isTrackingNumberStep) {
      onClose();
    } else {
      setCurrentStep('trackingNumber');
    }
  };

  useEffect(
    function initializeOrderItemsFields() {
      if (!order.isFetched || !order.data || form.values.items.length > 0)
        return;

      form.setFieldValue(
        'items',
        map(
          ({ order_item_id }) => ({
            orderItemId: order_item_id,
            serialNumber: '',
          }),
          order.data?.purchased_products
        )
      );
    },
    [form, order.data, order.isFetched]
  );

  return (
    <ModalCenteredMediaLayout
      opened
      isLoading={createOrderShipment.isLoading}
      title={
        isTrackingNumberStep
          ? 'Enter tracking number'
          : 'Enter serial numbers (optional)'
      }
      onClose={onClose}
      footer={
        <Group mt="md" className={classes.footer} position="apart">
          <Button
            size="md"
            variant="default"
            onClick={onPrevious}
            data-testid="order-shipment-cancel-button"
          >
            {isTrackingNumberStep ? 'Cancel' : 'Back'}
          </Button>

          <Button
            size="md"
            type={currentStep === 'serialNumbers' ? 'submit' : 'button'}
            onClick={onNext}
            disabled={!form.values.trackingNumber}
            data-testid="order-shipment-submit-button"
          >
            {isTrackingNumberStep ? 'Next' : 'Create'}
          </Button>
        </Group>
      }
      mediaSize={120}
      media={
        <img
          src={isTrackingNumberStep ? boxOpenSrc : keySrc}
          alt="Enter tracking number"
        />
      }
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack pt="md">{content}</Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    // Overriding default modal padding
    width: `calc(100% + ${32 * 2}px)`,
    transform: 'translateX(-32px)',
    padding: theme.spacing.xl,
    paddingBottom: 0,
  },
}));
