export const TICKETS_API_URL = 'ui/partner/tickets';

export const ticketsQueryKeys = {
  list: [TICKETS_API_URL],
  byId: (ticketId: string) => [...ticketsQueryKeys.list, ticketId],
  unresolvedCount: [TICKETS_API_URL, 'unresolvedCount'],
};

export function getTicketApiUrl(ticketId: string) {
  return `${TICKETS_API_URL}/${ticketId}`;
}
