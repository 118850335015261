import { Identifier, XYCoord } from 'dnd-core';
import { motion } from 'framer-motion';
import React, { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import FieldPreview, { FieldPreviewBaseProps } from './FieldPreview';

interface FieldPreviewDndWrapper {
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
}

const FieldPreviewDndWrapper: FC<
  FieldPreviewBaseProps & FieldPreviewDndWrapper
> = ({ index, onMove, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    { index: number; id: string },
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'command-field',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: 'command-field',
    item: () => ({ index, id: props.field.name }),
  });

  drag(drop(ref));

  return (
    <motion.div
      layout="position"
      transition={{ duration: 0.1 }}
      style={{ cursor: 'grab' }}
    >
      <FieldPreview {...props} handlerId={handlerId} innerRef={ref} />
    </motion.div>
  );
};

export default FieldPreviewDndWrapper;
