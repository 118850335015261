import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import {
  useCheckStoreListingSlugAvailability,
  useStoreListing,
  useUpdateStoreListing,
} from '@portals/api/partners';
import { duplicateLinkSrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import {
  ModalProps,
  ProductImage,
  StoreListingStatusBadge,
} from '@portals/framework';
import { RoutePanelLink } from '@portals/framework/route-panels';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as Export2 } from '@portals/icons/linear/export-2.svg';
import { formatPrice } from '@portals/utils';

import { useValidateStoreListingSlug } from './store-listing-wizard/hooks';
import { StoreListingSlugInput } from '../components/store-listings/StoreListingSlugInput';

export interface DuplicateSlugModalProps
  extends ModalProps<{
    onClose?: () => void;
    storeListingId: string;
    selectedStoreListingSlug: string;
    selectedProductImageUrl: string;
    selectedProductName: string;
    onChangeSelectedStoreListingSlug: (slug: string | null) => void;
  }> {}

export function DuplicateSlugModal({ closeMe, data }: DuplicateSlugModalProps) {
  const { classes } = useStyles();
  const validateStoreListingSlug = useValidateStoreListingSlug();

  const {
    storeListingId,
    selectedStoreListingSlug,
    selectedProductImageUrl,
    onChangeSelectedStoreListingSlug,
    selectedProductName,
  } = data;

  const [isDetectedSlugEditable, setIsDetectedSlugEditable] = useState(false);

  const [newListingSlug, setNewListingSlug] = useState<string | null>(
    selectedStoreListingSlug
  );

  const [detectedSlugErrorMessage, setDetectedSlugErrorMessage] = useState('');
  const [newSlugErrorMessage, setNewSlugErrorMessage] = useState('');

  const storeListing = useStoreListing(storeListingId);
  const updateStoreListing = useUpdateStoreListing();
  const checkStoreListingSlugAvailability =
    useCheckStoreListingSlugAvailability();

  const [detectedListingSlug, setDetectedListingSlug] = useState(
    storeListing.data?.slug
  );

  useEffect(
    function initDetectedListingSlug() {
      if (storeListing.data?.slug) {
        setDetectedListingSlug(storeListing.data.slug);
      }
    },
    [storeListing.data?.slug]
  );

  const price = useMemo(() => {
    if (isEmpty(storeListing.data?.prices) || !storeListing.data) {
      return '';
    }
    const {
      currency,
      yearly_price_in_scu,
      monthly_price_in_scu,
      one_time_price_in_scu,
    } = storeListing.data.prices[0];

    //TODO: Replaced with updated function written by Lamsh

    if (monthly_price_in_scu) {
      return `${formatPrice({
        value: monthly_price_in_scu,
        currencyCode: currency,
      })} / monthly`;
    } else if (yearly_price_in_scu) {
      return `${formatPrice({
        value: yearly_price_in_scu,
        currencyCode: currency,
      })} / yearly`;
    } else if (one_time_price_in_scu) {
      return formatPrice({
        value: one_time_price_in_scu,
        currencyCode: currency,
      });
    }
  }, [storeListing.data]);

  const onSubmit = async () => {
    const finalDetectedSlug = isEmpty(detectedListingSlug?.trim())
      ? null
      : (detectedListingSlug?.trim() as string);

    const finalNewSlug = isEmpty(newListingSlug?.trim())
      ? null
      : (newListingSlug?.trim() as string);

    if (
      finalNewSlug === finalDetectedSlug &&
      finalNewSlug !== null &&
      finalDetectedSlug !== null
    ) {
      setDetectedSlugErrorMessage(
        'Permalinks must be unique. Please choose a different link for this product or update the link of your other product.'
      );

      return;
    }

    // detectedSlug
    if (finalDetectedSlug !== storeListing.data?.slug) {
      if (!validateStoreListingSlug(finalDetectedSlug)) {
        setDetectedSlugErrorMessage(
          'Permalink is not a valid URL. Spaces and special characters are not allowed.'
        );

        return;
      }

      try {
        if (finalDetectedSlug !== null) {
          const foundStoreListing =
            await checkStoreListingSlugAvailability.mutateAsync(
              finalDetectedSlug
            );

          if (!isEmpty(foundStoreListing)) {
            setDetectedSlugErrorMessage(
              'This permalink is already in use by another product'
            );
            return;
          }
        }

        await updateStoreListing.mutateAsync({
          storeListingId: storeListing.data?.id,
          isStoreListingEditable: storeListing.data?.editable,
          slug: finalDetectedSlug,
        });
      } catch (error) {
        console.error(error);
      }
    }

    //newSlug
    if (finalNewSlug !== selectedStoreListingSlug) {
      if (!validateStoreListingSlug(finalNewSlug)) {
        setNewSlugErrorMessage(
          'Permalink is not a valid URL. Spaces and special characters are not allowed.'
        );

        return;
      }

      try {
        if (finalNewSlug !== null) {
          const foundStoreListing =
            await checkStoreListingSlugAvailability.mutateAsync(finalNewSlug);

          if (!isEmpty(foundStoreListing)) {
            setNewSlugErrorMessage(
              'This permalink is already in use by another product'
            );

            return;
          }
        }

        onChangeSelectedStoreListingSlug(finalNewSlug);
      } catch (error) {
        console.error(error);
      }
    }

    closeMe();
  };

  if (storeListing.isLoading || !storeListing.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={duplicateLinkSrc} alt="Duplicate Link" />}
      title="Duplicate Permalink Detected"
      description={
        <Text size="xs" align="center" color="gray.5">
          The permalink you've chosen is already in use by another product you
          own. Please choose a different link for this product or update the
          link of your other product.
        </Text>
      }
    >
      <Stack>
        <Paper
          p="md"
          withBorder
          bg={!isDetectedSlugEditable ? 'gray.0' : 'white'}
          radius="md"
        >
          <Stack>
            <Group align="start" position="apart">
              <Group align="start">
                <ProductImage
                  src={storeListing.data?.product.image_url}
                  radius="md"
                  width={48}
                  className={classes.image}
                />

                <Stack spacing={2}>
                  <Group>
                    <Text size="md">{storeListing.data?.product.name}</Text>

                    <RoutePanelLink
                      panelId="store_listing"
                      pathParams={[storeListing.data?.slug ?? '']}
                    >
                      <Tooltip label="Preview product">
                        <ActionIcon color="blue_gray.8">
                          <Export2 height={16} />
                        </ActionIcon>
                      </Tooltip>
                    </RoutePanelLink>
                  </Group>

                  <Text size="sm" color="gray.6">
                    {price}
                  </Text>
                </Stack>
              </Group>

              <Group align="start">
                <StoreListingStatusBadge status={storeListing.data?.status} />

                {!isDetectedSlugEditable ? (
                  <Tooltip label="Edit permalink">
                    <ActionIcon
                      variant="default"
                      data-testid="edit-permalink-button"
                      onClick={() => {
                        setIsDetectedSlugEditable((prev) => !prev);
                        setNewSlugErrorMessage('');
                        setDetectedSlugErrorMessage('');
                      }}
                    >
                      <Edit width={16} height={16} />
                    </ActionIcon>
                  </Tooltip>
                ) : null}
              </Group>
            </Group>

            <StoreListingSlugInput
              slug={detectedListingSlug ?? ''}
              onSlugChange={setDetectedListingSlug}
              errorMessage={detectedSlugErrorMessage}
              setErrorMessage={setDetectedSlugErrorMessage}
              disabled={!isDetectedSlugEditable}
              storeListingId={storeListing.data?.id}
            />
          </Stack>
        </Paper>

        <Paper p="md" withBorder radius="md">
          <Stack>
            <Group>
              <ProductImage
                src={selectedProductImageUrl}
                radius="md"
                width={48}
                className={classes.image}
              />

              <Text size="md">{selectedProductName}</Text>
            </Group>

            <StoreListingSlugInput
              slug={newListingSlug}
              onSlugChange={setNewListingSlug}
              setErrorMessage={setNewSlugErrorMessage}
              errorMessage={newSlugErrorMessage}
            />
          </Stack>
        </Paper>

        <Group position="right">
          <Button
            data-testid="duplicate-modal-cancel-button"
            variant="default"
            onClick={closeMe}
          >
            Cancel
          </Button>

          <Button data-testid="update-links-button" onClick={onSubmit}>
            Update Links
          </Button>
        </Group>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  image: {
    '& img': {
      border: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
