import { MenuProps, TooltipProps } from '@mantine/core';

export const HUBS_HEADER_TOOLTIP_OFFSET = 20;

export const hubsHeaderMenuStyles: MenuProps['styles'] = (theme) => ({
  dropdown: {
    minWidth: 300,
    border: 'none',
    backgroundColor: theme.colors.gray[9],
  },
  item: {
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.md,
    color: theme.colors.gray[4],

    '&:not(:last-child)': {
      marginBottom: theme.spacing.xs,
    },

    '&[data-hovered]': {
      color: theme.colors.gray[4],
      textDecoration: 'none',
      backgroundColor: theme.fn.rgba(theme.colors.gray[8], 0.5),
    },
  },
  itemLabel: {
    fontWeight: 600,
  },
  itemIcon: {
    width: 24,
    height: 24,
  },
});

export const hubsHeaderTooltipStyles: TooltipProps['styles'] = (theme) => ({
  tooltip: {
    backgroundColor: theme.colors.gray[9],
    color: theme.colors.gray[4],
  },
});
