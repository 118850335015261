import { Box, createStyles, Modal, Text } from '@mantine/core';
import React, { useMemo } from 'react';

import { useDeviceModelFiles } from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps, useConfirmationModal } from '@portals/framework';
import { useActionDispatch, useOpenModal } from '@portals/redux';
import { SupportedCommandType, FieldType, FieldTypeEnum } from '@portals/types';

import FileSelector from './FileSelector';
import { sendCommand as sendCommandAction } from '../../redux/actions/ticket_device';

interface RunCommandProps
  extends ModalProps<{
    ticketId: string;
    deviceId: string;
    deviceType: string;
    command: SupportedCommandType;
  }> {}

export function RunCommand({
  data: { ticketId, deviceId, deviceType, command },
  closeMe,
}: RunCommandProps) {
  const { classes } = useStyles();
  const deviceModelFiles = useDeviceModelFiles(deviceType);
  const openModal = useOpenModal();
  const sendCommand = useActionDispatch(sendCommandAction);

  const asyncConfirmationCheck = useConfirmationModal();

  const {
    name,
    friendly_name,
    file_type,
    description,
    with_file,
    custom_fields,
  } = command;

  const upgrade = async (formData: Record<string, any> = {}) => {
    const extra_params = (custom_fields || []).reduce(
      (rc, field) => ({ ...rc, [field.name]: formData[field.name] }),
      {}
    );

    if (command.confirmation_message) {
      const shouldSendCommand = await asyncConfirmationCheck({
        title: (
          <Text data-testid="command-approval-modal-title">
            Confirm {command.friendly_name} command
          </Text>
        ),
        description: (
          <Box className={classes.commandDescription}>
            {command.confirmation_message}
          </Box>
        ),
        confirmationLabel: 'Proceed with Command',
        confirmButtonProps: {
          color: 'primary',
          'data-testid': 'command-approval-modal-confirm-button',
        },
        cancelButtonProps: {
          'data-testid': 'command-approval-modal-cancel-button',
        },
      });

      if (!shouldSendCommand) {
        return;
      }
    }

    sendCommand(
      ticketId,
      {
        command: name,
        device_id: deviceId,
        file_id: formData.file_id,
        extra_params,
      },
      closeMe
    );
  };

  const modelFiles = useMemo(() => {
    return (deviceModelFiles.data || [])
      .filter((file) => file.type === file_type)
      .sort((a, b) => (a.version > b.version ? -1 : 1));
  }, [deviceModelFiles.data, file_type]);

  const formFields = useMemo(() => {
    let base: FieldType[] = [];

    // Build the command params form
    if (with_file) {
      const fileSelectorProps = {
        files: modelFiles,
        file_type,
        openModal,
        deviceType,
      };

      base.push({
        name: 'file_id',
        title: 'File',
        required: true,
        type: FieldTypeEnum.Custom,
        component: FileSelector,
        inputProps: fileSelectorProps,
      });
    }

    if (custom_fields) {
      base = [...base, ...custom_fields];
    }

    return base;
  }, [custom_fields, deviceType, file_type, modelFiles, openModal, with_file]);

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Send Command"
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
          marginBottom: theme.spacing.md,
        },
        '.modal-footer': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      })}
    >
      <AutoFormik
        title={friendly_name}
        subTitle={description}
        fields={formFields}
        inProgress={false}
        handleSubmit={upgrade}
        requireChange={false}
        modal={closeMe}
        submitTitle="Send"
        formikProps={{
          validateOnMount: true,
        }}
      />
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  commandDescription: {
    whiteSpace: 'break-spaces',
  },
}));
