import { Text } from '@mantine/core';
import React from 'react';

import { RoundDollarWithCursorSVG } from '@portals/assets';
import { EmptyState } from '@portals/core';

export function StoreListingPersonalizedPricingEmptyState() {
  return (
    <EmptyState
      p={32}
      media={<RoundDollarWithCursorSVG />}
      messageTitle="Personalized pricing product"
      messageDescription={
        <Text maw={450}>
          This product is using personalized pricing in which customers will
          manually enter the price for a product or service.
        </Text>
      }
    />
  );
}
