import React from 'react';

import { ProductTypeEnum } from '@portals/types';

import { DigitalProductIntegration } from './digital-product/DigitalProductIntegration';
import { PhysicalProductIntegration } from './physical-product/PhysicalProductIntegration';
import { useProductWizard } from './use-product-wizard';

export const IntegrationStep = () => {
  const { contextData } = useProductWizard();

  switch (contextData.product?.product_type) {
    case ProductTypeEnum.Physical:
      return <PhysicalProductIntegration />;
    case ProductTypeEnum.DeviceLicense:
      return <DigitalProductIntegration />;
    default:
      return null;
  }
};
