import { Text } from '@mantine/core';
import React from 'react';

import {
  Product,
  PRODUCTS_API_BASE_URL,
  StoreListing,
  usePaginatedProducts,
} from '@portals/api/partners';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { AvatarCell, DateCell, PaginatedTable } from '@portals/table';
import { TableColumn, TableViewModeType } from '@portals/types';
import { getPricingModelDisplayName } from '@portals/utils';

import { ProductGridItem } from './ProductGridItem';
import emptyProductCatalogSrc from '../../../assets/img/product-catalog-empty-state.svg';
import { AddNewCatalogProductButton } from '../../../pages/products-catalog/AddNewCatalogProductButton';
import { useStoreListingWizard } from '../hooks';
import { StoreListingWizardContext } from '../types';

const COLUMNS: TableColumn<Product>[] = [
  {
    dataField: 'name',
    text: 'Product name',
    formatter: (_, row) => (
      <AvatarCell
        src={row.image_url}
        label={row.name}
        radius="md"
        withAbbreviation={false}
        color="blue_gray"
      >
        <Gallery />
      </AvatarCell>
    ),
  },
  {
    dataField: 'category',
    text: 'Category',
    formatter: (_, row) => <Text transform="uppercase">{row.category}</Text>,
  },
  {
    dataField: 'pricing_model',
    text: 'Pricing model',
    formatter: (_, row) => getPricingModelDisplayName(row.pricing_model),
  },
  {
    dataField: 'created_at',
    text: 'Date added',
    formatter: (_, row) => (
      <DateCell date={row.created_at} withTimeAgo={false} />
    ),
  },
];

interface ProductsTableProps {
  viewType: TableViewModeType;
  category: string;
}

export function ProductsTable({ viewType, category }: ProductsTableProps) {
  const { contextData, setContextData } = useStoreListingWizard();

  const dataHookUrl =
    category === 'all'
      ? `${PRODUCTS_API_BASE_URL}/?q[archived_eq]=false`
      : `${PRODUCTS_API_BASE_URL}/?q[archived_eq]=false&q[category_eq]=${category}`;

  const onSelectProduct = (product: Product) => {
    const selectedProduct: StoreListing['product'] = {
      id: product.id,
      name: product.name,
      category: product.category,
      subtitle: product.subtitle,
      description: product.description,
      device_model: product.device_model,
      image_url: product.image_url,
      partner_name: product.manufacturer.display_name,
      partner_logo_url: product.manufacturer.logo ?? null,
      product_type: product.product_type,
      created_at: product.created_at,
      pricing_model: product.pricing_model,
      sku: product.sku,
      usage_based_display_name: product.usage_based_display_name,
      usage_based_telemetry_key: product.usage_based_telemetry_key,
      usage_based_unit_name: product.usage_based_unit_name,
    };

    const productOverrides: StoreListingWizardContext['productOverrides'] = {
      productName: product.name,
      productCategory: product.category,
      productSubtitle: product.subtitle,
      productDescription: product.description,
      productImageUrl: product.image_url,
    };

    setContextData((prev) => ({
      ...prev,
      selectedProduct,
      productOverrides,
      slug: undefined,
      productNameError: '',
      productDescriptionError: '',
      slugError: '',
    }));
  };

  return (
    <PaginatedTable<Product>
      keyField="id"
      name={`products-${category}`}
      noHeader
      viewType={viewType}
      isUrlSyncEnabled={false}
      noColumnsSelection
      columns={COLUMNS}
      dataHook={usePaginatedProducts}
      dataHookUrl={dataHookUrl}
      onRowClick={(row) => onSelectProduct(row.original)}
      gridView={{
        getColumnsCount: ({ tableWidth }) => (tableWidth < 1000 ? 3 : 4),
        itemRenderer: ({ item }) => (
          <ProductGridItem
            product={item.original}
            isSelected={item.original.id === contextData.selectedProduct?.id}
          />
        ),
      }}
      noDataIndication={{
        title: `No products found`,
        assetSrc: emptyProductCatalogSrc,
        actions: <AddNewCatalogProductButton />,
      }}
    />
  );
}
