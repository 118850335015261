export const ORDERS_API_URL = 'ui/partner/orders';

export const ordersQueryKeys = {
  all: [ORDERS_API_URL],

  list: () => [...ordersQueryKeys.all, 'list'],

  details: () => [...ordersQueryKeys.all, 'details'],
  detail: (orderId: string) => [...ordersQueryKeys.details(), orderId],
};
