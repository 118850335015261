import { useConfig } from '@portals/api/partners';
import { PartnerPricingPlanFeatures } from '@portals/types';

export function usePricingPlan() {
  const config = useConfig();

  return config.data?.pricing_plan;
}

export function usePricingPlanFeatures() {
  const pricingPlan = usePricingPlan();

  return pricingPlan?.features;
}

export function usePricingPlanFeature(
  feature: keyof PartnerPricingPlanFeatures
) {
  const pricingPlanFeatures = usePricingPlanFeatures();

  return pricingPlanFeatures?.[feature];
}

export function useIsPricingPlanFeatureEnabled(
  feature: keyof PartnerPricingPlanFeatures
) {
  const featureValue = usePricingPlanFeature(feature);

  return Boolean(featureValue);
}
