import React from 'react';

import { useAddSupportedCommand } from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { SupportedCommandType } from '@portals/types';

import { SupportedCommandForm } from './SupportedCommandForm';

export function AddSupportedCommand({
  data: modelId,
  closeMe,
}: ModalProps<string>) {
  const addSupportedCommand = useAddSupportedCommand(modelId);

  const onAdd = async (supportedCommand: Partial<SupportedCommandType>) => {
    try {
      await addSupportedCommand.mutateAsync(supportedCommand);

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <SupportedCommandForm
      isEdit={false}
      errors={addSupportedCommand.error?.error}
      isLoading={addSupportedCommand.isLoading}
      handleUpdate={onAdd}
      closeMe={closeMe}
    />
  );
}
