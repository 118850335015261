import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PaginationResponse, UsePaginatedTableApiQuery } from '@portals/types';

import {
  PARTNERS_INVITATIONS_API_URL,
  partnersInvitationsQueryKeys,
} from './invitations.constants';
import {
  AvailableInvitedPartnerResponse,
  IncomingInvitationsResponse,
  PartnerInvitationRequest,
  PartnerInvitationsResponse,
} from './invitations.types';
import { useApiQuery } from '../../hooks';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import { globalQueryKeys } from '../global-query-keys';

export function usePartnerInvitations(
  tableState: UsePaginatedTableApiQuery<PartnerInvitationsResponse>['tableState'],
  columns: UsePaginatedTableApiQuery<PartnerInvitationsResponse>['columns'],
  baseUrl = PARTNERS_INVITATIONS_API_URL,
  queryKey = partnersInvitationsQueryKeys.all()
) {
  return usePaginatedTableApiQuery<PartnerInvitationsResponse>({
    queryKey: [...queryKey, tableState],
    baseUrl,
    columns,
    tableState,
  });
}

export function useInvitedAvailablePartners() {
  return useApiQuery<PaginationResponse<AvailableInvitedPartnerResponse>>(
    `${PARTNERS_INVITATIONS_API_URL}/?q[status_in][]=pending&q[status_in][]=rejected`,
    partnersInvitationsQueryKeys.all()
  );
}

export function useCreatePartnerInvitation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PARTNERS_INVITATIONS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (partnerInvitation: PartnerInvitationRequest) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(partnerInvitation),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Invitation sent successfully'));

      queryClient.invalidateQueries(partnersInvitationsQueryKeys.all());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreatePartnerInvitation',
      baseUrl: PARTNERS_INVITATIONS_API_URL,
      method: 'POST',
    },
  });
}

export function useIncomingInvitations() {
  return useApiQuery<IncomingInvitationsResponse>(
    `${PARTNERS_INVITATIONS_API_URL}/incoming`,
    partnersInvitationsQueryKeys.incoming()
  );
}

export function useAvailablePartnersToInvite() {
  return useApiQuery<AvailableInvitedPartnerResponse[]>(
    `${PARTNERS_INVITATIONS_API_URL}/available_partners`,
    partnersInvitationsQueryKeys.available()
  );
}

export function useAcceptPartnerInvitation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PARTNERS_INVITATIONS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: (invitationId: string) => {
      return fetchApiRequest(`${url}/${invitationId}/accept`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Invitation accepted'));

      queryClient.invalidateQueries(partnersInvitationsQueryKeys.incoming());
      queryClient.invalidateQueries(globalQueryKeys.partnersConnections);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAcceptPartnerInvitation',
      baseUrl: `${PARTNERS_INVITATIONS_API_URL}/:id/accept`,
      method: 'POST',
    },
  });
}

export function useRejectPartnerInvitation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PARTNERS_INVITATIONS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: async (invitationId: string) => {
      return fetchApiRequest(`${url}/${invitationId}/reject`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Invitation rejected'));

      queryClient.invalidateQueries(partnersInvitationsQueryKeys.incoming());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRejectPartnerInvitation',
      baseUrl: `${PARTNERS_INVITATIONS_API_URL}/:id/reject`,
      method: 'POST',
    },
  });
}

export function useResendPartnerInvitation() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PARTNERS_INVITATIONS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: async (invitationId: string) => {
      return fetchApiRequest(`${url}/${invitationId}/resend`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Invitation resent'));

      queryClient.invalidateQueries(partnersInvitationsQueryKeys.all());
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useResendPartnerInvitation',
      baseUrl: `${PARTNERS_INVITATIONS_API_URL}/:id/resend`,
      method: 'POST',
    },
  });
}

export function useResendCanceledPartnerConnection() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: PARTNERS_INVITATIONS_API_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: async (partner: {
      partner_id: string;
      relationship_type: string;
    }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(partner),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Invitation resent'));

      queryClient.invalidateQueries(globalQueryKeys.partnersInvitations);
      queryClient.invalidateQueries(globalQueryKeys.partnersConnections);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useResendCanceledPartnerConnection',
      baseUrl: PARTNERS_INVITATIONS_API_URL,
      method: 'POST',
    },
  });
}
