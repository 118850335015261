import { motion, MotionProps } from 'framer-motion';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useWizard } from '@portals/framework';

import BrowserMock from './BrowserMock';
import { OnboardingWizardContextDataType, StepId } from './types';
import bg from '../../assets/img/onboarding-preview-area-bg.svg';

interface PreviewAreaProps {
  motionProps?: MotionProps;
  contentRenderer?: () => ReactNode;
  children?: ReactNode;
}

function PreviewArea({
  children,
  motionProps,
  contentRenderer,
}: PreviewAreaProps) {
  const { contextData } = useWizard<OnboardingWizardContextDataType, StepId>();

  const domain = `${contextData.on_xyte_domain || 'your-domain'}.on-xyte.com`;

  return (
    <Container>
      <Content>
        {contentRenderer ? (
          contentRenderer()
        ) : (
          <motion.div {...motionProps}>
            <BrowserMock domain={domain} portalConfig={contextData}>
              {children}
            </BrowserMock>
          </motion.div>
        )}
      </Content>
      <XyteCredits>
        <span>powered by</span>
        <img src="logo_full_dark.svg" width={60} alt="xyte logo" />
      </XyteCredits>
    </Container>
  );
}

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  flex: 1;
  padding: 0 9%;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;

const XyteCredits = styled.div`
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 0.3;
`;

export default PreviewArea;
