import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';

import { ChooseProductStep } from './choose-product-step/ChooseProductStep';
import { PaymentSettingsStep } from './payment-settings-step/PaymentSettingsStep';
import { ProductInformationStep } from './product-information-step/ProductInformationStep';
import { includeSubscriptionCancellationStep } from './store-listing-wizard.utils';
import { SubscriptionCancellationStep } from './subscription-cancellation-step/SubscriptionCancellationStep';
import { StepId, StoreListingWizardContext } from './types';

export const STEPS_MAP: WizardSteps<StoreListingWizardContext, StepId> = {
  [StepId.ChooseProduct]: {
    id: StepId.ChooseProduct,
    isFinalStep: false,
    component: <ChooseProductStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.ProductInformation },
      isDisabled: ({ contextData }) => !contextData.selectedProduct,
    },
  },
  [StepId.ProductInformation]: {
    id: StepId.ProductInformation,
    isFinalStep: false,
    component: <ProductInformationStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PaymentSettings },
    },
    [WizardTransitionType.Prev]: {
      transition: [
        {
          id: StepId.ChooseProduct,
          condition: ({ contextData }) => contextData.intent === 'create',
        },
      ],
    },
  },
  [StepId.PaymentSettings]: {
    id: StepId.PaymentSettings,
    isFinalStep: ({ contextData }) => {
      return !includeSubscriptionCancellationStep(contextData);
    },
    component: <PaymentSettingsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductInformation },
    },
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.SubscriptionCancellation,
          condition: ({ contextData }) => {
            return includeSubscriptionCancellationStep(contextData);
          },
        },
      ],
    },
  },
  [StepId.SubscriptionCancellation]: {
    id: StepId.SubscriptionCancellation,
    isFinalStep: true,
    component: <SubscriptionCancellationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PaymentSettings },
    },
  },
};
