import { Select, SelectProps } from '@mantine/core';
import React, { FC } from 'react';

import { TaxGroupType, useTaxGroups } from '@portals/api/partners';

import { TaxGroupOption } from './TaxGroupOption';

interface TaxGroupsSelectProps
  extends Omit<SelectProps, 'data' | 'itemComponent'> {
  onChange: (taxGroupId: string) => void;
}

export const TaxGroupsSelect: FC<TaxGroupsSelectProps> = (props) => {
  const taxGroups = useTaxGroups();
  const options = composeOptions(taxGroups.data);

  return (
    <Select
      searchable
      placeholder="Choose tax group"
      data={options}
      itemComponent={TaxGroupOption}
      data-testid="tax-group-select"
      {...props}
    />
  );
};

function composeOptions(taxGroups: TaxGroupType[] | undefined) {
  return (
    taxGroups?.map((taxGroup) => ({
      value: taxGroup.id,
      label: taxGroup.name,
      iconName: taxGroup.icon_name,
    })) || []
  );
}
