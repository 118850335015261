import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
} from '@mantine/core';
import { last, split } from 'lodash/fp';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useCallback, useState } from 'react';

import { OrderType } from '@portals/api/partners';
import { FilePreview } from '@portals/framework';
import { ReactComponent as Export } from '@portals/icons/linear/export.svg';
import { ReactComponent as Printer } from '@portals/icons/linear/printer.svg';
import { VerticalScrollBar } from '@portals/scrollbar';
import { downloadFile } from '@portals/utils';

export interface PurchaseOrderFilePreviewProps {
  order: OrderType;
  onSubmit: () => void;
  onCancel: () => void;
  setIsDownloaded: () => void;
  isDownloaded: boolean;
}

function PurchaseOrderFilePreview({
  order,
  onSubmit,
  onCancel,
  setIsDownloaded,
  isDownloaded,
}: PurchaseOrderFilePreviewProps) {
  const { classes } = useContentStyles();

  const [isFailedToLoad, setIsFailedToLoad] = useState(false);
  const [PDFDocumentProxy, setPDFDocumentProxy] =
    useState<PDFDocumentProxy>(null);

  const fileFormat = last(split('.', order?.purchase_order_file_url));

  const onDownload = useCallback(() => {
    if (fileFormat !== 'pdf' && !isDownloaded) {
      setIsDownloaded();
    }

    downloadFile(order.purchase_order_file_url, 'purchase_order');
  }, [
    fileFormat,
    isDownloaded,
    order.purchase_order_file_url,
    setIsDownloaded,
  ]);

  const onPrint = async () => {
    const data = await PDFDocumentProxy.getData();
    const blob = new Blob([data], { type: `application/pdf` });

    window.open(URL.createObjectURL(blob));
  };

  return (
    <Stack className={classes.content} spacing={0} p={0}>
      <Group position="right" className={classes.actions} spacing="xs">
        <Button
          variant="white"
          leftIcon={<Export />}
          color="blue_gray"
          onClick={onDownload}
          data-testid="download-po-file-button"
        >
          Download
        </Button>

        {fileFormat === 'pdf' && !isFailedToLoad ? (
          <Button
            variant="white"
            leftIcon={<Printer />}
            color="blue_gray"
            onClick={onPrint}
            data-testid="print-po-file-button"
          >
            Print
          </Button>
        ) : null}
      </Group>

      <Box className={classes.documentWrapper}>
        <VerticalScrollBar height="100%">
          <FilePreview
            fileUrl={order?.purchase_order_file_url}
            fileName="purchase order"
            PDFDocumentProxy={PDFDocumentProxy}
            setPDFDocumentProxy={setPDFDocumentProxy}
            isFailedToLoad={isFailedToLoad}
            setIsFailedToLoad={setIsFailedToLoad}
            isDownloaded={isDownloaded}
            setIsDownloaded={setIsDownloaded}
          />
        </VerticalScrollBar>
      </Box>

      <Group position="right" className={classes.footer}>
        <Button
          variant="default"
          onClick={onCancel}
          data-testid="cancel-po-approval-button"
        >
          Cancel
        </Button>

        <Button onClick={onSubmit} data-testid="submit-po-approval-button">
          Approve Purchase Order
        </Button>
      </Group>
    </Stack>
  );
}

export function PurchaseOrderFilePreviewWrapper({
  onCancel,
  onSubmit,
  setIsDownloaded,
  isDownloaded,
  order,
}: PurchaseOrderFilePreviewProps) {
  const { classes } = useModalStyles();

  return (
    <Modal
      opened
      size="sm"
      closeOnEscape={true}
      onClose={onCancel}
      padding={0}
      classNames={{
        root: classes.root,
        content: classes.content,
        header: classes.header,
      }}
    >
      {order ? (
        <PurchaseOrderFilePreview
          order={order}
          onSubmit={onSubmit}
          onCancel={onCancel}
          setIsDownloaded={setIsDownloaded}
          isDownloaded={isDownloaded}
        />
      ) : (
        <LoadingOverlay visible />
      )}
    </Modal>
  );
}

const useContentStyles = createStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridTemplateColumns: '1fr',
    height: '90vh',
    maxHeight: 1300,
  },
  actions: {
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  documentWrapper: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '100%',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.xl,
  },
}));

const useModalStyles = createStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    minWidth: 900,
    minHeight: 330,
    overflow: 'hidden',
  },
  header: {
    display: 'none',
  },
}));
