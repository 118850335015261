import { Button, Group } from '@mantine/core';
import React from 'react';

import { PublishButton } from './PublishButton';
import { SaveDraftButton } from './SaveDraftButton';
import { useStoreListingWizard } from '../hooks';

export function EditModeFooter() {
  const { contextData } = useStoreListingWizard();

  const isPublishedStoreListing =
    contextData.storeListing?.status === 'published';

  return (
    <Group position="right">
      <Button
        variant={isPublishedStoreListing ? 'default' : 'white'}
        color={isPublishedStoreListing ? undefined : 'dark'}
        data-testid="edit-modal-cancel-button"
        onClick={() => contextData.closeModal({ confirmBeforeClose: true })}
      >
        Cancel
      </Button>

      {!isPublishedStoreListing ? <SaveDraftButton /> : null}

      <PublishButton />
    </Group>
  );
}
