import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Flex,
  Group,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { array, object, string } from 'yup';

import {
  convertWidgetFormToWidgetProps,
  ScatterChartWidget,
  ScatterChartWidgetForm,
  ScatterChartWidgetFormType,
  WIDGET_COLORS,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

const schema = object({
  events: array()
    .test(
      'unique',
      'Event names must be unique',
      (value: ScatterChartWidgetFormType['events']) => {
        if (value && Array.isArray(value)) {
          const eventNames = value.map((item) => item.event_name);
          const uniqueEventNames = new Set(eventNames);

          return uniqueEventNames.size === eventNames.length;
        }

        return true;
      }
    )
    .of(
      object().shape({
        numeric_parameter: string().required('Numeric parameter is required'),
      })
    ),
});

export function ScatterChartWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  onAddCustomColor,
  colors,
}: CommonWidgetFormWrapperProps<ScatterChartWidgetFormType>) {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();

  const form = useForm<ScatterChartWidgetFormType>({
    initialValues: {
      name: '',
      scale_type: 'linear',
      number_format: 'none',
      num_of_decimals: 1,
      events: [
        {
          id: uuid(),
          event_name: '',
          color: colors?.[0] || WIDGET_COLORS[0],
          numeric_parameter: 'int_key_1',
          event_display_name: '',
          units: '',
        },
      ],
      ...(initialValues || {}),
    },
  });

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  const setDuplicateEventNamesFieldErrors = ({
    values,
    error,
  }: {
    values: typeof form.values;
    error: { message: string };
  }) => {
    const eventNames = values.events.map((event) => event.event_name);

    const duplicateEventNames: number[] = eventNames.reduce(
      (acc, name, index) => {
        if (eventNames.indexOf(name) !== index) {
          acc.push(index);
        }
        return acc;
      },
      []
    );

    for (const index of duplicateEventNames) {
      form.setFieldError(`events.${index}.event_name`, error.message);
    }
  };

  const handleSubmit = async (values: typeof form.values) => {
    try {
      await schema.validateAt('events', values);

      await onSubmit(values);
    } catch (error) {
      if (error.message === 'Numeric parameter is required') {
        const index = error.params.path
          .replace('events[', '')
          .replace('].numeric_parameter', '');

        form.setFieldError(`events.${index}.numeric_parameter`, error.message);

        return;
      }
      setDuplicateEventNamesFieldErrors({ values, error });
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p={30} pr={0} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9">
            Add Scatter Chart
          </Text>

          <VerticalScrollBar>
            <Space h="xl" />

            <Box pr={30}>
              <ScatterChartWidgetForm
                form={form}
                colors={colors}
                onAddCustomColor={onAddCustomColor}
              />
            </Box>

            <Space h="xl" />
          </VerticalScrollBar>
        </Stack>

        <Stack className={classes.config} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Flex align="center" justify="center" h="100%" w="100%">
            <ScatterChartWidget
              {...convertWidgetFormToWidgetProps.scatter_chart({
                formValues: form.values,
              })}
              stackProps={{ h: 380, w: '100%' }}
              withZoom={false}
            />
          </Flex>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
  },
  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
    width: 0,
    minWidth: '100%',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
