import { Badge, Button } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Row } from 'react-table';

import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { BadgeCell, RowMenuItems, SmartTable } from '@portals/table';
import {
  CommandStatusType,
  CommandType,
  RowMenuRefType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { ButtonGroup } from '@portals/ui';
import { getCommandStatusInfo, prettyTime } from '@portals/utils';

import { useConfirmationModal } from '../hooks/modals';

const MAX_COMMANDS = 50;

interface CommandsViewProps {
  commands: Array<CommandType>;
  cancelCommand?: (deviceId: string, id: string) => void;
  openModal: (type: string, data: Record<string, any>) => void;
  isUrlSyncEnabled?: boolean;
  tableName: string;
}

export function CommandsView({
  commands,
  cancelCommand = noop,
  openModal,
  isUrlSyncEnabled = true,
  tableName,
}: CommandsViewProps) {
  const asyncConfirmationCheck = useConfirmationModal();
  const [onlyPending, setOnlyPending] = useState(true);
  const showParams = useCallback(
    (params) =>
      openModal('ShowHash', { header: 'Command parameters', keyMap: params }),
    [openModal]
  );

  const columns = useMemo<Array<TableColumn<CommandType>>>(
    () => [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Select,
          options: {
            in_progress: 'In Progress',
            pending: 'Pending',
            done: 'Done',
            aborted: 'Aborted',
            failed: 'Failed',
          },
        },
        formatter: (_, { status }) => {
          const { color, label } = getCommandStatusInfo(status);
          return <BadgeCell color={color} label={label} />;
        },
      },
      {
        dataField: 'created_at',
        text: 'Created',
        formatter: (_, { created_at }) => prettyTime(Date.parse(created_at)),
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
      },
      {
        dataField: 'params',
        text: 'Params',
        formatter: (_, { params }) =>
          params ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => showParams(params)}
            >
              Show
            </Button>
          ) : (
            <span className="text-muted">None</span>
          ),
      },
      {
        dataField: 'message',
        text: 'Message',
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
    ],
    [showParams]
  );

  const commandsList = useMemo(
    () =>
      commands
        .filter(
          (command) =>
            !onlyPending ||
            command.status === CommandStatusType.Pending ||
            command.status === CommandStatusType.InProgress
        )
        .sort((a, b) => (a.created_at > b.created_at ? -1 : 1)),
    [commands, onlyPending]
  );

  const onCancelCommandClicked = async (
    command: Row<CommandType>,
    menuRef: RowMenuRefType
  ) => {
    if (command.original.status === CommandStatusType.InProgress) {
      const isConfirmed = await asyncConfirmationCheck({
        title: `Are you sure you want to cancel the command during execution?`,
        description: `This may leave the device in an erroneous state`,
      });

      if (isConfirmed) {
        cancelCommand(command.original.device_id, command.original.id);
      }
    } else {
      cancelCommand(command.original.device_id, command.original.id);
    }

    menuRef.onClose();
  };

  return (
    <SmartTable<CommandType>
      exportParams={{ isEnabled: false }}
      noColumnsSelection
      isUrlSyncEnabled={isUrlSyncEnabled}
      additionalActions={() => (
        <>
          {commands.length === MAX_COMMANDS ? (
            <Badge
              size="xl"
              leftSection={<Danger />}
              color="orange"
              radius="md"
              styles={(theme) => ({
                inner: {
                  textTransform: 'none',
                  fontWeight: 400,
                  fontSize: theme.fontSizes.sm,
                },
                leftSection: {
                  display: 'flex',
                  alignItems: 'center',
                  svg: {
                    width: 15,
                    height: 15,
                  },
                },
              })}
            >
              {`Displaying only last ${MAX_COMMANDS} commands`}
            </Badge>
          ) : null}

          <ButtonGroup
            height={35}
            options={[
              { value: true, label: 'Pending Only' },
              { value: false, label: 'All' },
            ]}
            onChange={setOnlyPending}
            value={onlyPending}
          />
        </>
      )}
      name={tableName}
      keyField="id"
      data={commandsList}
      columns={columns}
      noDataIndication={{ title: 'No commands' }}
      rowMenu={({ row, wrapperProps }) => (
        <RowMenuItems
          wrapperProps={wrapperProps}
          items={[
            {
              id: 'cancel',
              color: 'red',
              Icon: CloseSquare,
              label: 'Cancel',
              onClick: () => onCancelCommandClicked(row, wrapperProps.menuRef),
            },
          ]}
        />
      )}
    />
  );
}
