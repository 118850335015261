import {
  ActionIcon,
  ColorInput,
  Group,
  Stack,
  Textarea,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { set } from 'lodash/fp';
import React from 'react';

import { ImageSelectorField } from '@portals/autoformik';
import { useWizard } from '@portals/framework';
import { ReactComponent as RotateLeft } from '@portals/icons/linear/rotate-left.svg';
import { suppressPropagation } from '@portals/utils';

import { DetailsForm } from '../DetailsForm';
import { OnboardingWizardContextDataType, StepId } from '../types';

function PortalDesignForm() {
  const theme = useMantineTheme();
  const { onPrev, onNext, contextData, setContextData } = useWizard<
    OnboardingWizardContextDataType,
    StepId
  >();

  return (
    <DetailsForm
      title="Design Your Portal"
      description="Add your logo, and select a color that fits your brand to create the perfect whitelabeled experience"
      onNextClick={onNext}
      onPrevClick={onPrev}
    >
      <Stack>
        <Group>
          <ImageSelectorField
            horizontal={false}
            setFieldValue={(_, value) =>
              setContextData(set(['design', 'sign_in_page', 'logo'], value))
            }
            field={{
              title: 'Upload your logo',
              name: 'logo',
              cropConfig: {
                aspectRatio: 1,
                height: 100,
                width: 100,
              },
              previewConfig: {
                width: 100,
                height: 100,
              },
              height: 177,
              width: 177,
            }}
            value={contextData?.design?.sign_in_page?.logo}
          />

          <ImageSelectorField
            horizontal={false}
            setFieldValue={(_, value) =>
              setContextData(
                set(['design', 'sign_in_page', 'login_image'], value)
              )
            }
            field={{
              title: 'Upload your login image',
              name: 'hero',
              height: 177,
              width: 177,
              previewConfig: {
                width: 150,
                height: 150,
              },
              cropConfig: {
                aspectRatio: 1,
                width: 500,
                height: 500,
              },
              inputProps: {
                style: {
                  flex: 1,
                },
              },
            }}
            value={contextData?.design?.sign_in_page?.login_image}
          />
        </Group>

        <Group grow>
          <ColorInput
            name="hero_gradient_primary"
            rightSection={
              <Tooltip label="Revert to default color" withArrow>
                <ActionIcon
                  onClick={suppressPropagation(() =>
                    setContextData(
                      set(['design', 'sign_in_page', 'background_color'], null)
                    )
                  )}
                >
                  <RotateLeft width={16} height={16} />
                </ActionIcon>
              </Tooltip>
            }
            label="Primary color"
            styles={{
              root: {
                flex: 1,
              },
              label: {
                width: '100%',
              },
            }}
            value={
              contextData?.design?.sign_in_page?.background_color ||
              theme.colors.blue_accent[4]
            }
            onChange={(value) =>
              setContextData(
                set(['design', 'sign_in_page', 'background_color'], value)
              )
            }
          />
          <ColorInput
            rightSection={
              <Tooltip label="Revert to default color" withArrow>
                <ActionIcon
                  onClick={suppressPropagation(() =>
                    setContextData(
                      set(['design', 'sign_in_page', 'tagline_color'], null)
                    )
                  )}
                >
                  <RotateLeft width={16} height={16} />
                </ActionIcon>
              </Tooltip>
            }
            label="Tagline color"
            styles={{
              root: {
                flex: 1,
              },
              label: {
                width: '100%',
              },
            }}
            value={
              contextData?.design?.sign_in_page?.tagline_color || theme.white
            }
            onChange={(value) =>
              setContextData(
                set(['design', 'sign_in_page', 'tagline_color'], value)
              )
            }
          />
        </Group>

        <Textarea
          name="tagline"
          label="Tagline"
          placeholder="Supercharging your HaaS business"
          value={contextData?.design?.sign_in_page?.tagline}
          onChange={(event) =>
            setContextData(
              set(['design', 'sign_in_page', 'tagline'], event.target.value)
            )
          }
          minRows={1}
        />
      </Stack>
    </DetailsForm>
  );
}

export default PortalDesignForm;
