import { UsePaginatedTableApiQuery } from '@portals/types';

import { SubscriptionSummaryType } from './subscriptions.types';

export const SUBSCRIPTIONS_API_URL = 'ui/partner/subscriptions';

export const subscriptionsQueryKeys = {
  base: [SUBSCRIPTIONS_API_URL],
  list: (
    tableState: UsePaginatedTableApiQuery<SubscriptionSummaryType>['tableState']
  ) => [...subscriptionsQueryKeys.base, 'list', tableState],
  detail: (subscriptionId: string) => [
    ...subscriptionsQueryKeys.base,
    subscriptionId,
  ],
  invoiceItems: (subscriptionId: string) => [
    ...subscriptionsQueryKeys.detail(subscriptionId),
    'invoice_items',
  ],
};
