import { motion } from 'framer-motion';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

export const Widget = styled(motion.div)<{ $isPreview?: boolean }>`
  height: 250px;
  width: 100%;
  border: 1px solid ${getStyledThemeColor('gray300')};
  border-radius: 8px;
  display: grid;
  grid-template-rows: max-content 1fr;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.01), 0 2px 6px rgba(0, 0, 0, 0.01),
    0 0 1px rgba(0, 0, 0, 0.01);
  transition: box-shadow 0.15s ease-in-out;
  background-color: ${getStyledThemeColor('white')};
  overflow: hidden;
  padding-bottom: 20px;
  z-index: 1;
  position: relative;

  .title {
    width: 100%;
    font-size: 18px;
    background-color: ${getStyledThemeColor('gray150')};
    color: ${getStyledThemeColor('gray600')};
    padding: 5px 15px;
    transition: all 0.15s ease-in-out;
    border-bottom: 1px solid ${getStyledThemeColor('gray300')};
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  ${({ $isPreview, theme }) =>
    $isPreview
      ? `
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05),
      0 0 1px rgba(0, 0, 0, 0.05);
    cursor: default;

    .title {
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
    }
  `
      : `
    &:hover {
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05),
      0 0 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    .title {
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
    }
  }
  `}
`;

export const Footer = styled.div`
  padding: 15px 20px;
  width: 100%;
  border-top: 1px solid ${getStyledThemeColor('gray300')};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    width: 150px;
    padding: 10px 0;
  }
`;

export const WidgetAsset = styled.div<{ $assetSrc: string }>`
  background-image: url(${({ $assetSrc }) => $assetSrc});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
