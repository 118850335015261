import {
  Badge,
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { StoreListing } from '@portals/api/partners';
import { ProductImage } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { formatCurrency, getProductTypeDisplayName } from '@portals/utils';

import { useCreateOrderWizard } from '../create-order-wizard/hooks';
import { getStoreListingPrice } from '../utils';

interface SelectedStoreListingDetailsProps {
  storeListing: StoreListing;
}

export function SelectedStoreListingDetails({
  storeListing,
}: SelectedStoreListingDetailsProps) {
  const { classes, theme } = useStyles();
  const { contextData } = useCreateOrderWizard();

  return (
    <Stack mb="xl">
      <div className={classes.grid}>
        <Group align="flex-start" noWrap>
          <Box>
            <ProductImage
              src={storeListing.product_image_url}
              radius="lg"
              width={64}
              height={64}
              className={classes.imageWrapper}
            />
          </Box>

          <Stack
            align="flex-start"
            spacing={4}
            className={classes.storeListingNameWrapper}
          >
            <Text truncate fw={500} size="lg" w="100%">
              {storeListing.product_name}
            </Text>

            <Badge
              size="lg"
              color="gray.1"
              radius="md"
              variant="filled"
              c="gray.6"
              fw={400}
              fz="xs"
            >
              {contextData.storeListingsQuantities[storeListing.id]}x
            </Badge>
          </Stack>
        </Group>

        <Stack className={classes.column}>
          <Text className={classes.cellHeader}>Type</Text>

          <Text className={classes.cellValue} truncate>
            {getProductTypeDisplayName(storeListing.product.product_type)}
          </Text>
        </Stack>

        <Stack className={classes.column}>
          <Text className={classes.cellHeader}>Category</Text>

          <Text className={classes.cellValue} truncate>
            {storeListing.product.category}
          </Text>
        </Stack>

        <Stack className={classes.column}>
          <Text className={classes.cellHeader}>Store price</Text>

          <Text className={classes.cellValue} truncate>
            {formatCurrency(
              getStoreListingPrice(storeListing, contextData.currency),
              contextData.currency
            )}
          </Text>
        </Stack>
      </div>

      <Group spacing={4}>
        <Text>Provision with shareable code</Text>
        <Tooltip
          w={288}
          multiline
          label="A shareable code will be generated for each item after the order is complete."
        >
          <InfoCircle width={18} height={18} color={theme.colors.gray[5]} />
        </Tooltip>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  storeListingNameWrapper: {
    height: '100%',
    overflow: 'hidden',
  },

  imageWrapper: {
    overflow: 'hidden',
    border: `1px solid ${theme.colors.gray[1]}`,
    borderRadius: theme.radius.lg,
  },

  grid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(150px, 2fr)',
    gridAutoColumns: '1fr',
    gap: theme.spacing.xxl,
    alignItems: 'start',

    [theme.fn.smallerThan('lg')]: {
      gridAutoFlow: 'row',
      gridTemplateColumns: '1fr',
      width: '100%',
      gap: theme.spacing.xl,
    },
  },

  column: {
    gap: theme.spacing.xs,
    overflow: 'hidden',

    [theme.fn.smallerThan('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  cellHeader: {
    fontWeight: 500,
    color: theme.colors.blue_gray[9],
  },

  cellValue: {
    color: theme.colors.gray[5],
  },
}));
