import { Button, Grid, Stack, Switch, Text } from '@mantine/core';
import React, { useState } from 'react';

import { PartnerTypeSelection } from '../components/PartnerTypeSelection';
import { StepsFooter } from '../components/StepsFooter';
import { StepsHeader } from '../components/StepsHeader';
import { useInvitePartnerWizard } from '../hooks';

export function NewPartnerType() {
  const { contextData, onPrev, isNextDisabled } = useInvitePartnerWizard();

  const [isChecked, setIsChecked] = useState(true);

  return (
    <>
      <StepsHeader
        title="What is your relationship?"
        description="The partner is:"
      />

      <PartnerTypeSelection />

      <Stack mt="md" spacing="xs">
        <Text size="sm">This partner is a certified service provider</Text>

        <Grid align="center" justify="space-between">
          <Grid.Col span={10}>
            <Text size="sm" color="gray.5" align="justify">
              Enable this option to mark the selected partner as a certified
              service provider. Once marked, your customers will be able to
              directly contact this partner for support and services related to
              your products.
            </Text>
          </Grid.Col>

          <Grid.Col span="content">
            <Switch
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
              data-testid="certified-service-provider-switch"
            />
          </Grid.Col>
        </Grid>
      </Stack>

      <StepsFooter>
        <Button variant="default" onClick={onPrev}>
          Back
        </Button>

        <Button
          disabled={isNextDisabled}
          onClick={() => contextData.onInviteNewPartner(isChecked)}
          data-testid="invite-new-partner-button"
        >
          Invite
        </Button>
      </StepsFooter>
    </>
  );
}
