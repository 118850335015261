import {
  Anchor,
  createStyles,
  Divider,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { FC } from 'react';

import { IconPicker } from '@portals/core';

import { TAX_GROUPS_ICONS, TaxGroupIconName } from '../tax-groups-icons';

interface FormType {
  groupName: string;
  taxCode: string;
  iconName: TaxGroupIconName;
}

interface CreateTaxGroupFormProps {
  iconName: TaxGroupIconName;
  onChangeIconName: (iconName: TaxGroupIconName) => void;

  fieldsWidth?: number;

  form: UseFormReturnType<FormType>;
}

export const CreateTaxGroupForm: FC<CreateTaxGroupFormProps> = ({
  iconName,
  onChangeIconName,

  fieldsWidth,

  form,
}) => {
  const { classes } = useStyles(fieldsWidth);

  return (
    <Stack>
      <Input.Wrapper label="Group Name" className={classes.inputWrapper}>
        <Group
          sx={{
            width: '100%',
            flexWrap: 'nowrap',
            alignItems: 'start',
          }}
        >
          <TextInput
            placeholder="e.g TV Screens"
            size="md"
            {...form?.getInputProps('groupName')}
            styles={(theme) => ({
              root: {
                width: '100%',
              },
              error: {
                fontSize: theme.fontSizes.xs,
              },
            })}
          />

          <IconPicker<TaxGroupIconName>
            withTooltip={false}
            icons={TAX_GROUPS_ICONS}
            onChange={onChangeIconName}
            iconName={iconName}
            position="right"
            triggerSize={42}
          />
        </Group>
      </Input.Wrapper>

      <Divider sx={(theme) => ({ borderColor: theme.colors.gray[2] })} />

      <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[5] })}>
        Use{' '}
        <Anchor href="https://taxcode.avatax.avalara.com/" target="_blank">
          Avalara
        </Anchor>{' '}
        to search the tax code that best matches the type of product in this
        group.
      </Text>

      <Input.Wrapper label="Tax Code" className={classes.inputWrapper}>
        <TextInput
          placeholder="Enter the group’s tax code"
          size="md"
          {...form?.getInputProps('taxCode')}
          styles={(theme) => ({
            error: {
              fontSize: theme.fontSizes.xs,
            },
          })}
        />
      </Input.Wrapper>
    </Stack>
  );
};

const useStyles = createStyles(
  (_, fieldsWidth: CreateTaxGroupFormProps['fieldsWidth']) => ({
    inputWrapper: {
      width: fieldsWidth ? fieldsWidth : 'unset',
    },
  })
);
