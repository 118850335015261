import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { usePartnerConfig } from '@portals/api/partners';

import { TenantAvatar } from './TenantAvatar';

export function TenantDetails() {
  const { classes } = useStyles();

  const partnerConfig = usePartnerConfig();

  return (
    <Group noWrap className={classes.container}>
      <TenantAvatar src={partnerConfig?.logo_url || 'logo_full_white.svg'} />

      <Text weight={600} color="blue_gray.0" truncate>
        {partnerConfig?.display_name}
      </Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingInline: theme.spacing.md,
  },
}));
