import { Group } from '@mantine/core';
import React from 'react';

interface StepsFooterProps {
  children: React.ReactNode;
}

export function StepsFooter({ children }: StepsFooterProps) {
  return (
    <Group grow mt={32}>
      {children}
    </Group>
  );
}
