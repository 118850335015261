import { useWizard } from '@portals/framework';

import {
  PublishProductsStepIds,
  PublishProductsWizardContextDataType,
} from './types';

export function usePublishProductsWizard() {
  return useWizard<
    PublishProductsWizardContextDataType,
    PublishProductsStepIds
  >();
}
