import { Button, Modal } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { map, without } from 'lodash/fp';
import React from 'react';
import { useUpdateEffect } from 'react-use';

import { ModalBody, ModalFooter } from '@portals/core';
import { PostPurchaseParamsStepFieldType } from '@portals/types';

import { PostPurchaseParamForm } from './common/PostPurchaseParamForm';
import { getFormSchema } from './post-purchase-params-step.constants';

interface EditPostPurchaseParamModalProps {
  opened: boolean;
  onCancel: () => void;
  onConfirm: (field: PostPurchaseParamsStepFieldType) => void;
  postPurchaseParams: Array<PostPurchaseParamsStepFieldType>;
  fieldIndex: number;
}

export function EditPostPurchaseParamModal({
  opened,
  onCancel,
  onConfirm,
  postPurchaseParams,
  fieldIndex,
}: EditPostPurchaseParamModalProps) {
  const field = postPurchaseParams?.[fieldIndex];

  const existingFieldsLabels = without(
    [field?.label],
    map('label', postPurchaseParams)
  );

  const form = useForm<PostPurchaseParamsStepFieldType>({
    validate: yupResolver(getFormSchema({ usedLabels: existingFieldsLabels })),
  });

  useUpdateEffect(() => {
    if (field) {
      form.setValues(field);
    } else {
      form.reset();
    }
  }, [field]);

  return (
    <Modal opened={opened} title="Edit Parameter" onClose={onCancel}>
      <form onSubmit={form.onSubmit(onConfirm)}>
        <ModalBody>
          <PostPurchaseParamForm form={form} mode="edit" />
        </ModalBody>

        <ModalFooter position="right">
          <Button variant="white" color="dark" onClick={onCancel}>
            Cancel
          </Button>

          <Button variant="default" type="submit">
            Update
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
