import { Box, Title } from '@mantine/core';
import React from 'react';

import { MenuNavigation } from './MenuNavigation';
import { StepperNavigation } from './StepperNavigation';
import { useStoreListingWizard } from '../hooks';

export function WizardNavigation() {
  const { contextData } = useStoreListingWizard();

  const getTitle = () => {
    switch (contextData.intent) {
      case 'create':
        return 'Add Product';
      case 'edit':
        return 'Edit Product';
      case 'publish':
        return 'Publish Product';
    }
  };

  return (
    <Box py={30} px={30}>
      <Title order={3} weight={500} mb={44}>
        {getTitle()}
      </Title>

      {contextData.intent === 'edit' ? (
        <MenuNavigation />
      ) : (
        <StepperNavigation />
      )}
    </Box>
  );
}
