import { createStyles, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

import { VerticalScrollBar } from '@portals/scrollbar';

interface DetailsColumnContentProps {
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
}

export function DetailsColumnContent({
  title,
  subtitle,
  children,
}: DetailsColumnContentProps) {
  const { classes } = useStyles(!!subtitle);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Title order={4} className={classes.title}>
          {title}
        </Title>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </header>

      <VerticalScrollBar>
        <div className={classes.content}>{children}</div>
      </VerticalScrollBar>
    </div>
  );
}

const useStyles = createStyles((theme, hasSubtitle: boolean) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
    width: '100%',
  },
  header: {
    paddingInline: 30,
  },
  title: {
    marginBottom: hasSubtitle ? theme.spacing.xl : 48,
    fontWeight: 400,
  },
  subtitle: {
    marginBottom: theme.spacing.xl,
  },
  content: {
    height: '100%',
    paddingInline: 30,
  },
}));

interface SectionProps {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
}
function Section({ title, subtitle, children }: SectionProps) {
  const { classes } = useSectionStyles(!!subtitle);

  return (
    <div className={classes.container}>
      <header>
        <Text className={classes.title}>{title}</Text>
        {subtitle && <Text className={classes.subtitle}>{subtitle}</Text>}
      </header>
      {children}
    </div>
  );
}

const useSectionStyles = createStyles((theme, hasSubtitle: boolean) => ({
  container: {
    position: 'relative',
    marginBottom: 32,
  },
  title: {
    marginBottom: hasSubtitle ? theme.spacing.xs : theme.spacing.md,
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    color: theme.colors.gray[8],

    strong: {
      fontWeight: 700,
    },
  },
  subtitle: {
    marginBottom: theme.spacing.xl,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[6],
  },
}));

DetailsColumnContent.Section = Section;
