import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  modalContent: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  overlay: {
    display: 'none',
  },
  tableOverwrites: {
    '.table-scroll-container': {
      borderRadius: 0,
      borderBottom: `1px solid ${theme.colors.gray[3]} !important`,
      padding: 0,
    },

    '.expand-row-wrapper': {
      paddingBottom: 0,
      borderBottom: 'none',
    },

    '.trow-wrapper': {
      borderBottom: 'none !important',

      '&.expanded': {
        borderBottom: `1px solid ${theme.colors.gray[3]} !important`,
      },
    },

    '.trow': {
      borderBottom: `1px solid ${theme.colors.gray[3]} !important`,

      '&:hover': {
        backgroundColor: 'unset',
      },

      '.tcell': {
        '&:not(:last-of-type)': {
          borderRight: `1px solid ${theme.colors.gray[3]}`,
        },
      },
    },

    '.thead-cell': {
      '.th': {
        height: '100%',

        '&:not(:last-of-type)': {
          borderRight: `1px solid ${theme.colors.gray[3]}`,
        },
      },
    },

    '.table-footer-container': {
      display: 'none',
    },
  },
}));
