import React from 'react';

import { useUpdateSupportedCommand } from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { SupportedCommandType, UuidType } from '@portals/types';

import { SupportedCommandForm } from './SupportedCommandForm';

interface EditSupportedCommandProps extends ModalProps {
  modelId: UuidType;
  commandId: UuidType;
  commandData: SupportedCommandType;
}

export function EditSupportedCommand({
  data,
  closeMe,
}: EditSupportedCommandProps) {
  const updateSupportedCommand = useUpdateSupportedCommand(data.modelId);

  const onUpdate = async (supportedCommand: Partial<SupportedCommandType>) => {
    try {
      await updateSupportedCommand.mutateAsync(supportedCommand);

      closeMe();
    } catch (error) {
      console.error(error?.error);
    }
  };

  return (
    <SupportedCommandForm
      isEdit
      closeMe={closeMe}
      handleUpdate={onUpdate}
      initialValues={data.commandData}
      isLoading={updateSupportedCommand.isLoading}
      errors={updateSupportedCommand.error?.error}
    />
  );
}
