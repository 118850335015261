import { Stack, Text } from '@mantine/core';
import React from 'react';

import { WidgetTypeEnum } from '@portals/device-widgets';
import { FieldTypeEnum } from '@portals/types';

import gaugeWidget from '../../assets/img/charts/gauge-widget.svg';
import lineChart from '../../assets/img/charts/line-chart.svg';
import metricWidget from '../../assets/img/charts/metric-widget.svg';
import statusWidget from '../../assets/img/charts/status-widget.svg';
import { WidgetMetaType } from './types';

export const WIDGETS: Array<WidgetMetaType> = [
  {
    id: WidgetTypeEnum.StatusWidget,
    name: 'Status Widget',
    configIcon: statusWidget,
    width: 3,
    fields: [
      {
        name: 'name',
        title: 'Title',
        placeholder: 'On-Call Status',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'status_measurement',
        title: 'Status Field',
        placeholder: 'call_status',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'status_measurement_1',
        title: 'Status #1',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_1',
            title: 'Value',
            placeholder: 'on_call',
            type: FieldTypeEnum.String,
            required: true,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_1',
            title: 'Display Name',
            placeholder: 'On Call',
            type: FieldTypeEnum.String,
            required: true,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_1',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#f44455',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_1',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_2',
        title: 'Status #2',
        type: FieldTypeEnum.Group,
        horizontal: true,
        required: true,
        children: [
          {
            name: 'tracked_measurement_value_2',
            title: 'Value',
            placeholder: 'vacant',
            type: FieldTypeEnum.String,
            required: true,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_2',
            title: 'Display Name',
            placeholder: 'Vacant',
            type: FieldTypeEnum.String,
            required: true,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_2',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#22AA8F',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_2',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_3',
        title: 'Status #3',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_3',
            title: 'Value',
            placeholder: 'unavailable',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_3',
            title: 'Display Name',
            placeholder: 'Unavailable',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_3',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#495057',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_3',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_4',
        title: 'Status #4',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_4',
            title: 'Value',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_4',
            title: 'Display Name',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_4',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#000000',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_4',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_5',
        title: 'Status #5',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_5',
            title: 'Value',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_5',
            title: 'Display Name',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_5',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#000000',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_5',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_6',
        title: 'Status #6',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_6',
            title: 'Value',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_6',
            title: 'Display Name',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_6',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#000000',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_6',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_7',
        title: 'Status #7',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_7',
            title: 'Value',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_7',
            title: 'Display Name',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_7',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#000000',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_7',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_8',
        title: 'Status #8',
        type: FieldTypeEnum.Group,
        horizontal: true,
        children: [
          {
            name: 'tracked_measurement_value_8',
            title: 'Value',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_display_name_8',
            title: 'Display Name',
            placeholder: '',
            type: FieldTypeEnum.String,
            inputProps: {
              style: {
                flex: 1,
              },
            },
          },
          {
            name: 'tracked_measurement_color_8',
            title: 'Background Color',
            type: FieldTypeEnum.Color,
            default: '#000000',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
          {
            name: 'tracked_measurement_text_color_8',
            title: 'Text Color',
            type: FieldTypeEnum.Color,
            default: '#ffffff',
            inputProps: {
              style: {
                flex: 0.75,
              },
            },
          },
        ],
      },
      {
        name: 'status_measurement_hide_title',
        title: 'Display value only',
        type: FieldTypeEnum.Checkbox,
        default: false,
      },
    ],
  },
  {
    id: WidgetTypeEnum.GaugeWidget,
    name: 'Gauge Widget',
    configIcon: gaugeWidget,
    width: 3,
    fields: [
      {
        type: FieldTypeEnum.Group,
        name: 'settings',
        title: (
          <Stack>
            <Text size="md" color="gray.9" weight={400}>
              Settings
            </Text>
          </Stack>
        ),
        horizontal: true,
        children: [
          {
            name: 'name',
            title: 'Widget Title',
            placeholder: 'Example: Temperature',
            type: FieldTypeEnum.String,
            required: true,
          },
          {
            name: 'tracked_measurement',
            title: 'Tracked Measurement',
            placeholder: 'Example: temperature',
            type: FieldTypeEnum.String,
            required: true,
          },
          {
            name: 'tracked_measurement_unit',
            title: 'Unit',
            placeholder: 'Example: Celsius',
            type: FieldTypeEnum.String,
          },
        ],
      },
      {
        type: FieldTypeEnum.Group,
        title: (
          <Text size="md" color="gray.9" weight={400}>
            Segments
          </Text>
        ),
        name: 'range',
        horizontal: true,
        children: [
          {
            name: 'min_value',
            title: 'Min',
            placeholder: '0',
            type: FieldTypeEnum.Number,
            required: true,
          },
          {
            name: 'low_range_end',
            title: 'Low Range End',
            placeholder: '25',
            type: FieldTypeEnum.Number,
            required: true,
          },
          {
            name: 'medium_range_end',
            title: 'Mid. Range End',
            placeholder: '75',
            type: FieldTypeEnum.Number,
            required: true,
          },
          {
            name: 'max_value',
            title: 'Max',
            placeholder: '100',
            type: FieldTypeEnum.Number,
            required: true,
          },
        ],
      },
      {
        type: FieldTypeEnum.Group,
        name: 'colors',
        title: (
          <Text size="md" color="gray.9" weight={400}>
            Segments Colors
          </Text>
        ),
        horizontal: true,
        children: [
          {
            name: 'min_low_segment_color',
            title: 'Min - Low Range',
            type: FieldTypeEnum.Color,
            default: '#FF1744',
            required: true,
          },
          {
            name: 'low_mid_segment_color',
            title: 'Low Range - Mid. Range',
            type: FieldTypeEnum.Color,
            default: '#FF9100',
            required: true,
          },
          {
            name: 'mid_max_segment_color',
            title: 'Mid. Range - Max',
            type: FieldTypeEnum.Color,
            default: '#66BB6A',
            required: true,
          },
        ],
      },
      {
        type: FieldTypeEnum.Group,
        name: 'labels',
        title: (
          <Text size="md" color="gray.9" weight={400}>
            Segments Labels
          </Text>
        ),
        horizontal: true,
        children: [
          {
            name: 'min_low_segment_label',
            title: 'Min - Low Range',
            type: FieldTypeEnum.Text,
            placeholder: 'Low',
          },
          {
            name: 'low_mid_segment_label',
            title: 'Low Range - Mid. Range',
            type: FieldTypeEnum.Text,
            placeholder: 'Optimal',
          },
          {
            name: 'mid_max_segment_label',
            title: 'Mid. Range - Max',
            type: FieldTypeEnum.Text,
            placeholder: 'High',
          },
        ],
      },
    ],
  },
  {
    id: WidgetTypeEnum.MetricWidget,
    name: 'Value Widget',
    configIcon: metricWidget,
    width: 3,
    fields: [
      {
        name: 'name',
        title: 'Title',
        placeholder: 'Device temperature',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'tracked_measurement',
        title: 'Tracked Measurement',
        placeholder: 'temperature',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'tracked_measurement_unit',
        title: 'Tracked Measurement Unit',
        placeholder: 'Celsius',
        type: FieldTypeEnum.String,
      },
      {
        name: 'tracked_measurement_hide_icon',
        title: 'Display value only',
        type: FieldTypeEnum.Checkbox,
        default: false,
      },
    ],
  },
  {
    id: WidgetTypeEnum.LineChart,
    name: 'Line Chart',
    configIcon: lineChart,
    width: 9,
    fields: [
      {
        name: 'name',
        title: 'Title',
        placeholder: 'Device temperature over time',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'tracked_measurement_1',
        title: 'Tracked Measurement #1',
        placeholder: 'temp',
        type: FieldTypeEnum.String,
        required: true,
      },
      {
        name: 'tracked_measurement_2',
        title: 'Tracked Measurement #2',
        placeholder: 'fan_speed',
        type: FieldTypeEnum.String,
      },
      {
        name: 'tracked_measurement_3',
        title: 'Tracked Measurement #3',
        placeholder: 'signal_strength',
        type: FieldTypeEnum.String,
      },
      {
        name: 'tracked_measurement_4',
        title: 'Tracked Measurement #4',
        placeholder: 'bandwidth',
        type: FieldTypeEnum.String,
      },
    ],
  },
];
