import { isEmpty, set, size } from 'lodash/fp';
import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';
import { ProductPricingModel, ProductTypeEnum } from '@portals/types';

import { GeneralInformationStep } from './GeneralInformationStep';
import { IntegrationStep } from './IntegrationStep';
import { PostPurchaseParametersStep } from './post-purchase-parameters-step/PostPurchaseParametersStep';
import { PricingModelStep } from './pricing-model-step/PricingModelStep';
import { PlatformLicenseProductDetailsWrapper } from './product-details-for-platform-license-step/PlatformLicenseProductDetails';
import { ProductTypeStep } from './product-type-step/ProductTypeStep';
import { ProductDetailsStep } from './ProductDetailsStep';
import { TaxDetailsStep } from './TaxDetailsStep';
import { ContextDataErrors, ProductWizardContext, StepId } from './types';

export const DEVICE_PLATFORM_STEPS_MAP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.GeneralInformation },
    },
  },
  [StepId.GeneralInformation]: {
    id: StepId.GeneralInformation,
    isFinalStep: false,
    component: <GeneralInformationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductType },
    },
    [WizardTransitionType.Next]: {
      transition: {
        id: StepId.ProductDetails,
      },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;
      const isNameEmpty = isEmpty(contextData.product.name);
      const isDescriptionEmpty = isEmpty(contextData.product.description);

      const errors: ContextDataErrors[StepId.GeneralInformation] = {
        name: isNameEmpty ? 'Name is a required field' : '',
        description: isDescriptionEmpty
          ? 'Description is a required field'
          : '',
      };

      setContextData((curr) =>
        set(['errors', StepId.GeneralInformation], errors, curr)
      );

      const hasError = isNameEmpty || isDescriptionEmpty;

      return allowTransition || !hasError;
    },
  },
  [StepId.ProductDetails]: {
    id: StepId.ProductDetails,
    isFinalStep: false,
    component: <PlatformLicenseProductDetailsWrapper />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.GeneralInformation },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;

      //This step is based on the fact that the product_type is 'platform-license'
      if (size(contextData.product.supported_command_ids) === 0) {
        setContextData((prev) =>
          set(
            ['errors', StepId.ProductDetails, 'supportedCommandIds'],
            'Select commands you wish to be included in this license and move them here by using the arrows.',
            prev
          )
        );

        return allowTransition;
      }

      return true;
    },
  },
  [StepId.PricingModel]: {
    id: StepId.PricingModel,
    isFinalStep: false,
    component: <PricingModelStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductDetails },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.pricing_model) {
        setContextData((prev) =>
          set(
            ['errors', StepId.PricingModel, 'general'],
            'Please choose a pricing model',
            prev
          )
        );

        return false;
      }

      if (
        contextData.product.pricing_model === ProductPricingModel.UsageBased
      ) {
        const hasDisplayNameError =
          !contextData.product.usage_based_display_name;
        const hasTelemetryKeyError =
          !contextData.product.usage_based_telemetry_key;
        const hasUnitNameError = !contextData.product.usage_based_unit_name;

        if (
          !hasDisplayNameError &&
          !hasTelemetryKeyError &&
          !hasUnitNameError
        ) {
          return true;
        }

        const errors: ProductWizardContext['errors'][StepId.PricingModel] = {
          general: '',
          usageBasedDisplayName: hasDisplayNameError ? 'Required' : '',
          usageBasedTelemetryKey: hasTelemetryKeyError ? 'Required' : '',
          usageBasedUnitName: hasUnitNameError ? 'Required' : '',
        };

        setContextData((prev) =>
          set(['errors', StepId.PricingModel], errors, prev)
        );

        return false;
      }

      return true;
    },
  },
  [StepId.TaxDetails]: {
    id: StepId.TaxDetails,
    isFinalStep: true,
    component: <TaxDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.tax_group_id) {
        setContextData((prev) =>
          set(['errors', StepId.TaxDetails], 'Please choose a tax group', prev)
        );

        return false;
      }

      return true;
    },
  },
};

export const DEVICE_LICENSE_STEPS_MAP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.Integration },
    },
  },
  [StepId.Integration]: {
    id: StepId.Integration,
    isFinalStep: false,
    component: <IntegrationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductType },
      isDisabled: () => true,
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.GeneralInformation },
    },
    onTransition: async ({ contextData, setContextData }) => {
      if (
        contextData.product.product_type === ProductTypeEnum.Physical &&
        !contextData.physicalIntegrationType
      ) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select an integration type',
            curr
          )
        );
        return false;
      }

      const isDeviceModelRequired =
        contextData.product.product_type === ProductTypeEnum.DeviceLicense ||
        contextData.physicalIntegrationType === 'device';

      if (isDeviceModelRequired && !contextData.product.device_model_id) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select a device model',
            curr
          )
        );
        return false;
      } else {
        setContextData((curr) => set(['errors', StepId.Integration], '', curr));
        return true;
      }
    },
  },
  [StepId.GeneralInformation]: {
    id: StepId.GeneralInformation,
    isFinalStep: false,
    component: <GeneralInformationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.Integration },
    },
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.PricingModel,
          condition: () => true,
        },
      ],
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;
      const isNameEmpty = isEmpty(contextData.product.name);
      const isDescriptionEmpty = isEmpty(contextData.product.description);

      const errors: ContextDataErrors[StepId.GeneralInformation] = {
        name: isNameEmpty ? 'Name is a required field' : '',
        description: isDescriptionEmpty
          ? 'Description is a required field'
          : '',
      };

      setContextData((curr) =>
        set(['errors', StepId.GeneralInformation], errors, curr)
      );

      const hasError = isNameEmpty || isDescriptionEmpty;

      return allowTransition || !hasError;
    },
  },
  [StepId.ProductDetails]: {
    id: StepId.ProductDetails,
    isFinalStep: false,
    component: <ProductDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.GeneralInformation },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;

      if (!contextData.product.license_data) {
        setContextData((curr) =>
          set(
            ['errors', StepId.ProductDetails, 'licenseData'],
            'License data is a required field',
            curr
          )
        );

        return allowTransition;
      }

      return true;
    },
  },
  [StepId.PricingModel]: {
    id: StepId.PricingModel,
    isFinalStep: false,
    component: <PricingModelStep />,
    [WizardTransitionType.Prev]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.GeneralInformation,
          condition: () => true,
        },
      ],
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.pricing_model) {
        setContextData((prev) =>
          set(
            ['errors', StepId.PricingModel, 'general'],
            'Please choose a pricing model',
            prev
          )
        );

        return false;
      }

      if (
        contextData.product.pricing_model === ProductPricingModel.UsageBased
      ) {
        const hasDisplayNameError =
          !contextData.product.usage_based_display_name;
        const hasTelemetryKeyError =
          !contextData.product.usage_based_telemetry_key;
        const hasUnitNameError = !contextData.product.usage_based_unit_name;

        if (
          !hasDisplayNameError &&
          !hasTelemetryKeyError &&
          !hasUnitNameError
        ) {
          return true;
        }

        const errors: ProductWizardContext['errors'][StepId.PricingModel] = {
          general: '',
          usageBasedDisplayName: hasDisplayNameError ? 'Required' : '',
          usageBasedTelemetryKey: hasTelemetryKeyError ? 'Required' : '',
          usageBasedUnitName: hasUnitNameError ? 'Required' : '',
        };

        setContextData((prev) =>
          set(['errors', StepId.PricingModel], errors, prev)
        );

        return false;
      }

      return true;
    },
  },
  [StepId.TaxDetails]: {
    id: StepId.TaxDetails,
    isFinalStep: true,
    component: <TaxDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PricingModel },
    },
    [WizardTransitionType.Next]: {
      transition: {
        id: StepId.PostPurchaseParameters,
      },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.tax_group_id) {
        setContextData((prev) =>
          set(['errors', StepId.TaxDetails], 'Please choose a tax group', prev)
        );

        return false;
      }

      return true;
    },
  },
};

export const PHYSICAL_PRODUCT_STEPS_MAP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.Integration },
    },
  },
  [StepId.Integration]: {
    id: StepId.Integration,
    isFinalStep: false,
    component: <IntegrationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductType },
      isDisabled: () => true,
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.GeneralInformation },
    },
    onTransition: async ({ contextData, setContextData }) => {
      if (
        contextData.product.product_type === ProductTypeEnum.Physical &&
        !contextData.physicalIntegrationType
      ) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select an integration type',
            curr
          )
        );
        return false;
      }

      const isDeviceModelRequired =
        contextData.product.product_type === ProductTypeEnum.DeviceLicense ||
        contextData.physicalIntegrationType === 'device';

      if (isDeviceModelRequired && !contextData.product.device_model_id) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select a device model',
            curr
          )
        );
        return false;
      } else {
        setContextData((curr) => set(['errors', StepId.Integration], '', curr));
        return true;
      }
    },
  },
  [StepId.GeneralInformation]: {
    id: StepId.GeneralInformation,
    isFinalStep: false,
    component: <GeneralInformationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.Integration },
    },
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.PricingModel,
          condition: () => true,
        },
      ],
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;
      const isNameEmpty = isEmpty(contextData.product.name);
      const isDescriptionEmpty = isEmpty(contextData.product.description);

      const errors: ContextDataErrors[StepId.GeneralInformation] = {
        name: isNameEmpty ? 'Name is a required field' : '',
        description: isDescriptionEmpty
          ? 'Description is a required field'
          : '',
      };

      setContextData((curr) =>
        set(['errors', StepId.GeneralInformation], errors, curr)
      );

      const hasError = isNameEmpty || isDescriptionEmpty;

      return allowTransition || !hasError;
    },
  },
  [StepId.ProductDetails]: {
    id: StepId.ProductDetails,
    isFinalStep: false,
    component: <ProductDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.GeneralInformation },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;

      if (!contextData.product.license_data) {
        setContextData((curr) =>
          set(
            ['errors', StepId.ProductDetails, 'licenseData'],
            'License data is a required field',
            curr
          )
        );

        return allowTransition;
      }

      return true;
    },
  },
  [StepId.PricingModel]: {
    id: StepId.PricingModel,
    isFinalStep: false,
    component: <PricingModelStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductDetails },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.pricing_model) {
        setContextData((prev) =>
          set(
            ['errors', StepId.PricingModel, 'general'],
            'Please choose a pricing model',
            prev
          )
        );

        return false;
      }

      if (
        contextData.product.pricing_model === ProductPricingModel.UsageBased
      ) {
        const hasDisplayNameError =
          !contextData.product.usage_based_display_name;
        const hasTelemetryKeyError =
          !contextData.product.usage_based_telemetry_key;
        const hasUnitNameError = !contextData.product.usage_based_unit_name;

        if (
          !hasDisplayNameError &&
          !hasTelemetryKeyError &&
          !hasUnitNameError
        ) {
          return true;
        }

        const errors: ProductWizardContext['errors'][StepId.PricingModel] = {
          general: '',
          usageBasedDisplayName: hasDisplayNameError ? 'Required' : '',
          usageBasedTelemetryKey: hasTelemetryKeyError ? 'Required' : '',
          usageBasedUnitName: hasUnitNameError ? 'Required' : '',
        };

        setContextData((prev) =>
          set(['errors', StepId.PricingModel], errors, prev)
        );

        return false;
      }

      return true;
    },
  },
  [StepId.TaxDetails]: {
    id: StepId.TaxDetails,
    isFinalStep: true,
    component: <TaxDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PricingModel },
    },
    [WizardTransitionType.Next]: {
      transition: {
        id: StepId.PostPurchaseParameters,
      },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.tax_group_id) {
        setContextData((prev) =>
          set(['errors', StepId.TaxDetails], 'Please choose a tax group', prev)
        );

        return false;
      }

      return true;
    },
  },
};

export const DEVICE_LICENSE_STEPS_MAP_WITH_PPP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.Integration },
    },
  },
  [StepId.Integration]: {
    id: StepId.Integration,
    isFinalStep: false,
    component: <IntegrationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductType },
      isDisabled: () => true,
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.GeneralInformation },
    },
    onTransition: async ({ contextData, setContextData }) => {
      if (
        contextData.product.product_type === ProductTypeEnum.Physical &&
        !contextData.physicalIntegrationType
      ) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select an integration type',
            curr
          )
        );
        return false;
      }

      const isDeviceModelRequired =
        contextData.product.product_type === ProductTypeEnum.DeviceLicense ||
        contextData.physicalIntegrationType === 'device';

      if (isDeviceModelRequired && !contextData.product.device_model_id) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select a device model',
            curr
          )
        );
        return false;
      } else {
        setContextData((curr) => set(['errors', StepId.Integration], '', curr));
        return true;
      }
    },
  },
  [StepId.GeneralInformation]: {
    id: StepId.GeneralInformation,
    isFinalStep: false,
    component: <GeneralInformationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.Integration },
    },
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.PricingModel,
          condition: () => true,
        },
      ],
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;
      const isNameEmpty = isEmpty(contextData.product.name);
      const isDescriptionEmpty = isEmpty(contextData.product.description);

      const errors: ContextDataErrors[StepId.GeneralInformation] = {
        name: isNameEmpty ? 'Name is a required field' : '',
        description: isDescriptionEmpty
          ? 'Description is a required field'
          : '',
      };

      setContextData((curr) =>
        set(['errors', StepId.GeneralInformation], errors, curr)
      );

      const hasError = isNameEmpty || isDescriptionEmpty;

      return allowTransition || !hasError;
    },
  },
  [StepId.ProductDetails]: {
    id: StepId.ProductDetails,
    isFinalStep: false,
    component: <ProductDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.GeneralInformation },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;

      if (!contextData.product.license_data) {
        setContextData((curr) =>
          set(
            ['errors', StepId.ProductDetails, 'licenseData'],
            'License data is a required field',
            curr
          )
        );

        return allowTransition;
      }

      return true;
    },
  },
  [StepId.PricingModel]: {
    id: StepId.PricingModel,
    isFinalStep: false,
    component: <PricingModelStep />,
    [WizardTransitionType.Prev]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.GeneralInformation,
          condition: () => true,
        },
      ],
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.pricing_model) {
        setContextData((prev) =>
          set(
            ['errors', StepId.PricingModel, 'general'],
            'Please choose a pricing model',
            prev
          )
        );

        return false;
      }

      if (
        contextData.product.pricing_model === ProductPricingModel.UsageBased
      ) {
        const hasDisplayNameError =
          !contextData.product.usage_based_display_name;
        const hasTelemetryKeyError =
          !contextData.product.usage_based_telemetry_key;
        const hasUnitNameError = !contextData.product.usage_based_unit_name;

        if (
          !hasDisplayNameError &&
          !hasTelemetryKeyError &&
          !hasUnitNameError
        ) {
          return true;
        }

        const errors: ProductWizardContext['errors'][StepId.PricingModel] = {
          general: '',
          usageBasedDisplayName: hasDisplayNameError ? 'Required' : '',
          usageBasedTelemetryKey: hasTelemetryKeyError ? 'Required' : '',
          usageBasedUnitName: hasUnitNameError ? 'Required' : '',
        };

        setContextData((prev) =>
          set(['errors', StepId.PricingModel], errors, prev)
        );

        return false;
      }

      return true;
    },
  },
  [StepId.TaxDetails]: {
    id: StepId.TaxDetails,
    isFinalStep: false,
    component: <TaxDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PricingModel },
    },
    [WizardTransitionType.Next]: {
      transition: {
        id: StepId.PostPurchaseParameters,
      },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.tax_group_id) {
        setContextData((prev) =>
          set(['errors', StepId.TaxDetails], 'Please choose a tax group', prev)
        );

        return false;
      }

      return true;
    },
  },

  [StepId.PostPurchaseParameters]: {
    id: StepId.PostPurchaseParameters,
    isFinalStep: true,
    component: <PostPurchaseParametersStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async () => true,
  },
};

export const PHYSICAL_PRODUCT_STEPS_MAP_WITH_PPP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: { id: StepId.Integration },
    },
  },
  [StepId.Integration]: {
    id: StepId.Integration,
    isFinalStep: false,
    component: <IntegrationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.ProductType },
      isDisabled: () => true,
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.GeneralInformation },
    },
    onTransition: async ({ contextData, setContextData }) => {
      if (
        contextData.product.product_type === ProductTypeEnum.Physical &&
        !contextData.physicalIntegrationType
      ) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select an integration type',
            curr
          )
        );
        return false;
      }

      const isDeviceModelRequired =
        contextData.product.product_type === ProductTypeEnum.DeviceLicense ||
        contextData.physicalIntegrationType === 'device';

      if (isDeviceModelRequired && !contextData.product.device_model_id) {
        setContextData((curr) =>
          set(
            ['errors', StepId.Integration],
            'Please select a device model',
            curr
          )
        );
        return false;
      } else {
        setContextData((curr) => set(['errors', StepId.Integration], '', curr));
        return true;
      }
    },
  },
  [StepId.GeneralInformation]: {
    id: StepId.GeneralInformation,
    isFinalStep: false,
    component: <GeneralInformationStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.Integration },
    },
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.PricingModel,
          condition: () => true,
        },
      ],
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;
      const isNameEmpty = isEmpty(contextData.product.name);
      const isDescriptionEmpty = isEmpty(contextData.product.description);

      const errors: ContextDataErrors[StepId.GeneralInformation] = {
        name: isNameEmpty ? 'Name is a required field' : '',
        description: isDescriptionEmpty
          ? 'Description is a required field'
          : '',
      };

      setContextData((curr) =>
        set(['errors', StepId.GeneralInformation], errors, curr)
      );

      const hasError = isNameEmpty || isDescriptionEmpty;

      return allowTransition || !hasError;
    },
  },
  [StepId.ProductDetails]: {
    id: StepId.ProductDetails,
    isFinalStep: false,
    component: <ProductDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.GeneralInformation },
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.PricingModel },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      const allowTransition = transitionType === WizardTransitionType.Prev;

      if (!contextData.product.license_data) {
        setContextData((curr) =>
          set(
            ['errors', StepId.ProductDetails, 'licenseData'],
            'License data is a required field',
            curr
          )
        );

        return allowTransition;
      }

      return true;
    },
  },
  [StepId.PricingModel]: {
    id: StepId.PricingModel,
    isFinalStep: false,
    component: <PricingModelStep />,
    [WizardTransitionType.Prev]: {
      transition: [
        {
          id: StepId.ProductDetails,
          condition: ({ contextData }) => !!contextData.product.device_model_id,
        },
        {
          id: StepId.GeneralInformation,
          condition: () => true,
        },
      ],
    },
    [WizardTransitionType.Next]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.pricing_model) {
        setContextData((prev) =>
          set(
            ['errors', StepId.PricingModel, 'general'],
            'Please choose a pricing model',
            prev
          )
        );

        return false;
      }

      if (
        contextData.product.pricing_model === ProductPricingModel.UsageBased
      ) {
        const hasDisplayNameError =
          !contextData.product.usage_based_display_name;
        const hasTelemetryKeyError =
          !contextData.product.usage_based_telemetry_key;
        const hasUnitNameError = !contextData.product.usage_based_unit_name;

        if (
          !hasDisplayNameError &&
          !hasTelemetryKeyError &&
          !hasUnitNameError
        ) {
          return true;
        }

        const errors: ProductWizardContext['errors'][StepId.PricingModel] = {
          general: '',
          usageBasedDisplayName: hasDisplayNameError ? 'Required' : '',
          usageBasedTelemetryKey: hasTelemetryKeyError ? 'Required' : '',
          usageBasedUnitName: hasUnitNameError ? 'Required' : '',
        };

        setContextData((prev) =>
          set(['errors', StepId.PricingModel], errors, prev)
        );

        return false;
      }

      return true;
    },
  },
  [StepId.TaxDetails]: {
    id: StepId.TaxDetails,
    isFinalStep: false,
    component: <TaxDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.PricingModel },
    },
    [WizardTransitionType.Next]: {
      transition: {
        id: StepId.PostPurchaseParameters,
      },
    },
    onTransition: async ({ contextData, setContextData, transitionType }) => {
      if (transitionType === WizardTransitionType.Prev) {
        return true;
      }

      if (!contextData.product.tax_group_id) {
        setContextData((prev) =>
          set(['errors', StepId.TaxDetails], 'Please choose a tax group', prev)
        );

        return false;
      }

      return true;
    },
  },

  [StepId.PostPurchaseParameters]: {
    id: StepId.PostPurchaseParameters,
    isFinalStep: true,
    component: <PostPurchaseParametersStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepId.TaxDetails },
    },
    onTransition: async () => true,
  },
};

export const PRODUCT_TYPE_FIRST_STEP: Partial<
  WizardSteps<ProductWizardContext, StepId>
> = {
  [StepId.ProductType]: {
    id: StepId.ProductType,
    isFinalStep: false,
    component: <ProductTypeStep />,
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepId.Integration,
          condition: ({ contextData }) =>
            contextData.product.product_type === ProductTypeEnum.Physical,
        },
        {
          id: StepId.Integration,
          condition: ({ contextData }) =>
            contextData.product.product_type === ProductTypeEnum.DeviceLicense,
        },
        {
          id: StepId.GeneralInformation,
          condition: ({ contextData }) =>
            contextData.product.product_type ===
            ProductTypeEnum.PlatformLicense,
        },
      ],
    },
  },
};
