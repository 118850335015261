import { NumberInput, Select, TextInput } from '@mantine/core';
import { isBoolean, toString } from 'lodash/fp';
import React from 'react';
import { useEffectOnce } from 'react-use';

interface ValueInputProps {
  type: string;
  value: string | number | boolean;
  setValue: (value: string | number | boolean) => void;
  label: string;
  from?: number;
  disabled: boolean;
}

export function ValueInput({
  type,
  value,
  setValue,
  label,
  from,
  disabled,
}: ValueInputProps) {
  useEffectOnce(function setDefaultBooleanInputValue() {
    if (type !== 'boolean') return;

    if (!isBoolean(value)) {
      setValue(true);
    }
  });

  if (type === 'string') {
    return (
      <div>
        <TextInput
          label={label}
          type="text"
          name="value"
          data-testid="value-string-input"
          required
          disabled={disabled}
          value={(value as string) || ''}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  } else if (type === 'boolean') {
    return (
      <Select
        label={label}
        type="select"
        required
        disabled={disabled}
        name="value"
        data-testid="value-boolean-input"
        value={isBoolean(value) ? toString(value) : 'true'}
        onChange={(value) => setValue(value === 'true')}
        withinPortal={false}
        data={[
          {
            value: 'true',
            label: 'true',
          },
          {
            value: 'false',
            label: 'false',
          },
        ]}
      />
    );
  } else if (type === 'number') {
    return (
      <NumberInput
        label={label}
        step={0.001}
        data-testid="value-number-input"
        required
        disabled={disabled}
        name="value"
        min={from}
        precision={3}
        value={(value as number) || undefined}
        onChange={setValue}
      />
    );
  }

  return null;
}
