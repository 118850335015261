import {
  createStyles,
  Group,
  SegmentedControl,
  SegmentedControlProps,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import {
  CustomerFeatureFlagEnum,
  PartnerFeatureFlagEnum,
  PartnerFeatureFlagItemType,
  PartnerFeatureFlagsType,
} from '@portals/types';

const segmentedControlPartnerData: SegmentedControlProps['data'] = [
  {
    label: 'Off',
    value: PartnerFeatureFlagEnum.Off,
  },
  {
    label: 'On',
    value: PartnerFeatureFlagEnum.On,
  },
];

const segmentedControlCustomerData: SegmentedControlProps['data'] = [
  {
    label: 'Off',
    value: CustomerFeatureFlagEnum.Off,
  },
  {
    label: 'Lab',
    value: CustomerFeatureFlagEnum.LabOnly,
  },
  {
    label: 'All',
    value: CustomerFeatureFlagEnum.All,
  },
];

interface FeatureFlagsGroupProps {
  title: string;
  type: string;
  featuresToDisplay: Array<PartnerFeatureFlagItemType>;
  currentFeatures: Partial<PartnerFeatureFlagsType>;
  setCurrentFeatures: Dispatch<
    SetStateAction<Partial<PartnerFeatureFlagsType>>
  >;
}

export function FeatureFlagsGroup({
  title,
  featuresToDisplay,
  setCurrentFeatures,
  currentFeatures,
  type,
}: FeatureFlagsGroupProps) {
  const { classes, theme } = useStyles();

  const onChange = (feature: PartnerFeatureFlagItemType, value: string) => {
    setCurrentFeatures({ ...currentFeatures, [feature.id]: value });
  };

  const getActiveLabelColor = (value: string) => {
    switch (value) {
      case 'on':
      case 'all':
        return theme.colors.teal[4];
      case 'off':
        return theme.colors.red_accent[4];
      default:
        return theme.colors.orange[5];
    }
  };

  return (
    <Stack spacing="xs" className={classes.container}>
      <Text size="lg" weight={600} color="gray.9">
        {title}
      </Text>
      <div>
        {featuresToDisplay.map((feature) => (
          <Group
            key={feature.id}
            position="apart"
            noWrap
            className={classes.listItem}
          >
            <Group spacing="xs" noWrap>
              <Text weight={600} color="gray.8">
                {feature.label}
              </Text>
              <Tooltip label={feature.description}>
                <div>
                  <InfoCircle
                    color={theme.colors.gray[5]}
                    width={18}
                    height={18}
                  />
                </div>
              </Tooltip>
            </Group>

            <SegmentedControl
              styles={() => ({
                root: {
                  width: 150,
                  flexShrink: 0,
                },
                controlActive: {
                  label: {
                    color: `${getActiveLabelColor(
                      currentFeatures[feature.id] || 'off'
                    )} !important`,
                  },
                },
              })}
              data={
                type === 'partner'
                  ? segmentedControlPartnerData
                  : segmentedControlCustomerData
              }
              value={currentFeatures[feature.id] || 'off'}
              onChange={(value) => onChange(feature, value)}
            />
          </Group>
        ))}
      </div>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xl,
    width: '100%',
  },
  listItem: {
    paddingBlock: theme.spacing.sm,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
