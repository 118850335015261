import { StoreListing } from '@portals/api/partners';
import { CurrencyCode } from '@portals/types';

export function getStoreListingPrice(
  storeListing: StoreListing,
  contextDataCurrency: CurrencyCode
) {
  const price = storeListing?.prices?.find(
    (price) => price.currency === contextDataCurrency
  );
  return price?.one_time_price_in_scu || 0;
}
