import { LoadingOverlay } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { useCreateDeviceModelWidget } from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { NetworkButton } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { useIsWidgetFormValid } from './hooks';
import { Footer, Widget } from '../../components/ModelWidgets/common';
import { WidgetMetaType } from '../../components/ModelWidgets/types';
import { useWidgetMeta } from '../../hooks/model-widgets';

interface WidgetConfigProps extends WidgetMetaType {
  onBack: () => void;
  onClose: () => void;
  modelId: string;
}

const WidgetConfig: FC<WidgetConfigProps> = ({
  id,
  name,
  configIcon,
  fields,
  modelId,
  onBack,
  onClose,
}) => {
  const ref = useRef(null);
  const [form, setForm] = useState<Record<string, string>>({});
  const { mutateAsync, isLoading } = useCreateDeviceModelWidget(modelId);

  const widgetMeta = useWidgetMeta(id);

  const isValid = useIsWidgetFormValid({ ref, form, widgetMeta });

  const onSubmit = useCallback(async () => {
    if (!isValid) return;

    const { name } = form;

    await mutateAsync({
      name,
      config: {
        id: widgetMeta.id,
        width: widgetMeta.width,
        fields: form,
      },
    });

    onClose();
  }, [form, widgetMeta.id, widgetMeta.width, isValid, mutateAsync, onClose]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <Container className="p-4">
        <div className="d-flex justify-content-center widget-preview-container">
          <Widget key={id} layoutId={`widget-${id}`} $isPreview>
            <div className="title">{name}</div>

            <div className="icon-wrapper">
              <img src={configIcon} alt={name} />
            </div>
          </Widget>
        </div>

        <AutoFormik
          innerRef={ref}
          fields={fields}
          inProgress={false}
          actionWrap={() => null}
          handleSubmit={noop}
          onChange={setForm}
        />
      </Container>

      <Footer>
        <Button
          size="lg"
          color="light"
          className="mr-4"
          onClick={onBack}
          disabled={isLoading}
        >
          Back
        </Button>

        <NetworkButton
          reloading={isLoading}
          data-testid="add-button"
          size="lg"
          color="primary"
          disabled={!isValid}
          onClick={onSubmit}
        >
          Add
        </NetworkButton>
      </Footer>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 25px;
  min-height: 650px;

  .widget-preview-container {
    border-right: 1px solid ${getStyledThemeColor('gray300')};
    padding-right: 25px;
  }

  form {
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 0 !important;
      flex: 1;
    }

    input::placeholder {
      color: ${getStyledThemeColor('gray500')};
    }
  }
`;

export default WidgetConfig;
