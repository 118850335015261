import { Button, createStyles, Group, Text } from '@mantine/core';
import React, { useState } from 'react';

import {
  useIsChannelPartner,
  useRequestPlanUpgrade,
} from '@portals/api/partners';
import {
  useAssignSupportSeatToUser,
  useRevokeUserSupportSeat,
} from '@portals/api/ui';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';
import { useOpenModal } from '@portals/redux';

import { AddSupportSeatsModalProps } from './AddSupportSeatsModal';
import { ReactComponent as LifeBuoyAddCircle } from '../assets/img/lifebuoy-add-circle.svg';
import { ReactComponent as LifeBuoyMinusCircle } from '../assets/img/lifebuoy-minus-circle.svg';
import { useNumOfAvailableSupportSeats } from '../pages/settings/support/support.hooks';

export interface ToggleUserSupportSeatModalProps
  extends ModalProps<{ userId: string; intent: 'assign' | 'revoke' }> {}

export function ToggleUserSupportSeatModal({
  closeMe,
  data: { userId, intent },
}: ToggleUserSupportSeatModalProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const [isOpen, setIsOpen] = useState(true);

  const assignSupportSeatToUser = useAssignSupportSeatToUser();
  const revokeUserSupportSeat = useRevokeUserSupportSeat();
  const numOfAvailableSeats = useNumOfAvailableSupportSeats();
  const requestPlanUpgrade = useRequestPlanUpgrade();
  const isChannelPartner = useIsChannelPartner();

  const onAddMoreSeatsClick = () => {
    if (isChannelPartner) {
      setIsOpen(false);
      openModal<AddSupportSeatsModalProps['data']>('AddSupportSeatsModal', {
        onClose: () => setIsOpen(true),
      });
    } else {
      requestPlanUpgrade.mutate(
        'Partners > Settings > Users > Toggle user support seat modal > Add Seats'
      );
    }
  };

  const onSubmit = async () => {
    try {
      if (intent === 'assign') {
        await assignSupportSeatToUser.mutateAsync({ userId });
      } else {
        await revokeUserSupportSeat.mutateAsync({ userId });
      }

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  const isLoading =
    assignSupportSeatToUser.isLoading || revokeUserSupportSeat.isLoading;

  const title =
    intent === 'assign' ? 'Assign a support seat' : 'Revoke support seat';

  const description =
    intent === 'assign'
      ? "A support seat is required for the user to access, provide support and manage end customers' tenants."
      : "A support seat is required for the user to access, provide support and manage end customers' tenants. Removing the support seat from this user will enable assigning it to another user if needed.";

  const media =
    intent === 'assign' ? <LifeBuoyAddCircle /> : <LifeBuoyMinusCircle />;

  return (
    <ModalCenteredMediaLayout
      opened={isOpen}
      onClose={closeMe}
      title={title}
      description={description}
      media={media}
      footer={
        <Group grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onSubmit}>
            {intent === 'assign'
              ? 'Assign Support Seat'
              : 'Revoke Support Seat'}
          </Button>
        </Group>
      }
    >
      <Group position="apart" className={classes.alertBox}>
        <Group spacing="xs" c={numOfAvailableSeats === 0 ? 'red' : 'gray.9'}>
          <InfoCircleIcon className={classes.infoIcon} />

          <div>
            <Text weight={700} span>
              {numOfAvailableSeats} seats{' '}
            </Text>
            <Text span>available</Text>
            {intent === 'revoke' && (
              <Text span color="gray.6">
                {' '}
                (+1 after removal)
              </Text>
            )}
          </div>
        </Group>

        <Text className={classes.addSeatsAction} onClick={onAddMoreSeatsClick}>
          Add more seats
        </Text>
      </Group>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  alertBox: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    borderRadius: 100,
    backgroundColor: theme.colors.primary[0],
  },
  infoIcon: {
    width: 18,
    height: 18,
  },
  addSeatsAction: {
    fontWeight: 600,
    color: theme.fn.primaryColor(),
    cursor: 'pointer',
    userSelect: 'none',
  },
}));
