import { SaveProductRequestPayload } from '@portals/api/partners';

export enum StepId {
  ProductType = 'productType',
  Integration = 'integration',
  GeneralInformation = 'generalInformation',
  ProductDetails = 'productDetails',
  PricingModel = 'pricingModel',
  TaxDetails = 'taxDetails',
  PostPurchaseParameters = 'postPurchaseParameters',
}

export type PhysicalIntegrationType = 'device' | 'none';

export type ProductWizardModalIntent = 'create' | 'edit' | 'duplicate';

export interface ContextDataErrors {
  [StepId.Integration]: string;
  [StepId.GeneralInformation]: {
    name: string;
    description: string;
  };
  [StepId.ProductDetails]: {
    licenseData: string;
    supportedCommandIds: string;
  };
  [StepId.PricingModel]: {
    general: string;
    usageBasedDisplayName: string;
    usageBasedTelemetryKey: string;
    usageBasedUnitName: string;
  };
  [StepId.TaxDetails]: string;
}

export interface ProductWizardContext {
  intent: ProductWizardModalIntent;

  product: Partial<SaveProductRequestPayload>;

  productId: string | null;

  // Physical product
  physicalIntegrationType: PhysicalIntegrationType | null;

  // Steps errors
  errors: ContextDataErrors;
}
