import { LoadingOverlay, Modal, Text } from '@mantine/core';
import { get } from 'lodash/fp';
import React from 'react';

import { useUpdatePartnerInfo } from '@portals/api/partners';
import { ImageSelectorField } from '@portals/autoformik';
import { ModalProps, useCommonConfig } from '@portals/framework';

export function EditPartnerLogo({ closeMe }: ModalProps) {
  const config = useCommonConfig();
  const updatePartnerInfo = useUpdatePartnerInfo();

  const onUpdate = async (_, value: string) => {
    try {
      await updatePartnerInfo.mutateAsync({ logo_url: value });

      if (value) {
        closeMe();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal opened onClose={closeMe} centered title="Choose Partner Logo">
      <LoadingOverlay
        visible={config.isInitialLoading || updatePartnerInfo.isLoading}
      />

      <Text>Partner Logo</Text>

      <ImageSelectorField
        horizontal={true}
        setFieldValue={onUpdate}
        value={get(['partner', 'logo_url'], config?.data)}
        field={{
          name: 'logo',
          cropConfig: {
            aspectRatio: 1,
            width: 84,
            height: 84,
          },
          previewConfig: {
            width: 84,
            height: 84,
          },
          height: 300,
          inputProps: {
            mb: 15,
          },
        }}
      />
    </Modal>
  );
}
