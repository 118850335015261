import { createStyles, Divider, Group, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion } from 'framer-motion';
import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import { ReactComponent as Home3Icon } from '@portals/icons/bold/home-3.svg';

import { Hub } from '../hubs.types';
import { useHubs } from '../HubsContext';

export function HubsNavbar() {
  const { classes, cx } = useStyles();
  const { hubs, activeHub } = useHubs();
  const isLargerThan1500 = useMediaQuery(`(min-width: 1500px)`);

  return (
    <Group mr="auto" noWrap spacing={isLargerThan1500 ? 'md' : 'xs'}>
      {hubs.map((hub) => {
        const isActiveHub = activeHub?.id === hub.id;
        const isHomeHub = hub.id === 'home';

        return (
          <div key={hub.id} className={classes.hubNavbarItem}>
            {isHomeHub && (
              <Divider
                orientation="vertical"
                color="blue_gray.7"
                className={classes.homeDivider}
              />
            )}

            <NavLink
              to={calculatePathname(hub)}
              data-testid={`nav-item-${hub.id}`}
              className={cx(classes.hubNavLink, {
                active: isActiveHub,
              })}
            >
              <Text weight={500}>
                {isHomeHub && isActiveHub ? <Home3Icon /> : hub.header}
              </Text>

              {isActiveHub && (
                <motion.div
                  layoutId="active-hub-indicator"
                  className={classes.activeHubIndicator}
                />
              )}
            </NavLink>

            {isHomeHub && (
              <Divider
                orientation="vertical"
                color="blue_gray.7"
                className={classes.homeDivider}
              />
            )}
          </div>
        );
      })}
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  hubNavbarItem: {
    display: 'flex',
    gap: theme.spacing.md,
    height: '100%',
  },
  hubNavLink: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingInline: theme.spacing.md,
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[4],

    '&:hover': {
      color: theme.white,
      backgroundColor: theme.colors.gray[8],
      textDecoration: 'none',
    },

    '&.active': {
      color: theme.white,
      backgroundColor: theme.colors.gray[8],
    },
  },
  homeDivider: {
    position: 'relative',
    height: '60%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  activeHubIndicator: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 5,
    borderTopLeftRadius: theme.radius.lg,
    borderTopRightRadius: theme.radius.lg,
    backgroundColor: theme.colors.indigo_accent[2],
  },
}));

function calculatePathname(hub: Hub) {
  const firstSidebarItem = hub.sidebarItems[0];

  if (firstSidebarItem.subItems) {
    return generatePath(firstSidebarItem.subItems[0].path);
  } else {
    return generatePath(firstSidebarItem.path);
  }
}
