import { ActionIcon, SimpleGrid } from '@mantine/core';
import * as React from 'react';

import { WIDGET_ICONS } from '../widgets.constants';
import { WidgetColorType, WidgetIconNameType } from '../widgets.types';

export interface IconSelectorProps {
  selectedIconName: WidgetIconNameType | null;
  onChange: (iconName: WidgetIconNameType) => void;
  color?: WidgetColorType;
}

export function IconSelector({
  onChange,
  selectedIconName,
  color,
}: IconSelectorProps) {
  return (
    <SimpleGrid cols={9} spacing="xs">
      {WIDGET_ICONS.map(({ Icon, iconName }) => {
        const isSelected = selectedIconName === iconName;

        return (
          <ActionIcon
            key={iconName}
            radius="md"
            size={40}
            onClick={() => onChange(iconName)}
            variant={isSelected ? 'light' : 'subtle'}
            color={isSelected ? color || 'blue_accent' : 'blue_gray'}
            data-testid={`widget-icon-selector-${iconName}`}
          >
            <Icon />
          </ActionIcon>
        );
      })}
    </SimpleGrid>
  );
}
