import {
  createStyles,
  Group,
  Image,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import React, { FC } from 'react';

import { PublishableProduct, useDeviceModel } from '@portals/api/partners';
import { TextWithTooltip } from '@portals/core';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';

import { ProductHeaderPrices } from './ProductHeaderPrices';

interface ProductHeaderProps {
  product: PublishableProduct;
}

export const ProductHeader: FC<ProductHeaderProps> = ({ product }) => {
  const { classes, theme } = useStyles();
  const deviceModel = useDeviceModel(product.device_model?.id || '');

  return (
    <header className={classes.header}>
      <LoadingOverlay visible={deviceModel.isInitialLoading} />

      <Group position="apart" noWrap>
        <div>
          {deviceModel.isFetched && (
            <Text lineClamp={1} className={classes.deviceModel}>
              {deviceModel.data?.model}
            </Text>
          )}

          <TextWithTooltip
            className={classes.name}
            tooltipProps={{ sx: { display: 'block' } }}
          >
            {product.name ? product.name : '--'}
          </TextWithTooltip>

          <ProductHeaderPrices product={product} />
        </div>
        <Image
          width={90}
          height={90}
          radius="md"
          src={product.image_url ?? undefined}
          withPlaceholder
          placeholder={
            <Gallery
              width="50%"
              height="50%"
              color={theme.colors.blue_gray[2]}
            />
          }
        />
      </Group>
    </header>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    position: 'relative',
  },
  deviceModel: {
    marginBottom: theme.spacing.xs,
    fontWeight: 600,
    fontSize: theme.fontSizes.md,
    color: theme.colors.blue_gray[6],
  },
  name: {
    fontSize: theme.fontSizes.xl,
    color: theme.colors.blue_gray[9],
  },
}));
