import { Modal } from '@mantine/core';
import React from 'react';

import {
  CreateDeviceModelFileParams,
  useCreateDeviceModelFile,
  useFileInfoById,
  useUpdateDeviceModelFile,
} from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps } from '@portals/framework';
import {
  FieldType,
  FieldTypeEnum,
  PartnerFileResponseType,
} from '@portals/types';
import { ChecksumAdornment, SignatureAdornment } from '@portals/ui';

const FIELDS: Array<FieldType> = [
  {
    name: 'url',
    title: 'File',
    type: FieldTypeEnum.File,
    required: true,
    options: {
      fileNameField: 'name',
    },
    inputProps: {
      'data-testid': 'upload-file',
    },
  },
  { name: 'name', title: 'File name', required: true },
  { name: 'desc', title: 'Description' },
  { name: 'file_type', title: 'File Type', default: 'firmware' },
  {
    name: 'version',
    title: 'Version',
    type: FieldTypeEnum.String,
    default: '1.0.0',
    required: true,
  },
  {
    name: 'checksum',
    title: 'Checksum',
    type: FieldTypeEnum.String,
    inputProps: {
      endAdornment: (
        <ChecksumAdornment docsUrl="https://dev.xyte.io/docs/files" />
      ),
    },
  },
  {
    name: 'signature',
    title: 'Signature',
    type: FieldTypeEnum.Textarea,
    inputProps: {
      endAdornment: (
        <SignatureAdornment docsUrl="https://dev.xyte.io/docs/files" />
      ),
    },
  },
  { name: 'notes', title: 'Notes', type: FieldTypeEnum.Text },
  {
    name: 'public_notes',
    title: 'Public Notes',
    type: FieldTypeEnum.Textarea,
  },
];

export interface FileUploaderProps
  extends ModalProps<{ modelId: string; fileId?: string }> {}

export const FileUploader = ({ data, closeMe }: FileUploaderProps) => {
  const { modelId, fileId } = data;

  const initialValues = useFileInfoById(fileId);

  const createDeviceModelFile = useCreateDeviceModelFile(modelId);
  const updateDeviceModelFile = useUpdateDeviceModelFile(modelId);

  const error =
    createDeviceModelFile.error?.error || updateDeviceModelFile.error?.error;

  const inProgress =
    createDeviceModelFile.isLoading ||
    updateDeviceModelFile.isLoading ||
    initialValues.isInitialLoading;

  const handleAdd = async (formData: CreateDeviceModelFileParams) => {
    try {
      await createDeviceModelFile.mutateAsync(formData);

      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  const handleEdit = async (updatedFile: PartnerFileResponseType) => {
    try {
      await updateDeviceModelFile.mutateAsync(updatedFile);

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={fileId ? 'Edit file' : 'Upload new file'}
      padding="xl"
      sx={(theme) => ({
        '.modal-body': {
          padding: 0,
          marginBottom: theme.spacing.md,
        },
        '.modal-footer': {
          paddingRight: 0,
          paddingLeft: 0,
        },
      })}
    >
      <AutoFormik
        initialValues={initialValues.data || {}}
        fields={FIELDS}
        submitTitle={fileId ? 'Save' : 'Add'}
        serverError={error}
        inProgress={inProgress}
        handleSubmit={fileId ? handleEdit : handleAdd}
        modal={closeMe}
      />
    </Modal>
  );
};
