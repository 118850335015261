import {
  Anchor,
  Avatar,
  Box,
  Button,
  Group,
  Select,
  Text,
} from '@mantine/core';
import { first } from 'lodash/fp';
import React, { forwardRef, useEffect } from 'react';

import { StepsFooter } from '../components/StepsFooter';
import { StepsHeader } from '../components/StepsHeader';
import { useInvitePartnerWizard } from '../hooks';
import { PartnerOptionType } from '../types';

export function SelectPartner() {
  const { contextData, isNextDisabled, onNext } = useInvitePartnerWizard();
  const {
    partners,
    selectedPartner,
    onSelectExistingPartner,
    closeMe,
    isInvitingNewPartner,
    toggleInviteNewPartner,
  } = contextData;

  useEffect(
    function moveToInviteNewPartnerStep() {
      if (isInvitingNewPartner) {
        onNext();
      }
    },
    [isInvitingNewPartner, onNext]
  );

  return (
    <Box>
      <StepsHeader
        title="Invite Partner"
        description="Search manufacturers, distributors, integrators and others that you wish to collaborate with"
      />

      <Select
        name="partner"
        size="md"
        value={selectedPartner?.value ?? null}
        placeholder="Search partner..."
        data={partners}
        onChange={onSelectExistingPartner}
        clearable
        searchable
        itemComponent={PartnerOption}
        data-testid="select-partner-dropdown"
      />

      <Group spacing="xs" mt="sm" position="center">
        <Text>Can't find your partner?</Text>
        <Anchor
          onClick={toggleInviteNewPartner}
          data-testid="invite-partner-anchor"
        >
          Invite partner to Xyte
        </Anchor>
      </Group>

      <StepsFooter>
        <Button
          variant="default"
          onClick={closeMe}
          data-testid="invite-partner-cancel-button"
        >
          Cancel
        </Button>

        <Button
          disabled={isNextDisabled}
          onClick={onNext}
          data-testid="invite-partner-continue-button"
        >
          Continue
        </Button>
      </StepsFooter>
    </Box>
  );
}

type OptionType = PartnerOptionType & React.ComponentPropsWithoutRef<'div'>;
const PartnerOption = forwardRef<HTMLDivElement, OptionType>(
  ({ label, logo, ...rest }, ref) => {
    return (
      <div {...rest} ref={ref}>
        <Group py="md">
          <Avatar radius="xl" color="blue" src={logo}>
            {first(label)}
          </Avatar>

          <Text size="sm" color="gray.8">
            {label}
          </Text>
        </Group>
      </div>
    );
  }
);
