import { Input, Stack, Switch, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { SectionHeaderWidgetFormType } from './section-header-form.types';
import { ColorSelector } from '../../common/ColorSelector';
import { IconSelector } from '../../common/IconSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface SectionHeaderWidgetFormProps {
  form: UseFormReturnType<SectionHeaderWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function SectionHeaderWidgetForm({
  form,
  colors,
  onAddCustomColor,
}: SectionHeaderWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-autofocus
          label="Title"
          required
        />

        <Input.Wrapper label="Text Color" h={100}>
          <ColorSelector
            onAddCustomColor={onAddCustomColor}
            colors={colors}
            selectedColor={form.values.text_color}
            onPresetColorSelect={(color) =>
              form.setFieldValue('text_color', color)
            }
            groupProps={{
              sx: (theme) => ({
                display: 'grid',
                gridTemplateColumns: 'repeat(12, 34px)',
                gap: theme.spacing.xs,
              }),
            }}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Background Color" h={100}>
          <ColorSelector
            onAddCustomColor={onAddCustomColor}
            colors={colors}
            selectedColor={form.values.background_color}
            onPresetColorSelect={(color) =>
              form.setFieldValue('background_color', color)
            }
            groupProps={{
              sx: (theme) => ({
                padding: 0,
                margin: 0,
                display: 'grid',
                gridTemplateColumns: 'repeat(12, 34px)',
                gap: theme.spacing.xs,
              }),
            }}
          />
        </Input.Wrapper>

        <Switch
          {...form.getInputProps('display_icon', { type: 'checkbox' })}
          label="Display Icon"
        />

        {form.values.display_icon ? (
          <Input.Wrapper label="Icon" required={form.values.display_icon}>
            <IconSelector
              selectedIconName={form.values.icon_name}
              onChange={(iconName) => form.setFieldValue('icon_name', iconName)}
            />
          </Input.Wrapper>
        ) : null}
      </Stack>
    </Stack>
  );
}
