import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { UserPermissions } from '@portals/types';

import { GROUPS_API_URL, groupsQueryKeys } from './groups.constants';
import { GroupsDetailsType, GroupType } from './groups.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { uiGlobalQueryKeys } from '../../ui/global-query-keys';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useGroups() {
  return useApiQuery<GroupType[]>(GROUPS_API_URL, groupsQueryKeys.all(), {
    staleTime: 0,
  });
}

export function useGroup(groupId: string) {
  return useApiQuery<GroupsDetailsType>(
    `${GROUPS_API_URL}/${groupId}`,
    groupsQueryKeys.detail(groupId),
    { staleTime: 0, enabled: !!groupId }
  );
}

interface UseCreateGroupMutationParams {
  name: string;
  iconName: string;
  userIds: string[];
  permissions: UserPermissions;
}

export function useCreateGroup() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: GROUPS_API_URL,
    method: 'POST',
  });

  return useMutation<
    GroupsDetailsType,
    ServerError,
    UseCreateGroupMutationParams
  >({
    mutationFn: ({ name, iconName, userIds, permissions }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name,
          icon_name: iconName,
          user_ids: userIds,
          permissions,
        }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Group created successfully'));

      queryClient.invalidateQueries(groupsQueryKeys.all());
      queryClient.invalidateQueries(uiGlobalQueryKeys.users);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateGroup',
      baseUrl: GROUPS_API_URL,
      method: 'POST',
    },
  });
}

interface UseUpdateGroupMutationParams {
  groupId: string;
  name: string;
  iconName: string;
  userIds: string[];
  permissions?: UserPermissions;
}

export function useUpdateGroup() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: GROUPS_API_URL,
    method: 'PUT',
  });

  return useMutation<
    GroupsDetailsType,
    ServerError,
    UseUpdateGroupMutationParams
  >({
    mutationFn: ({ groupId, name, iconName, userIds, permissions }) => {
      return fetchApiRequest(`${url}/${groupId}`, {
        ...options,
        body: JSON.stringify({
          name,
          icon_name: iconName,
          user_ids: userIds,
          permissions,
        }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Group updated successfully'));

      queryClient.invalidateQueries(groupsQueryKeys.all());
      queryClient.invalidateQueries(uiGlobalQueryKeys.users);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateGroup',
      baseUrl: `${GROUPS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

interface UseRemoveGroupMutationParams {
  groupId: string;
}

export function useRemoveGroup() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: GROUPS_API_URL,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, UseRemoveGroupMutationParams>({
    mutationFn: ({ groupId }) => {
      return fetchApiRequest(`${url}/${groupId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Group removed successfully'));

      queryClient.invalidateQueries(groupsQueryKeys.all());
      queryClient.invalidateQueries(uiGlobalQueryKeys.users);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRemoveGroup',
      baseUrl: `${GROUPS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
