import {
  BarControllerWidgetFormType,
  BarIndicatorWidgetFormType,
  ButtonWidgetFormType,
  DeviceLocationWidgetFormType,
  DeviceWidgetNameType,
  GaugeIndicatorWidgetFormType,
  getWidgetPreviewSrc,
  GroupedSwitchesWidgetFormType,
  NumericMetricWidgetFormType,
  ScatterChartWidgetFormType,
  SectionHeaderWidgetFormType,
  SplineChartWidgetFormType,
  StateControllerWidgetFormType,
  StatusWidgetFormType,
  ToggleWidgetFormType,
  ValueWidgetFormType,
} from '@portals/device-widgets';

import { DeviceWidgetMetaType } from './device-widgets.types';
import { BarControllerWidgetFormWrapper } from './forms-wrappers/BarControllerWidgetFormWrapper';
import { BarIndicatorWidgetFormWrapper } from './forms-wrappers/BarIndicatorWidgetFormWrapper';
import { ButtonWidgetFormWrapper } from './forms-wrappers/ButtonWidgetFormWrapper';
import { DeviceLocationWidgetFormWrapper } from './forms-wrappers/DeviceLocationWidgetFormWrapper';
import { GaugeIndicatorWidgetFormWrapper } from './forms-wrappers/GaugeIndicatorWidgetFormWrapper';
import { GroupedSwitchesWidgetFormWrapper } from './forms-wrappers/GroupedSwitchesWidgetFormWrapper';
import { NumericMetricWidgetFormWrapper } from './forms-wrappers/NumericMetricWidgetFormWrapper';
import { ScatterChartWidgetFormWrapper } from './forms-wrappers/ScatterChartWidgetFormWrapper';
import { SectionHeaderWidgetFormWrapper } from './forms-wrappers/SectionHeaderWidgetFormWrapper';
import { SplineChartWidgetFormWrapper } from './forms-wrappers/SplineChartWidgetFormWrapper';
import { StateControllerWidgetFormWrapper } from './forms-wrappers/StateControllerWidgetFormWrapper';
import { StatusWidgetFormWrapper } from './forms-wrappers/StatusWidgetFormWrapper';
import { ToggleWidgetFormWrapper } from './forms-wrappers/ToggleWidgetFormWrapper';
import { ValueWidgetFormWrapper } from './forms-wrappers/ValueWidgetFormWrapper';

export const DEVICE_WIDGET_NAMES: Array<DeviceWidgetNameType> = [
  'status',
  'numeric_metric',
  'value',
  'spline_chart',
  'scatter_chart',
  'toggle',
  'button',
  'device_location',
  'gauge_indicator',
  'bar_indicator',
  'grouped_switches',
  'bar_controller',
  'section_header',
  'state_controller',
];

export function getDeviceWidgetMeta(deviceWidgetName: DeviceWidgetNameType) {
  switch (deviceWidgetName) {
    case 'status':
      return {
        id: 'status',
        type: 'monitoring',
        name: 'Status',
        description: 'Displays the current status of the device',
        widgetPreviewSrc: getWidgetPreviewSrc('status'),
        width: 6,
        height: 3,
        widgetFormRenderer: StatusWidgetFormWrapper,
      } as DeviceWidgetMetaType<StatusWidgetFormType>;

    case 'numeric_metric':
      return {
        id: 'numeric_metric',
        type: 'monitoring',
        name: 'Numeric Metric',
        description: 'Shows numerical telemetry data of the device',
        widgetPreviewSrc: getWidgetPreviewSrc('numeric_metric'),
        width: 6,
        height: 3,
        widgetFormRenderer: NumericMetricWidgetFormWrapper,
      } as DeviceWidgetMetaType<NumericMetricWidgetFormType>;

    case 'value':
      return {
        id: 'value',
        type: 'monitoring',
        name: 'Value',
        description: 'Displays the value of a device telemetry',
        widgetPreviewSrc: getWidgetPreviewSrc('value'),
        width: 6,
        height: 3,
        widgetFormRenderer: ValueWidgetFormWrapper,
      } as DeviceWidgetMetaType<ValueWidgetFormType>;

    case 'spline_chart':
      return {
        id: 'spline_chart',
        type: 'monitoring',
        name: 'Spline Chart',
        description: 'Visualizes device telemetry data over time',
        widgetPreviewSrc: getWidgetPreviewSrc('spline_chart'),
        width: 25,
        height: 9,
        widgetFormRenderer: SplineChartWidgetFormWrapper,
      } as DeviceWidgetMetaType<SplineChartWidgetFormType>;

    case 'toggle':
      return {
        id: 'toggle',
        type: 'commands',
        name: 'Toggle',
        description: 'Allows toggling a device state with a switch',
        widgetPreviewSrc: getWidgetPreviewSrc('toggle'),
        width: 6,
        height: 5,
        widgetFormRenderer: ToggleWidgetFormWrapper,
      } as DeviceWidgetMetaType<ToggleWidgetFormType>;

    case 'button':
      return {
        id: 'button',
        type: 'commands',
        name: 'Button',
        description: 'Allows triggering a device command with a button',
        widgetPreviewSrc: getWidgetPreviewSrc('button'),
        width: 6,
        height: 3,
        widgetFormRenderer: ButtonWidgetFormWrapper,
      } as DeviceWidgetMetaType<ButtonWidgetFormType>;

    case 'device_location':
      return {
        id: 'device_location',
        type: 'monitoring',
        name: 'Device Location',
        description: 'Displays the current location of the device',
        widgetPreviewSrc: getWidgetPreviewSrc('device_location'),
        width: 10,
        height: 10,
        widgetFormRenderer: DeviceLocationWidgetFormWrapper,
      } as DeviceWidgetMetaType<DeviceLocationWidgetFormType>;

    case 'gauge_indicator':
      return {
        id: 'gauge_indicator',
        type: 'monitoring',
        name: 'Gauge Indicator',
        description: 'Visualizes telemetry data as a gauge indicator',
        widgetPreviewSrc: getWidgetPreviewSrc('gauge_indicator'),
        width: 9,
        height: 10,
        widgetFormRenderer: GaugeIndicatorWidgetFormWrapper,
      } as DeviceWidgetMetaType<GaugeIndicatorWidgetFormType>;

    case 'bar_indicator':
      return {
        id: 'bar_indicator',
        type: 'monitoring',
        name: 'Bar Indicator',
        description: 'Visualizes telemetry data as a bar indicator',
        widgetPreviewSrc: getWidgetPreviewSrc('bar_indicator'),
        width: 9,
        height: 4,
        widgetFormRenderer: BarIndicatorWidgetFormWrapper,
      } as DeviceWidgetMetaType<BarIndicatorWidgetFormType>;

    case 'grouped_switches':
      return {
        id: 'grouped_switches',
        type: 'commands',
        name: 'Grouped Switches',
        description: 'Allows toggling multiple device states with switches',
        widgetPreviewSrc: getWidgetPreviewSrc('grouped_switches'),
        width: 2,
        height: 4,
        widgetFormRenderer: GroupedSwitchesWidgetFormWrapper,
      } as DeviceWidgetMetaType<GroupedSwitchesWidgetFormType>;

    case 'bar_controller':
      return {
        id: 'bar_controller',
        type: 'commands',
        name: 'Bar Controller',
        description: 'Allows controlling a device state with a slider',
        widgetPreviewSrc: getWidgetPreviewSrc('bar_controller'),
        width: 9,
        height: 4,
        widgetFormRenderer: BarControllerWidgetFormWrapper,
      } as DeviceWidgetMetaType<BarControllerWidgetFormType>;

    case 'section_header':
      return {
        id: 'section_header',
        type: 'UI',
        name: 'Section Header',
        description: 'Displays a section header',
        widgetPreviewSrc: getWidgetPreviewSrc('section_header'),
        width: 12,
        height: 2,
        widgetFormRenderer: SectionHeaderWidgetFormWrapper,
      } as DeviceWidgetMetaType<SectionHeaderWidgetFormType>;

    case 'state_controller':
      return {
        id: 'state_controller',
        type: 'commands',
        name: 'State Controller',
        description:
          'Allows controlling a device state with a segmented control',
        widgetPreviewSrc: getWidgetPreviewSrc('state_controller'),
        width: 9,
        height: 5,
        widgetFormRenderer: StateControllerWidgetFormWrapper,
      } as DeviceWidgetMetaType<StateControllerWidgetFormType>;

    case 'scatter_chart':
      return {
        id: 'scatter_chart',
        type: 'monitoring',
        name: 'Scatter Chart',
        description: 'Scatter plot showing device events over time',
        widgetPreviewSrc: getWidgetPreviewSrc('scatter_chart'),
        width: 25,
        height: 9,
        widgetFormRenderer: ScatterChartWidgetFormWrapper,
      } as DeviceWidgetMetaType<ScatterChartWidgetFormType>;
  }
}

export const ALL_WIDGETS_META = DEVICE_WIDGET_NAMES.map(getDeviceWidgetMeta);
