import {
  Button,
  Center,
  Group,
  Modal,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { ServerError } from '@portals/api';
import {
  DeviceModelSubModel,
  useCreateDeviceModelSubModel,
  useUpdateDeviceModelSubModel,
} from '@portals/api/partners';
import { ImageSelectorField } from '@portals/autoformik';
import { ModalProps } from '@portals/framework';
import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';

export interface ConfigureSubModelModalProps
  extends ModalProps<{
    deviceModelId: string;
    subModel: DeviceModelSubModel | undefined;
  }> {}

export function ConfigureSubModelModal({
  closeMe,
  data: { deviceModelId, subModel },
}: ConfigureSubModelModalProps) {
  const createSubModel = useCreateDeviceModelSubModel();
  const updateSubModel = useUpdateDeviceModelSubModel();

  const isEdit = subModel !== undefined;
  const isLoading = createSubModel.isLoading || updateSubModel.isLoading;

  const form = useForm({
    initialValues: {
      name: subModel?.name || '',
      imageUrl: subModel?.image_url || '',
    },
  });

  function handleError({ error }: ServerError) {
    form.setFieldError('name', error);
  }

  function onSubmit(values: typeof form.values) {
    if (isEdit) {
      updateSubModel.mutate(
        {
          deviceModelId,
          subModelId: subModel.id,
          name: values.name,
          imageUrl: values.imageUrl || null,
        },
        { onSuccess: closeMe, onError: handleError }
      );
    } else {
      createSubModel.mutate(
        {
          deviceModelId,
          name: values.name,
          imageUrl: values.imageUrl,
        },
        { onSuccess: closeMe, onError: handleError }
      );
    }
  }

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title={subModel ? 'Edit sub-model' : 'New sub-model'}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Text>
          Add an image and name to the sub-model. The sub-model name should be
          identical to the name as reported by a device. Devices which belong to
          a sub-model not listed here will show the parent model image.
        </Text>

        <Center py="xxl">
          <ImageSelectorField
            isRemovable
            horizontal={false}
            value={form.values.imageUrl}
            setFieldValue={(_, value: string) =>
              form.setFieldValue('imageUrl', value)
            }
            field={{
              name: 'imageUrl',
              height: 150,
              width: 150,
              previewConfig: {
                height: 150,
                width: 150,
                radius: 'lg',
              },
              cropConfig: {
                aspectRatio: 1,
                width: 560,
                height: 560,
              },
            }}
          />
        </Center>

        <TextInput
          mb="md"
          required
          withAsterisk={false}
          data-autofocus
          placeholder="Sub-model name"
          {...form.getInputProps('name')}
          label={
            <Group spacing="xs">
              <Text>Sub-model name</Text>

              <Tooltip
                multiline
                width={260}
                label="Make sure the sub-model name is identical to the name as reported by the device."
              >
                <InfoCircleIcon width={18} height={18} />
              </Tooltip>
            </Group>
          }
        />

        <Group position="right" pt="xxl">
          <Button size="md" onClick={closeMe} variant="default">
            Cancel
          </Button>
          <Button size="md" type="submit" loading={isLoading}>
            {isEdit ? 'Save' : 'Add'}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
