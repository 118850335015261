import { ActionIcon, Badge, Grid, Group, TextInput } from '@mantine/core';
import { isString } from 'lodash/fp';
import React, { FC } from 'react';

import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';

import { isDoubleValue } from './mapping';
import PredicateInput from './PredicateInput';
import TypeInput from './TypeInput';
import { ValueInput } from './ValueInput';

const trimString = (value) => (isString(value) ? value.trim() : value);

type Line = {
  key?: string;
  type?: string;
  predicate?: string | null;
  value?: string | null;
  value2?: string | null;
};

type RuleLineProps = {
  line: Line;
  first: boolean;
  type: string;
  handleUpdate: (updatedLine: Line) => void;
  removeLine: () => void;
  readOnly: boolean;
};

const RuleLine: FC<RuleLineProps> = ({
  line = {},
  first,
  type,
  handleUpdate,
  removeLine,
  readOnly,
}) => {
  const setKey = (key: string) => handleUpdate({ ...line, key: key.trim() });
  const setType = (type: string) =>
    handleUpdate({ ...line, type, value: null, value2: null, predicate: null });
  const setPredicate = (predicate: string) =>
    handleUpdate({ ...line, predicate, value: null, value2: null });
  const setValue = (value: string) =>
    handleUpdate({ ...line, value: trimString(value) });
  const setValue2 = (value2: string) =>
    handleUpdate({ ...line, value2: trimString(value2) });

  const doubleValue = isDoubleValue(line.predicate);

  return (
    <>
      {!first ? (
        <Group>
          <Badge variant="outline" size="lg" radius="sm">
            {type === 'all' ? 'AND' : 'OR'}
          </Badge>
        </Group>
      ) : null}

      <Grid columns={16}>
        <Grid.Col span={3}>
          <TextInput
            id="key"
            name="key"
            data-testid={`telemetry-key-${type}`}
            required
            label="Telemetry Key"
            disabled={readOnly}
            value={line.key || ''}
            onChange={(e) => setKey(e.target.value)}
          />
        </Grid.Col>

        <Grid.Col span={3}>
          {line.key ? (
            <TypeInput
              value={line.type}
              setValue={setType}
              disabled={readOnly}
            />
          ) : null}
        </Grid.Col>

        <Grid.Col span={3}>
          {line.type ? (
            <PredicateInput
              type={line.type}
              value={line.predicate}
              setValue={setPredicate}
              disabled={readOnly}
            />
          ) : null}
        </Grid.Col>

        <Grid.Col span={3}>
          {line.predicate ? (
            <ValueInput
              label={doubleValue ? 'From' : 'Value'}
              type={line.type}
              value={line.value}
              setValue={setValue}
              disabled={readOnly}
            />
          ) : null}
        </Grid.Col>

        <Grid.Col span={3}>
          {doubleValue ? (
            <ValueInput
              label="To"
              type={line.type}
              // @ts-ignore
              from={line.value}
              value={line.value2}
              setValue={setValue2}
              disabled={readOnly}
            />
          ) : null}
        </Grid.Col>

        <Grid.Col span={1}>
          <Group
            align="center"
            position="center"
            sx={{ height: '100%', paddingTop: 25 }}
          >
            <ActionIcon
              onClick={removeLine}
              color="red"
              p={3}
              disabled={readOnly}
            >
              <Trash />
            </ActionIcon>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default RuleLine;
