import { AvailableInvitedPartnerResponse } from '@portals/api/partners';

export enum StepIdEnum {
  SelectPartner = 'selectPartner',
  ExistingPartnerType = 'existingPartnerType',
  InviteNewPartner = 'inviteNewPartner',
  NewPartnerType = 'newPartnerType',
}

export interface PartnerOptionType {
  value: string;
  label: string;
  name: string;
  logo: AvailableInvitedPartnerResponse['logo'];
}

export interface InvitePartnerWizardContextDataType {
  partners: Array<PartnerOptionType>;
  selectedPartner: PartnerOptionType | null;

  onSelectExistingPartner: (
    selectedPartner: PartnerOptionType['value'] | null
  ) => void;
  onSetNewPartnerEmail: (email: string) => void;

  onInviteExisting: (certified: boolean) => void;
  isLoading: boolean;

  closeMe: () => void;

  onInviteNewPartner: (certified: boolean) => void;
  newPartnerEmail?: string;
  isInvitingNewPartner: boolean;
  toggleInviteNewPartner: () => void;

  selectedRelationshipType: RelationshipTypeEnum | null;
  onChangeRelationshipType: (relationshipType: RelationshipTypeEnum) => void;

  otherRelationshipType: string;
  setOtherRelationshipType: (value: string) => void;
}

export enum RelationshipTypeEnum {
  Manufacturer = 'Manufacturer',
  Integrator = 'Integrator',
  Distributor = 'Distributor',
  Reseller = 'Reseller',
  Other = 'Other',
}
