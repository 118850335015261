import { isEmpty } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { Col, ListGroup, ListGroupItem, Modal, Row } from 'reactstrap';

import { JsonBlob } from '@portals/ui';
import { timeAgo } from '@portals/utils';

const DeviceTelemetries = ({ data, closeMe }) => {
  const [selected, setSelected] = useState(0);
  const { telemetries } = data;

  // Auto select the first telemetry everything telemetries data changes
  useEffect(() => {
    setSelected(0);
  }, [data]);

  if (typeof telemetries === 'undefined') {
    return 'Fetching telemetries...';
  }

  if (isEmpty(telemetries)) {
    return 'No telemetry';
  }

  return (
    <Modal className="p-3" isOpen={true} toggle={closeMe} centered>
      <div className="p-3">
        <h3>Telemetries</h3>
        <h5 className="text-muted">last 5 telemetries available</h5>
        <Row>
          <Col sm={2} className="border-right mr-3">
            <ListGroup flush>
              {telemetries.map(({ id, timestamp }, index) => (
                <ListGroupItem
                  tag="a"
                  key={id}
                  onClick={() => setSelected(index)}
                  action
                  active={index === selected}
                >
                  {id}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col className="overflow-auto">
            <div className="property-wrapper">
              <h5>Created at</h5>
              <div className="mb-3">
                {telemetries[selected].timestamp}{' '}
                <b>{timeAgo(Date.parse(telemetries[selected].created_at))}</b>
              </div>

              <h5>Device timestamp</h5>
              <div className="mb-3">
                {telemetries[selected].timestamp}{' '}
                <b>
                  {timeAgo(Date.parse(telemetries[selected].device_timestamp))}
                </b>
              </div>

              <h5>Telemetries</h5>
              <JsonBlob json={telemetries[selected].common} />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default DeviceTelemetries;
