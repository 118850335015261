import { Modal, Text } from '@mantine/core';
import { get, toNumber, toString } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';

import { AutoFormik } from '@portals/autoformik';
import { RuleType, FieldType, FieldTypeEnum } from '@portals/types';
import { PRIORITY_NAMES } from '@portals/utils';

import { updateRule } from '../../redux/actions/rules';

const FIELDS: Array<FieldType> = [
  {
    name: 'active',
    title: 'Active',
    required: true,
    default: true,
    type: FieldTypeEnum.Checkbox,
  },
  { name: 'name', title: 'Name', required: true, default: '' },
  {
    name: 'description',
    title: 'Description',
    required: true,
    type: FieldTypeEnum.Textarea,
    default: '',
    inputProps: {
      minRows: 3,
      maxRows: 3,
    },
  },
  {
    name: 'priority',
    title: 'Priority',
    required: true,
    type: 'select',
    default: '3',
    options: PRIORITY_NAMES,
    parse: (v) => toNumber(v),
  },
];

const EditRule = ({ data, inProgress, closeMe, updateRule }) => {
  const rule: RuleType = data;

  const handleSubmit = (data) => {
    updateRule(rule.id, data, closeMe);
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={
        <div>
          <Text span>Update Rule </Text>
          <Text span color="dimmed">
            to {rule.name}
          </Text>
        </div>
      }
    >
      <AutoFormik
        fields={FIELDS}
        initialValues={{
          ...rule,
          priority: toString(rule.priority),
        }}
        inProgress={inProgress}
        handleSubmit={handleSubmit}
        modal={closeMe}
        submitTitle="Update"
      />
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => ({
  inProgress: get(['ui', 'network', `addRule_${ownProps.data.id}`], state),
});

export default connect(mapStateToProps, { updateRule })(EditRule);
