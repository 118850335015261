import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ReactComponent as TickCircle } from '@portals/icons/bulk/tick-circle.svg';
import { mediumDateFormatter } from '@portals/utils';

interface SubscriptionCancellationSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCancelledImmediately: boolean;
  nextChargeAt: string;
}

export function SubscriptionCancellationSuccessModal({
  isOpen,
  onClose,
  isCancelledImmediately,
  nextChargeAt,
}: SubscriptionCancellationSuccessModalProps) {
  const theme = useMantineTheme();

  let title;

  if (isCancelledImmediately) {
    title = <Text size={24}>The subscription was canceled successfully</Text>;
  } else {
    title = (
      <Stack spacing="sm" data-testid="suscription-cancellation-success-stack">
        <Text size={24}>The subscription will be canceled on</Text>
        <Text
          size="xl"
          weight={700}
          data-testid="subscription-cancellation-date"
        >
          {mediumDateFormatter(nextChargeAt)}
        </Text>
      </Stack>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened={isOpen}
      withCloseButton={false}
      onClose={onClose}
      media={<TickCircle color={theme.colors.blue[2]} />}
      title={title}
      footer={
        <Button
          fullWidth
          onClick={onClose}
          data-testid="subscription-cancellation-success-done-button"
        >
          Done
        </Button>
      }
    >
      <Stack spacing="xl">
        <Text color="gray.7" ta="center">
          Customer will receive an email confirming the cancellation.
        </Text>
      </Stack>
    </ModalCenteredMediaLayout>
  );
}
