import { Button, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { connect } from 'react-redux';

import { useUpdateDevice } from '@portals/api/partners';
import { ModalFooter, ModalBody } from '@portals/core';
import { toastrSuccess } from '@portals/redux/actions/toastr';

const DeviceNote = ({ closeMe, data }) => {
  const { device } = data;
  const { mutateAsync } = useUpdateDevice(device.id);

  const form = useForm({
    initialValues: {
      noteText: device.note || '',
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    try {
      await mutateAsync({
        partner_note: values.noteText,
      });
      closeMe();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal onClose={closeMe} opened title="Notes">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ModalBody>
          <Textarea w="100%" minRows={20} {...form.getInputProps('noteText')} />
        </ModalBody>

        <ModalFooter position="right">
          <Button onClick={closeMe} variant="default">
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default connect(null, { toastrSuccess })(DeviceNote);
