import { Stack, Switch, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { ValueWidgetFormType } from './value-form.types';
import { InputLabelWithTooltip } from '../../common/input-helpers';

export interface ValueWidgetFormProps {
  form: UseFormReturnType<ValueWidgetFormType>;
}

export function ValueWidgetForm({ form }: ValueWidgetFormProps) {
  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          autoFocus
          data-testid="value-title-name-input"
          data-autofocus
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}

        <TextInput
          {...form.getInputProps('telemetry')}
          withAsterisk={false}
          data-testid="telemetry-key-input"
          label={
            <InputLabelWithTooltip
              label="Telemetry Key"
              Icon={QuestionCircle}
              tooltipLabel="The key of telemetry value that will be displayed in the widget"
            />
          }
          required
        />
      </Stack>

      <motion.div layout="position">
        <Stack>
          <Switch
            data-testid="track-measurement-unit-radio-button"
            label="Track Measurement Unit"
            {...form.getInputProps('with_measurement_unit', {
              type: 'checkbox',
            })}
          />

          {form.values.with_measurement_unit ? (
            <TextInput
              {...form.getInputProps('measurement_unit')}
              data-testid="measurement-unit-input"
              required
              width={50}
            />
          ) : null}
        </Stack>
      </motion.div>
    </Stack>
  );
}
