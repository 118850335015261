import { Box, Title } from '@mantine/core';
import React from 'react';

import { ProductTypeEnum } from '@portals/types';

import { CreateModeNavigation } from './CreateModeNavigation';
import { EditModeNavigation } from './EditModeNavigation';
import { ProductWizardContext } from '../types';
import { useProductWizard } from '../use-product-wizard';

interface NavigationColumnProps {
  isEdit: boolean;
}

export function NavigationColumn({ isEdit }: NavigationColumnProps) {
  const { contextData } = useProductWizard();

  const title = getTitle(contextData.product?.product_type);

  return (
    <Box p={30}>
      <Title order={3} weight={500} mb={44}>
        {title}
      </Title>

      {isEdit ? <EditModeNavigation /> : <CreateModeNavigation />}
    </Box>
  );
}

function getTitle(
  productType: ProductWizardContext['product']['product_type']
) {
  switch (productType) {
    case ProductTypeEnum.Physical:
      return 'Physical Product';
    case ProductTypeEnum.DeviceLicense:
      return 'Device license';
    case ProductTypeEnum.PlatformLicense:
      return 'Platform License';
    default:
      return 'TBD';
  }
}
