import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';

import { PricesDetailsStep } from './prices-details-step/PricesDetailsStep';
import { ProductDetailsStep } from './product-details-step/ProductDetailsStep';
import {
  PublishProductsStepIds,
  PublishProductsWizardContextDataType,
} from './types';
import { checkIsMissingPrices, checkIsMissingFields } from './utils';

export const StepsMap: WizardSteps<
  PublishProductsWizardContextDataType,
  PublishProductsStepIds
> = {
  [PublishProductsStepIds.PricesDetails]: {
    id: PublishProductsStepIds.PricesDetails,
    isFinalStep: false,
    component: <PricesDetailsStep />,
    [WizardTransitionType.Next]: {
      transition: { id: PublishProductsStepIds.ProductDetails },
      isDisabled: ({ contextData }) => {
        // Disable next step transition if there are missing prices
        return (
          !contextData.products ||
          contextData.products.some((product) => {
            const productStoreListingPrices =
              contextData.storeListingsMap?.[product.id]?.prices;

            return checkIsMissingPrices(product, productStoreListingPrices);
          })
        );
      },
    },
  },
  [PublishProductsStepIds.ProductDetails]: {
    id: PublishProductsStepIds.ProductDetails,
    isFinalStep: true,
    component: <ProductDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: PublishProductsStepIds.PricesDetails },
    },
    [WizardTransitionType.Next]: {
      isDisabled: ({ contextData }) => {
        return (
          !contextData.products ||
          contextData.products.some(checkIsMissingFields)
        );
      },
    },
  },
};
