import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { RouteModalLink } from '@portals/framework/route-modals';
import { AuditLogType } from '@portals/types';

interface OfflineOrderCreatedProps {
  auditLog: AuditLogType;
}

export function OfflineOrderCreated({ auditLog }: OfflineOrderCreatedProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const orderParam = paramsMap['order'];

  return (
    <Text size="sm">
      Offline Order:{' '}
      <RouteModalLink modalId="offline-order" pathParams={[orderParam?.id]}>
        {orderParam?.display_name}
      </RouteModalLink>{' '}
      created
    </Text>
  );
}
