import {
  Box,
  createStyles,
  Flex,
  Group,
  Image,
  ImageProps,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { find } from 'lodash/fp';
import React from 'react';

import { OrderType } from '@portals/api/partners';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { ReactComponent as Key } from '@portals/icons/linear/key.svg';
import { ProductTypeEnum } from '@portals/types';

import { ShipmentFormType } from './create-shipment.types';

interface ProductsSerialNumbersListProps {
  form: UseFormReturnType<ShipmentFormType>;
  order: OrderType;
}

export function ProductsSerialNumbersList({
  form,
  order,
}: ProductsSerialNumbersListProps) {
  const { classes, theme } = useStyles();

  return (
    <Stack spacing={0} pos="relative">
      <SimpleGrid cols={2} spacing={0}>
        <Flex className={classes.cell} align="center">
          Item
        </Flex>

        <Flex className={classes.cell} align="center" color="gray.9">
          <Key width={18} height={18} />

          <Box ml="xs">Serial Number</Box>
        </Flex>
      </SimpleGrid>

      {form.values.items.map(({ orderItemId }, index) => {
        const item = find({ id: orderItemId }, order?.items);

        // Device license product are auto-connected to org, do not require a serial number
        if (
          !item ||
          item?.product?.product_type === ProductTypeEnum.DeviceLicense
        )
          return null;

        return (
          <SimpleGrid cols={2} key={index} spacing={0} className={classes.row}>
            <Flex className={classes.cell} align="center">
              <Group
                align="center"
                spacing="sm"
                noWrap
                sx={{ overflow: 'hidden' }}
              >
                <Image
                  withPlaceholder
                  src={item.product.image_url}
                  styles={imageStyles}
                  radius="md"
                  placeholder={
                    <Gallery
                      width="50%"
                      height="50%"
                      color={theme.colors.blue_gray[2]}
                    />
                  }
                />

                <Text
                  truncate
                  title={item.product.name}
                  data-testid={`order-shipment-item-${index}-name`}
                >
                  {item.product.name}
                </Text>
              </Group>
            </Flex>

            <Flex className={classes.cell} align="center" p={0}>
              <TextInput
                autoFocus={index === 0}
                data-autofocus={index === 0}
                w="100%"
                styles={textInputStyles}
                placeholder="Enter serial number"
                data-testid={`order-shipment-item-${index}-serial-number-input`}
                {...form.getInputProps(`items.${index}.serialNumber`)}
              />
            </Flex>
          </SimpleGrid>
        );
      })}
    </Stack>
  );
}

const textInputStyles: TextInputProps['styles'] = (theme) => ({
  root: {
    height: '100%',
  },
  wrapper: {
    height: '100%',
  },
  input: {
    height: '100%',
    border: 'none',
    borderRadius: 0,

    '&:focus': {
      border: `1px solid ${theme.colors.blue_accent[4]}`,
      background: theme.white,
      boxShadow: '1px 2px 5px 0px rgba(0, 0, 0, 0.46)',
    },
  },
});

const imageStyles: ImageProps['styles'] = {
  root: {
    width: '34px !important',
    height: '34px !important',
  },
  figure: { height: 34, width: 34 },
  imageWrapper: { height: 34, width: 34 },
};

const useStyles = createStyles((theme) => ({
  cell: {
    backgroundColor: theme.colors.gray[0],
    paddingInline: theme.spacing.md,
    height: 50,

    '&:first-of-type': {
      borderRight: `1px solid ${theme.colors.gray[3]}`,
    },
  },
  row: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,

    '&:last-of-type': {
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
  },
}));
