import { Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';

import { AuditLogType } from '@portals/types';

interface PartnerRejectedInvitationProps {
  auditLog: AuditLogType;
}

export function PartnerRejectedInvitation({
  auditLog,
}: PartnerRejectedInvitationProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const partnerParam = paramsMap['partner'];

  return (
    <Text size="sm">
      Rejected invitation from:{' '}
      <Text span weight={700}>
        {partnerParam.display_name}
      </Text>
    </Text>
  );
}
