import { some } from 'lodash/fp';

import { ProductWizardContext, StepId } from './types';

export function getFirstStepWithErrors(
  errors: ProductWizardContext['errors']
): StepId | null {
  if (isStepHasErrors(StepId.Integration, errors)) {
    return StepId.Integration;
  }

  if (isStepHasErrors(StepId.GeneralInformation, errors)) {
    return StepId.GeneralInformation;
  }

  if (isStepHasErrors(StepId.ProductDetails, errors)) {
    return StepId.ProductDetails;
  }

  if (isStepHasErrors(StepId.PricingModel, errors)) {
    return StepId.PricingModel;
  }

  if (isStepHasErrors(StepId.TaxDetails, errors)) {
    return StepId.TaxDetails;
  }

  return null;
}

export function isStepHasErrors(
  stepId: StepId,
  errors: ProductWizardContext['errors']
) {
  switch (stepId) {
    case StepId.Integration:
      return Boolean(errors[StepId.Integration]);
    case StepId.GeneralInformation:
      return some(Boolean, errors[StepId.GeneralInformation]);
    case StepId.ProductDetails:
      return (
        Boolean(errors[StepId.ProductDetails].licenseData) ||
        Boolean(errors[StepId.ProductDetails].supportedCommandIds)
      );
    case StepId.PricingModel:
      return some(Boolean, errors[StepId.PricingModel]);
    case StepId.TaxDetails:
      return Boolean(errors[StepId.TaxDetails]);
    default:
      return false;
  }
}
