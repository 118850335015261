export const MONITORING_API_URL = 'ui/partner/monitoring';
export const MONITORING_CUSTOMERS_API_URL = `${MONITORING_API_URL}/customers`;
export const MONITORING_KPIS_API_URL = `${MONITORING_API_URL}/kpis`;

export const monitoringQueryKeys = {
  base: [MONITORING_API_URL],
  customers: {
    all: () => [...monitoringQueryKeys.base, 'customers'],
    withLabs: () => [...monitoringQueryKeys.customers.all(), 'withLabs'],
    withoutLabs: () => [...monitoringQueryKeys.customers.all(), 'withoutLabs'],
    cecPartners: () => [...monitoringQueryKeys.customers.all(), 'cecPartners'],
  },
  kpis: {
    all: () => [...monitoringQueryKeys.base, 'kpis'],
    withLabs: () => [...monitoringQueryKeys.kpis.all(), 'withLabs'],
    withoutLabs: () => [...monitoringQueryKeys.kpis.all(), 'withoutLabs'],
    byCustomerId: (customerId: string) => [
      ...monitoringQueryKeys.base,
      'customerCoverAndKpis',
      customerId,
    ],
  },
};
