import { Group, Select, Stack, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { PartnerFileResponseType } from '@portals/types';

type FilesListProps = {
  files: Array<PartnerFileResponseType>;
  selected?: string;
  handleSelected?: (selected: string) => void;
};

const FilesList: FC<FilesListProps> = ({ files, selected, handleSelected }) => {
  const publicNotes = useMemo(() => {
    const file = find({ id: selected }, files);

    return file?.public_notes;
  }, [files, selected]);

  return (
    <Stack>
      {publicNotes ? (
        <Stack spacing="xs">
          <Text color="blue_gray">
            <Group align="center" spacing={5}>
              <InfoCircle width={14} height={14} />
              <Text inline size="xs" weight={600}>
                Manufacturer's note:
              </Text>
            </Group>
          </Text>

          <Text size="xs" color="blue_gray">
            {publicNotes}
          </Text>
        </Stack>
      ) : null}

      <Select
        id="file"
        name="file"
        value={selected}
        onChange={handleSelected}
        placeholder="-- select a file --"
        data={files.map(({ id, name, desc, version }) => ({
          value: id,
          label: `${name} ${desc ? `(${desc})` : ''} ${version || ''}`,
        }))}
      />
    </Stack>
  );
};

export default FilesList;
