import {
  ActionIcon,
  createStyles,
  Divider,
  Group,
  Indicator,
  IndicatorProps,
  Popover,
  PopoverProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as InfoCircleIcon } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as NotificationIcon } from '@portals/icons/linear/notification.svg';
import { getNotifications } from '@portals/redux';

import { HUBS_HEADER_TOOLTIP_OFFSET } from './common-styles';

export const SystemNotifications = forwardRef<HTMLButtonElement>((_, ref) => {
  const { classes, theme } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const notifications = useSelector(getNotifications);
  const numOfNotifications = notifications?.length ?? 0;

  return (
    <Popover
      opened={isOpen}
      withinPortal
      trapFocus
      radius="lg"
      width={320}
      position="bottom-end"
      shadow="md"
      offset={HUBS_HEADER_TOOLTIP_OFFSET}
      onChange={setIsOpen}
      styles={popoverStyles}
    >
      <Popover.Target>
        <ActionIcon
          ref={ref}
          variant="transparent"
          size="lg"
          color={isOpen ? 'indigo_accent.3' : 'gray.4'}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Indicator
            size={22}
            label={numOfNotifications}
            disabled={!numOfNotifications}
            withBorder
            offset={4}
            color="red_accent.4"
            styles={indicatorStyles}
          >
            <NotificationIcon />
          </Indicator>
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown>
        <header className={classes.header}>
          {numOfNotifications} System notifications
        </header>

        {numOfNotifications > 0 && <Divider />}

        <div>
          {notifications.map((notification) => (
            <Link
              key={notification.id}
              to={{ pathname: notification.link }}
              onClick={() => setIsOpen(false)}
              className={classes.notificationLink}
            >
              <Group noWrap>
                <InfoCircleIcon
                  width={18}
                  height={18}
                  color={theme.colors.red[4]}
                />

                <Stack spacing="xs">
                  {notification.title && <Text>{notification.title}</Text>}

                  <Text color="dimmed">{notification.message}</Text>
                </Stack>
              </Group>
            </Link>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
});

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.sm,
    textAlign: 'center',
    fontWeight: 600,
  },
  notificationLink: {
    display: 'block',
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.lg,
    color: 'inherit',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },

    '&:hover': {
      color: 'inherit',
      backgroundColor: theme.colors.gray[2],
      textDecoration: 'none',
    },
  },
}));

const popoverStyles: PopoverProps['styles'] = (theme) => ({
  dropdown: {
    padding: 0,
    backgroundColor: theme.white,
  },
});

const indicatorStyles: IndicatorProps['styles'] = (theme) => ({
  indicator: {
    borderColor: theme.colors.midnight_blue_accent[4],
  },
});
