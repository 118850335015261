import { createStyles, getStylesRef, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { useFeatureFlags } from '@portals/api/partners';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { PartnerFeatureFlagsType, ProductTypeEnum } from '@portals/types';

import { isStepHasErrors } from '../product-wizard.utils';
import { ProductWizardContext, StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export function EditModeNavigation() {
  const featureFlags = useFeatureFlags();

  const { classes, cx } = useStyles();

  const { contextData, skipToStep, currentStep } = useProductWizard();

  const navItems = composeNavItems(contextData, featureFlags);

  const onClick = async (stepId: StepId) => {
    await skipToStep(stepId, {
      runTransitionHandler: true,
      forceSkip: true,
    });
  };

  return (
    <Stack spacing="xs">
      {navItems.map(({ stepId, label, hasError }) => (
        <Group
          key={stepId}
          onClick={() => onClick(stepId)}
          position="apart"
          className={cx(classes.navItem, {
            [classes.active]: currentStep.id === stepId,
            [classes.error]: hasError,
          })}
        >
          <Text size="sm" inherit data-testid={`navigation-item-${stepId}`}>
            {label}
          </Text>
          {hasError && <Danger width={18} height={18} />}
        </Group>
      ))}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  navItem: {
    height: 40,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.sm,
    borderRadius: theme.radius.xl,
    color: theme.colors.blue_gray[7],
    cursor: 'pointer',
    userSelect: 'none',

    [`:hover:not(.${getStylesRef('active')}):not(.${getStylesRef('error')})`]: {
      color: theme.colors.blue_gray[9],
      backgroundColor: theme.colors.gray[0],
    },
  },
  active: {
    ref: getStylesRef('active'),
    color: theme.fn.primaryColor(),
    backgroundColor: theme.colors.primary[1],

    [`&.${getStylesRef('error')}`]: {
      color: theme.colors.red_accent[4],
      backgroundColor: theme.fn.rgba(theme.colors.red[0], 0.5),
    },
  },
  error: {
    ref: getStylesRef('error'),
    color: theme.colors.red_accent[4],

    ':hover': {
      backgroundColor: theme.fn.rgba(theme.colors.red[0], 0.5),
    },
  },
}));

function composeNavItems(
  contextData: ProductWizardContext,
  featureFlags: PartnerFeatureFlagsType | undefined
) {
  const { product, errors } = contextData;

  const result: Array<{ stepId: StepId; label: string; hasError: boolean }> =
    [];

  if (product.product_type !== ProductTypeEnum.PlatformLicense) {
    result.push({
      stepId: StepId.Integration,
      label: 'Product Type',
      hasError: isStepHasErrors(StepId.ProductType, errors),
    });
  }

  result.push({
    stepId: StepId.GeneralInformation,
    label: 'General Information',
    hasError: isStepHasErrors(StepId.GeneralInformation, errors),
  });

  if (
    product.device_model_id ||
    product.product_type === ProductTypeEnum.PlatformLicense
  ) {
    result.push({
      stepId: StepId.ProductDetails,
      label: 'Product Details',
      hasError: isStepHasErrors(StepId.ProductDetails, errors),
    });
  }

  result.push({
    stepId: StepId.PricingModel,
    label: 'Pricing Model',
    hasError: isStepHasErrors(StepId.PricingModel, errors),
  });

  result.push({
    stepId: StepId.TaxDetails,
    label: 'Tax Details',
    hasError: isStepHasErrors(StepId.TaxDetails, errors),
  });

  if (
    isPartnerFeatureOn(featureFlags, 'ppp') &&
    product.product_type === ProductTypeEnum.Physical
  ) {
    result.push({
      stepId: StepId.PostPurchaseParameters,
      label: 'Post Purchase Parameters',
      hasError: false,
    });
  }

  return result;
}
