import { ActionIcon, createStyles, Tooltip } from '@mantine/core';
import React from 'react';

import { useIsChannelPartner } from '@portals/api/partners';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';

export function NavbarContactSupport() {
  const { classes } = useStyles();
  const isChannelPartner = useIsChannelPartner();

  if (isChannelPartner) return null;

  return (
    <Tooltip label="Contact support" withinPortal>
      <ActionIcon
        component="a"
        href="mailto:support@xyte.io"
        variant="transparent"
        size="lg"
        target="_blank"
        className={classes.actionIcon}
      >
        <Lifebuoy />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  actionIcon: {
    color: theme.colors.blue_gray[6],

    '&:hover': {
      color: theme.colors.blue_gray[6],
    },
  },
}));
