import barControllerSrc from './bar-controller.svg';
import barIndicatorSrc from './bar-indicator.svg';
import buttonSrc from './button.svg';
import deviceLocationSrc from './device-location.png';
import gaugeIndicatorSrc from './gauge-indicator.svg';
import groupedSwitchesSrc from './grouped-switches.svg';
import numericMetricSrc from './numeric-metric.svg';
import scatterChartSrc from './scatter-chart.svg';
import sectionHeaderSrc from './section-header.svg';
import splineChartSrc from './spline-chart.svg';
import stateControllerSrc from './state-controller.svg';
import statusSrc from './status.svg';
import toggleSrc from './toggle.svg';
import valueSrc from './value.png';
import { DeviceWidgetNameType } from '../device-widgets.types';

export function getWidgetPreviewSrc(deviceWidgetName: DeviceWidgetNameType) {
  switch (deviceWidgetName) {
    case 'status':
      return statusSrc;

    case 'numeric_metric':
      return numericMetricSrc;

    case 'spline_chart':
      return splineChartSrc;

    case 'bar_indicator':
      return barIndicatorSrc;

    case 'gauge_indicator':
      return gaugeIndicatorSrc;

    case 'toggle':
      return toggleSrc;

    case 'button':
      return buttonSrc;

    case 'device_location':
      return deviceLocationSrc;

    case 'grouped_switches':
      return groupedSwitchesSrc;

    case 'bar_controller':
      return barControllerSrc;

    case 'section_header':
      return sectionHeaderSrc;

    case 'state_controller':
      return stateControllerSrc;

    case 'scatter_chart':
      return scatterChartSrc;

    case 'value':
    default:
      return valueSrc;
  }
}
