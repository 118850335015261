import React from 'react';

import { RouteOverlayLink, RouteOverlayLinkProps } from '../route-overlays';

interface RoutePanelLinkProps
  extends Omit<RouteOverlayLinkProps, 'overlayType' | 'entityId'> {
  panelId: RouteOverlayLinkProps['entityId'];
}

export function RoutePanelLink({ panelId, ...rest }: RoutePanelLinkProps) {
  return <RouteOverlayLink overlayType="panel" entityId={panelId} {...rest} />;
}
