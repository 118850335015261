import { LoadingOverlay } from '@mantine/core';
import { flow, forEach, set } from 'lodash/fp';
import React, { useEffect, useMemo, useState } from 'react';

import { usePublishedSupportedCommand } from '@portals/api/partners';
import { SupportedCommandType } from '@portals/types';

import {
  ItemRenderer,
  TransferableItemsTitleRenderer,
  TransferredItemsTitleRenderer,
} from './transfer-list/transfer-list-renderers';
import { useTransferList } from './transfer-list/transfer-list.hooks';
import { TransferList } from './transfer-list/TransferList';
import { DetailsColumnContent } from '../common/DetailsColumnContent';
import { StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export function PlatformLicenseProductDetailsWrapper() {
  const publishedSupportedCommand = usePublishedSupportedCommand();

  if (!publishedSupportedCommand.data) {
    return (
      <DetailsColumnContent title="Product Details">
        <LoadingOverlay visible />
      </DetailsColumnContent>
    );
  }

  return <PlatformLicenseProductDetails />;
}

function PlatformLicenseProductDetails() {
  const [searchTerm, setSearchTerm] = useState('');

  const publishedSupportedCommand = usePublishedSupportedCommand();
  const { contextData, setContextData } = useProductWizard();

  // Create the initial list of left & right side of transfer list
  const { initialTransferableItems, initialTransferredItems } = useMemo(() => {
    if (!contextData.product?.supported_command_ids?.length) {
      return {
        initialTransferableItems: publishedSupportedCommand.data,
        initialTransferredItems: [],
      };
    }

    const initialTransferableItems = [];
    const initialTransferredItems = [];

    forEach((item) => {
      if (contextData.product?.supported_command_ids?.includes(item.id)) {
        initialTransferredItems.push(item);
      } else {
        initialTransferableItems.push(item);
      }
    }, publishedSupportedCommand.data);

    return {
      initialTransferableItems,
      initialTransferredItems,
    };
  }, [
    contextData.product?.supported_command_ids,
    publishedSupportedCommand.data,
  ]);

  const transferList = useTransferList<SupportedCommandType>(
    initialTransferableItems,
    initialTransferredItems
  );

  useEffect(
    function updateWizardProductPayload() {
      const supportedCommandIds = transferList.transferredItems.map(
        (item) => item.id
      );

      setContextData((curr) =>
        flow([
          set(['product', 'supported_command_ids'], supportedCommandIds),
          // Reset errors on transfer to either side
          set(['errors', StepId.ProductDetails, 'supportedCommandIds'], false),
        ])(curr)
      );
    },
    [setContextData, transferList.transferredItems]
  );

  return (
    <DetailsColumnContent title="Product Details">
      <LoadingOverlay visible={publishedSupportedCommand.isLoading} />

      <TransferList<SupportedCommandType>
        {...transferList}
        groupByKey="device_model_name"
        transferableItemsTitleRenderer={(transferableItemsCount) => (
          <TransferableItemsTitleRenderer
            transferableItemsCount={transferableItemsCount}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        )}
        transferredItemsTitleRenderer={(transferredItems) => (
          <TransferredItemsTitleRenderer transferredItems={transferredItems} />
        )}
        itemRenderer={(item) => <ItemRenderer item={item} />}
        searchTerm={searchTerm}
      />
    </DetailsColumnContent>
  );
}
