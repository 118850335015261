import { findIndex, forEach, get, isEmpty, noop, reduce, set } from 'lodash/fp';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  StoreListing,
  useNonePaginatedStoreListings,
  useStoreSettings,
} from '@portals/api/partners';
import { CurrencyCode, PaginatedFilterTypeEnum } from '@portals/types';
import { convertFromMajorToMinor } from '@portals/utils';

import { PricingTableType, StoreListingKeys } from './types';
import { getCellPricesList } from './utils';

interface PricingTableContextType {
  data: PricingTableType;
  onUpdate: (updateParams: OnUpdateParams) => void;
  storeListings: StoreListing[] | undefined;
}

const PricingTableContext = createContext<PricingTableContextType>({
  data: {},
  onUpdate: noop,
  storeListings: undefined,
});

interface PricingTableProviderProps {
  selectedCurrencies: Array<CurrencyCode>;
  children?: ReactNode;
}

interface OnUpdateParams {
  storeListingId: StoreListing['id'];
  currency: CurrencyCode;
  storeListingKey: StoreListingKeys;
  amount: number;
}

// Holds an object, with [storeListingID:currency:prices] relations
// {
//   [storeListing.id]: {
//     defaultCurrency: 'USD',
//     numOfAssignedPrices: 2, <--- used to decide whether a row has expanded row or not
//     pricesByCurrencies: {
//       USD: [
//          { key: 'monthly_price_in_scu', amount_in_scu: 10 },
//          { key: 'yearly_price_in_scu', amount_in_scu: 100 }
//       ]
//     }
// }
//
// Used for editing prices, before sending them to the server
// Iterates over existing currencies & newly added currencies together,
// filling blank values for new currencies (basing on existing ones)

//
export function PricingTableProvider({
  children,
  selectedCurrencies,
}: PricingTableProviderProps) {
  const storeSettings = useStoreSettings();
  const storeListings = useNonePaginatedStoreListings({
    filters: [
      { id: 'status', value: 'published', type: PaginatedFilterTypeEnum.Eq },
    ],
  });

  // Joined list of both existing currencies and new ones user trying to add.
  // Example: existing = ['USD']
  // Trying to add: ['JPY', 'RUB']
  // -> allCurrencies === ['USD', 'JPY', 'RUB']
  const allCurrencies = useMemo<Array<CurrencyCode>>(
    () => [...(storeSettings.data?.currencies ?? []), ...selectedCurrencies],
    [storeSettings.data, selectedCurrencies]
  );

  const initialPricingTable = useMemo<PricingTableType>(
    () =>
      // Iterating over `allCurrencies`, where the first ones are the existing ones, and then
      // the newly added ones
      reduce(
        (acc, storeListing) => {
          acc[storeListing.id] = {
            defaultCurrency: storeSettings.data?.default_currency,
            numOfAssignedPrices: 0,
            pricesByCurrencies: {},
          };

          // Assigns the prices list per row, per currency, which is later used by the ExpandRow
          // renderer: { 123: { USD: [{ key: 'one_time_fee', amount_in_scu: 1 }], ... } }
          forEach((currency) => {
            // Returns an array of prices for specific currency.
            // Example:
            //
            // 1. If store listing has only monthly price assigned:
            // [{ key: 'monthly_price_in_scu', amount_in_scu: 1000 }]
            //
            // 2. If store listing has both monthly & yearly prices assigned:
            // [
            //  { key: 'monthly_price_in_scu', amount_in_scu: 1000 },
            //  { key: 'yearly_price_in_scu', amount_in_scu: 10000 },
            // ]
            const currencyPricesList = getCellPricesList({
              prices: storeListing.prices,
              currency,
            });

            // `currencyPricesList` is not empty when iterating over existing currency. Meaning
            // the store listing already has an assigned price for this currency
            if (!isEmpty(currencyPricesList)) {
              // `numOfAssignedPrices` is used for deciding whether an expanded row is required (if
              // more than 1 price assigned)
              const numOfAssignedPrices =
                acc[storeListing.id].numOfAssignedPrices ||
                currencyPricesList.length;

              acc[storeListing.id] = {
                ...acc[storeListing.id],
                numOfAssignedPrices,
                pricesByCurrencies: {
                  ...acc[storeListing.id].pricesByCurrencies,
                  [currency]: currencyPricesList,
                },
              };

              return;
            }

            // If `currencyPricesList` is empty, it means that current `currency` we're
            // iteration over is newly added. Populating its prices with empty `amount_in_scu`
            if (isEmpty(currencyPricesList)) {
              const basePrices =
                acc[storeListing.id].pricesByCurrencies[
                  acc[storeListing.id].defaultCurrency
                ];

              acc[storeListing.id].pricesByCurrencies[currency] =
                basePrices.map(({ key }) => ({
                  key,
                  amount_in_scu: null,
                }));
            }
          }, allCurrencies);

          return acc;
        },
        {},
        storeListings.data
      ),
    [allCurrencies, storeListings.data, storeSettings.data?.default_currency]
  );

  const [pricingTable, setPricingTable] = useState(initialPricingTable);

  // Callback used by cells w/ prices input.
  // 1. Each cell sits inside a store listing row (storeListingId)
  // 2. Each cell sits inside a specific currency column (currency)
  // 3. Each cell represents a payment type: one time, monthly, yearly... (storeListingKey)
  // 4. Each cell passed the entered value onChang (amount)
  // +------------------+-----------+----------+
  // |  Product Name    |    USD    |   ILS    |
  // +------------------+-----------+----------+
  // | Store Listing 1  |   [$100]  | [NIS100] |
  // |-----------------------------------------|
  // | Store Listing 2  |           |          |
  // | - Monthly price  |   [$100]  | [NIS100] |
  // | - Yearly price   |   [$100]  | [NIS100] |
  // +------------------+-----------+----------+
  const onUpdate = useCallback(
    ({
      storeListingId,
      currency,
      storeListingKey,
      amount = 0,
    }: OnUpdateParams) => {
      const pricesByCurrency = get(
        [storeListingId, 'pricesByCurrencies', currency],
        pricingTable
      );
      const indexOfPriceObjectToUpdate = findIndex(
        { key: storeListingKey },
        pricesByCurrency
      );

      let updatedPrice =
        pricesByCurrency[indexOfPriceObjectToUpdate].amount_in_scu;

      try {
        updatedPrice = convertFromMajorToMinor(amount, currency);
      } catch (err) {
        console.error(err);
      }

      const updatedPricingTable = set(
        [
          storeListingId,
          'pricesByCurrencies',
          currency,
          indexOfPriceObjectToUpdate,
        ],
        {
          ...pricesByCurrency[indexOfPriceObjectToUpdate],
          amount_in_scu: updatedPrice,
        },
        pricingTable
      );

      setPricingTable(updatedPricingTable);
    },
    [pricingTable]
  );

  return (
    <PricingTableContext.Provider
      value={{
        data: pricingTable,
        onUpdate,
        storeListings: storeListings.data,
      }}
    >
      {children}
    </PricingTableContext.Provider>
  );
}

export const usePricingTableContext = (): PricingTableContextType =>
  useContext(PricingTableContext);
