import {
  ActionIcon,
  Alert,
  AlertProps,
  Button,
  createStyles,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import React from 'react';

import { useAddSupportSeats, useSupportSeats } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as AddIcon } from '@portals/icons/linear/add.svg';
import { ReactComponent as MinusIcon } from '@portals/icons/linear/minus.svg';
import { ReactComponent as Star1Icon } from '@portals/icons/linear/star-1.svg';

import { useNumOfAvailableSupportSeats } from '../pages/settings/support/support.hooks';

export interface AddSupportSeatsModalProps
  extends ModalProps<{
    onClose?: () => void;
  }> {}

export function AddSupportSeatsModal({
  closeMe,
  data: { onClose },
}: AddSupportSeatsModalProps) {
  const { classes } = useStyles();

  const supportSeats = useSupportSeats();
  const addSupportSeats = useAddSupportSeats();
  const numOfAvailableSeats = useNumOfAvailableSupportSeats();

  const [count, handlers] = useCounter(1, { min: 1, max: 10 });

  const noAvailableSeats = numOfAvailableSeats === 0;

  const closeModal = () => {
    closeMe();
    onClose?.();
  };

  const onAddSeatsClick = async () => {
    try {
      await addSupportSeats.mutateAsync({ seats: count });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened onClose={closeModal} title="Add support seats">
      <ModalBody>
        <Stack spacing="xl">
          <Paper p="xl" withBorder radius="lg">
            <Text size={36} color={noAvailableSeats ? 'red' : 'gray.9'}>
              {supportSeats.data?.in_use} /{' '}
              <Text span color={noAvailableSeats ? 'inherit' : 'gray.5'}>
                {supportSeats.data?.total}
              </Text>
            </Text>
            <Text color="gray.8" size="md">
              Seats are in use{' '}
              <Text span color={noAvailableSeats ? 'red' : 'gray.5'}>
                ({numOfAvailableSeats} available)
              </Text>
            </Text>
          </Paper>

          <Stack spacing="xs">
            <Group position="apart">
              <Group>
                <Text weight={500}>Additional seats</Text>

                <SeatsCounter
                  count={count}
                  increment={handlers.increment}
                  decrement={handlers.decrement}
                />
              </Group>

              <Text size="md">
                <Text span>$0.00 / </Text>
                <Text span color="gray.5">
                  month
                </Text>
              </Text>
            </Group>

            <Alert
              p="xl"
              radius="lg"
              styles={alertStyles}
              title={
                <Group spacing="xs">
                  <Star1Icon width={19} height={19} />
                  <span>Special offer for a limited time</span>
                </Group>
              }
            >
              <svg
                width="26"
                height={TRIANGLE_HEIGHT}
                viewBox="0 0 26 13"
                fill="none"
                className={classes.triangleIcon}
              >
                <path
                  d="M9.22155 2.35975C11.2153 0.0592833 14.7847 0.0592813 16.7784 2.35974L26 13H0L9.22155 2.35975Z"
                  fill="#F2F7FF"
                />
              </svg>
              <Text color="gray.9">
                As a channel partner, you're now eligible to add free support
                seats.
                <br />
                <Text span weight={700}>
                  This offer is valid until September 30, 2024.
                </Text>{' '}
                After this date, each seat will be billed at $99.00/month.
              </Text>
            </Alert>
          </Stack>
        </Stack>
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={addSupportSeats.isLoading} onClick={onAddSeatsClick}>
          Add Seats
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const TRIANGLE_HEIGHT = 13;

const alertStyles: AlertProps['styles'] = (theme) => ({
  root: {
    overflow: 'visible',
    position: 'relative',
    marginTop: TRIANGLE_HEIGHT,
    background:
      'linear-gradient(120deg, rgba(241, 249, 254, 0.65) 36.36%, rgba(197, 206, 255, 0.44) 150.95%)',
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  message: {
    color: theme.colors.gray[9],
  },
});

const useStyles = createStyles(() => ({
  triangleIcon: {
    position: 'absolute',
    top: -TRIANGLE_HEIGHT,
    right: 40,
  },
}));

interface SeatsCounterProps {
  count: number;
  increment: () => void;
  decrement: () => void;
}
function SeatsCounter({ count, increment, decrement }: SeatsCounterProps) {
  const { classes } = useSeatsCounterStyles();

  return (
    <Group>
      <ActionIcon
        variant="default"
        onClick={decrement}
        className={classes.counterActionIcon}
      >
        <MinusIcon />
      </ActionIcon>

      {count}

      <ActionIcon
        variant="default"
        onClick={increment}
        className={classes.counterActionIcon}
      >
        <AddIcon />
      </ActionIcon>
    </Group>
  );
}

const useSeatsCounterStyles = createStyles(() => ({
  counterActionIcon: {
    svg: {
      width: 18,
      height: 18,
    },
  },
}));
