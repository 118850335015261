import { createStyles, Menu, MenuProps } from '@mantine/core';
import React, { useState } from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import { HubSidebarItemWithSubItems } from '../hubs.types';
import { useHubs } from '../HubsContext';

interface MenuSidebarItemProps {
  sidebarItem: HubSidebarItemWithSubItems;
}

export function MenuSidebarItem({ sidebarItem }: MenuSidebarItemProps) {
  const { classes, cx } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { isSidebarItemActive } = useHubs();

  const isActive = isSidebarItemActive(sidebarItem);

  return (
    <Menu
      withinPortal
      position="right-start"
      offset={20}
      shadow="xl"
      radius="md"
      trigger="hover"
      onChange={setIsOpen}
      styles={menuStyles}
    >
      <Menu.Target>
        <div
          className={cx(classes.menuTarget, {
            [classes.activeMenuTarget]: isOpen || isActive,
          })}
        >
          {sidebarItem.icon}
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        {sidebarItem.subItems.map((subItem) => (
          <Menu.Item
            key={subItem.path}
            component={NavLink}
            to={generatePath(subItem.path)}
          >
            {subItem.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  menuTarget: {
    padding: 10,
    borderRadius: theme.radius.md,
    color: theme.colors.blue_gray[6],
    cursor: 'pointer',

    '&:hover': {
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[2],
    },
  },
  activeMenuTarget: {
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.gray[2],
  },
}));

const menuStyles: MenuProps['styles'] = (theme) => ({
  dropdown: {
    minWidth: 160,
  },
  item: {
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.md,
    color: theme.colors.blue_gray[6],

    '&:not(:last-child)': {
      marginBottom: theme.spacing.xs,
    },

    '&:hover, &.active': {
      textDecoration: 'none',
      color: theme.colors.gray[9],
      backgroundColor: theme.colors.gray[2],
    },
  },
});
