import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';

import { CreateOrderWizardContextDataType, StepIdEnum } from './types';
import { OrderDetailsStep } from '../order-details-step/OrderDetailsStep';
import { SelectStoreListingsStep } from '../select-store-listings-step/SelectStoreListingsStep';

export const STEPS_MAP: WizardSteps<
  CreateOrderWizardContextDataType,
  StepIdEnum
> = {
  [StepIdEnum.SelectProducts]: {
    id: StepIdEnum.SelectProducts,
    isFinalStep: false,
    component: <SelectStoreListingsStep />,

    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.OrderDetails },
    },
  },
  [StepIdEnum.OrderDetails]: {
    id: StepIdEnum.OrderDetails,
    isFinalStep: true,
    component: <OrderDetailsStep />,
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.SelectProducts },
    },
  },
};
