import { StoreListing } from '@portals/api/partners';
import { ProductPricingModel } from '@portals/types';

import { StoreListingWizardContext } from './types';

export function includeSubscriptionCancellationStep(
  contextData: StoreListingWizardContext
) {
  const { selectedProduct, paymentSettings } = contextData;

  if (selectedProduct?.pricing_model === ProductPricingModel.UsageBased) {
    return true;
  }

  if (paymentSettings.monthly || paymentSettings.yearly) {
    return true;
  }

  return false;
}

export function calcIsBasePriceEnabled(storeListing: StoreListing | undefined) {
  if (
    !storeListing ||
    storeListing.product.pricing_model !== ProductPricingModel.UsageBased
  ) {
    return false;
  }

  return storeListing.prices.some(
    (price) => price.monthly_price_in_scu !== null
  );
}

export function isProductInformationError(
  contextData: StoreListingWizardContext
) {
  const { productNameError, productDescriptionError, slugError } = contextData;

  return Boolean(productNameError || productDescriptionError || slugError);
}
