import {
  Button,
  Center,
  createStyles,
  getStylesRef,
  Modal,
  SimpleGrid,
  Text,
  UnstyledButton,
} from '@mantine/core';
import React, { useState } from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as TickCircle } from '@portals/icons/bulk/tick-circle.svg';
import { ReactComponent as ArrowRight } from '@portals/icons/linear/arrow-right.svg';

import { ReactComponent as DocumentUpload } from '../assets/img/document-upload.svg';
import { ReactComponent as EnvelopWithSearch } from '../assets/img/envelope-with-search.svg';

export interface SelectInvitationTypeModalProps
  extends ModalProps<{
    title: string;
    directInvitationText: string;
    bulkInvitationText: string;
    onDirectInvitationClick: () => void;
    onBulkInvitationClick: () => void;
  }> {}

export function SelectInvitationTypeModal({
  closeMe,
  data,
}: SelectInvitationTypeModalProps) {
  const { classes, theme } = useStyles();

  const [isBulkProcessingModalOpen, setIsBulkProcessingModalOpen] =
    useState(false);

  const {
    title,
    directInvitationText,
    bulkInvitationText,
    onDirectInvitationClick,
    onBulkInvitationClick,
  } = data;

  const onCardClick = (invitationType: 'direct' | 'bulk') => {
    if (invitationType === 'direct') {
      closeMe();

      onDirectInvitationClick();
    } else {
      onBulkInvitationClick();
      setIsBulkProcessingModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        opened={!isBulkProcessingModalOpen}
        title={title}
        onClose={closeMe}
        withCloseButton={false}
        padding="xxl"
        size="fit-content"
        styles={{ header: { justifyContent: 'center' } }}
        radius="lg"
      >
        <SimpleGrid cols={2}>
          <UnstyledButton
            className={classes.card}
            onClick={() => onCardClick('direct')}
          >
            <EnvelopWithSearch className={classes.icon} />

            <Text
              className={classes.cardTitle}
              data-testid="direct-invitation-text"
            >
              Direct Invitation
            </Text>

            <Text className={classes.cardDescription}>
              {directInvitationText}
            </Text>

            <ArrowRight className={classes.arrow} />
          </UnstyledButton>

          <UnstyledButton
            className={classes.card}
            onClick={() => onCardClick('bulk')}
          >
            <DocumentUpload className={classes.icon} />

            <Text className={classes.cardTitle}>
              Bulk Invitations{' '}
              <Text weight={400} span>
                (CSV Import)
              </Text>
            </Text>

            <Text className={classes.cardDescription}>
              {bulkInvitationText}
            </Text>

            <ArrowRight className={classes.arrow} />
          </UnstyledButton>
        </SimpleGrid>
      </Modal>

      <ModalCenteredMediaLayout
        opened={isBulkProcessingModalOpen}
        onClose={() => setIsBulkProcessingModalOpen(false)}
        media={<TickCircle color={theme.colors.blue[2]} />}
        title="Your request is being processed"
        footer={
          <Center>
            <Button
              w={250}
              onClick={() => {
                setIsBulkProcessingModalOpen(false);
                closeMe();
              }}
            >
              Close
            </Button>
          </Center>
        }
      >
        <Text ta="center">
          Our team has received your request and is currently working on it.
          We'll reach out to you shortly to assist with the CSV file import.
          Thank you for your patience
        </Text>
      </ModalCenteredMediaLayout>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    width: 300,
    paddingTop: theme.spacing.xxl,
    paddingInline: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    textAlign: 'center',
    cursor: 'pointer',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,

    '&:hover': {
      boxShadow: theme.shadows.md,
      borderColor: theme.fn.primaryColor(),

      [`& .${getStylesRef('arrow')}`]: {
        opacity: 1,
      },
    },
  },
  icon: {
    marginBottom: theme.spacing.xl,
  },
  cardTitle: {
    marginBottom: theme.spacing.xs,
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  cardDescription: {
    marginBottom: theme.spacing.md,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
  },
  arrow: {
    ref: getStylesRef('arrow'),
    opacity: 0,
    color: theme.fn.primaryColor(),
  },
}));
