import { createStyles } from '@mantine/core';
import { set } from 'lodash/fp';
import React from 'react';

import { CurrencyRow, CurrencyRowProps } from '@portals/framework';
import { CurrencyCode, PaymentIntervalEnum } from '@portals/types';

import { PaymentSettingItemType } from '../../../types/store-listings.types';
import { useStoreListingWizard } from '../hooks';

interface PaymentSettingPricesProps {
  period: PaymentIntervalEnum;
  defaultCurrency: CurrencyCode;
}
export function PaymentSettingPrices({
  period,
  defaultCurrency,
}: PaymentSettingPricesProps) {
  const { classes } = useStyles();
  const { contextData, setContextData } = useStoreListingWizard();

  const prices = (contextData.paymentSettings[period]?.prices ?? []).sort((a) =>
    a.currency === defaultCurrency ? -1 : 1
  );

  const onChange = (
    newValue: CurrencyRowProps['value'],
    currency: CurrencyCode
  ) => {
    const newPrices: PaymentSettingItemType['prices'] = prices.map((price) => {
      if (price.currency === currency) {
        return {
          ...price,
          amountInScu: newValue || null,
          error: price.error ? newValue === undefined : price.error,
        };
      }
      return price;
    });

    setContextData((prev) =>
      set(['paymentSettings', period, 'prices'], newPrices, prev)
    );
  };

  return (
    <>
      {prices.map((price) => (
        <CurrencyRow
          key={price.currency}
          className={classes.currencyRow}
          error={price.error}
          readOnly={contextData.storeListing?.editable === false}
          value={price.amountInScu ?? undefined}
          onChange={(value) => onChange(value, price.currency)}
          currencyCode={price.currency}
          isDefaultCurrency={defaultCurrency === price.currency}
        />
      ))}
    </>
  );
}

const useStyles = createStyles(() => ({
  currencyRow: {
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));
