import { Button, LoadingOverlay, TextInput } from '@mantine/core';
import React, { useState } from 'react';

import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { ModalProps } from '@portals/framework';

import { ReactComponent as InvitePartnerIcon } from '../../assets/img/invite-partner.svg';

export interface CreateNewPartnerTypeModalProps
  extends ModalProps<{
    onChange: (type: string) => void;
  }> {}

export function CreateNewPartnerTypeModal({
  closeMe,
  data,
}: CreateNewPartnerTypeModalProps) {
  const [customPartnerType, setCustomPartnerType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { onChange } = data;

  const onSubmit = () => {
    if (customPartnerType.trim() === '') {
      return;
    }

    setIsLoading(true);

    onChange(customPartnerType);
    closeMe();

    setIsLoading(false);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />

      <ModalCenteredMediaLayout
        opened
        onClose={closeMe}
        title="Create New Partner Type"
        media={<InvitePartnerIcon />}
      >
        <ModalBody>
          <TextInput
            autoFocus
            placeholder="Custom Partner Type"
            value={customPartnerType}
            onChange={(event) =>
              setCustomPartnerType(event.currentTarget.value)
            }
          />
        </ModalBody>

        <ModalFooter position="center" grow>
          <Button variant="default" onClick={closeMe}>
            Back
          </Button>
          <Button
            type="submit"
            onClick={onSubmit}
            disabled={customPartnerType.trim() === ''}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalCenteredMediaLayout>
    </>
  );
}
