import {
  Button,
  Group,
  Modal,
  Radio,
  RadioProps,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { SubscriptionDetailsType } from '@portals/api/partners';
import { ModalBody, ModalFooter } from '@portals/core';
import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';
import { ProductPricingModel } from '@portals/types';
import { mediumDateFormatter } from '@portals/utils';

enum CancellationTimeOptionsEnum {
  Immediately = 'immediately',
  NotImmediately = 'not_immediately',
}

interface SubscriptionCancellationConfirmModalProps {
  isOpen: boolean;
  subscription: SubscriptionDetailsType;
  isCancelImmediately: boolean;
  setIsCancelImmediately: (value: boolean) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export function SubscriptionCancellationConfirmModal({
  isOpen,
  subscription,
  isCancelImmediately,
  setIsCancelImmediately,
  onCancel,
  onConfirm,
}: SubscriptionCancellationConfirmModalProps) {
  const theme = useMantineTheme();

  const isUsageBased =
    subscription.product.pricing_model === ProductPricingModel.UsageBased;

  let descriptionMessage;

  if (isUsageBased) {
    descriptionMessage = (
      <Text>
        You are about to cancel the subscription for this product. By
        cancelling, the customer will lose access to its features and benefits
        immediately.
      </Text>
    );
  } else {
    descriptionMessage = (
      <Text>
        You are about to cancel the subscription for this product. By cancelling
        the customer will lose access to its features and benefits.
      </Text>
    );
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onCancel}
      title="Confirm Subscription Cancellation"
      padding="xxl"
      styles={modalStyles}
    >
      <ModalBody>
        <Stack mb="md">{descriptionMessage}</Stack>
        {!isUsageBased ? (
          <Radio.Group
            onChange={(value) =>
              setIsCancelImmediately(
                value === CancellationTimeOptionsEnum.Immediately
              )
            }
            value={
              isCancelImmediately
                ? CancellationTimeOptionsEnum.Immediately
                : CancellationTimeOptionsEnum.NotImmediately
            }
          >
            <Radio
              value={CancellationTimeOptionsEnum.NotImmediately}
              styles={radioStyles}
              mb="md"
              label={
                <Stack data-testid="end-of-billing-option" spacing="xs">
                  <Text weight={600} color="gray.9">
                    Cancel at end of Billing Period (
                    {mediumDateFormatter(subscription.next_charge_at)})
                  </Text>

                  <Text color="gary.7">
                    This will cancel the customer's subscription at the end of
                    their current billing cycle. They will still be able to
                    access your services until the end of their paid-for period.
                    They will not be charged again for the next billing cycle.
                  </Text>
                </Stack>
              }
            />
            <Radio
              value={CancellationTimeOptionsEnum.Immediately}
              styles={radioStyles}
              label={
                <Stack data-testid="immediately-option" spacing="xs">
                  <Group spacing="xs">
                    <Text weight={600} color="gray.9">
                      Cancel Subscription Immediately
                    </Text>

                    <Tooltip label="Immediate cancelation of the subscription is not reversible">
                      <Danger
                        width={18}
                        height={18}
                        color={theme.colors.red[4]}
                      />
                    </Tooltip>
                  </Group>

                  <Text color="gary.7">
                    This will inactivate the customer's subscription instantly.
                    They will no longer be able to access any of your services
                    immediately after the cancellation goes into effect.
                    Depending on your refund policy, they may be entitled to a
                    partial refund for the remaining period of the current
                    billing cycle.
                  </Text>
                </Stack>
              }
            />
          </Radio.Group>
        ) : null}
      </ModalBody>

      <ModalFooter position="right">
        <Button variant="default" onClick={onCancel}>
          Keep Subscription
        </Button>
        <Button
          color="red"
          onClick={onConfirm}
          data-testid="cancel-subscription-button"
        >
          Cancel Subscription
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const radioStyles: RadioProps['styles'] = (theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.md,
  },

  inner: {
    alignSelf: 'center',
  },
});

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
});
