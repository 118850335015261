import { Switch, SwitchProps } from '@mantine/core';
import React, { FC } from 'react';

interface ToggleCellProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  switchProps?: Partial<SwitchProps>;
}

export const ToggleCell: FC<ToggleCellProps> = ({
  isChecked,
  onChange,
  switchProps = {},
}) => (
  <Switch
    checked={isChecked}
    data-testid="table-toggle-cell"
    onChange={(event) => onChange(event.target.checked)}
    {...switchProps}
  />
);
