import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UsePaginatedTableApiQuery } from '@portals/types';

import { API_KEYS_URL, apiKeysQueryKeys } from './api-keys.constants';
import { ApiKeyType } from './api-keys.types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export const useApiKeys = (
  tableState: UsePaginatedTableApiQuery<ApiKeyType>['tableState'],
  columns: UsePaginatedTableApiQuery<ApiKeyType>['columns'],
  baseUrl: string = API_KEYS_URL
) =>
  usePaginatedTableApiQuery({
    queryKey: [...apiKeysQueryKeys.all, tableState],
    baseUrl,
    columns,
    tableState,
  });

export function useCreateApiKey() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: API_KEYS_URL,
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ name }: { name: string }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ name }),
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries(apiKeysQueryKeys.all);
    },
    meta: {
      mutationName: 'useCreateApiKey',
      baseUrl: API_KEYS_URL,
      method: 'POST',
    },
  });
}

export function useUpdateApiKey() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: API_KEYS_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({ name, apiKeyId }: { name: string; apiKeyId: string }) =>
      fetchApiRequest(`${url}/${apiKeyId}`, {
        ...options,
        body: JSON.stringify({ name }),
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries(apiKeysQueryKeys.all);
    },
    meta: {
      mutationName: 'useUpdateApiKey',
      baseUrl: `${API_KEYS_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useDeleteApiKey() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: API_KEYS_URL,
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: ({ apiKeyId }: { apiKeyId: string }) =>
      fetchApiRequest(`${url}/${apiKeyId}`, options),
    onSuccess: async () => {
      await queryClient.refetchQueries(apiKeysQueryKeys.all);
    },
    meta: {
      mutationName: 'useDeleteApiKey',
      baseUrl: `${API_KEYS_URL}/:id`,
      method: 'DELETE',
    },
  });
}
