import { Box, createStyles, Group, Menu, Stack, Text } from '@mantine/core';
import React, { ReactNode, useMemo, useState } from 'react';

import { usePartnerConfig } from '@portals/api/partners';
import { useUsers } from '@portals/api/ui';
import {
  useCurrentUserAccessibleTenants,
  usePermissionAccess,
} from '@portals/framework';
import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { ReactComponent as CloudChangeIcon } from '@portals/icons/linear/cloud-change.svg';
import { ReactComponent as CloudPlusIcon } from '@portals/icons/linear/cloud-plus.svg';
import { ReactComponent as FlagIcon } from '@portals/icons/linear/flag.svg';
import { ReactComponent as StarIcon } from '@portals/icons/linear/star.svg';
import { useOpenModal } from '@portals/redux';

import { hubsHeaderMenuStyles } from './common-styles';
import { TenantAvatar } from './TenantAvatar';
import { usePricingPlan } from '../../hooks/partner-config';

export function TenantMenu() {
  const { classes, cx } = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = useOpenModal();

  const users = useUsers();
  const partnerConfig = usePartnerConfig();
  const pricingPlan = usePricingPlan();

  const membersCount = users.data?.length || 0;

  const menuItems = useTenantMenuItems();

  return (
    <Menu
      withinPortal
      width="fit-content"
      offset={15}
      radius="lg"
      shadow="lg"
      position="bottom-start"
      opened={isOpen}
      onChange={setIsOpen}
      styles={hubsHeaderMenuStyles}
      data-testid="tenant-user-content"
    >
      <Menu.Target>
        <div className={classes.menuTarget}>
          <Group
            noWrap
            className={cx(classes.trigger, { active: isOpen })}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <TenantAvatar
              src={partnerConfig?.logo_url || 'logo_full_white.svg'}
            />

            <Text color="blue_gray.0" weight={600} mr="auto">
              {partnerConfig?.display_name}
            </Text>

            <ChevronDownIcon
              className={cx(classes.chevron, { [classes.rotate]: isOpen })}
            />
          </Group>
        </div>
      </Menu.Target>

      <Menu.Dropdown p="md">
        <Group noWrap className={classes.tenantDetails}>
          <TenantAvatar
            size={44}
            src={partnerConfig?.logo_url || 'logo_full_white.svg'}
          />

          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Text color="gray.1" size="md" weight={500} truncate>
              {partnerConfig?.display_name}
            </Text>

            <Text size="xs" color="gray.5" truncate data-testid="pricing-plan">
              <Text span>{pricingPlan?.name} </Text>
              <Text span>&bull; </Text>
              <Text span>
                {membersCount}
                {membersCount !== 1 ? ' Members' : ' Member'}
              </Text>
            </Text>
          </Box>

          <Text
            size="xs"
            className={classes.editLink}
            onClick={() => {
              openModal('EditPartnerLogo');

              setIsOpen(false);
            }}
          >
            Edit
          </Text>
        </Group>

        <Stack spacing="xs">
          {menuItems.map((menuItem) => (
            <Menu.Item
              key={menuItem.label}
              icon={menuItem.icon}
              onClick={menuItem.onClick}
            >
              {menuItem.label}
            </Menu.Item>
          ))}
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  menuTarget: {
    height: '100%',
  },
  trigger: {
    height: '100%',
    paddingInline: theme.spacing.xs,
    borderRadius: theme.radius.md,
    cursor: 'pointer',

    '&:hover, &.active': {
      backgroundColor: theme.fn.rgba(theme.colors.gray[8], 0.5),
    },
  },
  chevron: {
    color: theme.colors.blue_gray[2],
    transform: 'rotate(0deg)',
    transition: 'transform 0.15s ease-in-out',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  editLink: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    color: theme.colors.indigo_accent[3],
  },
  tenantDetails: {
    paddingInline: theme.spacing.xs,
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[8]}`,
  },
}));

export function useTenantMenuItems() {
  const openModal = useOpenModal();

  const { isAdmin, isSuperAdmin } = usePermissionAccess();
  const accessibleTenants = useCurrentUserAccessibleTenants();

  return useMemo(() => {
    const menuItems: Array<{
      icon: ReactNode;
      label: string;
      onClick: () => void;
    }> = [];

    if (isAdmin) {
      menuItems.push({
        label: 'Your Success Journey',
        icon: <StarIcon />,
        onClick: () => window.open('https://success.xyte.io', '_blank'),
      });
    }

    if (isSuperAdmin) {
      menuItems.push({
        label: 'Feature Flags',
        icon: <FlagIcon />,
        onClick: () => openModal('FeatureFlags'),
      });
    }

    if (accessibleTenants?.length !== 0) {
      menuItems.push({
        label: 'Switch Tenant',
        icon: <CloudChangeIcon data-testid="tenant-menu-item-Switch Tenant" />,
        onClick: () => openModal('SwitchTenantModal'),
      });
    }

    if (isSuperAdmin) {
      menuItems.push({
        label: 'Create New Tenant',
        icon: (
          <CloudPlusIcon data-testid="tenant-menu-item-Create New Tenant" />
        ),
        onClick: () => openModal('CreatePartner'),
      });
    }

    return menuItems;
  }, [accessibleTenants?.length, isAdmin, isSuperAdmin, openModal]);
}
