import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ErrorBoundary } from '@portals/framework';

import { router } from './app/Root';

// @todo: verify if these imports are actually needed
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ErrorBoundary>
    <RouterProvider router={router} />
  </ErrorBoundary>
);
