import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { DeviceWidgetsSectionType } from '@portals/device-widgets';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getSectionsApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/device_sections`;
}

export function useDeviceModelWidgetsSections(modelId = '') {
  return useApiQuery<Array<DeviceWidgetsSectionType>>(
    getSectionsApiUrl(modelId),
    deviceModelsQueryKeys.sections.all(modelId),
    {
      enabled: !!modelId,
    }
  );
}

interface CreateOrEditSectionPayload {
  name: string;
  condition?: DeviceWidgetsSectionType['condition'];
}

interface UseCreateDeviceModelWidgetsSectionProps {
  modelId: string;
  successMessage: string;
}

export function useCreateDeviceModelWidgetsSection({
  modelId,
  successMessage,
}: UseCreateDeviceModelWidgetsSectionProps) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getSectionsApiUrl(modelId),
    method: 'POST',
  });

  return useMutation<
    DeviceWidgetsSectionType,
    ServerError,
    CreateOrEditSectionPayload
  >({
    mutationFn: (params) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess(successMessage));

      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );
    },
    onError: (error) => {
      dispatch(toastrError(error.error));
    },
    meta: {
      mutationName: 'useCreateDeviceModelWidgetsSection',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_sections`,
      method: 'POST',
    },
  });
}

interface UseUpdateDeviceModelWidgetsSectionProps {
  modelId: string;
  sectionId?: string;
}

export function useUpdateDeviceModelWidgetsSection({
  modelId,
  sectionId,
}: UseUpdateDeviceModelWidgetsSectionProps) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: sectionId ? `${getSectionsApiUrl(modelId)}/${sectionId}` : '',
    method: 'PUT',
  });

  return useMutation<
    DeviceWidgetsSectionType,
    ServerError,
    CreateOrEditSectionPayload
  >({
    mutationFn: (params) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(params),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Section edited successfully'));

      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );
    },
    onError: (error) => {
      dispatch(toastrError(error.error));
    },
    meta: {
      mutationName: 'useEditDeviceModelWidgetsSection',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_sections`,
      method: 'POST',
    },
  });
}

interface UseRemoveDeviceModelWidgetsSection {
  modelId: string;
  sectionId: string;
}

export function useRemoveDeviceModelWidgetsSection({
  modelId,
  sectionId,
}: UseRemoveDeviceModelWidgetsSection) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: sectionId ? `${getSectionsApiUrl(modelId)}/${sectionId}` : '',
    method: 'DELETE',
  });

  return useMutation<DeviceWidgetsSectionType, ServerError>({
    mutationFn: () => {
      return fetchApiRequest(url, {
        ...options,
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess(`Section removed successfully`));

      queryClient.invalidateQueries(
        deviceModelsQueryKeys.sections.all(modelId)
      );
    },
    onError: (error) => {
      dispatch(toastrError(error.error));
    },
    meta: {
      mutationName: 'useEditDeviceModelWidgetsSection',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/device_sections`,
      method: 'POST',
    },
  });
}
