import { Select } from '@mantine/core';
import React from 'react';

import { PREDICATE_MAP } from './mapping';

const PredicateInput = ({ type, value, setValue, disabled }) => {
  const predicateOptions = PREDICATE_MAP[type];

  return (
    <Select
      id="predicate"
      type="select"
      name="predicate"
      label="Predicate"
      data-testid="select-predicate"
      value={value || ''}
      required
      disabled={disabled}
      onChange={setValue}
      data={predicateOptions.map((option) => ({
        value: option.id,
        label: option.label,
      }))}
      withinPortal
      styles={{
        dropdown: {
          width: 'fit-content',
        },
      }}
    />
  );
};

export default PredicateInput;
