import { Alert, createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { ReactComponent as LockIcon } from '@portals/icons/linear/lock.svg';

export function PriceRestrictionAlert() {
  const { classes } = useStyles();

  return (
    <Alert
      p="xl"
      radius="lg"
      classNames={{
        root: classes.root,
        title: classes.title,
        message: classes.message,
      }}
      title={
        <Group spacing="xs">
          <LockIcon width={18} height={18} />
          <Text>Price Modification Restricted</Text>
        </Group>
      }
    >
      This purchased product cannot have its prices edited. To make any price
      modifications, kindly create a new listing for another product.
    </Alert>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    overflow: 'unset',
  },
  title: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[9],
  },
  message: {
    color: theme.colors.gray[8],
  },
}));
