import React, { ReactNode } from 'react';
import styled from 'styled-components';

import PaginationDots from './PaginationDots';

function DetailsArea({ children }: { children: ReactNode }) {
  return (
    <Container>
      <Content>{children}</Content>

      <PaginationDots />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 100px;
  place-items: center;
  place-content: center;
  padding: 40px 40px 0;
`;

const Content = styled.div`
  width: clamp(370px, 100%, 550px);
`;

export default DetailsArea;
