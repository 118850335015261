import { Button, Group } from '@mantine/core';
import React, { FC, useState } from 'react';

import { chooseTaxGroupSrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { TaxGroupsSelect } from '../../components/tax-groups/tax-groups-select/TaxGroupsSelect';

export const ChooseTaxGroup: FC<ModalProps> = ({ closeMe }) => {
  const [selectedTaxGroupId, setSelectedTaxGroupId] = useState('');

  return (
    <ModalCenteredMediaLayout
      opened
      title="Choose Tax Group"
      onClose={closeMe}
      footer={
        <Group grow mt="md">
          <Button size="md" variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button size="md" disabled={selectedTaxGroupId === ''}>
            Move Product
          </Button>
        </Group>
      }
      media={<img src={chooseTaxGroupSrc} alt="Choose tax group" />}
    >
      <TaxGroupsSelect
        value={selectedTaxGroupId}
        onChange={setSelectedTaxGroupId}
      />
    </ModalCenteredMediaLayout>
  );
};
