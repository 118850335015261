import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import React, { useMemo } from 'react';

import { DeviceType, IncidentType } from '@portals/api/organizations';
import { ReactComponent as Danger } from '@portals/icons/bold/danger.svg';
import { SmartTable } from '@portals/table';
import { TableColumn, TableDetailsPanelType } from '@portals/types';
import {
  getIncidentPriorityLevelColor,
  getIncidentPriorityLevelName,
  prettyTimeMoment,
} from '@portals/utils';

interface IncidentsViewProps {
  incidents: Array<IncidentType>;
  device: DeviceType;
  tableName: string;
  detailsPanelRenderer: TableDetailsPanelType['renderer'];
}

const timeFormatter = (cell, { created_at }) => (
  <span>{prettyTimeMoment(created_at)}</span>
);

export function IncidentsView({
  incidents,
  tableName,
  detailsPanelRenderer,
}: IncidentsViewProps) {
  const mantineTheme = useMantineTheme();

  const columns = useMemo(() => {
    const defaultColumns: Array<TableColumn<IncidentType>> = [
      {
        dataField: 'priority',
        text: '',
        minWidth: 70,
        maxWidth: 70,
        sort: true,
        formatter: (_, { priority }) => {
          return (
            <Tooltip label={getIncidentPriorityLevelName(priority)}>
              <Danger
                color={mantineTheme.fn.themeColor(
                  getIncidentPriorityLevelColor(priority),
                  4
                )}
              />
            </Tooltip>
          );
        },
      },
      {
        dataField: 'title',
        text: 'Title',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Description',
        sort: false,
      },
      {
        dataField: 'created_at',
        text: 'Created',
        sort: true,
        formatter: timeFormatter,
      },
      {
        dataField: 'issue',
        text: 'Issue',
        sort: true,
        minWidth: 150,
        maxWidth: 150,
      },
    ];

    return defaultColumns;
  }, [mantineTheme]);

  return (
    <Box py="md" sx={{ height: '100%' }}>
      <SmartTable<IncidentType>
        columns={columns}
        data={incidents}
        noHeader
        noFilters
        keyField="id"
        isUrlSyncEnabled={false}
        defaultSorted={[{ id: 'priority', desc: false }]}
        detailsPanel={{
          type: 'inner',
          renderer: detailsPanelRenderer,
        }}
        rowStyle={({ status }) => ({
          color: status === 'closed' ? mantineTheme.colors.gray[6] : 'unset',
        })}
        noDataIndication={{ title: 'No incidents' }}
        name={tableName}
      />
    </Box>
  );
}
