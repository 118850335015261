import { LoadingOverlay, Modal } from '@mantine/core';
import React from 'react';

import { useUploadInvoice } from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { FieldType, FieldTypeEnum } from '@portals/types';

const FIELDS: Array<FieldType> = [
  { name: 'url', title: 'File', type: FieldTypeEnum.File, required: true },
];

const UploadInvoice = ({ data, inProgress, closeMe, serverError }) => {
  const { invoiceId } = data;
  const uploadInvoice = useUploadInvoice(invoiceId);

  const onSubmit = async ({ url }: { url?: string } = {}) => {
    if (!url) return;

    try {
      await uploadInvoice.mutateAsync(url);

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened={true} onClose={closeMe} title="Upload invoice">
      <LoadingOverlay visible={uploadInvoice.isLoading} />

      <AutoFormik
        fields={FIELDS}
        submitTitle="Add"
        serverError={serverError}
        inProgress={inProgress}
        handleSubmit={onSubmit}
        modal={closeMe}
      />
    </Modal>
  );
};

export default UploadInvoice;
