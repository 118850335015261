import React, { FC, useEffect, useState } from 'react';

import {
  StoreListing,
  useInvalidatePaginatedProducts,
  usePublishableProductsByIds,
  useNonePaginatedStoreListings,
} from '@portals/api/partners';
import { ModalProps, WizardProvider } from '@portals/framework';

import { StepsMap } from './steps-map';
import {
  PublishProductsStepIds,
  PublishProductsWizardContextDataType,
} from './types';
import { WizardContent } from './WizardContent';

export const PublishProductsWizard: FC<
  ModalProps<{ productIds: string[] }>
> = ({ closeMe, data: modalData }) => {
  const storeListings = useNonePaginatedStoreListings();
  const invalidatePaginatedProducts = useInvalidatePaginatedProducts();
  const isMultipleProducts = modalData.productIds.length > 1;

  const products = usePublishableProductsByIds(modalData.productIds);

  const [contextData, setContextData] =
    useState<PublishProductsWizardContextDataType>({
      products: undefined,
      activeProductId: undefined,
      storeListingsMap: undefined,
      selectedPricingOptionMap: undefined,
    });

  const handleClose = async () => {
    products.remove();

    await invalidatePaginatedProducts.invalidate();
    closeMe();
  };

  useEffect(
    function initContextDataMaps() {
      if (
        products.isFetching ||
        !products.data ||
        storeListings.isFetching ||
        !storeListings.data
      ) {
        return;
      }

      const selectedPricingOptionMap: Record<string, null> = {};
      const storeListingsMap: Record<string, StoreListing> = {};

      modalData.productIds.forEach((productId) => {
        selectedPricingOptionMap[productId] = null;

        storeListingsMap[productId] = storeListings.data.find(
          (storeListing) => storeListing.product.id === productId
        ) as StoreListing;
      });

      setContextData((curr) => ({
        ...curr,
        products: products.data,
        activeProductId: curr.activeProductId || products.data[0].id,
        storeListingsMap,
        selectedPricingOptionMap,
      }));
    },
    [
      modalData.productIds,
      products.data,
      products.isFetching,
      storeListings.data,
      storeListings.isFetching,
    ]
  );

  return (
    <WizardProvider<
      PublishProductsWizardContextDataType,
      PublishProductsStepIds
    >
      steps={StepsMap}
      initialStep={PublishProductsStepIds.PricesDetails}
      contextData={contextData}
      setContextData={setContextData}
    >
      <WizardContent
        isMultipleProducts={isMultipleProducts}
        onClose={handleClose}
      />
    </WizardProvider>
  );
};
