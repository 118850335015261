import {
  Button,
  Grid,
  Group,
  Input,
  Select,
  SelectProps,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { compact, concat, flow, set, uniq } from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import { useProductCategories } from '@portals/api/partners';
import { ImageSelectorField } from '@portals/autoformik';
import {
  ControlledTextEditor,
  LimitedTextInput,
  TextEditorProps,
  useTextEditor,
} from '@portals/core';
import { ReactComponent as AddCircle } from '@portals/icons/bulk/add-circle.svg';
import { ReactComponent as RefreshIcon } from '@portals/icons/linear/refresh.svg';

import { StoreListingSlugInput } from '../../../components/store-listings/StoreListingSlugInput';
import { DetailsColumn } from '../DetailsColumn';
import { useStoreListingWizard } from '../hooks';
import { StoreListingWizardContext } from '../types';

const CATEGORY_MAX_LENGTH = 25;

export function ProductInformationStep() {
  const theme = useMantineTheme();

  const { contextData, setContextData } = useStoreListingWizard();

  const categories = useProductCategories();
  const [localCategories, setLocalCategories] = useState<string[]>([]);

  const editor = useTextEditor({
    content: contextData.productOverrides?.productDescription,
    onUpdate: ({ editor }) => {
      const hasContent = editor.getText().trim() !== '';

      setContextData((prev) =>
        flow([
          set(['productOverrides', 'productDescription'], editor.getHTML()),
          set(['productDescriptionError'], hasContent ? '' : 'Required'),
        ])(prev)
      );
    },
  });

  const onResetFields = () => {
    setContextData((prev) => {
      const productOverrides: StoreListingWizardContext['productOverrides'] =
        contextData.selectedProduct
          ? {
              productImageUrl: contextData.selectedProduct.image_url,
              productName: contextData.selectedProduct.name,
              productCategory: contextData.selectedProduct.category,
              productSubtitle: contextData.selectedProduct.subtitle,
              productDescription: contextData.selectedProduct.description,
            }
          : undefined;

      editor?.commands?.setContent(productOverrides?.productDescription ?? '');

      return {
        ...prev,
        productOverrides,
        productNameError: '',
        productDescriptionError: '',
      };
    });
  };

  useEffect(
    function initCategories() {
      if (!categories.data) return;

      const newCategoryList = flow([
        // add the current selected category to the list of categories because it might not have been saved yet,
        // if the user has moved between steps without saving.
        concat(contextData.productOverrides?.productCategory || ''),
        // we might concatenate existing categories, thus we must unique-fy the list
        uniq,
        compact,
      ])(categories.data);

      setLocalCategories(newCategoryList);
    },
    [categories.data, contextData.productOverrides?.productCategory]
  );

  const onCreateCategory: SelectProps['onCreate'] = (query) => {
    setLocalCategories((prev) => [...prev, query]);
    return query;
  };

  const onSlugChange = (slug: string | null) => {
    setContextData((prev) => ({
      ...prev,
      slug,
      slugError: '',
    }));
  };

  return (
    <DetailsColumn
      title={
        <Group position="apart">
          <Text>Product Information</Text>

          <Button
            variant="default"
            leftIcon={<RefreshIcon width={14} height={14} />}
            onClick={onResetFields}
            data-testid="reset-to-catalog-button"
          >
            Reset to Catalog
          </Button>
        </Group>
      }
    >
      <Stack>
        <Grid>
          <Grid.Col span={7}>
            <TextInput
              label="Name"
              data-testid="product-name-text-input"
              error={contextData.productNameError}
              value={contextData.productOverrides?.productName ?? ''}
              onChange={(e) =>
                setContextData((prev) => {
                  const { value } = e.target;

                  return flow([
                    set(['productOverrides', 'productName'], value),
                    set(['productNameError'], value.trim() ? '' : 'Required'),
                  ])(prev);
                })
              }
              onBlur={() => {
                if (contextData.productOverrides?.productName?.trim()) {
                  setContextData((prev) => ({ ...prev, productNameError: '' }));
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <Select
              searchable
              clearable
              creatable
              maxLength={CATEGORY_MAX_LENGTH}
              styles={{ label: { width: '100%' } }}
              data={localCategories}
              data-testid="product-listing-category-input"
              value={contextData.productOverrides?.productCategory}
              onChange={(value) =>
                setContextData((curr) =>
                  set(['productOverrides', 'productCategory'], value, curr)
                )
              }
              onCreate={onCreateCategory}
              getCreateLabel={(query) => (
                <Group spacing="xs" noWrap>
                  <div>
                    <AddCircle color={theme.colors.blue[5]} />
                  </div>
                  <Text size="sm">Add "{query}"</Text>
                </Group>
              )}
              label={
                <Group position="apart">
                  <Text>Category</Text>
                  <Text size="xs" weight={600} color="blue_gray.3">
                    {contextData.productOverrides?.productCategory?.length || 0}
                    /{CATEGORY_MAX_LENGTH}
                  </Text>
                </Group>
              }
            />
          </Grid.Col>
        </Grid>

        <LimitedTextInput
          maxLength={40}
          label="Subtitle"
          data-testid="product-subtitle-text-input"
          value={contextData.productOverrides?.productSubtitle ?? ''}
          onChange={(e) =>
            setContextData((prev) =>
              set(['productOverrides', 'productSubtitle'], e.target.value, prev)
            )
          }
        />

        <Input.Wrapper label="Description">
          <ControlledTextEditor
            data-testid="description-controlled-text-editor"
            styles={textEditorStyles(contextData.productDescriptionError)}
            editor={editor}
          />

          {contextData.productDescriptionError && (
            <Input.Error mt="xs">
              {contextData.productDescriptionError}
            </Input.Error>
          )}
        </Input.Wrapper>

        <StoreListingSlugInput
          label="Permalink"
          slug={contextData.slug ?? ''}
          onSlugChange={onSlugChange}
          storeListingId={contextData.storeListing?.id}
          errorMessage={contextData.slugError}
          setErrorMessage={(message) =>
            setContextData((prev) => ({
              ...prev,
              slugError: message,
            }))
          }
        />

        <ImageSelectorField
          horizontal={false}
          value={contextData.productOverrides?.productImageUrl ?? ''}
          setFieldValue={(_, value) =>
            setContextData((curr) =>
              set(['productOverrides', 'productImageUrl'], value, curr)
            )
          }
          field={{
            title: '',
            name: 'image',
            height: 372,
            width: 350,
            cropConfig: {
              aspectRatio: 372 / 350,
              height: 1000,
              width: 940,
            },
          }}
        />
      </Stack>
    </DetailsColumn>
  );
}

const textEditorStyles =
  (error: string): TextEditorProps['styles'] =>
  (theme) => ({
    root: {
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
      minHeight: '230px',
      borderColor: error ? theme.colors.red[7] : theme.colors.gray[4],
    },
    content: {
      height: '100%',

      '.ProseMirror': {
        height: '100%',
      },
    },
  });
