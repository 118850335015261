import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import {
  fetchIntegrations,
  removeIntegration,
} from '../../../redux/actions/integrations';

const NAME = 'zoho';
const SCOPE = [
  'Desk.tickets.ALL',
  'Desk.events.ALL',
  'Desk.products.READ',
  'Desk.settings.ALL',
  'Desk.contacts.ALL',
  'Desk.basic.READ',
  'Desk.search.READ',
];

const EditConnection = ({
  partnerId,
  fetchIntegrations,
  removeIntegration,
  integration,
}) => {
  const authorizeUrl = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=${
    process.env.NX_ZOHO_CLIENT_ID
  }&access_type=offline&scope=${SCOPE.join(',')}&redirect_uri=${
    process.env.NX_SERVER_URL
  }external/zoho/oauth&state='${partnerId}',${window.location.origin}`;
  const authorized = get('extra.org_ids', integration);
  const handleRemove = () => removeIntegration(NAME);

  useEffect(fetchIntegrations, [fetchIntegrations]);
  return (
    <div className="mt-5 mb-5">
      {!authorized && (
        <>
          <h1>Zoho Integration</h1>
          <a href={authorizeUrl}>
            <Button className="m-2" color="success" size="lg">
              Authorize Zoho
            </Button>
          </a>
        </>
      )}

      {authorized && (
        <>
          <Button className="mr-2" onClick={handleRemove}>
            Remove
          </Button>
          <span className="text-danger">
            This will remove the integration and require re-authentication from
            Zoho
          </span>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerId: state.ui.auth.tenant.id,
  integration: get(['data', 'integrations', NAME], state),
});

export default connect(mapStateToProps, {
  fetchIntegrations,
  removeIntegration,
})(EditConnection);
