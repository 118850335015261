import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop, set } from 'lodash/fp';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { updateAuthTenantName } from '@portals/redux/actions/auth';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PartnerConfigurationType, TenantType } from '@portals/types';

import { OPERATOR_API_URL, PARTNERS_API_URL } from './partners.constants';
import { UseCreatePartnerRequestPayload } from './partners.types';
import { useClearDataCache } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { configurationQueryKeys, useConfig } from '../configuration';
import {
  DomainChangeType,
  PortalConfigType,
  useRequestDomainUpdate,
  useUpdatePortalConfig,
} from '../portal-config';

export function useUpdatePartner() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: config } = useConfig();
  const { url, options } = useRequestOptions({
    url: `${PARTNERS_API_URL}/${config?.[TenantType.Partner]?.id}`,
    method: 'PATCH',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (name) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ name }),
      });
    },
    onSuccess: async (_, name) => {
      queryClient.setQueryData<PartnerConfigurationType>(
        configurationQueryKeys.config,
        (currConfig) => {
          return set(
            [TenantType.Partner, 'name'],
            name,
            currConfig as PartnerConfigurationType
          );
        }
      );

      dispatch(updateAuthTenantName(name));
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdatePartner',
      baseUrl: `${PARTNERS_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}

interface UseUpdatePartnerInfoParams {
  display_name?: string;
  logo_url?: string;
}

export function useUpdatePartnerInfo() {
  const clearDataCache = useClearDataCache();
  const dispatch = useDispatch();

  const { data: config } = useConfig();
  const { url, options } = useRequestOptions({
    url: `${PARTNERS_API_URL}/${config?.[TenantType.Partner]?.id}`,
    method: 'PATCH',
  });

  return useMutation<
    PartnerConfigurationType[TenantType.Partner],
    ServerError,
    UseUpdatePartnerInfoParams
  >({
    mutationFn: (info) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(info),
      });
    },
    onSuccess: () => {
      clearDataCache([configurationQueryKeys.config]);
      dispatch(toastrSuccess('Partner info updated successfully'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdatePartnerInfo',
      baseUrl: `${PARTNERS_API_URL}/:id`,
      method: 'PATCH',
    },
  });
}

export function useUpdateDomain({
  onOnXyteDomainChanged,
  onOnXyteDomainChangeError,
  onCustomDomainRequested,
  onLogEvent = noop,
}: {
  onOnXyteDomainChanged: ({ name }: { name: string }) => void;
  onOnXyteDomainChangeError: (error: any) => void;
  onCustomDomainRequested?: (portalConfig: PortalConfigType) => void;
  onLogEvent?: (savedDomain: string) => void;
}) {
  const {
    mutateAsync: requestDomainUpdate,
    isLoading: isRequestingDomainUpdate,
  } = useRequestDomainUpdate();
  const { mutateAsync: updatePartnerName, isLoading: isUpdatingPartnerName } =
    useUpdatePartner();
  const { mutateAsync: updatePortalConfig, isLoading: isUpdatingPortalConfig } =
    useUpdatePortalConfig();

  const onUpdate = useCallback(
    async ({
      onXyteDomain,
      customDomain,
      domainChangeType,
    }: {
      onXyteDomain: string;
      customDomain?: string;
      domainChangeType: DomainChangeType;
    }) => {
      if (domainChangeType === DomainChangeType.OnXyte) {
        try {
          await updatePartnerName(onXyteDomain);

          onOnXyteDomainChanged({ name: onXyteDomain });
          onLogEvent(onXyteDomain);
        } catch ({ error }) {
          onOnXyteDomainChangeError(error);
        }
      } else {
        if (onCustomDomainRequested && customDomain) {
          await requestDomainUpdate({ domain: customDomain });
          const updatedPortalConfig = await updatePortalConfig({
            updatedConfig: {
              candidate_domain: customDomain,
            },
          });

          onCustomDomainRequested(updatedPortalConfig);
          onLogEvent(customDomain);
        }
      }
    },
    [
      onOnXyteDomainChangeError,
      onCustomDomainRequested,
      onOnXyteDomainChanged,
      onLogEvent,
      requestDomainUpdate,
      updatePartnerName,
      updatePortalConfig,
    ]
  );

  return {
    onUpdate,
    isLoading:
      isRequestingDomainUpdate ||
      isUpdatingPartnerName ||
      isUpdatingPortalConfig,
  };
}

interface UseCreatePartnerResponse {
  id: string;
  name: string;
}

export function useCreatePartner() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: OPERATOR_API_URL,
    method: 'POST',
  });

  return useMutation<
    UseCreatePartnerResponse,
    ServerError,
    UseCreatePartnerRequestPayload
  >({
    mutationFn: (payload) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(payload),
      }),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreatePartner',
      baseUrl: OPERATOR_API_URL,
      method: 'POST',
    },
  });
}
