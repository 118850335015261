import { API } from '@portals/redux';
import {
  replaceNormalizedData,
  updateNormalizedData,
} from '@portals/redux/actions/data';
import { RULES_SCHEMA } from '@portals/redux/schemas';
import { mergeActions } from '@portals/utils';

export const getRule = (id) => ({
  type: API,
  payload: {
    url: `ui/partner/rules/${id}`,
    normalize: RULES_SCHEMA,
    success: replaceNormalizedData('rules'),
  },
});

export const getRules = () => ({
  type: API,
  payload: {
    url: `ui/partner/rules`,
    normalize: [RULES_SCHEMA],
    success: replaceNormalizedData('rules'),
  },
});

export const getModelRules = (modelId) => ({
  type: API,
  payload: {
    url: `ui/partner/device_models/${modelId}/rules`,
    normalize: [RULES_SCHEMA],
    success: updateNormalizedData('rules'),
  },
});

export const addRule = (modelId, data, extraActions) => ({
  type: API,
  payload: {
    method: 'POST',
    url: `ui/partner/device_models/${modelId}/rules`,
    data,
    name: `addRule_${modelId}`,
    normalize: RULES_SCHEMA,
    success: mergeActions(updateNormalizedData('rules'), extraActions),
    toastr: 'Add Rule',
  },
});

export const updateRule = (ruleId, data, extraActions?) => ({
  type: API,
  payload: {
    method: 'PUT',
    url: `ui/partner/rules/${ruleId}`,
    data,
    name: `updateRule_${ruleId}`,
    normalize: RULES_SCHEMA,
    success: [updateNormalizedData('rules'), extraActions],
    toastr: 'Update Rule',
  },
});

export const deleteRule = (ruleId, extraActions) => ({
  type: API,
  payload: {
    method: 'DELETE',
    url: `ui/partner/rules/${ruleId}`,
    normalize: [RULES_SCHEMA],
    success: [replaceNormalizedData('rules'), extraActions],
    toastr: 'Delete Rule',
  },
});

export const clearErrors = (modelId, ruleId) => ({
  type: API,
  payload: {
    method: 'DELETE',
    url: `ui/partner/device_models/${modelId}/rules/${ruleId}/clear_errors`,
    normalize: RULES_SCHEMA,
    success: updateNormalizedData('rules'),
    toastr: 'Errors Removed',
  },
});
