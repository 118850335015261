import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSidebar } from '../actions/sidebarActions';
import { getIsSidebarOpen, getSidebar } from '../selectors/sidebar';

export const useSidebar = () => useSelector(getSidebar);

export const useIsSidebarOpen = () => useSelector(getIsSidebarOpen);

export function useToggleSidebar(): [boolean, VoidFunction] {
  const dispatch = useDispatch();

  const isSidebarOpen = useIsSidebarOpen();

  const toggle = useCallback(() => {
    return dispatch(toggleSidebar());
  }, [dispatch]);

  return [isSidebarOpen, toggle];
}
