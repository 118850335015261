import {
  Badge,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { usePermissionAccess } from '@portals/framework';

import RuleArea from './RuleArea';

const prepareDefinition = (
  base: {
    all?: Array<any>;
    any?: Array<any>;
  } = {}
) => {
  base.all = base.all || [];
  base.any = base.any || [];

  return base;
};

const RulesEditor = ({ value, field, setFieldValue }) => {
  const { classes } = useStyles();
  const definition = prepareDefinition(value);
  const { canEdit } = usePermissionAccess();

  const updateParent = (value) => setFieldValue(field.name, value);

  const addLine = (type) =>
    updateParent({ ...definition, [type]: [...definition[type], {}] });

  const updateLine = (type, index, line) => {
    const newLines = [...definition[type]];

    newLines.splice(index, 1, line);

    updateParent({ ...definition, [type]: newLines });
  };

  const removeLine = (type, index) =>
    updateParent({
      ...definition,
      [type]: definition[type].filter((item, i) => i !== index),
    });

  return (
    <Stack className={classes.wrapper}>
      <Stack p={0}>
        <Stack className={classes.section}>
          <Group
            position="center"
            py="sm"
            bg="gray.0"
            align="center"
            spacing="xs"
            className={classes.subHeader}
          >
            <Text color="gray.8">Match</Text>
            <Badge variant="outline" size="lg" px="xs" radius="sm">
              ALL
            </Badge>
            <Text color="gray.8">of the following:</Text>
          </Group>

          <Stack className={classes.sectionContent}>
            <RuleArea
              type="all"
              definition={definition}
              addLine={addLine}
              removeLine={removeLine}
              updateLine={updateLine}
              readOnly={!canEdit('models')}
            />
          </Stack>
        </Stack>

        <Divider
          variant="dashed"
          label="AND"
          labelPosition="center"
          color="gray.4"
        />

        <Stack className={classes.section}>
          <Group
            position="center"
            py="sm"
            bg="gray.0"
            align="center"
            spacing="xs"
            className={classes.subHeader}
          >
            <Text color="gray.8">Match</Text>
            <Badge variant="outline" size="lg" px="xs" radius="sm">
              ANY
            </Badge>
            <Text color="gray.8">of the following:</Text>
          </Group>

          <Stack className={classes.sectionContent}>
            <RuleArea
              type="any"
              definition={definition}
              addLine={addLine}
              removeLine={removeLine}
              updateLine={updateLine}
              readOnly={!canEdit('models')}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  header: {
    padding: `0 ${theme.spacing.sm}`,
    backgroundColor: theme.colors.gray[0],
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  section: {
    border: `1px solid ${theme.colors.gray[2]}`,
  },
  subHeader: {
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  sectionContent: {
    padding: `0 ${theme.spacing.sm} ${theme.spacing.sm}`,
  },
}));

export default RulesEditor;
