import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { keyBy, map, values } from 'lodash/fp';
import React, { FC, useState } from 'react';

import {
  PartnerUnshippedRequestProductType,
  useCreateShipment,
} from '@portals/api/partners';
import { ModalProps } from '@portals/framework';
import { ReactComponent as Devices } from '@portals/icons/linear/devices.svg';
import { ReactComponent as HashtagNormal } from '@portals/icons/linear/hashtag-normal.svg';

interface LabelProps {
  id: string;
  name: string;
}

const Label: FC<LabelProps> = ({ id, name }) => {
  const { classes } = useStyles();

  return (
    <Stack spacing="xs" className={classes.label}>
      <Group spacing="xs">
        <HashtagNormal />
        <Text size="xs">{id}</Text>
      </Group>

      <Group spacing="xs">
        <Devices />
        <Text size="xs">{name}</Text>
      </Group>
    </Stack>
  );
};

export function CreateShipment({
  closeMe,
  data: { products },
}: ModalProps<{ products: Array<PartnerUnshippedRequestProductType> }>) {
  const { classes } = useStyles();
  const createShipment = useCreateShipment();

  const productsMap: Record<string, { id: string; name: string; sn?: string }> =
    keyBy(
      'id',
      map(({ id, name }) => ({ id, name }), products)
    );

  const [trackingNumber, setTrackingNumber] = useState('');
  const [productsList, setProductsList] = useState(productsMap);

  const onSubmit = async () => {
    await createShipment.mutateAsync({
      tracking_number: trackingNumber,
      products: values(productsList),
    });

    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      title="Create Shipment"
      padding="xl"
      size="xl"
      radius="md"
    >
      <LoadingOverlay visible={createShipment.isLoading} />

      <Stack>
        <TextInput
          label="Tracking Number"
          value={trackingNumber}
          onChange={(event) => setTrackingNumber(event.target.value)}
          required
        />

        <Text size="sm">Serial Numbers (optional)</Text>

        <SimpleGrid cols={2}>
          {map(
            ({ id, name, sn }) => (
              <Box className={classes.fieldWrapper}>
                <TextInput
                  label={<Label id={id} name={name} />}
                  key={id}
                  value={sn || ''}
                  iconWidth={180}
                  placeholder={`Enter serial number for ${name}`}
                  onChange={(event) =>
                    setProductsList((curr) => ({
                      ...curr,
                      [id]: {
                        ...curr[id],
                        sn: event.target.value,
                      },
                    }))
                  }
                />
              </Box>
            ),
            productsList
          )}
        </SimpleGrid>
      </Stack>

      <Group position="right" pt="lg">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button type="submit" disabled={!trackingNumber} onClick={onSubmit}>
          Create
        </Button>
      </Group>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  fieldWrapper: {
    border: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
  },
  label: {
    color: theme.colors.blue_gray[4],

    svg: {
      width: 14,
      height: 14,
    },
  },
}));
