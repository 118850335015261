import { Avatar, AvatarProps } from '@mantine/core';
import React from 'react';

export function TenantAvatar(
  props: React.ComponentProps<typeof Avatar<'div'>>
) {
  return <Avatar p={3} radius="md" styles={avatarStyles} {...props} />;
}

const avatarStyles: AvatarProps['styles'] = {
  placeholder: {
    backgroundColor: 'transparent',
  },
  image: {
    objectFit: 'contain',
  },
};
