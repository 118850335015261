import { Collapse, createStyles, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@portals/icons/linear/chevron-down.svg';
import { useIsSidebarOpen } from '@portals/redux';

import { MenuSidebarItem } from './MenuSidebarItem';
import { HubSidebarItemWithSubItems } from '../hubs.types';
import { useHubs } from '../HubsContext';

interface CollapsibleSidebarItemProps {
  sidebarItem: HubSidebarItemWithSubItems;
}

export function CollapsibleSidebarItem({
  sidebarItem,
}: CollapsibleSidebarItemProps) {
  const { classes, cx } = useStyles();
  const { isSidebarItemActive } = useHubs();

  const isSidebarOpen = useIsSidebarOpen();

  const [isExpanded, setIsExpanded] = useState(true);

  const isActive = isSidebarItemActive(sidebarItem);

  if (!isSidebarOpen) {
    return <MenuSidebarItem sidebarItem={sidebarItem} />;
  }

  return (
    <Stack spacing="xs">
      <Group
        noWrap
        onClick={() => setIsExpanded((prev) => !prev)}
        data-testid={`collapsible-sidebar-item-${sidebarItem.id}`}
        className={cx(classes.sidebarItem, {
          active: isActive && !isExpanded,
          [classes.collapsedSidebarItem]: !isSidebarOpen,
        })}
      >
        <span>{sidebarItem.icon}</span>

        {isSidebarOpen && (
          <>
            <Text sx={{ flexGrow: 1 }}>{sidebarItem.label}</Text>

            <ChevronDownIcon
              className={cx(classes.chevron, {
                [classes.collapsed]: !isExpanded,
              })}
            />
          </>
        )}
      </Group>

      <Collapse in={isExpanded && isSidebarOpen}>
        <div className={classes.subItemsContainer}>
          {sidebarItem.subItems.map((subItem) => (
            <div key={subItem.path} className={classes.subItem}>
              <NavLink
                to={generatePath(subItem.path)}
                className={classes.subItemLabel}
                data-testid={`sidebar-sub-item-${subItem.path}`}
              >
                {subItem.label}
              </NavLink>
            </div>
          ))}
        </div>
      </Collapse>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  sidebarItem: {
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.md,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue_gray[6],
    userSelect: 'none',
    cursor: 'pointer',

    '&:hover, &.active': {
      backgroundColor: theme.colors.gray[2],
      color: theme.colors.gray[9],
    },
  },
  collapsedSidebarItem: {
    padding: 10,
  },
  chevron: {
    transition: 'transform 0.2s ease-in-out',
    transform: 'rotate(0deg)',
  },
  collapsed: {
    transform: 'rotate(180deg)',
  },
  subItemsContainer: {
    paddingLeft: theme.spacing.xl,
  },
  subItem: {
    position: 'relative',
    paddingInline: theme.spacing.sm,
    paddingBottom: theme.spacing.xs,
    borderLeft: `1px solid ${theme.colors.blue_gray[1]}`,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -1,
      height: '40%',
      width: 10,
      borderBottom: `1px solid ${theme.colors.blue_gray[1]}`,
      borderLeft: `1px solid ${theme.colors.blue_gray[1]}`,
      borderBottomLeftRadius: 6,
    },

    '&:last-child': {
      borderLeftColor: 'transparent',
    },
  },
  subItemLabel: {
    display: 'block',
    paddingInline: theme.spacing.md,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.md,
    borderRadius: theme.radius.md,
    color: theme.colors.blue_gray[6],

    '&:hover, &.active': {
      backgroundColor: theme.colors.gray[2],
      color: theme.colors.gray[9],
      textDecoration: 'none',
    },
  },
}));
