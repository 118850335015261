import { Anchor, Text } from '@mantine/core';
import { keyBy } from 'lodash/fp';
import React from 'react';
import { Link } from 'react-router-dom';

import { AuditLogType } from '@portals/types';

interface LicenseCodeGeneratedProps {
  auditLog: AuditLogType;
}

export function LicenseCodeGenerated({ auditLog }: LicenseCodeGeneratedProps) {
  const paramsMap = keyBy('name', auditLog.params);
  const shareableTokenParam = paramsMap['shareable_token'];
  const deviceModelParam = paramsMap['device_model'];

  return (
    <Text>
      <Text size="sm" span>
        Generated license code: {shareableTokenParam.value} for{' '}
        {deviceModelParam.model}
      </Text>

      <Anchor size="sm" component={Link} to={`/models/${deviceModelParam.id}`}>
        {deviceModelParam.display_name}
      </Anchor>
    </Text>
  );
}
