import { Divider, Stack } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { DomainChangeType } from '@portals/api/partners';
import { ReactComponent as Global } from '@portals/icons/linear/global.svg';
import { ReactComponent as New } from '@portals/icons/linear/new.svg';

import { useIsPricingPlanFeatureEnabled } from '../../hooks/partner-config';
import {
  customDomainValidation,
  onXyteDomainValidation,
} from '../OnboardingWizard/constants';
import { CustomDomainInput, OnXyteDomainInput } from './domain-inputs';
import DomainCard from './DomainCard';

interface ChangeDomainProps {
  onXyteDomain: string;
  customDomain?: string;
  domainChangeType: DomainChangeType;
  onSetDomainChangeType?: (type: DomainChangeType) => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement>,
    key: 'custom_domain' | 'on_xyte_domain'
  ) => void;
  onFocusToggle?: (isFocused: boolean) => void;
  externalOnXyteDomainError?: string | null;
  withCustomDomain: boolean;
}

const ChangeDomain: FC<ChangeDomainProps> = ({
  domainChangeType,
  onSetDomainChangeType,

  onXyteDomain,
  externalOnXyteDomainError,

  customDomain,

  onChange,
  onFocusToggle = noop,

  withCustomDomain,
}) => {
  const [onXyteDomainError, setDefaultDomainError] = useState(null);
  const [customDomainError, setCustomDomainError] = useState(null);
  const isCustomDomainEnabled = useIsPricingPlanFeatureEnabled('custom_domain');

  const [isFocused, setIsFocused] = useState(false);
  const focusTimeout = useRef<ReturnType<typeof setTimeout>>();

  useUpdateEffect(() => {
    if (!isFocused) {
      focusTimeout.current = setTimeout(() => {
        onFocusToggle(false);
      }, 250);
    } else {
      if (focusTimeout.current) {
        clearTimeout(focusTimeout.current);
      }

      onFocusToggle(true);
    }

    return () => {
      if (focusTimeout.current) {
        clearTimeout(focusTimeout.current);
      }
    };
  }, [isFocused]);

  const validateDefaultDomain = () => {
    if (onXyteDomain) {
      try {
        onXyteDomainValidation.validateSync(onXyteDomain);
      } catch ({ errors }) {
        setDefaultDomainError(errors[0]);
      }
    }
  };

  const validateCustomDomain = () => {
    if (customDomain) {
      const isValid = customDomainValidation(customDomain);

      if (!isValid) setCustomDomainError('Should be valid domain');
    }
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <DomainCard
        onSelect={() => onSetDomainChangeType?.(DomainChangeType.OnXyte)}
        isActive={domainChangeType === DomainChangeType.OnXyte}
        label="Create your custom on-xyte URL"
        Icon={New}
      >
        <OnXyteDomainInput
          value={onXyteDomain}
          onChange={(event) => {
            setDefaultDomainError(null);
            onChange(event, 'on_xyte_domain');
          }}
          onFocus={() => {
            setDefaultDomainError(null);
            setIsFocused(true);
          }}
          onBlur={() => {
            validateDefaultDomain();
            setIsFocused(false);
          }}
          autoFocus={domainChangeType === DomainChangeType.OnXyte}
          placeholder="your-domain"
          error={onXyteDomainError || externalOnXyteDomainError}
        />
      </DomainCard>

      {withCustomDomain ? (
        <>
          <Divider
            label="OR"
            labelPosition="center"
            styles={(theme) => ({
              label: {
                '&:before, &:after': {
                  borderTopColor: theme.colors.gray[3],
                },
              },
            })}
          />

          <DomainCard
            onSelect={() => onSetDomainChangeType?.(DomainChangeType.Custom)}
            isActive={domainChangeType === DomainChangeType.Custom}
            isDisabled={!isCustomDomainEnabled}
            label="Connect a domain you already own"
            Icon={Global}
          >
            <CustomDomainInput
              value={customDomain}
              placeholder="your-domain.com"
              onChange={(event) => {
                setCustomDomainError(null);
                onChange(event, 'custom_domain');
              }}
              onFocus={() => {
                setCustomDomainError(null);
                setIsFocused(true);
              }}
              onBlur={() => {
                validateCustomDomain();
                setIsFocused(false);
              }}
              autoFocus={domainChangeType === DomainChangeType.Custom}
              error={customDomainError}
            />
          </DomainCard>
        </>
      ) : null}
    </Stack>
  );
};

export default ChangeDomain;
