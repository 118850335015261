import { Box, Button, createStyles, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { size } from 'lodash';
import { filter, groupBy, sortBy } from 'lodash/fp';
import React, { ReactNode, useMemo } from 'react';

import { ReactComponent as ChevronDown } from '@portals/icons/linear/chevron-down.svg';
import { VerticalScrollBar } from '@portals/scrollbar';

import {
  TransferListItemWrapper,
  TransferListItemWrapperWithAccordion,
} from './transfer-list-renderers';
import { useTransferList } from './transfer-list.hooks';
import { TransferListItem, TransferListItems } from './transfer-list.types';
import { StepId } from '../../types';
import { useProductWizard } from '../../use-product-wizard';

export type TransferListProps<TItem extends object> = {
  groupByKey: keyof TItem;
  itemRenderer: (item: TransferListItem<TItem>) => React.ReactNode;
  transferableItemsTitleRenderer: (itemsCount: number) => ReactNode;
  transferredItemsTitleRenderer: (items: TransferListItems<TItem>) => ReactNode;
  searchTerm: string;
} & ReturnType<typeof useTransferList<TItem>>;

export function TransferList<TItem extends object>({
  // Props
  groupByKey,
  itemRenderer,
  transferableItemsTitleRenderer,
  transferredItemsTitleRenderer,
  searchTerm,

  // useTransferList
  transferableItems,
  transferredItems,
  transferableCheckedItems,
  transferredCheckedItems,
  onTransferableItemsMove,
  onTransferredItemsMove,
  onTransferableItemCheck,
  onTransferredItemCheck,
  getIsChecked,
}: TransferListProps<TItem>) {
  const { classes, theme, cx } = useStyles();
  const { contextData } = useProductWizard();

  const isSmallerThanLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const isLargerThanXl = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

  const showSupportedCommandIdsError =
    !!contextData.errors[StepId.ProductDetails].supportedCommandIds;

  const filteredTransferableList = useMemo(() => {
    if (!searchTerm) return transferableItems;

    const lowerCaseSearchTerm = searchTerm?.toLowerCase();

    return filter(
      (transferableItem) =>
        transferableItem.name.toLowerCase().includes(lowerCaseSearchTerm),
      transferableItems
    );
  }, [searchTerm, transferableItems]);

  const transferableItemsList = useMemo(() => {
    if (!filteredTransferableList?.length) {
      return (
        <Stack px="xl" spacing="xl" h="100%">
          <Box className={classes.transferableItemsEmptyState}>
            <Text size="xs" color="gray.7">
              {searchTerm ? 'No search results' : 'No available commands left'}
            </Text>
          </Box>
        </Stack>
      );
    }

    const groupedItems = groupBy(groupByKey, filteredTransferableList);
    const sortedGroupedItems = sortBy(
      ([groupName]) => groupName,
      Object.entries(groupedItems)
    );

    const groupedItemsList = sortedGroupedItems.map(
      ([groupName, groupItems]) => (
        <Stack key={groupName} spacing="sm">
          <Text color="gray.7" size="sm" fw={500}>
            {groupName}
          </Text>

          {groupItems.map((item: TransferListItem<TItem>) =>
            isLargerThanXl ? (
              <TransferListItemWrapperWithAccordion
                key={item.id}
                item={item}
                itemRenderer={itemRenderer}
                isChecked={getIsChecked('transferable', item.id)}
                onCheck={onTransferableItemCheck}
              />
            ) : (
              <TransferListItemWrapper
                key={item.id}
                item={item}
                itemRenderer={itemRenderer}
                isChecked={getIsChecked('transferable', item.id)}
                onCheck={onTransferableItemCheck}
              />
            )
          )}
        </Stack>
      )
    );

    return (
      <Stack px="xl" spacing="xl" h="100%">
        {groupedItemsList}
      </Stack>
    );
  }, [
    filteredTransferableList,
    groupByKey,
    classes.transferableItemsEmptyState,
    searchTerm,
    isLargerThanXl,
    itemRenderer,
    getIsChecked,
    onTransferableItemCheck,
  ]);

  const transferredItemsList = useMemo(() => {
    const groupedItems = groupBy(groupByKey, transferredItems);
    const sortedGroupedItems = sortBy(
      ([groupName]) => groupName,
      Object.entries(groupedItems)
    );

    const groupedItemsList = sortedGroupedItems.map(
      ([groupName, groupItems]) => (
        <Stack key={groupName} spacing="sm">
          <Text color="gray.7" size="sm">
            {groupName}
          </Text>

          {groupItems.map((item: TransferListItem<TItem>) =>
            isLargerThanXl ? (
              <TransferListItemWrapperWithAccordion
                key={item.id}
                item={item}
                itemRenderer={itemRenderer}
                isChecked={getIsChecked('transferred', item.id)}
                onCheck={onTransferredItemCheck}
              />
            ) : (
              <TransferListItemWrapper
                key={item.id}
                item={item}
                itemRenderer={itemRenderer}
                isChecked={getIsChecked('transferred', item.id)}
                onCheck={onTransferredItemCheck}
              />
            )
          )}
        </Stack>
      )
    );

    return (
      <Stack px="xl" spacing="xl">
        {groupedItemsList}
      </Stack>
    );
  }, [
    transferredItems,
    groupByKey,
    getIsChecked,
    itemRenderer,
    onTransferredItemCheck,
    isLargerThanXl,
  ]);

  if (!transferableItems?.length && !transferredItems.length) {
    return (
      <Stack align="center" spacing="xs" justify="center" h="100%">
        <Text size="md" weight={500}>
          No active commands
        </Text>

        <Text align="center" color="gray.7" w="80%">
          You need to create commands in the “Models” section first. If you
          already created commands, please mark them as active in the command
          settings.
        </Text>
      </Stack>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.transferredItemsWrapper}>
        {transferredItemsTitleRenderer(transferredItems)}
        {!transferredItems.length ? (
          <Stack
            justify="center"
            align="center"
            className={cx(classes.transferredItemsEmptyState, {
              [classes.transferredItemsErrorState]:
                showSupportedCommandIdsError,
            })}
            p="xl"
          >
            <Text
              maw={320}
              color={!showSupportedCommandIdsError ? 'gray.7' : 'red.6'}
              align="center"
              size="xs"
            >
              Select commands you wish to be included in this license and move
              them here by using the arrows.
            </Text>
          </Stack>
        ) : (
          <VerticalScrollBar
            className={classes.transferredItemsListContainer}
            renderView={(props) => (
              <Box {...props} className={classes.scrollContainer} />
            )}
          >
            <Box py="xl">{transferredItemsList}</Box>
          </VerticalScrollBar>
        )}
      </Box>

      <Stack
        justify="center"
        spacing="sm"
        className={cx({ [classes.transferButtons]: isSmallerThanLg })}
      >
        <Button
          data-testid="left-arrow-button"
          color="gray"
          className={classes.transferButton}
          h={40}
          w={40}
          p={0}
          disabled={transferableCheckedItems.length === 0}
          onClick={() => onTransferableItemsMove()}
          variant="default"
        >
          <ChevronDown
            color={
              size(transferableCheckedItems) === 0
                ? theme.colors.gray[4]
                : theme.colors.gray[9]
            }
            style={{
              transform: !isSmallerThanLg ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          />
        </Button>

        <Button
          data-testid="right-arrow-button"
          color="gray"
          className={classes.transferButton}
          h={40}
          w={40}
          p={0}
          disabled={transferredCheckedItems.length === 0}
          onClick={onTransferredItemsMove}
          variant="default"
        >
          <ChevronDown
            color={
              size(transferredCheckedItems) === 0
                ? theme.colors.gray[4]
                : theme.colors.gray[9]
            }
            style={{
              transform: !isSmallerThanLg ? 'rotate(-90deg)' : 'rotate(180deg)',
            }}
          />
        </Button>
      </Stack>

      <Box className={classes.transferableItemsWrapper}>
        {transferableItemsTitleRenderer(filteredTransferableList?.length)}

        <VerticalScrollBar
          className={classes.transferableItemsListContainer}
          renderView={(props) => (
            <Box {...props} className={classes.scrollContainer} />
          )}
        >
          <Box h="100%" py="xl">
            {transferableItemsList}
          </Box>
        </VerticalScrollBar>
      </Box>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content 1fr',
    gridTemplateRows: '1fr',
    gap: 40,
    width: '100%',
    height: '100%',
    overflow: 'hidden !important',
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('lg')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      overflow: 'scroll',
    },
  },
  transferredItemsWrapper: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: 22,
    paddingTop: 4,

    [theme.fn.smallerThan('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  transferredItemsListContainer: {
    background: theme.colors.gray[0],
    border: `1px solid ${theme.colors.gray[3]}`,
    height: '100%',
    borderRadius: theme.radius.md,

    [theme.fn.smallerThan('lg')]: {
      height: '100%',
    },
  },
  transferredItemsEmptyState: {
    background: theme.colors.gray[0],
    border: `1px solid ${theme.colors.gray[3]}`,
    height: '100%',
    borderRadius: theme.radius.md,
  },
  transferredItemsErrorState: {
    background: theme.fn.rgba(theme.colors.red[0], 0.24),
    border: `1px solid ${theme.colors.red[4]}`,
  },
  transferButton: {
    border: `1px solid ${theme.colors.gray[3]}`,

    '&:hover': {
      border: `1px solid ${theme.colors.gray[4]}`,
      backgroundColor: theme.white,
    },
  },
  transferableItemsWrapper: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gap: theme.spacing.md,

    [theme.fn.smallerThan('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  transferableItemsListContainer: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.gray[0],

    [theme.fn.smallerThan('lg')]: {
      height: '100%',
    },
  },

  transferButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  transferableItemsEmptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  scrollContainer: {
    marginBottom: `${theme.spacing.xl} !important`,
  },
}));
