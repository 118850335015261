import { Divider, Group, Stack } from '@mantine/core';
import { filter, findIndex, set } from 'lodash/fp';
import React, { ReactNode } from 'react';

import { ModalButton } from '@portals/framework';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import {
  SupportedCommandFieldType,
  SupportedCommandType,
} from '@portals/types';

import CustomFieldsList from './CustomFieldsList';

interface CustomFieldsProps {
  command: Partial<SupportedCommandType>;
  onChange: (data: Partial<SupportedCommandType>) => void;
  children?: ReactNode;
}

function CustomFields({ children, command, onChange }: CustomFieldsProps) {
  const onAddCustomField = (customField: SupportedCommandFieldType) => {
    onChange({
      ...command,
      custom_fields: [...(command.custom_fields || []), customField],
    });
  };

  const onRemoveCustomField = (fieldName: string) => {
    onChange({
      ...command,
      custom_fields: filter(
        ({ name }) => name !== fieldName,
        command.custom_fields
      ),
    });
  };

  const onFieldValueUpdate = (
    fieldName: string,
    path: Array<string>,
    value: any
  ) => {
    const fieldIndex = findIndex({ name: fieldName }, command.custom_fields);

    onChange({
      ...command,
      custom_fields: set([fieldIndex, ...path], value, command.custom_fields),
    });
  };

  const onFieldUpdate = (
    field: SupportedCommandFieldType,
    fieldName: string
  ) => {
    const fieldIndex = findIndex({ name: fieldName }, command.custom_fields);

    onChange({
      ...command,
      custom_fields: set([fieldIndex], field, command.custom_fields),
    });
  };

  const onUpdateFieldPosition = (dragIndex: number, hoverIndex: number) => {
    const fieldsList = [...command.custom_fields];
    [fieldsList[dragIndex], fieldsList[hoverIndex]] = [
      fieldsList[hoverIndex],
      fieldsList[dragIndex],
    ];

    onChange({
      ...command,
      custom_fields: fieldsList,
    });
  };

  return (
    <>
      <Stack spacing="xl" pb="xl">
        {children}

        <Divider label="CUSTOM FIELDS" labelPosition="center" />

        <CustomFieldsList
          command={command}
          onRemove={onRemoveCustomField}
          onFieldValueUpdate={onFieldValueUpdate}
          onFieldUpdate={onFieldUpdate}
          onUpdateFieldPosition={onUpdateFieldPosition}
        />

        <Group position="center">
          <ModalButton
            data={{
              onSubmit: onAddCustomField,
              custom_fields: command.custom_fields,
            }}
            variant="default"
            color="blue_gray"
            modalName="CustomFieldForm"
            data-testid="add-custom-field-button"
            label="Add custom field"
            leftIcon={<AddSquare />}
            sx={{
              svg: {
                height: 16,
                width: 16,
              },
            }}
          />
        </Group>
      </Stack>
    </>
  );
}

export default CustomFields;
