import { Button, Group, ModalProps as MantineModalProps } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SuccessIcon } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

interface ProductCreationSuccessModalProps extends ModalProps {}

export function ProductCreationSuccessModal({
  closeMe,
}: ProductCreationSuccessModalProps) {
  const navigate = useNavigate();

  const onCreateProductListingClick = () => {
    closeMe();
    navigate('/products?modal=store-listing-wizard');
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      styles={modalStyles}
      title="Product created successfully"
      media={<SuccessIcon />}
      description="You need to create and publish a product listing in order to sell this product in your store. Would you like to do this now?"
      footer={
        <Group grow w="100%">
          <Button
            variant="default"
            onClick={closeMe}
            data-testid="i-will-do-it-later-button"
            size="md"
          >
            I'll do it later
          </Button>

          <Button
            onClick={onCreateProductListingClick}
            data-testid="create-product-listing-button"
            size="md"
          >
            Create product listing
          </Button>
        </Group>
      }
    />
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    paddingBottom: 0,
  },
});
