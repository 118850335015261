import {
  ActionIcon,
  Group,
  Input,
  NumberInput,
  SegmentedControl,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { motion } from 'framer-motion';
import { last } from 'lodash';
import { size } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { BarIndicatorWidgetFormType } from './bar-indicator-form.types';
import { InputLabelWithTooltip } from '../../common/input-helpers';
import { NumberFormatSelector } from '../../common/NumberFormatSelector';
import { SegmentedRangeSelector } from '../../common/segmented-range-selector/SegmentedRangeSelector';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface BarIndicatorWidgetFormProps {
  form: UseFormReturnType<BarIndicatorWidgetFormType>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function BarIndicatorWidgetForm({
  form,
  colors,
  onAddCustomColor,
}: BarIndicatorWidgetFormProps) {
  const adjustedSegments = useMemo(
    () =>
      (form.values.segments || []).map((segment, index) => ({
        ...segment,
        disabled: index === size(form.values.segments) - 1,
      })),
    [form.values.segments]
  );

  const onAddSegment = useCallback(() => {
    // Add a segment in the middle of last 2 segments
    const lastSegment = last(form.values.segments);
    const penultimateSegment =
      form.values.segments[size(form.values.segments) - 2];

    if (!lastSegment) return;

    if (!penultimateSegment) {
      // add between global min & last segment
      form.setFieldValue('segments', [
        {
          id: uuid(),
          max: (lastSegment.max + form.values.min) / 2,
          color: 'blue_accent.4',
        },
        lastSegment,
      ]);
    } else {
      form.setFieldValue('segments', [
        ...form.values.segments.slice(0, size(form.values.segments) - 1),
        {
          id: uuid(),
          max: (lastSegment.max + penultimateSegment.max) / 2,
          color: 'blue_accent.4',
        },
        lastSegment,
      ]);
    }
  }, [form]);

  const onRemoveSegment = useCallback(
    (segmentIndex: number) => form.removeListItem('segments', segmentIndex),
    [form]
  );

  const onUpdateSegmentColor = useCallback(
    (segmentIndex: number, color: WidgetColorType) =>
      form.setFieldValue(`segments.${segmentIndex}.color`, color),
    [form]
  );

  const onUpdateSegmentMax = useCallback(
    (segmentIndex: number, max: number) =>
      form.setFieldValue(`segments.${segmentIndex}.max`, max),
    [form]
  );

  const globalRange = useMemo(
    () => ({
      min: form.values.min,
      max: form.values.max,
    }),
    [form.values.min, form.values.max]
  );

  return (
    <Stack spacing="xl" pl={30} pr={45}>
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          data-testid="title-input-text"
          autoFocus
          data-autofocus
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}

        <Input.Wrapper>
          <Stack spacing={0}>
            <Input.Label>Scale Type</Input.Label>

            <SegmentedControl
              data={[
                {
                  label: 'Linear',
                  value: 'linear',
                },
                {
                  label: 'Logarithmic',
                  value: 'log',
                },
              ]}
              value={form.values.scale_type}
              onChange={(value: 'linear' | 'log') =>
                form.setFieldValue('scale_type', value)
              }
            />
          </Stack>
        </Input.Wrapper>

        <NumberFormatSelector
          format={form.values.number_format}
          numOfDecimals={form.values.num_of_decimals}
          onFormatChange={(format) =>
            form.setFieldValue('number_format', format)
          }
          onNumOfDecimalsChange={(numOfDecimals) =>
            form.setFieldValue('num_of_decimals', numOfDecimals)
          }
        />

        <motion.div layout="position">
          <Input.Wrapper>
            <Group align="center" noWrap>
              <Input.Label w="100%">
                <Text size="sm" color="gray.7">
                  Segments
                </Text>
              </Input.Label>

              <ActionIcon
                size="sm"
                color="gray.7"
                onClick={onAddSegment}
                h={24}
                w={24}
              >
                <Tooltip label="Add segment" withinPortal>
                  <Add />
                </Tooltip>
              </ActionIcon>
            </Group>

            <SegmentedRangeSelector
              colors={colors}
              onAddCustomColor={onAddCustomColor}
              range={globalRange}
              segments={adjustedSegments}
              onRemoveSegment={onRemoveSegment}
              onUpdateSegmentColor={onUpdateSegmentColor}
              onUpdateSegmentMax={onUpdateSegmentMax}
              numberFormat={form.values.number_format}
              numOfDecimals={form.values.num_of_decimals}
            />
          </Input.Wrapper>
        </motion.div>
      </Stack>

      <motion.div layout="position">
        <Stack>
          <Text size="sm" color="gray.9">
            Configuration
          </Text>

          <TextInput
            {...form.getInputProps('telemetry')}
            data-testid="telemetry-input-text"
            label="Telemetry"
            required
          />

          <Group grow>
            <NumberInput
              {...form.getInputProps('min')}
              min={form.values.scale_type === 'log' ? 1 : undefined}
              max={form.values.max}
              data-testid="minimum-input-text"
              label="Minimum"
              precision={
                form.values.number_format === 'none'
                  ? 0
                  : form.values.num_of_decimals
              }
              required
            />

            <NumberInput
              {...form.getInputProps('max')}
              min={form.values.min}
              data-testid="maximum-input-text"
              label="Maximum"
              precision={
                form.values.number_format === 'none'
                  ? 0
                  : form.values.num_of_decimals
              }
              required
            />
          </Group>

          <TextInput
            {...form.getInputProps('unit')}
            data-testid="unit-input-text"
            label="Unit (optional)"
          />
        </Stack>
      </motion.div>
    </Stack>
  );
}
