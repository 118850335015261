import { Button, Group, Space, Stack, Text } from '@mantine/core';
import { isEmpty, isNumber } from 'lodash/fp';
import React, { useState } from 'react';

import { PostPurchaseParamsStepFieldType } from '@portals/types';

import { AddPostPurchaseParamModal } from './AddPostPurchaseParamModal';
import { EditPostPurchaseParamModal } from './EditPostPurchaseParamModal';
import { PostPurchaseParamsFields } from './PostPurchaseParamsFields';
import { DetailsColumnContent } from '../common/DetailsColumnContent';
import { useProductWizard } from '../use-product-wizard';

export function PostPurchaseParametersStep() {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [fieldIndexToEdit, setFieldIndexToEdit] = useState<number | null>(null);

  const { contextData, setContextData } = useProductWizard();
  const { product } = contextData;

  const onConfirmAdd = (field: PostPurchaseParamsStepFieldType) => {
    const updatedProduct = { ...product };

    if (!product.post_purchase_parameters) {
      updatedProduct.post_purchase_parameters = [field];
    } else {
      updatedProduct.post_purchase_parameters.push(field);
    }

    setContextData((curr) => ({
      ...curr,
      product: updatedProduct,
    }));

    setIsAddOpen(false);
  };

  const onConfirmEdit = (field: PostPurchaseParamsStepFieldType) => {
    onChange(fieldIndexToEdit as number, field);

    setFieldIndexToEdit(null);
  };

  const onReorder = (dragIndex: number, hoverIndex: number) => {
    const updatedProduct = { ...product };

    [
      updatedProduct.post_purchase_parameters[dragIndex],
      updatedProduct.post_purchase_parameters[hoverIndex],
    ] = [
      updatedProduct.post_purchase_parameters[hoverIndex],
      updatedProduct.post_purchase_parameters[dragIndex],
    ];

    setContextData((curr) => ({
      ...curr,
      product: updatedProduct,
    }));
  };

  const onRemove = (index: number) => {
    const updatedProduct = { ...product };

    updatedProduct.post_purchase_parameters.splice(index, 1);

    setContextData((curr) => ({
      ...curr,
      product: updatedProduct,
    }));
  };

  const onChange = (index: number, field: PostPurchaseParamsStepFieldType) => {
    const updatedProduct = { ...product };

    updatedProduct.post_purchase_parameters[index] = field;

    setContextData((curr) => ({
      ...curr,
      product: updatedProduct,
    }));
  };

  return (
    <>
      <DetailsColumnContent title="Post Purchase Data">
        <Stack spacing="xl" h="100%">
          <Text size="md" color="gray.8">
            In this step you can customize post-purchase data collection form.
            The customer will be required to fill these details after the
            purchase will be placed.
            {isEmpty(product.post_purchase_parameters) ? (
              <Text inline inherit pt="md">
                Start by adding a parameter
              </Text>
            ) : null}
          </Text>

          <PostPurchaseParamsFields
            fields={product.post_purchase_parameters || []}
            onChange={onChange}
            onRemove={onRemove}
            onEdit={setFieldIndexToEdit}
            onReorder={onReorder}
          />

          <Group>
            <Button
              variant="default"
              data-testid="add-parameter-modal-button"
              onClick={() => setIsAddOpen(true)}
            >
              Add Parameter
            </Button>
          </Group>

          <Space h="md" w="100%" />
        </Stack>
      </DetailsColumnContent>

      <AddPostPurchaseParamModal
        opened={isAddOpen}
        onCancel={() => setIsAddOpen(false)}
        onConfirm={onConfirmAdd}
        postPurchaseParams={product?.post_purchase_parameters}
      />

      <EditPostPurchaseParamModal
        opened={isNumber(fieldIndexToEdit)}
        onCancel={() => setFieldIndexToEdit(null)}
        onConfirm={onConfirmEdit}
        fieldIndex={fieldIndexToEdit}
        postPurchaseParams={product?.post_purchase_parameters}
      />
    </>
  );
}
