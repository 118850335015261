import {
  Box,
  Group,
  Input,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';

import { BarControllerWidgetFormType } from './bar-controller-form.types';
import { GradientSelector } from '../../common/gradient-selector/GradientSelector';
import { InputLabelWithTooltip } from '../../common/input-helpers';
import { OnAddCustomColorFn, WidgetColorType } from '../../widgets.types';

export interface BarControllerWidgetFormProps {
  form: UseFormReturnType<BarControllerWidgetFormType>;
  commands: Array<{ value: string; label: string; disabled: boolean }>;
  params: Array<{ value: string; label: string; disabled: boolean }>;
  colors: Array<WidgetColorType> | undefined;
  onAddCustomColor: OnAddCustomColorFn | undefined;
}

export function BarControllerWidgetForm({
  form,
  commands,
  params,
  colors,
  onAddCustomColor,
}: BarControllerWidgetFormProps) {
  const onChangeGradient = (gradient: typeof form.values.gradient) => {
    form.setFieldValue('gradient', gradient);
  };

  return (
    <Stack spacing="xl">
      <Stack>
        <Text size="sm" color="gray.9">
          General
        </Text>

        <TextInput
          {...form.getInputProps('name')}
          data-testid="bar-controller-title-input"
          autoFocus
          data-autofocus
          label={form.values.telemetry_as_title ? 'Widget Name' : 'Title'}
          required
        />

        <Switch
          label="Use telemetry as title"
          data-testid="use-telemetry-as-title-checkbox"
          {...form.getInputProps('telemetry_as_title', {
            type: 'checkbox',
          })}
        />

        {form.values.telemetry_as_title ? (
          <TextInput
            {...form.getInputProps('title_telemetry')}
            data-autofocus
            withAsterisk={false}
            data-testid="title-telemetry-input"
            label={
              <InputLabelWithTooltip
                tooltipLabel="Key of telemetry value that will be used as title"
                label="Title Telemetry"
                Icon={QuestionCircle}
              />
            }
            required
          />
        ) : null}

        <Input.Wrapper>
          <Input.Label w="100%">
            <Group w="100%" position="apart">
              <Text size="sm" color="gray.7">
                Colors
              </Text>
            </Group>
          </Input.Label>

          <GradientSelector
            colors={colors}
            onAddCustomColor={onAddCustomColor}
            gradient={form.values.gradient}
            onChangeGradient={onChangeGradient}
          />
        </Input.Wrapper>
      </Stack>

      <Stack>
        <Text size="sm" color="gray.9">
          Configuration
        </Text>

        <Group grow>
          <TextInput
            {...form.getInputProps('telemetry')}
            withAsterisk={false}
            data-testid="numeric-telemetry-key-input"
            label={
              <Group align="center" spacing="xs">
                <Input.Label required p={0} m={0}>
                  Numeric Telemetry Key
                </Input.Label>

                <Tooltip
                  withArrow
                  withinPortal
                  label="The key to obtain numeric telemetry data used to determine the slider's value"
                  p="md"
                >
                  <Text color="gray.4">
                    <QuestionCircle width={16} height={16} />
                  </Text>
                </Tooltip>
              </Group>
            }
            required
          />

          <TextInput
            {...form.getInputProps('unit')}
            data-testid="unit-input"
            label="Unit (optional)"
          />
        </Group>

        <Group grow>
          <Group grow>
            <Select
              data={commands}
              searchable
              clearable
              required
              withinPortal
              labelProps={{
                w: '100%',
              }}
              withAsterisk={false}
              data-testid="command-input"
              label={
                <Group align="center" spacing="xs">
                  <Input.Label required p={0} m={0}>
                    Command
                  </Input.Label>

                  <Tooltip
                    withArrow
                    withinPortal
                    label="The command sent to the device when the slider is updated"
                    p="md"
                  >
                    <Text color="gray.4">
                      <QuestionCircle width={16} height={16} />
                    </Text>
                  </Tooltip>
                </Group>
              }
              {...form.getInputProps('command_name')}
              itemComponent={({ label, ...rest }) => (
                <Group {...rest} position="apart" align="center">
                  <Text>{label}</Text>

                  {rest.disabled ? (
                    <Tooltip
                      withArrow
                      withinPortal
                      label="Command must have a numeric field"
                      p="md"
                    >
                      <Text color="amber.4">
                        <InfoCircle width={16} height={16} />
                      </Text>
                    </Tooltip>
                  ) : null}
                </Group>
              )}
            />

            <Select
              data={params}
              searchable
              clearable
              required
              withinPortal
              withAsterisk={false}
              data-testid="numeric-parameter-input"
              label={
                <Group align="center" spacing="xs">
                  <Input.Label required p={0} m={0}>
                    Numeric Parameter
                  </Input.Label>

                  <Tooltip
                    withArrow
                    withinPortal
                    label="The key of the numeric parameter sent with the update command"
                    p="md"
                  >
                    <Text color="gray.4">
                      <QuestionCircle width={16} height={16} />
                    </Text>
                  </Tooltip>
                </Group>
              }
              {...form.getInputProps('command_param_key')}
              itemComponent={({ label, ...rest }) => (
                <Box
                  {...rest}
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr max-content',
                    gap: theme.spacing.sm,
                  })}
                >
                  <Text truncate>{label}</Text>

                  {rest.disabled ? (
                    <Tooltip
                      withArrow
                      withinPortal
                      label="Only numeric paramaters are supported"
                      p="md"
                    >
                      <Text color="amber.4">
                        <InfoCircle width={16} height={16} />
                      </Text>
                    </Tooltip>
                  ) : null}
                </Box>
              )}
            />
          </Group>
        </Group>

        <Group grow>
          <NumberInput
            {...form.getInputProps('min')}
            data-testid="minimum-input"
            label="Minimum"
            required
          />

          <NumberInput
            {...form.getInputProps('max')}
            data-testid="maximum-input"
            label="Maximum"
            required
          />
        </Group>
      </Stack>
    </Stack>
  );
}
