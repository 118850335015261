import { Alert, AlertProps, Anchor, Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';

import { SplineChartWidgetFormType } from './spline-chart-form.types';

interface DynamicMeasurementsProps {
  form: UseFormReturnType<SplineChartWidgetFormType>;
}

export function DynamicMeasurements({ form }: DynamicMeasurementsProps) {
  return (
    <Stack spacing="xxl">
      <Alert
        icon={<InfoCircle width={18} height={18} />}
        title="Dynamic parameters"
        color="gray"
        styles={alertStyles}
        p="xl"
      >
        Dynamic parameters let you define where to find the measurement in the
        device configuration or details, and then have it update dynamically per
        device. <br />
        <Anchor
          href="https://dev.xyte.io/docs/custom-widget-params"
          target="_blank"
        >
          Learn more here
        </Anchor>
      </Alert>

      <TextInput
        label="Path"
        placeholder="Example: details.video_settings[1].supported_inputs"
        required
        {...form.getInputProps('path')}
      />
    </Stack>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  root: {
    borderRadius: theme.radius.lg,
  },

  label: {
    fontWeight: 500,
  },

  message: {
    color: theme.colors.gray[8],
  },
});
