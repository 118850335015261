import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { setValue } from '@portals/redux/actions/data';
import { toastrError } from '@portals/redux/actions/toastr';
import { TestDeviceType } from '@portals/types';

import { DevTestingFormValues } from './dev-testing.types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { deviceModelsQueryKeys } from '../device-models/device-models.constants';
import { devicesQueryKeys } from '../devices';

export const useRegisterDevice = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: (
      deviceConfig: Partial<DevTestingFormValues>
    ): Promise<Partial<TestDeviceType>> =>
      fetchApiRequest(`${process.env.NX_DEVICE_HUB_URL}/v1/devices`, {
        ...options,
        body: JSON.stringify(deviceConfig),
      }),
    onSuccess: (response: Partial<TestDeviceType>) => {
      dispatch(setValue('dev', 'auth')(response));

      queryClient.invalidateQueries(devicesQueryKeys.list);
      queryClient.invalidateQueries(deviceModelsQueryKeys.base);
    },
    onError: ({ error }: any = {}) => {
      if (!error) return;

      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useRegisterDevice',
      baseUrl: `${process.env.NX_DEVICE_HUB_URL}/v1/devices`,
      method: 'POST',
    },
  });
};
