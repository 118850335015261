import { Button, createStyles, Group, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  DeviceModelSummaryType,
  useBulkPublishDeviceModels,
} from '@portals/api/partners';
import { DeviceAvatar, ModalProps } from '@portals/framework';

export interface PublishDeviceModelsSummarizedModalProps
  extends ModalProps<{
    deviceModels: Array<DeviceModelSummaryType>;
  }> {}

export function PublishDeviceModelsSummarized({
  closeMe,
  data: { deviceModels },
}: PublishDeviceModelsSummarizedModalProps) {
  const { classes } = useStyles();
  const publishDeviceModels = useBulkPublishDeviceModels();

  const onSubmit = () => {
    const deviceModelIds = deviceModels.map((deviceModel) => deviceModel.id);

    publishDeviceModels.mutate(deviceModelIds, { onSuccess: closeMe });
  };

  return (
    <Modal opened onClose={closeMe} title="Publish Model">
      <Stack spacing="md">
        {deviceModels.map((deviceModel) => (
          <Group key={deviceModel.id} className={classes.row}>
            <DeviceAvatar
              src={deviceModel.image_url}
              icon={deviceModel.user_settings.icon}
              size={36}
            />

            <Text size="sm" color="blue_gray.9">
              {deviceModel.model}
            </Text>
          </Group>
        ))}

        <Group grow spacing="md">
          <Button variant="default" onClick={closeMe}>
            Back
          </Button>

          <Button
            onClick={onSubmit}
            loading={publishDeviceModels.isLoading}
            data-testid="publish-models"
          >
            Publish Models
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  row: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    padding: `${theme.spacing.md} ${theme.spacing.xs}`,
  },
}));
