import classNames from 'classnames';
import { pick } from 'lodash/fp';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useWizard } from '@portals/framework';

import CustomUrlForm from './CustomUrlForm';
import BrowserMock from '../BrowserMock';
import { LayoutContainer } from '../common';
import DetailsArea from '../DetailsArea';
import PreviewArea from '../PreviewArea';
import { SignInBrowserMock } from '../SignInBrowserMock';
import { OnboardingWizardContextDataType, StepId } from '../types';

function CustomUrlStep() {
  const [isFocused, setIsFocused] = useState(false);
  const { contextData } = useWizard<OnboardingWizardContextDataType, StepId>();

  const domain = `${contextData.on_xyte_domain || 'your-domain'}.on-xyte.com`;

  return (
    <LayoutContainer>
      <DetailsArea>
        <CustomUrlForm onFocusToggle={(isFocused) => setIsFocused(isFocused)} />
      </DetailsArea>

      <PreviewArea
        contentRenderer={() => (
          <Content className={classNames({ focused: isFocused })}>
            <BrowserMock domain={domain} portalConfig={contextData}>
              <SignInBrowserMock portalConfig={pick(['design'], contextData)} />
            </BrowserMock>
          </Content>
        )}
      />
    </LayoutContainer>
  );
}

const Content = styled.div`
  transition: all 0.4s linear;

  &.focused {
    transform: scale(2.2) translate(30%, 40%);
  }
`;

export default CustomUrlStep;
