import { Box, Stack, Text, Tooltip } from '@mantine/core';
import { keys, size } from 'lodash/fp';
import React, { FC, ReactNode } from 'react';

import { SupportedCommandFieldType } from '@portals/types';

interface ColumnProps {
  label: string;
  content: ReactNode;
}

export const Column: FC<ColumnProps> = ({ label, content }) => (
  <Stack spacing={5}>
    <Text size="xs" color="blue_gray">
      {label ? `${label}:` : ''}
    </Text>

    {content}
  </Stack>
);

interface OptionsColumnProps {
  field: SupportedCommandFieldType;
}

export const OptionsColumn: FC<OptionsColumnProps> = ({ field }) => {
  const sortedOptionsArray = keys(field.options)
    .map((optionKey) => ({
      value: optionKey,
      label: field.options?.[optionKey].label,
      order: field.options?.[optionKey].order,
    }))
    .sort((a, b) => a.order - b.order);

  return (
    <Column
      label="Options"
      content={
        <Tooltip
          label={
            <Stack justify="center" spacing="xs" p="xs">
              {sortedOptionsArray.map(({ value, label }) => (
                <Box
                  key={value}
                  sx={(theme) => ({
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    columnGap: theme.spacing.md,
                  })}
                >
                  <Stack spacing={5}>
                    <Text size="xs" color="blue_gray">
                      Key:
                    </Text>

                    <Text size="sm">{value}</Text>
                  </Stack>

                  <Stack spacing={5}>
                    <Text size="xs" color="blue_gray">
                      Label:
                    </Text>

                    <Text size="sm">{label}</Text>
                  </Stack>
                </Box>
              ))}
            </Stack>
          }
          position="top"
          withArrow
        >
          <Text size="sm">{size(field.options)} options</Text>
        </Tooltip>
      }
    />
  );
};
