import { StoreListing, useStoreSettings } from '@portals/api/partners';
import {
  CurrencyCode,
  PaymentIntervalEnum,
  ProductPricingModel,
} from '@portals/types';
import { formatPrice } from '@portals/utils';

import { mapStoreListingPricesToPaymentSettings } from '../utils/store-listings.utils';

const PERIOD_LABELS: Record<
  Exclude<PaymentIntervalEnum, PaymentIntervalEnum.UsageBased>,
  string
> = {
  [PaymentIntervalEnum.OneTime]: '',
  [PaymentIntervalEnum.Monthly]: ' / month',
  [PaymentIntervalEnum.Yearly]: ' / year',
};

export function useStoreListingPeriodPriceTitle() {
  const storeSettings = useStoreSettings();

  const getPriceTitle = (params: {
    currencyCode?: CurrencyCode;
    period: PaymentIntervalEnum;
    product?: StoreListing['product'];
    paymentSettings: ReturnType<typeof mapStoreListingPricesToPaymentSettings>;
  }) => {
    const currency =
      params.currencyCode || storeSettings.data?.default_currency;

    if (!currency) return 'No currency set';

    const price = params.paymentSettings[params.period]?.prices?.find(
      (price) => price.currency === currency
    );

    if (!price) return 'No price set';

    const formattedPrice = formatPrice({
      value: price.amountInScu,
      currencyCode: price.currency,
    });

    let label: string;

    if (params.product?.pricing_model === ProductPricingModel.UsageBased) {
      label = ` / ${params.product.usage_based_unit_name}`;
    } else {
      label = PERIOD_LABELS[params.period as keyof typeof PERIOD_LABELS];
    }

    return `${formattedPrice}${label}`;
  };

  return { getPriceTitle, isLoadingStoreSettings: storeSettings.isLoading };
}
