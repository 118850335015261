import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { fetchApiRequest, useApiQuery, useRequestOptions } from '@portals/api';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

interface MutationFnParams<StepId extends PropertyKey = string> {
  stepId: StepId | 'complete';
  withSuccessNotification?: boolean;
}

export function useUpdateOnboardingStep<StepId extends PropertyKey = string>() {
  const dispatch = useDispatch();
  const { url, options } = useRequestOptions({
    url: 'ui/onboarding/update_step',
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({ stepId }: MutationFnParams<StepId>) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ step: stepId }),
      }),
    onSuccess: (_, { withSuccessNotification }) => {
      if (withSuccessNotification) {
        dispatch(toastrSuccess('Onboarding step updated successfully'));
      }
    },
    onError: ({ error }: any) => dispatch(toastrError(error)),
  });
}

interface FetchDashboardStepsStatusResponse {
  products_exist: boolean;
  commands_exist: boolean;
  files_exist: boolean;
  rules_exist: boolean;
}
export function useFetchDashboardStepsStatus() {
  return useApiQuery<FetchDashboardStepsStatusResponse>(
    'ui/onboarding/dashboard_steps',
    ['dashboard_steps_status'],
    { staleTime: 500 }
  );
}
