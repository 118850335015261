import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Flex,
  Group,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { noop } from 'lodash/fp';
import React from 'react';

import {
  convertWidgetFormToWidgetProps,
  StateControllerWidget,
  StateControllerWidgetForm,
  StateControllerWidgetFormType,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';
import { useOptionsCommandOptions } from '../device-widgets.hooks';

export function StateControllerWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
  modelId,
  colors,
  onAddCustomColor,
}: CommonWidgetFormWrapperProps<StateControllerWidgetFormType>) {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();
  const form = useForm<StateControllerWidgetFormType>({
    initialValues: {
      name: '',
      display_title: true,
      display_icons: true,
      color: 'red.4',

      telemetry: '',
      title_telemetry: '',
      telemetry_as_title: false,
      command_name: '',
      command_param_key: '',

      options: [],

      ...(initialValues || {}),
    },
  });

  const { commandsOptions, paramsOptions } =
    useOptionsCommandOptions<StateControllerWidgetFormType>({
      form,
      modelId,
      commandFieldName: 'command_name',
      commandParamFieldName: 'command_param_key',
    });

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.config} p={30} pr={0} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9">
            Add State Controller
          </Text>

          <VerticalScrollBar>
            <Space h="xl" />

            <StateControllerWidgetForm
              form={form}
              commands={commandsOptions}
              params={paramsOptions}
              colors={colors}
              onAddCustomColor={onAddCustomColor}
            />

            <Space h="xl" />
          </VerticalScrollBar>
        </Stack>

        <Stack className={classes.preview} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Flex align="center" justify="center" h="100%">
            <StateControllerWidget
              {...convertWidgetFormToWidgetProps.state_controller({
                formValues: form.values,
                onToggle: noop,
              })}
              stackProps={{
                h: 191,
                w: '100%',
              }}
            />
          </Flex>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "config preview"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
    backgroundColor: theme.colors.gray[1],
    overflow: 'hidden',
  },
  config: {
    gridArea: 'config',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
