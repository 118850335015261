import {
  Box,
  Button,
  createStyles,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useState } from 'react';

import { OrganizationType, useDeleteCustomer } from '@portals/api/partners';
import { ModalFooter, NameAbbreviationAvatar } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface DeleteCustomerModalProps
  extends ModalProps<{ customer: OrganizationType }> {}

export function DeleteCustomerModal({
  closeMe,
  data: { customer },
}: DeleteCustomerModalProps) {
  const { classes } = useStyles();

  const [confirmationText, setConfirmationText] = useState('');

  const deleteCustomer = useDeleteCustomer();

  const onDeleteCustomer = () => {
    deleteCustomer.mutate(
      { customerId: customer.id, customerName: customer.name },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal opened onClose={closeMe} title="Delete Customer" padding="xxl">
      <Stack spacing="xxl" align="center" mb="xxl">
        <Stack align="center">
          <NameAbbreviationAvatar
            name={customer.name}
            src={customer.logo_url}
            radius="50%"
            size={84}
          />

          <Stack spacing={4} align="center">
            <Text size="xl" color="blue_gray.9">
              {customer.name}
            </Text>
            <Text color="gray.5">{customer.email}</Text>
          </Stack>
        </Stack>

        <Box className={classes.note}>
          <Text color="gray.8" align="center">
            Please note that deleting this customer is a permanent irreversible
            action. All associated data will be lost forever. <br />
            <b>To proceed, type 'I understand' below.</b>
          </Text>
        </Box>

        <TextInput
          w={240}
          placeholder="Type “I understand” to continue"
          onChange={(event) => setConfirmationText(event.currentTarget.value)}
        />
      </Stack>

      <ModalFooter position="right" mt="xl">
        <Button onClick={closeMe} variant="default">
          Cancel
        </Button>
        <Button
          onClick={onDeleteCustomer}
          disabled={confirmationText !== 'I understand'}
          loading={deleteCustomer.isLoading}
          color="red.4"
        >
          Delete customer
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  note: {
    background: theme.fn.rgba(theme.colors.red[0], 0.7),
    padding: theme.spacing.lg,
    borderRadius: theme.radius.lg,
  },
}));
