import {
  createStyles,
  getStylesRef,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as Lock1Icon } from '@portals/icons/bold/lock-1.svg';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';

import { useStoreListingWizard } from '../hooks';
import {
  includeSubscriptionCancellationStep,
  isProductInformationError,
} from '../store-listing-wizard.utils';
import { StepId } from '../types';

export function MenuNavigation() {
  const { cx, classes } = useStyles();
  const { contextData, skipToStep, currentStep } = useStoreListingWizard();

  const onClick = async (stepId: StepId) => {
    if (currentStep.id === stepId) return;

    await skipToStep(stepId);
  };

  const showSubscriptionCancellationStep =
    includeSubscriptionCancellationStep(contextData);

  return (
    <Stack spacing="xs">
      <Group
        position="apart"
        onClick={() => onClick(StepId.ProductInformation)}
        className={cx(classes.navItem, {
          [classes.active]: currentStep.id === StepId.ProductInformation,
          [classes.error]: isProductInformationError(contextData),
        })}
        data-testid="menu-navigation-product-information"
      >
        <Text>Product Information</Text>

        {isProductInformationError(contextData) && (
          <Danger width={18} height={18} />
        )}
      </Group>

      <Group
        position="apart"
        onClick={() => onClick(StepId.PaymentSettings)}
        className={cx(classes.navItem, {
          [classes.active]: currentStep.id === StepId.PaymentSettings,
          [classes.error]: contextData.paymentSettingsGeneralError,
        })}
        data-testid="menu-navigation-payment-settings"
      >
        <Text>Payment Settings</Text>

        {contextData.paymentSettingsGeneralError && (
          <Danger width={18} height={18} />
        )}

        {contextData.storeListing?.editable === false && (
          <Tooltip
            withArrow
            multiline
            width={365}
            position="bottom"
            label="This purchased product cannot have its prices edited. To make any price modifications, kindly create a new listing for another product."
          >
            <Lock1Icon width={18} height={18} />
          </Tooltip>
        )}
      </Group>

      {showSubscriptionCancellationStep && (
        <Group
          position="apart"
          onClick={() => onClick(StepId.SubscriptionCancellation)}
          className={cx(classes.navItem, {
            [classes.active]:
              currentStep.id === StepId.SubscriptionCancellation,
          })}
          data-testid="menu-navigation-subscription-cancellation"
        >
          <Text>Cancellations & Returns</Text>
        </Group>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  navItem: {
    height: 40,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.sm,
    borderRadius: theme.radius.xl,
    color: theme.colors.blue_gray[7],
    cursor: 'pointer',
    userSelect: 'none',

    [`:hover:not(.${getStylesRef('active')}):not(.${getStylesRef('error')})`]: {
      color: theme.colors.blue_gray[9],
      backgroundColor: theme.colors.gray[0],
    },
  },
  active: {
    ref: getStylesRef('active'),
    color: theme.other.primaryColor,
    backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.5),

    [`&.${getStylesRef('error')}`]: {
      color: theme.colors.red_accent[4],
      backgroundColor: theme.fn.rgba(theme.colors.red[0], 0.5),
    },
  },
  error: {
    ref: getStylesRef('error'),
    color: theme.colors.red_accent[4],

    ':hover': {
      backgroundColor: theme.fn.rgba(theme.colors.red[0], 0.5),
    },
  },
}));
