import { Button, createStyles, Stack, Text, Title } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { FormEvent, ReactNode } from 'react';

import { useWizard } from '@portals/framework';

import { OnboardingWizardContextDataType, StepId } from './types';

interface DetailsFormProps {
  title: string;
  description: ReactNode;
  onNextClick: () => void;
  onPrevClick?: () => void;
  nextButtonText?: string;
  prevButtonText?: string;
  className?: string;
  isLoading?: boolean;
  children: ReactNode;
}

export function DetailsForm({
  title,
  description,
  onNextClick,
  onPrevClick,
  nextButtonText = 'Save and continue',
  prevButtonText = 'Go to previous step',
  children,
  isLoading = false,
}: DetailsFormProps) {
  const { classes } = useStyles();

  const { isNextDisabled, isPrevDisabled, isInTransition } = useWizard<
    OnboardingWizardContextDataType,
    StepId
  >();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onNextClick();
  };

  return (
    <motion.form
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
      onSubmit={onSubmit}
      className={classes.form}
    >
      <Title order={1} fz={32} mb="sm">
        {title}
      </Title>
      <Text mb={40} color="dimmed">
        {description}
      </Text>

      {children}

      <Stack mt={40}>
        <Button
          sx={{ width: '100%' }}
          type="submit"
          data-testid="save-and-continue-button"
          size="md"
          disabled={isNextDisabled || isLoading}
          loading={isLoading || isInTransition}
        >
          {nextButtonText}
        </Button>

        {onPrevClick && (
          <Button
            sx={{ width: '100%' }}
            type="button"
            variant="default"
            color="gray"
            size="md"
            onClick={onPrevClick}
            disabled={isPrevDisabled}
          >
            {prevButtonText}
          </Button>
        )}
      </Stack>
    </motion.form>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    maxWidth: 370,
    marginBottom: theme.spacing.xl,
  },
}));
