import { ordersQueryKeys } from '../orders';

export const SHIPMENTS_API_URL = 'ui/partner/shipments';

export const shipmentsQueryKeys = {
  all: [SHIPMENTS_API_URL],
  processing: () => [...ordersQueryKeys.all, 'shipments', 'processing'],
  shipment_ready: () => [...ordersQueryKeys.all, 'shipments', 'shipment_ready'],
  shipped: () => [...ordersQueryKeys.all, 'shipments', 'shipped'],
  delivered: () => [...ordersQueryKeys.all, 'shipments', 'delivered'],
  cancelled: () => [...ordersQueryKeys.all, 'shipments', 'cancelled'],
  list: () => [...ordersQueryKeys.all, 'shipments', 'list'],
};
