import { Alert, AlertProps } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';

import { useStoreListingWizard } from '../hooks';

export function PaymentSettingsGeneralError() {
  const { contextData } = useStoreListingWizard();

  return (
    <AnimatePresence initial={false}>
      {contextData.paymentSettingsGeneralError && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Alert radius="md" color="red" icon={<Danger />} styles={alertStyles}>
            {contextData.paymentSettingsGeneralError}
          </Alert>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const alertStyles: AlertProps['styles'] = (theme) => ({
  root: {
    overflow: 'unset',
    backgroundColor: theme.fn.rgba(theme.colors.red[0], 0.6),
  },
  wrapper: {
    alignItems: 'center',
  },
  message: {
    color: theme.colors.red[4],
  },
});
