import { FC, SVGProps } from 'react';
import { array, number, object, string } from 'yup';

import { PostPurchaseParamsStepType } from '@portals/types';

import { ReactComponent as FileField } from '../../../assets/img/file-field.svg';
import { ReactComponent as NumberField } from '../../../assets/img/number-field.svg';
import { ReactComponent as SelectField } from '../../../assets/img/select-field.svg';
import { ReactComponent as TextField } from '../../../assets/img/text-field.svg';
import { ReactComponent as TextareaField } from '../../../assets/img/textarea-field.svg';

export const FIELD_LABELS: Record<PostPurchaseParamsStepType, string> = {
  text: 'Short text',
  textarea: 'Paragraph',
  number: 'Number',
  file: 'File',
  select: 'Selection list',
};

export const FIELD_TYPES_OPTIONS: Array<{
  label: string;
  value: PostPurchaseParamsStepType;
}> = [
  {
    label: FIELD_LABELS['text'],
    value: 'text',
  },
  {
    label: FIELD_LABELS['textarea'],
    value: 'textarea',
  },
  {
    label: FIELD_LABELS['number'],
    value: 'number',
  },
  {
    label: FIELD_LABELS['file'],
    value: 'file',
  },
  {
    label: FIELD_LABELS['select'],
    value: 'select',
  },
];

export const FIELD_ICONS: Record<
  PostPurchaseParamsStepType,
  FC<SVGProps<SVGElement>>
> = {
  text: TextField,
  textarea: TextareaField,
  number: NumberField,
  file: FileField,
  select: SelectField,
};

interface GetFormSchemaParams {
  usedLabels: Array<string>;
}

export const getFormSchema = ({ usedLabels }: GetFormSchemaParams) =>
  object({
    label: string()
      .notOneOf(usedLabels, () => 'A field with this label already exists')
      .required(),
    type: string()
      .oneOf(['text', 'textarea', 'number', 'select', 'file'])
      .required(() => 'Field type is required'),
    description: string().optional(),
    configuration: object()
      .when('type', {
        is: 'number',
        then: object({
          min: number().nullable(true),
          max: number().nullable(true),
        }),
      })
      .when('type', {
        is: 'select',
        then: (schema) =>
          schema.shape({
            options: array().of(
              object({
                label: string().required(),
                value: string().required(),
              })
            ),
          }),
      }),
  });
