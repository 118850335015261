import {
  Box,
  LoadingOverlay,
  SegmentedControl,
  Select,
  Stack,
  Switch,
  SwitchProps,
  Text,
} from '@mantine/core';
import { set } from 'lodash/fp';
import React, { useEffect } from 'react';

import {
  PartnerStoreSettingsType,
  useStoreSettings,
} from '@portals/api/partners';
import { PaymentIntervalEnum } from '@portals/types';

import { PaymentSettingsGeneralError } from './PaymentSettingsGeneralError';
import { PriceRestrictionAlert } from './PriceRestrictionAlert';
import { UsageBasedPaymentSettingPrices } from './UsageBasedPaymentSettingPrices';
import { PaymentSettingItemType } from '../../../types/store-listings.types';
import { useStoreListingWizard } from '../hooks';

export function UsageBasedPaymentSettings() {
  const storeSettings = useStoreSettings();
  const { contextData, setContextData } = useStoreListingWizard();

  useEffect(
    function initUsageBasedPrices() {
      // No need to initialize if there are prices set already
      if (contextData.paymentSettings.usage_based) return;

      // Initialize "empty" prices for usage based pricing model
      const prices = composeInitialSortedPrices(
        storeSettings.data,
        Boolean(contextData.paymentSettingsGeneralError)
      );

      setContextData((prev) =>
        set(
          ['paymentSettings', PaymentIntervalEnum.UsageBased],
          {
            prices,
            period: PaymentIntervalEnum.UsageBased,
          } satisfies PaymentSettingItemType,
          prev
        )
      );
    },
    [
      contextData.paymentSettings.usage_based,
      contextData.paymentSettingsGeneralError,
      setContextData,
      storeSettings.data,
    ]
  );

  useEffect(
    function initUsageBasedBasePrices() {
      // No need to initialize if there are prices set already or if base price is not included
      if (
        contextData.paymentSettings.monthly ||
        !contextData.isBasePriceEnabled
      ) {
        return;
      }

      // Initialize "empty" prices for monthly pricing model (when in usage based pricing model - monthly is used as the "Base Price")
      const prices = composeInitialSortedPrices(
        storeSettings.data,
        Boolean(contextData.paymentSettingsGeneralError)
      );

      setContextData((prev) =>
        set(
          ['paymentSettings', PaymentIntervalEnum.Monthly],
          {
            prices,
            period: PaymentIntervalEnum.Monthly,
          } satisfies PaymentSettingItemType,
          prev
        )
      );
    },
    [
      contextData.isBasePriceEnabled,
      contextData.paymentSettings.monthly,
      contextData.paymentSettingsGeneralError,
      setContextData,
      storeSettings.data,
    ]
  );

  useEffect(
    function updatePaymentSettingsGeneralError() {
      const hasBasePriceErrors =
        contextData.isBasePriceEnabled &&
        contextData.paymentSettings.monthly?.prices?.some?.(
          (price) => price.error
        );

      const hasUsagePriceErrors =
        contextData.paymentSettings.usage_based?.prices?.some?.(
          (price) => price.error
        );

      const paymentSettingsGeneralError =
        hasUsagePriceErrors ||
        (hasBasePriceErrors && contextData.isBasePriceEnabled)
          ? 'Please fill in all prices'
          : undefined;

      setContextData((prev) => ({
        ...prev,
        paymentSettingsGeneralError,
      }));
    },
    [
      contextData.isBasePriceEnabled,
      contextData.paymentSettings.monthly?.prices,
      contextData.paymentSettings.usage_based?.prices,
      setContextData,
    ]
  );

  if (storeSettings.isLoading || !storeSettings.data) {
    return <LoadingOverlay visible />;
  }

  return (
    <Box>
      <Stack mb="xxl">
        {contextData.storeListing?.editable === false && (
          <PriceRestrictionAlert />
        )}

        <Text size="md" color="gray.8">
          Usage based pricing model
        </Text>
      </Stack>

      <Stack spacing="xl">
        <SegmentedControl
          readOnly={contextData.storeListing?.editable === false}
          data={[
            {
              value: 'payAsYouGo',
              label: 'Pay-as-you-go',
            },
            {
              value: 'package',
              label: 'Package (coming soon)',
              disabled: true,
            },
          ]}
        />

        <Select
          label="Billing period"
          value={PaymentIntervalEnum.Monthly}
          readOnly={contextData.storeListing?.editable === false}
          data={[
            { value: PaymentIntervalEnum.Monthly, label: 'Monthly' },
            {
              value: PaymentIntervalEnum.Yearly,
              label: 'Yearly (coming soon)',
              disabled: true,
            },
          ]}
        />

        <Switch
          styles={switchStyles}
          label="Add base price"
          description="Charge a fixed monthly price in addition to the usage based price"
          data-testid="base-price-switch"
          labelPosition="left"
          checked={contextData.isBasePriceEnabled}
          disabled={contextData.storeListing?.editable === false}
          onChange={(e) =>
            setContextData((prev) => ({
              ...prev,
              isBasePriceEnabled: e.target.checked,
            }))
          }
        />

        <Stack spacing="xs" data-testid="pay-per-usage-stack">
          <Text size="sm" color="gray.6">
            Price per{' '}
            <Text span c="blue_gray.9" weight={700}>
              {contextData.selectedProduct?.usage_based_unit_name}
            </Text>
          </Text>

          <PaymentSettingsGeneralError />

          <UsageBasedPaymentSettingPrices
            defaultCurrency={storeSettings.data.default_currency}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

const switchStyles: SwitchProps['styles'] = (theme) => ({
  root: {
    paddingBlock: theme.spacing.xxl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  labelWrapper: {
    flexGrow: 1,
  },
  label: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[8],
  },
  description: {
    color: theme.colors.gray[5],
  },
});

function composeInitialSortedPrices(
  storeSettings: PartnerStoreSettingsType | undefined,
  hasErrors: boolean
) {
  if (!storeSettings) return [];

  const { currencies, default_currency } = storeSettings;

  const prices: PaymentSettingItemType['prices'] = currencies
    .map((currency) => ({
      currency,
      amountInScu: null,
      error: hasErrors,
    }))
    .sort((a) => (a.currency === default_currency ? -1 : 1));

  return prices;
}
