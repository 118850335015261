import { StoreListingPrice, PublishableProduct } from '@portals/api/partners';

export function checkIsMissingPrices(
  product: PublishableProduct,
  storeListingPrices: StoreListingPrice[] | undefined
): boolean {
  if (!storeListingPrices || storeListingPrices.length === 0) {
    return true;
  }

  if (
    product.allow_one_time_price &&
    storeListingPrices.some((price) => !price.one_time_price_in_scu)
  ) {
    return true;
  }

  if (
    product.allow_monthly_price &&
    storeListingPrices.some((price) => !price.monthly_price_in_scu)
  ) {
    return true;
  }

  if (
    product.allow_yearly_price &&
    storeListingPrices.some((price) => !price.yearly_price_in_scu)
  ) {
    return true;
  }

  return false;
}

export function checkIsMissingFields(product: PublishableProduct): boolean {
  return !product.name || !product.description || !product.product_type;
}
