import { Box, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { VerticalScrollBar } from '@portals/scrollbar';

import { SelectedStoreListingsList } from './SelectedStoreListingsList';
import { OrderGeneralInfo } from '../../../components/offline-payment-order-general-info/OrderGeneralInfo';
import { useCreateOrderWizard } from '../create-order-wizard/hooks';

export function OrderDetailsStep() {
  const { classes } = useStyles();
  const { contextData, setContextData } = useCreateOrderWizard();

  const onOrderReferenceIdSave = (orderReferenceId: string) => {
    setContextData((prev) => ({
      ...prev,
      orderReferenceId,
    }));
  };

  const onOrderNotesSave = (orderNotes: string) => {
    setContextData((prev) => ({
      ...prev,
      orderNotes,
    }));
  };

  const onUploadAttachmentSuccess = (attachmentUrl: string) => {
    setContextData((prev) => ({
      ...prev,
      attachmentUrl,
    }));
  };

  return (
    <Stack spacing="xs">
      <Text size={24}>Order details</Text>

      <Text mb="xl">
        Please review your order before completing it. You can add more
        information now or after you’ve finished.
      </Text>

      <VerticalScrollBar renderView={(props) => <Box {...props} pr="md" />}>
        <div className={classes.grid}>
          <SelectedStoreListingsList />
          <OrderGeneralInfo
            currency={contextData.currency}
            itemsTotalPrice={contextData.selectedStoreListingsTotalPrice}
            referenceId={contextData.orderReferenceId}
            notes={contextData.orderNotes}
            attachmentUrl={contextData.attachmentUrl}
            setReferenceId={onOrderReferenceIdSave}
            setNotes={onOrderNotesSave}
            setAttachmentUrl={onUploadAttachmentSuccess}
          />
        </div>
      </VerticalScrollBar>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing.xl,
  },
}));
