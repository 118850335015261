import { size, get } from 'lodash/fp';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'reactstrap';

import { AutoFormik } from '@portals/autoformik';
import { FieldTypeEnum } from '@portals/types';

import { updateIntegration } from '../../../redux/actions/integrations';

const INTEGRATION = 'zoho';

const getField = (id) => [
  {
    name: `title${id}`,
    title: 'Zoho Desk API Name (1)',
    placeholder: 'cf_fieldName',
    type: FieldTypeEnum.Text,
    default: '',
  },
  {
    name: `value${id}`,
    title: 'Field value (2)',
    placeholder: 'text and *dynamic_values*',
    type: FieldTypeEnum.Textarea,
    default: '',
  },
];

const parseCf = (obj) => {
  const parsed = {};
  let i = 0;
  Object.keys(obj).forEach((key) => {
    parsed[`title${i}`] = key;
    parsed[`value${i}`] = obj[key];
    i = i + 1;
  });
  return parsed;
};

const initialChildren = (fieldsCount) => {
  const returnArray = [];
  for (let i = 0; i < fieldsCount; i++) {
    returnArray.push(...getField(i));
  }
  return returnArray;
};

const CustomFieldsRemarks = () => (
  <>
    <p>(1) The "API Name" is generated by Zoho for custom fields.</p>
    <p className="ml-3">The full list is available at Setup: "Fields List"</p>
    <p>(2) Field value is a template of the data to be sent.</p>
    <p className="ml-3">
      Sample:
      <br />
      <code className="ml-3">
        Device properties: *device_name* at *location*
      </code>
      <br />
      <br />
      Result:
      <br />
      <code className="ml-3">
        Device properties: DLink Router at Mars &gt; Area 1
      </code>
    </p>
  </>
);

const CustomFieldsVariablesList = () => (
  <>
    <h5 className="mt-3">Available template variables</h5>

    <Table>
      <tbody>
        <tr>
          <td className="font-weight-bold">Device ID (Xyte system)</td>
          <td>*device_id*</td>
          <td className="font-weight-bold">Device SN (if available)</td>
          <td>*sn*</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Device MAC (if available)</td>
          <td>*mac*</td>
          <td className="font-weight-bold">Device Firmware (if available)</td>
          <td>*firmware*</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Device Name (Xyte system)</td>
          <td>*device_name*</td>
          <td className="font-weight-bold">Device Model</td>
          <td>*device_model*</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Device Location</td>
          <td>*location*</td>
          <td className="font-weight-bold">Manufacturer</td>
          <td>*manufacturer*</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Organization</td>
          <td>*org*</td>
          <td className="font-weight-bold">Ticket Title</td>
          <td>*title*</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Ticket Description</td>
          <td>*description*</td>
          <td className="font-weight-bold">Ticket Status</td>
          <td>*status*</td>
        </tr>
      </tbody>
    </Table>
  </>
);

const CustomFields = ({ integration, inProgress, updateIntegration }) => {
  const [initialData] = useState(parseCf(integration.params.cf || {}));
  const [fieldsCount, setFieldsCount] = useState(size(initialData) / 2);
  const [children, setChildren] = useState(initialChildren(fieldsCount));
  const [fields, setFields] = useState([
    {
      name: 'group',
      type: FieldTypeEnum.Group,
      children: children,
    },
  ]);

  useEffect(() => {
    setFields([
      { name: 'group', type: FieldTypeEnum.Group, children: children },
    ]);
  }, [children, setFields]);

  useEffect(() => {
    if (size(children) / 2 !== fieldsCount) {
      setChildren(children.concat(getField(fieldsCount - 1)));
    }
  }, [fieldsCount, children, setChildren]);

  const handleSubmit = (values) => {
    const parsedValues = {};
    for (let i = 0; i < size(values) / 2; i++) {
      parsedValues[values[`title${i}`]] = values[`value${i}`];
    }
    updateIntegration(INTEGRATION, { ...integration.params, cf: parsedValues });
  };
  const clearCfData = () => {
    setChildren([]);
    setFieldsCount(0);
    handleSubmit({});
  };
  const addFieldToChildren = () => setFieldsCount(fieldsCount + 1);
  const addCf = () => (
    <>
      <Button onClick={addFieldToChildren}>Add Field</Button>
      <Button className="mx-1" onClick={clearCfData}>
        Clear All
      </Button>
    </>
  );
  return (
    <div className="m-3">
      <h4 className="mt-3">Add custom fields to tickets (optional)</h4>

      <CustomFieldsVariablesList />

      <AutoFormik
        fields={fields}
        initialValues={initialData}
        inProgress={inProgress}
        wrapped={addCf}
        handleSubmit={handleSubmit}
      />

      <CustomFieldsRemarks />
    </div>
  );
};

const mapStateToProps = (state) => ({
  integration: state.data.integrations[INTEGRATION],
  inProgress: get(['ui', 'network', `integration_${INTEGRATION}`], state),
});

export default connect(mapStateToProps, {
  updateIntegration,
})(CustomFields);
