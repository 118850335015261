import { Notifications } from '@mantine/notifications';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom';

import { QueryClientWrapper } from '@lib/query-client-wrapper';
import { MantineThemeProvider, Responsive } from '@portals/core';
import {
  AuthenticationGuard,
  ChangePassword,
  DescopeAuthProvider,
  DevTools,
  EnvName,
  HubspotConversationsProvider,
  initGoogleMap,
  MobileSupport,
  NetworkStatusNotification,
  RootErrorBoundary,
  SignOut,
  UnsubscribeLayout,
  VersionMonitor,
  Welcome,
} from '@portals/framework';
import { AppConfigProvider } from '@portals/framework/context';
import { default as frameworkModals } from '@portals/framework/modals';
import { Localization } from '@portals/i18n';
import { store } from '@portals/redux';
import { TenantType } from '@portals/types';
import { ThemeProvider } from '@portals/ui';
import { initSentry } from '@portals/utils';

import { NavbarContactSupport } from './components/NavbarContactSupport';
import { OnboardingWizard } from './components/OnboardingWizard';
import { PartnersMeta } from './components/PartnersMeta';
import { useAnalytics } from './hooks/analytics';
import modals from './modals';
import { routes } from './routes';
import { AuthLayout } from './routes/auth/AuthLayout';
import { ResetPassword } from './routes/auth/ResetPassword';
import { Signin } from './routes/auth/Signin';
import { DashboardLayout } from './routes/dashboard/DashboardLayout';

function Root() {
  initSentry();
  initGoogleMap();

  const params = new URLSearchParams(document.location.search);
  const existingAuth = params.get('auth');

  if (existingAuth) {
    try {
      // Parsing first to catch errors
      const auth = JSON.parse(atob(existingAuth));

      localStorage.setItem('auth', JSON.stringify(auth));

      window.location.assign('/');
    } catch (err) {
      console.error(err);
    }
  }

  if (process.env.NX_GIT_LATEST_COMMIT_SHA) {
    console.log(
      `Current portal version: ${process.env.NX_GIT_LATEST_COMMIT_SHA}`
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider>
        <AppConfigProvider
          value={{
            routes,
            tenantType: TenantType.Partner,
            modals: { ...frameworkModals, ...modals },
            extraLayout: {
              navbarLayout: () => <NavbarContactSupport />,
            },
          }}
        >
          <Provider store={store}>
            <QueryClientWrapper>
              <PartnersMeta />

              <MantineThemeProvider>
                <Notifications position="bottom-center" />

                <EnvName />

                {process.env.NX_ENV === 'production' ? (
                  <VersionMonitor />
                ) : null}
                {process.env.NX_ENV === 'production' ? null : <DevTools />}
                <NetworkStatusNotification />

                <HubspotConversationsProvider>
                  <Localization>
                    <DescopeAuthProvider>
                      <DndProvider backend={HTML5Backend}>
                        <Responsive>
                          <Responsive.Desktop>
                            <AnalyticsTracker />

                            <Outlet />
                          </Responsive.Desktop>

                          <Responsive.Mobile>
                            <MobileSupport />
                          </Responsive.Mobile>
                        </Responsive>
                      </DndProvider>
                    </DescopeAuthProvider>
                  </Localization>
                </HubspotConversationsProvider>
              </MantineThemeProvider>
            </QueryClientWrapper>
          </Provider>
        </AppConfigProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

const basename = process.env.NX_PREVIEW_APP_NAME
  ? `/${process.env.NX_PREVIEW_APP_NAME}`
  : '/';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<RootErrorBoundary />}>
      <Route path="/auth" element={<AuthLayout />}>
        <Route index element={<Signin />} />
        <Route path="sign-in" element={<Signin />} />
        <Route path="sign-out" element={<SignOut />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="welcome" element={<Welcome />} />
      </Route>

      <Route path="/unsubscribe" element={<UnsubscribeLayout />} />

      <Route element={<AuthenticationGuard />}>
        <Route path="/onboarding" element={<OnboardingWizard />} />

        <Route path="/*" element={<DashboardLayout />} />
      </Route>
    </Route>
  ),
  { basename }
);

function AnalyticsTracker() {
  useAnalytics();

  return null;
}
