import { Stepper, StepperProps } from '@mantine/core';
import { omit } from 'lodash/fp';
import React from 'react';

import { useStoreListingWizard } from '../hooks';
import {
  includeSubscriptionCancellationStep,
  isProductInformationError,
} from '../store-listing-wizard.utils';
import { StepId } from '../types';

export function StepperNavigation() {
  const { contextData, steps, currentStep, skipToStep } =
    useStoreListingWizard();

  const stepIds =
    contextData.intent === 'publish'
      ? (Object.keys(omit([StepId.ChooseProduct], steps)) as StepId[])
      : (Object.keys(steps) as StepId[]);

  const showSubscriptionCancellationStep =
    includeSubscriptionCancellationStep(contextData);

  return (
    <Stepper
      orientation="vertical"
      styles={stepperStyles(Boolean(contextData.paymentSettingsGeneralError))}
      active={stepIds.findIndex((stepId) => stepId === currentStep.id)}
      onStepClick={(stepIndex) => skipToStep(stepIds[stepIndex])}
    >
      {contextData.intent === 'create' && (
        <Stepper.Step allowStepSelect={false} label="Choose Product" />
      )}

      <Stepper.Step
        allowStepSelect={false}
        label="Product Information"
        color={isProductInformationError(contextData) ? 'red' : 'primary'}
      />

      <Stepper.Step
        allowStepSelect={false}
        label="Payment Settings"
        color={contextData.paymentSettingsGeneralError ? 'red' : 'primary'}
      />

      {showSubscriptionCancellationStep && (
        <Stepper.Step allowStepSelect={false} label="Cancellations & Returns" />
      )}
    </Stepper>
  );
}

const stepperStyles =
  (error: boolean): StepperProps['styles'] =>
  (theme) => ({
    step: {
      position: 'relative',
      width: '100%',
      paddingLeft: 4,
      paddingTop: 4,
      cursor: 'default !important',

      '&:not(:first-of-type)': {
        marginTop: -4,
      },

      '&[data-progress]': {
        ':before': {
          content: '""',
          zIndex: -1,
          position: 'absolute',
          top: 1,
          left: 0,
          display: 'block',
          width: '100%',
          height: 41,
          borderRadius: theme.radius.xl,
          backgroundColor: error
            ? theme.fn.rgba(theme.colors.red[0], 0.5)
            : theme.fn.rgba(theme.colors.blue[0], 0.5),
        },
      },
    },
    stepBody: {
      marginTop: theme.spacing.sm,
    },
    verticalSeparator: {
      marginTop: -4,
    },
  });
