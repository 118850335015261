import React from 'react';

import { ModalButton, PermissionAccess } from '@portals/framework';
import { AccessLevelEnum } from '@portals/types';

import { ProductWizardModalData } from '../../modals';

export function AddNewCatalogProductButton() {
  return (
    <PermissionAccess
      minAccessLevel={AccessLevelEnum.Edit}
      permissionKeys="store_management"
    >
      <ModalButton
        modalName="ProductWizardModal"
        label="Create New Product"
        data-testid="add-new-product-button"
        size="sm"
        data={
          {
            intent: 'create',
            product: undefined,
          } as ProductWizardModalData
        }
      />
    </PermissionAccess>
  );
}
