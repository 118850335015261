import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Input,
  InputProps,
  Tooltip,
} from '@mantine/core';
import React from 'react';

import { usePortalProtocolAndDomain } from '@portals/framework';
import { ReactComponent as Refresh } from '@portals/icons/linear/refresh.svg';

interface StoreListingSlugInputProps {
  slug: string | null;
  onSlugChange: (slug: string | null) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  storeListingId?: string | undefined;
  disabled?: boolean;
  label?: string;
}

export function StoreListingSlugInput({
  slug,
  onSlugChange,
  setErrorMessage,
  errorMessage,
  disabled,
  storeListingId,
  label,
}: StoreListingSlugInputProps) {
  const { protocolAndDomain } = usePortalProtocolAndDomain();

  const { classes } = useStyles({
    isError: errorMessage !== '',
  });

  return (
    <Input.Wrapper data-testid="custom-link-wrapper">
      {label && <Input.Label c="gray.6">{label}</Input.Label>}

      <Group noWrap>
        <Box className={classes.inputFieldsWrapper}>
          <Input
            variant="filled"
            disabled
            radius={0}
            value={`${protocolAndDomain}/store/`}
            styles={disabledInputStyles}
          />

          <Input
            data-testid="custom-link-input"
            error={errorMessage}
            styles={inputStyles(!errorMessage)}
            disabled={disabled}
            rightSection={
              storeListingId && (
                <Tooltip label="Reset permalink to default">
                  <ActionIcon
                    data-testid="reset-custom-link"
                    onClick={() => onSlugChange(null)}
                  >
                    <Refresh height={18} width={18} />
                  </ActionIcon>
                </Tooltip>
              )
            }
            placeholder={storeListingId}
            value={slug ?? undefined}
            onChange={(event) => {
              onSlugChange(event.target.value);
              setErrorMessage('');
            }}
          />
        </Box>
      </Group>

      {errorMessage ? (
        <Input.Error data-testid="error-message" mt="xs">
          {errorMessage}
        </Input.Error>
      ) : null}
    </Input.Wrapper>
  );
}

const useStyles = createStyles((theme, params: { isError: boolean }) => ({
  inputFieldsWrapper: {
    width: '100%',
    display: 'flex',
    border: params.isError
      ? `1px solid ${theme.colors.red[4]}`
      : `1px solid ${theme.colors.gray[2]}`,
  },
}));

const inputStyles =
  (isError: boolean): InputProps['styles'] =>
  (theme) => ({
    input: {
      borderLeft: `1px solid ${theme.colors.gray[3]} !important`,
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      borderRadius: 0,
      borderColor: isError
        ? `1px solid ${theme.colors.gray[3]} !important`
        : `1px solid ${theme.colors.gray[3]}`,

      '&:focus': {
        borderLeft: isError
          ? 'none'
          : `1px solid ${theme.colors.gray[3]} !important`,
      },
    },

    wrapper: {
      flexGrow: 1,
    },
  });

const disabledInputStyles: InputProps['styles'] = (theme) => ({
  wrapper: {
    flexGrow: 1,
  },
});
