import { UseFormReturnType } from '@mantine/form';
import { find, map, sortBy } from 'lodash/fp';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import {
  usePortalConfig,
  useSupportedCommands,
  useUpdatePortalConfig,
} from '@portals/api/partners';
import { toastrError } from '@portals/redux/actions/toastr';
import {
  CustomColor,
  FieldTypeEnum,
  SupportedCommandType,
} from '@portals/types';

export function useBooleanCommandOptions<TForm>({
  modelId,
  form,
  commandFieldName,
  commandParamFieldName,
}: {
  modelId: string;
  form: UseFormReturnType<TForm>;
  commandFieldName: keyof TForm;
  commandParamFieldName: keyof TForm;
}) {
  const deviceModelCommands = useSupportedCommands(modelId);

  const commandsOptions = useMemo(() => {
    const options = map((command) => {
      const hasCustomFields = command.custom_fields?.length > 0;
      const hasBooleanFields =
        hasCustomFields &&
        Boolean(find({ type: FieldTypeEnum.Checkbox }, command.custom_fields));

      const isDisabled = !hasBooleanFields;

      return {
        value: command.name,
        label: command.friendly_name,
        disabled: isDisabled,
      };
    }, deviceModelCommands.data);

    return sortBy('disabled', options);
  }, [deviceModelCommands.data]);

  const paramsOptions = useMemo(() => {
    if (!form.values[commandFieldName]) {
      return [];
    }

    const command = (deviceModelCommands.data || []).find(
      (command) => command.name === form.values[commandFieldName]
    );

    if (!command || !command.custom_fields) return [];

    const options = map((param) => {
      return {
        value: param.name,
        label: param.name,
        disabled: param.type !== FieldTypeEnum.Checkbox,
      };
    }, command?.custom_fields);

    return sortBy('disabled', options);
  }, [commandFieldName, deviceModelCommands.data, form.values]);

  useUpdateEffect(() => {
    form.setFieldValue(commandParamFieldName, null);
  }, [form.values[commandFieldName]]);

  return {
    commandsOptions,
    paramsOptions,
  };
}

export function useNumericCommandOptions<TForm>({
  modelId,
  form,
  commandFieldName,
  commandParamFieldName,
}: {
  modelId: string;
  form: UseFormReturnType<TForm>;
  commandFieldName: keyof TForm;
  commandParamFieldName: keyof TForm;
}) {
  const deviceModelCommands = useSupportedCommands(modelId);

  const commandsOptions = useMemo(() => {
    const options = map((command) => {
      const hasCustomFields = command.custom_fields?.length > 0;
      const hasNumericFields =
        hasCustomFields &&
        Boolean(find({ type: FieldTypeEnum.Number }, command.custom_fields));

      return {
        value: command.name,
        label: command.friendly_name,
        disabled: !hasNumericFields,
      };
    }, deviceModelCommands.data);

    return sortBy('disabled', options);
  }, [deviceModelCommands.data]);

  const paramsOptions = useMemo(() => {
    if (!form.values[commandFieldName]) {
      return [];
    }

    const command = (deviceModelCommands.data || []).find(
      (command) => command.name === form.values[commandFieldName]
    );

    if (!command || !command.custom_fields) return [];

    const options = map((param) => {
      return {
        value: param.name,
        label: param.name,
        disabled: param.type !== FieldTypeEnum.Number,
      };
    }, command?.custom_fields);

    return sortBy('disabled', options);
  }, [commandFieldName, deviceModelCommands.data, form.values]);

  useUpdateEffect(() => {
    form.setFieldValue(commandParamFieldName, null);
  }, [form.values[commandFieldName]]);

  return {
    commandsOptions,
    paramsOptions,
  };
}

export function useOptionsCommandOptions<TForm>({
  modelId,
  form,
  commandFieldName,
  commandParamFieldName,
}: {
  modelId: string;
  form: UseFormReturnType<TForm>;
  commandFieldName: keyof TForm;
  commandParamFieldName: keyof TForm;
}) {
  const deviceModelCommands = useSupportedCommands(modelId);

  const commandsOptions = useMemo(() => {
    const options = map((command) => {
      const hasCustomFields = command.custom_fields?.length > 0;
      const hasOptionsFields =
        hasCustomFields &&
        Boolean(find({ type: FieldTypeEnum.Select }, command.custom_fields));

      return {
        value: command.name,
        label: command.friendly_name,
        disabled: !hasOptionsFields,
      };
    }, deviceModelCommands.data);

    return sortBy('disabled', options);
  }, [deviceModelCommands.data]);

  const paramsOptions = useMemo(() => {
    if (!form.values[commandFieldName]) {
      return [];
    }

    const command = (deviceModelCommands.data || []).find(
      (command) => command.name === form.values[commandFieldName]
    );

    if (!command || !command.custom_fields) return [];

    const options = map((param) => {
      return {
        value: param.name,
        label: param.name,
        disabled: param.type !== FieldTypeEnum.Select,
      };
    }, command?.custom_fields);

    return sortBy('disabled', options);
  }, [commandFieldName, deviceModelCommands.data, form.values]);

  const paramOptions = useMemo(() => {
    const selectedCommand = find(
      { name: form.values[commandFieldName] },
      deviceModelCommands.data
    ) as SupportedCommandType | undefined;

    if (!selectedCommand) return [];

    const selectedCommandParam = find(
      { name: form.values[commandParamFieldName] },
      selectedCommand?.custom_fields
    ) as SupportedCommandType['custom_fields'][number] | undefined;

    if (!selectedCommandParam || !selectedCommandParam?.options) return [];

    const sortedOptions = sortBy('order', selectedCommandParam?.options);

    return map((option) => {
      return {
        value: option.value,
        label: option.label,
        telemetry_value: option.telemetry_value || option.value,
      };
    }, sortedOptions);
  }, [
    commandFieldName,
    commandParamFieldName,
    deviceModelCommands.data,
    form.values,
  ]);

  useUpdateEffect(() => {
    form.setFieldValue(commandParamFieldName, null);
  }, [form.values[commandFieldName]]);

  useUpdateEffect(() => {
    form.setValues({
      ...form.values,
      options: paramOptions.map((option) => ({
        key: option.value,
        label: option.label,
        value: option.value,
        telemetry_value: option.telemetry_value || option.value,
        icon_name: 'battery_full',
      })),
    });
  }, [form.values[commandParamFieldName]]);

  return {
    commandsOptions,
    paramsOptions,
  };
}

export function useUpdateCustomColors() {
  const updatePortalConfig = useUpdatePortalConfig();
  const portalConfig = usePortalConfig();
  const dispatch = useDispatch();

  const handleUpdateColors = ({ colorHex }: CustomColor) => {
    const isColorAlreadyAdded =
      portalConfig.data?.design?.custom_colors?.includes(colorHex);

    if (isColorAlreadyAdded) {
      dispatch(toastrError(`Color ${colorHex} already added`));
      return;
    }

    updatePortalConfig.mutate({
      updatedConfig: {
        design: {
          ...portalConfig.data?.design,
          custom_colors: [
            ...(portalConfig.data?.design?.custom_colors || []),
            colorHex,
          ],
        },
      },
    });
  };

  return handleUpdateColors;
}
