import { Group, Text, ThemeIcon } from '@mantine/core';
import React, { FC, forwardRef } from 'react';

import { TAX_GROUPS_ICONS } from '../tax-groups-icons';

interface TaxGroupOptionType extends React.ComponentPropsWithoutRef<'div'> {
  iconName: string;
  label: string;
}

export const TaxGroupOption: FC<TaxGroupOptionType> = forwardRef<
  HTMLDivElement,
  TaxGroupOptionType
>(({ iconName, label, ...rest }, ref) => {
  return (
    <div {...rest} ref={ref} style={{ padding: 0 }}>
      <Group
        sx={(theme) => ({
          padding: `${theme.spacing.sm} ${theme.spacing.md}`,
        })}
      >
        <ThemeIcon
          variant="light"
          radius="md"
          color={TAX_GROUPS_ICONS[iconName]?.color}
          size={36}
        >
          {TAX_GROUPS_ICONS[iconName]?.component}
        </ThemeIcon>

        <Text size="sm" sx={(theme) => ({ color: theme.colors.blue_gray[9] })}>
          {label}
        </Text>
      </Group>
    </div>
  );
});
