import React, { useEffect } from 'react';

import FilesList from './FilesList';

const FileSelector = ({ value, field, setFieldValue, files }) => {
  useEffect(() => {
    if (files && files.length > 0 && !value) {
      setFieldValue(field.name, files[0].id);
    }
  }, [files, setFieldValue, value, field.name]);

  return (
    <FilesList
      files={files}
      selected={value}
      handleSelected={(fileId) => setFieldValue(field.name, fileId)}
    />
  );
};

export default FileSelector;
