import React, { ReactNode, useMemo } from 'react';

import { AccessLevelEnum, UserPermissionNames } from '@portals/types';

import { usePermissionAccess } from './use-permission-access';

interface PermissionAccessProps {
  minAccessLevel: AccessLevelEnum;
  permissionKeys: UserPermissionNames | Array<UserPermissionNames>;
  children: ReactNode;
}

export function PermissionAccess({
  minAccessLevel,
  permissionKeys,
  children,
}: PermissionAccessProps) {
  const { canView, canEdit } = usePermissionAccess();

  const hasPermission = useMemo(() => {
    switch (minAccessLevel) {
      case AccessLevelEnum.None:
        return true;
      case AccessLevelEnum.View:
        return canView(permissionKeys);
      case AccessLevelEnum.Edit:
        return canEdit(permissionKeys);
      default:
        return false;
    }
  }, [canEdit, canView, minAccessLevel, permissionKeys]);

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
}
