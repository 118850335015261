import { useMutation, useQueryClient } from '@tanstack/react-query';
import { map } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import {
  DeviceModelDetailsType,
  DeviceModelSummaryType,
} from './device-models.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { devicesQueryKeys } from '../devices';
import { productsQueryKeys } from '../products';

export function useDeviceModels() {
  return useApiQuery<DeviceModelSummaryType[]>(
    DEVICE_MODELS_API_URL,
    deviceModelsQueryKeys.base,
    {
      staleTime: 0,
      keepPreviousData: true,
    }
  );
}

export function useDeviceModel(modelId = '') {
  return useApiQuery<DeviceModelDetailsType>(
    getDeviceModelApiUrl(modelId),
    deviceModelsQueryKeys.detail(modelId),
    {
      staleTime: 0,
      enabled: !!modelId,
      keepPreviousData: true,
    }
  );
}

export type UseCreateDeviceModelRequestPayload = Partial<
  Pick<
    DeviceModelDetailsType,
    | 'active'
    | 'sku'
    | 'auth_method'
    | 'is_config_schema_readonly'
    | 'communication_protocol'
    | 'ttl_in_minutes'
    | 'current_api_version'
    | 'enable_configuration_device_tab'
    | 'image_url'
    | 'enable_state_device_tab'
    | 'enable_events_device_tab'
    | 'enable_telemetries_device_tab'
    | 'enable_details_device_tab'
    | 'enable_files_device_tab'
    | 'user_settings'
    | 'troubleshooting_steps'
  > & {
    name: string;
    device_type: DeviceModelDetailsType['type'];
    create_reboot_command: boolean;
    create_firmware_upgrade_command: boolean;
    create_dump_command: boolean;
  }
>;

export function useCreateDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<
    DeviceModelDetailsType,
    ServerError,
    UseCreateDeviceModelRequestPayload
  >({
    mutationFn: (deviceModel) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(deviceModel),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      queryClient.invalidateQueries(productsQueryKeys.all);
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Model created successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Failed to create new model', error));
    },
    meta: {
      mutationName: 'useCreateDeviceModel',
      baseUrl: DEVICE_MODELS_API_URL,
      method: 'POST',
    },
  });
}

export interface UseEditDeviceModelRequestPayload {
  deviceModelId: string;
  payload: Partial<
    Pick<
      DeviceModelDetailsType,
      | 'active'
      | 'sku'
      | 'auth_method'
      | 'config_schema'
      | 'ttl_in_minutes'
      | 'is_config_schema_readonly'
      | 'current_api_version'
      | 'communication_protocol'
      | 'enable_configuration_device_tab'
      | 'enable_state_device_tab'
      | 'enable_telemetries_device_tab'
      | 'image_url'
      | 'enable_details_device_tab'
      | 'enable_events_device_tab'
      | 'enable_files_device_tab'
      | 'user_settings'
      | 'troubleshooting_steps'
    > & { name: string }
  >;
}

export function useEditDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'PUT',
  });

  return useMutation<
    DeviceModelDetailsType,
    ServerError,
    UseEditDeviceModelRequestPayload
  >({
    mutationFn: ({ deviceModelId, payload }) =>
      fetchApiRequest(`${url}/${deviceModelId}`, {
        ...options,
        body: JSON.stringify(payload),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      await queryClient.invalidateQueries(productsQueryKeys.all);
      await queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Model successfully updated'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError('Failed to update model', error));
    },
    meta: {
      mutationName: 'useEditDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useBulkPublishDeviceModels() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'PUT',
  });

  return useMutation<DeviceModelSummaryType[], ServerError, string[]>({
    mutationFn: async (deviceModelIds) => {
      const promises = map((deviceModelId) => {
        return fetchApiRequest(`${url}/${deviceModelId}`, {
          ...options,
          body: JSON.stringify({ active: true }),
        });
      }, deviceModelIds);

      return await Promise.all(promises);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      await queryClient.invalidateQueries(productsQueryKeys.all);
      await queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Models published successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Publish models error', error));
    },
    meta: {
      mutationName: 'useBulkPublishDeviceModels',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useDeleteDeviceModel() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'DELETE',
  });

  return useMutation<DeviceModelSummaryType, ServerError, string>({
    mutationFn: (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}`, options),
    onSuccess: () => {
      dispatch(toastrSuccess('Model successfully removed'));

      return queryClient.invalidateQueries(deviceModelsQueryKeys.base);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError('Failed to remove model', error));
    },
    meta: {
      mutationName: 'useDeleteDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}

export function useDuplicateDeviceModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<DeviceModelDetailsType, ServerError, string>({
    mutationFn: (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}/duplicate`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      queryClient.invalidateQueries(productsQueryKeys.all);
      queryClient.invalidateQueries(devicesQueryKeys.list);

      dispatch(toastrSuccess('Model copied successfully'));
    },
    onError: ({ error }) => {
      dispatch(toastrError('Failed to copy new model', error));
    },
    meta: {
      mutationName: 'useDuplicateDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/duplicate`,
      method: 'POST',
    },
  });
}

export function useResetModelTroubleshootingSteps() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'POST',
  });

  return useMutation<DeviceModelSummaryType, ServerError, string>({
    mutationFn: async (deviceModelId) =>
      fetchApiRequest(`${url}/${deviceModelId}/reset_troubleshooting_steps`, {
        ...options,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(deviceModelsQueryKeys.base);
      toastrSuccess('Model troubleshooting steps reset successfully');
    },
    onError: ({ error }: any) => {
      toastrError('Failed to reset model troubleshooting steps', error);
    },
    meta: {
      mutationName: 'useEditDeviceModel',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}
