import { Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { CurrencyCode } from '@portals/types';
import { formatCurrency } from '@portals/utils';

interface SummaryCardProps {
  currency: CurrencyCode;
  itemsTotalPrice: number;
}

export function SummaryCard({ currency, itemsTotalPrice }: SummaryCardProps) {
  return (
    <Paper withBorder p="xl" radius="md">
      <Stack spacing="xs">
        <Text size="md" fw={500}>
          Summary
        </Text>
        <Group position="apart">
          <Text fw={500}>Total</Text>
          <Text size="xl" fw={500} color="blue_gray.9">
            {formatCurrency(itemsTotalPrice, currency)}
          </Text>
        </Group>
      </Stack>
    </Paper>
  );
}
