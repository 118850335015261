import { Avatar, Group, Text } from '@mantine/core';
import { first } from 'lodash/fp';
import React, { FC } from 'react';

interface NameAbbreviationCellProps {
  abbreviation?: string;
  name: string;
}

export const NameAbbreviationCell: FC<NameAbbreviationCellProps> = ({
  abbreviation,
  name,
}) => (
  <Group>
    <Avatar radius="xl" color="blue">
      {abbreviation || first(name)}
    </Avatar>

    <Text size="sm">{name}</Text>
  </Group>
);
