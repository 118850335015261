import {
  Accordion,
  AccordionProps,
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import { size } from 'lodash/fp';
import React, { useState } from 'react';

import { useFeatureFlags, useUpdateFeatureFlags } from '@portals/api/partners';
import { SearchInput } from '@portals/core';
import {
  FEATURE_LIST,
  FeatureFlagCategoryNamesEnum,
  FeatureFlagTypeEnum,
  PartnerFeatureFlagsType,
} from '@portals/types';

import { FeatureFlagsGroup } from './FeatureFlagsGroup';

interface FeatureFlagsProps {
  closeMe: () => void;
}

export function FeatureFlags({ closeMe }: FeatureFlagsProps) {
  const { classes } = useStyles();

  const updateFeatureFlags = useUpdateFeatureFlags();
  const features = useFeatureFlags();

  const [searchTerm, setSearchTerm] = useState('');

  const [updatedFeatures, setUpdatedFeatures] = useState<
    Partial<PartnerFeatureFlagsType>
  >({ ...features });

  const partnerCommonFeatures = [];
  const partnerReleaseFeatures = [];
  const customerCommonFeatures = [];
  const customerReleaseFeatures = [];

  FEATURE_LIST.forEach((feature) => {
    if (!feature.label.toLowerCase().includes(searchTerm.toLowerCase())) {
      return;
    }

    if (
      feature.type === FeatureFlagTypeEnum.Partner &&
      feature.category === FeatureFlagCategoryNamesEnum.Common
    ) {
      partnerCommonFeatures.push(feature);
    } else if (
      feature.type === FeatureFlagTypeEnum.Partner &&
      feature.category === FeatureFlagCategoryNamesEnum.Release
    ) {
      partnerReleaseFeatures.push(feature);
    } else if (
      feature.type === FeatureFlagTypeEnum.Customer &&
      feature.category === (FeatureFlagCategoryNamesEnum.Common as any)
    ) {
      customerCommonFeatures.push(feature);
    } else if (
      feature.type === FeatureFlagTypeEnum.Customer &&
      feature.category === FeatureFlagCategoryNamesEnum.Release
    ) {
      customerReleaseFeatures.push(feature);
    }
  });

  const onSubmit = () => {
    updateFeatureFlags.mutate(
      { feature_flags: updatedFeatures },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      size="80%"
      padding={0}
      onClose={closeMe}
      withCloseButton={false}
      styles={modalStyles}
      title={
        <Group position="apart">
          <Text>Feature Flags</Text>
          <SearchInput
            autoFocus
            placeholder="Search feature flags..."
            size="sm"
            onClear={() => setSearchTerm('')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Group>
      }
    >
      <Stack className={classes.contentContainer}>
        <Accordion
          multiple
          chevronPosition="left"
          styles={accordionStyles}
          defaultValue={['partner-side', 'customer-side']}
        >
          <Accordion.Item value="partner-side">
            <Accordion.Control>Partner side</Accordion.Control>
            <Accordion.Panel>
              <Group noWrap align="stretch">
                {size(partnerCommonFeatures) > 0 ? (
                  <FeatureFlagsGroup
                    title="Common"
                    type="partner"
                    featuresToDisplay={partnerCommonFeatures}
                    setCurrentFeatures={setUpdatedFeatures}
                    currentFeatures={updatedFeatures}
                  />
                ) : null}
                {size(partnerReleaseFeatures) > 0 ? (
                  <FeatureFlagsGroup
                    title="Release"
                    type="partner"
                    featuresToDisplay={partnerReleaseFeatures}
                    setCurrentFeatures={setUpdatedFeatures}
                    currentFeatures={updatedFeatures}
                  />
                ) : null}
              </Group>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="customer-side">
            <Accordion.Control>Customer side</Accordion.Control>
            <Accordion.Panel>
              <Group noWrap align="stretch">
                {size(customerCommonFeatures) > 0 ? (
                  <FeatureFlagsGroup
                    title="Common"
                    type="customer"
                    featuresToDisplay={customerCommonFeatures}
                    setCurrentFeatures={setUpdatedFeatures}
                    currentFeatures={updatedFeatures}
                  />
                ) : null}
                {size(customerReleaseFeatures) > 0 ? (
                  <FeatureFlagsGroup
                    title="Release"
                    type="customer"
                    featuresToDisplay={customerReleaseFeatures}
                    setCurrentFeatures={setUpdatedFeatures}
                    currentFeatures={updatedFeatures}
                  />
                ) : null}
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>

      <Group position="right" className={classes.footer}>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button loading={updateFeatureFlags.isLoading} onClick={onSubmit}>
          Submit
        </Button>
      </Group>
    </Modal>
  );
}

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  item: {
    borderBottom: 'none',
  },

  control: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  label: {
    fontSize: theme.fontSizes.xl,
  },
});

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: theme.spacing.xxl,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  title: {
    width: '100%',
  },
  body: {
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  },
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
});

const useStyles = createStyles((theme) => ({
  contentContainer: {
    overflow: 'auto',
    height: '100%',
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.xl,
  },
  footer: {
    padding: theme.spacing.xxl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));
