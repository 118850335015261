import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { PortalConfigType } from '@portals/api/partners';
import { getStyledThemeColor } from '@portals/utils';

import AddressBar from './AddressBar';

interface BrowserMockProps {
  portalConfig: Partial<PortalConfigType>;
  domain: string;
  children: ReactNode;
}

function BrowserMock({ children, portalConfig, domain }: BrowserMockProps) {
  return (
    <Container className="browser-mock-container">
      <AddressBar portalConfig={portalConfig} domain={domain} />

      {children}
    </Container>
  );
}

export const BROWSER_MOCK_WIDTH = 1351;

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  width: ${BROWSER_MOCK_WIDTH}px;
  height: 729px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${getStyledThemeColor('white')};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`;

export default BrowserMock;
