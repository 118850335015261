import React from 'react';

import { useWizard } from '@portals/framework';

import { OnboardingWizardContextDataType, StepId } from './types';

function OnboardingLayout() {
  const { currentStep } = useWizard<OnboardingWizardContextDataType, StepId>();

  return <>{currentStep.component}</>;
}

export default OnboardingLayout;
