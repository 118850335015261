import {
  StoreListing,
  StoreListingPrice,
  PublishableProduct,
} from '@portals/api/partners';

export enum PublishProductsStepIds {
  PricesDetails = 'pricesDetails',
  ProductDetails = 'productDetails',
}

export type PricingOptionKey = keyof Pick<
  StoreListingPrice,
  'one_time_price_in_scu' | 'monthly_price_in_scu' | 'yearly_price_in_scu'
>;

export interface PublishProductsWizardContextDataType {
  products: PublishableProduct[] | undefined;
  activeProductId: string | undefined;

  // Map of <productId, storeListing>
  storeListingsMap: Record<string, StoreListing> | undefined;

  // Map of <productId, PricingOptionKey>
  selectedPricingOptionMap: Record<string, PricingOptionKey | null> | undefined;
}
