import { Button } from '@mantine/core';
import React from 'react';

import { useProductWizard } from '../use-product-wizard';

interface CreateModeFooterProps {
  isLoading: boolean;
  onSave: () => void;
}

export function CreateModeFooter({ isLoading, onSave }: CreateModeFooterProps) {
  const { isFinalStep, onPrev, isPrevDisabled, onNext } = useProductWizard();

  const onNextClick = () => {
    onNext();

    if (isFinalStep) {
      onSave();
    }
  };

  return (
    <>
      <Button
        onClick={onPrev}
        disabled={isPrevDisabled}
        data-testid="modal-back-button"
        variant="default"
      >
        Back
      </Button>
      <Button
        loading={isLoading}
        data-testid={
          isFinalStep ? 'modal-save-button' : 'modal-continue-button'
        }
        onClick={onNextClick}
      >
        {isFinalStep ? 'Save & Close' : 'Continue'}
      </Button>
    </>
  );
}
