import { createStyles, Divider, Group } from '@mantine/core';
import React, { FC } from 'react';

import { StoreListingPrice, PublishableProduct } from '@portals/api/partners';

import { PricingOptionKey } from '../types';
import { Price } from './Price';

interface RecurringPricesProps {
  price: StoreListingPrice;
  product: PublishableProduct;
  selectedPriceKey: PricingOptionKey | undefined;
}

export const RecurringPrices: FC<RecurringPricesProps> = ({
  price,
  product,
  selectedPriceKey,
}) => {
  const { classes } = useStyles();

  return (
    <Group spacing="sm">
      {product.allow_monthly_price && (
        <Price
          isActive={selectedPriceKey === 'monthly_price_in_scu'}
          value={price.monthly_price_in_scu}
          currencyCode={price.currency}
        >
          &nbsp;/&nbsp;month
        </Price>
      )}
      {product.allow_monthly_price && product.allow_yearly_price && (
        <Divider orientation="vertical" size="md" className={classes.divider} />
      )}
      {product.allow_yearly_price && (
        <Price
          isActive={selectedPriceKey === 'yearly_price_in_scu'}
          value={price.yearly_price_in_scu}
          currencyCode={price.currency}
        >
          &nbsp;/&nbsp;year
        </Price>
      )}
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  divider: {
    height: 18,
    alignSelf: 'center',
    borderRadius: theme.radius.md,
    borderColor: theme.colors.blue_gray[9],
  },
}));
