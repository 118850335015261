import { Box, Button, createStyles, Stack } from '@mantine/core';
import React, { useRef, useState } from 'react';

import { ReactComponent as Play2 } from '@portals/icons/linear/play-2.svg';

const useStyles = createStyles((theme, isActive: boolean) => ({
  container: {
    position: 'relative',
  },
  videoWrapper: {
    borderRadius: theme.radius.sm,
    overflow: 'hidden',
    height: 250,
    border: `1px solid ${theme.colors.blue_gray[1]}`,

    video: {
      objectFit: 'fill',
    },
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.fn.rgba(theme.colors.blue_gray[9], 0.4),
    display: isActive ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.sm,
  },
  playButton: {
    borderRadius: '50%',
    width: 53,
    height: 53,
    boxShadow: `0px 5px 11px ${theme.fn.rgba(theme.white, 0.15)}`,
  },
}));

function Video() {
  const videoRef = useRef(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const { classes } = useStyles(videoIsPlaying);

  return (
    <Stack className={classes.container} justify="center">
      <Box className={classes.videoWrapper}>
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          controls={videoIsPlaying}
          onPlay={() => setVideoIsPlaying(true)}
          onPause={() => setVideoIsPlaying(false)}
        >
          <source
            src="https://s3.eu-west-1.amazonaws.com/static.xyte.io/integrate-connect.mp4"
            type="video/mp4"
          />
        </video>
      </Box>

      <Box className={classes.overlay}>
        <Button
          className={classes.playButton}
          onClick={() => videoRef.current.play()}
          variant="white"
          styles={{
            inner: {
              width: 18,
              height: 18,
            },
          }}
        >
          <Play2 color="black" />
        </Button>
      </Box>
    </Stack>
  );
}

export default Video;
