import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { DeviceModelSubModel } from './device-models.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

function getApiUrl(deviceModelId: string) {
  return `${getDeviceModelApiUrl(deviceModelId)}/sub_models`;
}

export function useDeviceModelSubModels(deviceModelId: string) {
  return useApiQuery<DeviceModelSubModel[]>(
    getApiUrl(deviceModelId),
    deviceModelsQueryKeys.subModels.all(deviceModelId),
    { staleTime: 0 }
  );
}

interface UseCreateSubModelParams {
  deviceModelId: string;
  name: string;
  imageUrl?: string | null;
}

export function useCreateDeviceModelSubModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, ServerError, UseCreateSubModelParams>({
    mutationFn: ({ deviceModelId, name, imageUrl }) => {
      return fetchApiRequest(`${url}${getApiUrl(deviceModelId)}`, {
        ...options,
        body: JSON.stringify({ name, image_url: imageUrl }),
      });
    },
    onSuccess: (_, variables) => {
      dispatch(toastrSuccess('Sub-model created successfully'));

      return queryClient.invalidateQueries(
        deviceModelsQueryKeys.subModels.all(variables.deviceModelId)
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

interface UseUpdateSubModelParams {
  deviceModelId: string;
  subModelId: string;
  name?: string;
  imageUrl?: string | null;
}

export function useUpdateDeviceModelSubModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<
    DeviceModelSubModel[],
    ServerError,
    UseUpdateSubModelParams
  >({
    mutationFn: ({ deviceModelId, subModelId, name, imageUrl }) => {
      return fetchApiRequest(
        `${url}${getApiUrl(deviceModelId)}/${subModelId}`,
        { ...options, body: JSON.stringify({ name, image_url: imageUrl }) }
      );
    },
    onSuccess: (response, variables) => {
      dispatch(toastrSuccess('Sub-model updated successfully'));

      queryClient.setQueryData(
        deviceModelsQueryKeys.subModels.all(variables.deviceModelId),
        response
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

interface UseDeleteSubModelParams {
  deviceModelId: string;
  subModelId: string;
}

export function useDeleteDeviceModelSubModel() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation<void, ServerError, UseDeleteSubModelParams>({
    mutationFn: ({ deviceModelId, subModelId }) => {
      return fetchApiRequest(
        `${url}${getApiUrl(deviceModelId)}/${subModelId}`,
        options
      );
    },
    onSuccess: (_, variables) => {
      dispatch(toastrSuccess('Sub-model deleted successfully'));

      return queryClient.invalidateQueries(
        deviceModelsQueryKeys.subModels.all(variables.deviceModelId)
      );
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
