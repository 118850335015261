import { Box, createStyles } from '@mantine/core';
import React, { useEffect } from 'react';

import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { Modals } from '@portals/framework';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

import { HubsHeader } from './hubs-header/HubsHeader';
import { HubsProvider } from './HubsContext';
import { HubsMainContent } from './HubsMainContent';
import { DashboardRoutesProvider } from '../routes/dashboard/DashboardRoutesProvider';
import { QuickNavigationProvider } from '../routes/dashboard/QuickNavigationProvider';

export function HubsLayout() {
  const { classes, cx } = useStyles();

  const openModal = useOpenModal();
  const currentUser = useCurrentUser();

  useEffect(
    function showNewPartnerHubsAnnouncement() {
      if (
        currentUser.data?.feature_notifications?.find(
          (feature) => feature === FeatureNotificationEnum.NewPartnerHubs
        )
      ) {
        openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
          featureNotification: FeatureNotificationEnum.NewPartnerHubs,
        });
      }
    },
    [currentUser.data?.feature_notifications, openModal]
  );

  return (
    <DashboardRoutesProvider>
      <QuickNavigationProvider>
        <HubsProvider>
          <Box className={cx(classes.wrapper, 'page-wrapper')}>
            <HubsHeader />

            <HubsMainContent />
          </Box>

          <Modals />
        </HubsProvider>
      </QuickNavigationProvider>
    </DashboardRoutesProvider>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: '100dvh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: `${theme.other.navbarHeight}px 1fr`,
  },
}));
