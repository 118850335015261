import {
  Badge,
  Box,
  Button,
  createStyles,
  Divider,
  Group,
  Paper,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { AnimatePresence, motion } from 'framer-motion';
import { size } from 'lodash/fp';
import React, { useState } from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as DollarCircle } from '@portals/icons/linear/dollar-circle.svg';
import {
  SupportedCommandProductType,
  SupportedCommandType,
} from '@portals/types';

import { LinkCommandToLicenseModal } from './LinkCommandToLicenseModal';
import { SelectedProducts } from './SelectedProducts';

export interface ActivateCommandWithLicenseProps {
  form: UseFormReturnType<Partial<SupportedCommandType>>;
  closeSupportedCommandModal: () => void;
  initialProducts: SupportedCommandProductType[];
}

export function ActivateCommandWithLicense({
  form,
  initialProducts,
  closeSupportedCommandModal,
}: ActivateCommandWithLicenseProps) {
  const { classes, cx } = useStyles();

  const [isLinkToLicenseModalOpened, setIsLinkToLicenseModalOpened] =
    useState(false);

  const onDeleteProduct = (productId: string) => {
    const filteredProducts = form.values.products?.filter(
      (product) => product.id !== productId
    );

    form.setFieldValue('products', filteredProducts);

    if (filteredProducts?.length === 0) {
      form.setFieldValue('premium', false);
    }
  };

  const onSetPremiumCommandStatus = (status: boolean) => {
    form.setFieldValue('premium', status);

    if (!status) {
      form.setFieldValue('products', []);
      return;
    }

    setIsLinkToLicenseModalOpened(true);
  };

  const onNavigateToCatalog = () => {
    closeSupportedCommandModal();
  };

  return (
    <Paper
      withBorder
      p="xl"
      radius="md"
      className={cx({
        [classes.disabled]: !form.values.active,
      })}
    >
      {isLinkToLicenseModalOpened ? (
        <LinkCommandToLicenseModal
          form={form}
          closeMe={() => setIsLinkToLicenseModalOpened(false)}
          onNavigateToCatalog={onNavigateToCatalog}
        />
      ) : null}

      <Group position="apart">
        <Group>
          <DollarCircle />

          <Box>
            <Group spacing="xs">
              <Text fw={500}>Activation with a license</Text>

              <Badge
                h={24}
                c="gray.9"
                fw={400}
                fz="xs"
                variant="gradient"
                gradient={{ from: '#E9F3F7', to: '#DEDBFD', deg: 108 }}
              >
                BETA
              </Badge>
            </Group>

            <Text color="gray.7">
              Turning this on will make this command available for users only by
              buying a license.
            </Text>
          </Box>
        </Group>

        <Switch
          checked={form.values.premium}
          data-testid="activation-with-license-switch"
          onChange={(event) => onSetPremiumCommandStatus(event.target.checked)}
        />
      </Group>

      {form.values.premium ? (
        <Stack>
          <Divider mt="xl" mb="sm" />

          <Group position="apart">
            <Box>
              {form.values.products?.length === 0 ? (
                <Text>Add license(s) that can unlock this command</Text>
              ) : (
                <>
                  <Text span>
                    This command will be included in{' '}
                    <b>{form.values.products?.length}</b> license(s)
                  </Text>
                </>
              )}

              {form.errors.product_ids && (
                <Text color="red">{form.errors.product_ids}</Text>
              )}
            </Box>

            <Button
              variant="default"
              leftIcon={<Add height={14} width={14} />}
              onClick={() => {
                setIsLinkToLicenseModalOpened(true);
              }}
            >
              Add license
            </Button>
          </Group>

          <AnimatePresence>
            <Stack>
              {form.values.products?.map((product) => (
                <motion.div
                  key={product.id}
                  layout
                  initial={
                    size(initialProducts) < size(form.values.products)
                      ? { opacity: 0, x: -400, scale: 0.5 }
                      : false
                  }
                  animate={{ opacity: 1, y: 0, x: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 200, x: 200, scale: 1.2 }}
                  transition={{ duration: 0.6, type: 'spring' }}
                >
                  <SelectedProducts
                    product={product}
                    onDeleteProduct={onDeleteProduct}
                    key={product.id}
                  />
                </motion.div>
              ))}
            </Stack>
          </AnimatePresence>
        </Stack>
      ) : null}
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));
