export const PRODUCT_SHIPMENTS_API_URL = 'ui/partner/product_shipments';

export const productShipmentsQueryKeys = {
  all: [PRODUCT_SHIPMENTS_API_URL],

  unshipped: {
    all: [PRODUCT_SHIPMENTS_API_URL, 'unshippedRequests'],
    byOrganizationId: (organizationId: string) => [
      ...productShipmentsQueryKeys.unshipped.all,
      organizationId,
    ],
  },
};
