import {
  Box,
  Button,
  CloseButton,
  createStyles,
  Flex,
  Group,
  ScrollArea,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import {
  convertWidgetFormToWidgetProps,
  ValueWidget,
  ValueWidgetForm,
  ValueWidgetFormType,
} from '@portals/device-widgets';
import { useConfirmationModal } from '@portals/framework';

import { CommonWidgetFormWrapperProps } from './form-wrappers.types';

export function ValueWidgetFormWrapper({
  initialValues,
  onSubmit,
  onCancel,
  onClose,
}: CommonWidgetFormWrapperProps<ValueWidgetFormType>) {
  const { classes } = useStyles();
  const asyncConfirmationCheck = useConfirmationModal();
  const form = useForm<ValueWidgetFormType>({
    initialValues: {
      name: '',
      telemetry_as_title: false,
      title_telemetry: '',
      telemetry: '',
      with_measurement_unit: false,
      measurement_unit: '',
      ...(initialValues || {}),
    },
  });

  const onCloseWrapper = async () => {
    if (!form.isDirty()) {
      onClose();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onClose();
  };

  const onCancelWrapper = async () => {
    if (!form.isDirty()) {
      onCancel();
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Are you sure?',
      description: 'All your changes will be lost',
      confirmationLabel: 'Confirm',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) onCancel();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.container}>
        <Stack className={classes.preview} p={30} pb={0} spacing={0}>
          <Text size={24} color="blue_gray.9">
            Add Value
          </Text>

          <ScrollArea.Autosize
            mah={645}
            sx={{
              '.modal-body': {
                padding: 0,
              },
            }}
          >
            <Space h="xl" />

            <ValueWidgetForm form={form} />

            <Space h="xl" />
          </ScrollArea.Autosize>
        </Stack>

        <Stack className={classes.config} p={30}>
          <Group position="right">
            <CloseButton size="xl" onClick={onCloseWrapper} />
          </Group>

          <Flex align="center" justify="center" h="100%">
            <ValueWidget
              {...convertWidgetFormToWidgetProps.value({
                formValues: form.values,
              })}
              stackProps={{ h: 130, w: 297 }}
            />
          </Flex>
        </Stack>

        <Group className={classes.footer} p="xl" position="right">
          <Button variant="default" onClick={onCancelWrapper}>
            {initialValues ? 'Close' : 'Back'}
          </Button>

          <Button
            type="submit"
            data-testid={
              initialValues ? 'save-widget-button' : 'add-widget-button'
            }
          >
            {initialValues ? 'Save' : 'Add Widget'}
          </Button>
        </Group>
      </Box>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr min-content',
    gridTemplateAreas: `
      "preview config"
      "footer footer"
    `,
    gap: 0,
    height: 790,
  },
  preview: {
    gridArea: 'preview',
  },
  config: {
    gridArea: 'config',
    backgroundColor: theme.colors.gray[0],
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    gridArea: 'footer',
  },
}));
