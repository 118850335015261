import { HANDLE_WIDTH } from './segmented-range-selector.constants';
import { RangeType } from './segmented-range-selector.types';

export function relativeToNumMax(
  relativeValue: number,
  globalRange: RangeType
): number {
  const value = (globalRange.max - globalRange.min) * (relativeValue / 100);

  return globalRange.min + value;
}

export function maxValueToXPos(containerWidth: number, segmentMax?: number) {
  if (segmentMax === undefined) {
    return 0;
  }

  return containerWidth * (segmentMax / 100);
}

export function xPosToMaxValue(xPosition: number, containerWidth: number) {
  return (xPosition / containerWidth) * 100;
}

export function computeDragLimit(
  containerWidth: number,
  segmentMaxToTheLeft: number,
  segmentMaxToTheRight: number,
  offset: number = HANDLE_WIDTH / 2
) {
  const min = maxValueToXPos(containerWidth, segmentMaxToTheLeft || 0);
  const max = maxValueToXPos(
    containerWidth,
    segmentMaxToTheRight || containerWidth
  );

  return { left: min, right: max };
}

export function calculateAdjustedXPosition(
  xPosition: number,
  containerLeftOffset: number
): number {
  return xPosition - containerLeftOffset;
}

export function isSegmentValueWithinRange(
  segmentValue: number,
  leftSideSegmentMax: number | undefined,
  rightSideSegmentMax: number | undefined,
  globalRange: { min: number; max: number }
): boolean {
  return (
    segmentValue > relativeToNumMax(leftSideSegmentMax || 0, globalRange) &&
    segmentValue < relativeToNumMax(rightSideSegmentMax || 100, globalRange)
  );
}
