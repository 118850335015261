import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { EmptyState } from '@portals/core';
import { VerticalScrollBar } from '@portals/scrollbar';

import { OrderStoreListingCard } from './OrderStoreListingCard';
import { SelectedStoreListingsTotalInfo } from './SelectedStoreListingsTotalInfo';
import selectStoreListingsEmptyStateSrc from '../../../assets/img/store-listing-empty-state.svg';
import { useCreateOrderWizard } from '../create-order-wizard/hooks';

export function SelectStoreListingsStep() {
  const { classes } = useStyles();
  const { contextData } = useCreateOrderWizard();

  return (
    <Stack spacing="xs" pos="relative">
      <Text size={24}>Select products</Text>
      <Text mb="xs">
        Choose licenses for this order. Only device licenses with a one time
        payment option can be selected.
      </Text>

      {contextData.storeListings?.length > 0 ? (
        <VerticalScrollBar>
          <div className={classes.storeListingsContainer}>
            {contextData.storeListings?.map((storeListing) => (
              <OrderStoreListingCard
                key={storeListing.id}
                storeListing={storeListing}
                data-testid="order-store-listing-card"
              />
            ))}
          </div>
        </VerticalScrollBar>
      ) : (
        <EmptyState
          messageTitle="No available products"
          messageDescription={
            <Text color="gray.9" size="xs" data-testid="no-available-products">
              There are currently no product listings available in the store.{' '}
              <br />
              Start adding products, publish, and sell them.
            </Text>
          }
          media={<img src={selectStoreListingsEmptyStateSrc} />}
        />
      )}

      <SelectedStoreListingsTotalInfo />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  storeListingsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: theme.spacing.xl,
    justifyContent: 'center',
    marginTop: theme.spacing.xl,
    paddingBottom: 100,
  },
}));
