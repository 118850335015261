import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  SUPPORT_SEATS_API_URL,
  supportSeatsQueryKeys,
} from './support-seats.constants';
import { SupportSeatsResponse } from './support-seats.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { configurationQueryKeys } from '../configuration';

export function useSupportSeats() {
  return useApiQuery<SupportSeatsResponse>(
    SUPPORT_SEATS_API_URL,
    supportSeatsQueryKeys.base,
    {
      staleTime: 0,
    }
  );
}

interface UseAddSupportSeatsParams {
  seats: number;
}
export function useAddSupportSeats() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: SUPPORT_SEATS_API_URL,
    method: 'PUT',
  });

  return useMutation<
    SupportSeatsResponse,
    ServerError,
    UseAddSupportSeatsParams
  >({
    mutationFn: ({ seats }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ seats }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Added support seats successfully'));

      queryClient.invalidateQueries(supportSeatsQueryKeys.base);
      queryClient.invalidateQueries(configurationQueryKeys.config);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useAddSupportSeats',
      baseUrl: SUPPORT_SEATS_API_URL,
      method: 'PUT',
    },
  });
}
