import { Button, Group, Modal, Text } from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import React from 'react';

import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface UnsavedChangesModalProps
  extends ModalProps<{
    onSave: (closeUnsavedChangesModal: () => void | undefined) => void;
    onCloseDrawer: () => void;
    title: string;
    description: string;
    confirmLabel: string;
  }> {}

export function UnsavedChangesModal({
  closeMe,
  data: { onSave, onCloseDrawer, title, description, confirmLabel },
}: UnsavedChangesModalProps) {
  const onClose = () => {
    onCloseDrawer();
    closeMe();
  };

  return (
    <Modal opened title={title} onClose={closeMe} styles={modalStyles}>
      <Text color="blue_gray.9">{description}</Text>

      <ModalFooter mt="md" position="right">
        <Group>
          <Button onClick={onClose} variant="default">
            Continue without saving
          </Button>

          <Button
            onClick={() => onSave(closeMe)}
            loaderPosition="left"
            styles={{ leftIcon: { svg: { stroke: 'black' } } }}
          >
            {confirmLabel}
          </Button>
        </Group>
      </ModalFooter>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.md}`,
  },

  body: {
    padding: `${theme.spacing.xxl} ${theme.spacing.xxl} ${theme.spacing.xl}`,
  },
});
