import { createStyles, Stack, Text } from '@mantine/core';
import { values } from 'lodash/fp';
import React from 'react';

import { formatCurrency } from '@portals/utils';

import { useCreateOrderWizard } from '../create-order-wizard/hooks';

export function SelectedStoreListingsTotalInfo() {
  const { classes } = useStyles();
  const { contextData } = useCreateOrderWizard();

  const selectedStoreListingsTotalQuantity = values(
    contextData.storeListingsQuantities
  ).reduce((acc, storeListingQuantity) => acc + storeListingQuantity, 0);

  if (
    selectedStoreListingsTotalQuantity === 0 ||
    !contextData.storeListings ||
    contextData.storeListings.length === 0
  ) {
    return null;
  }

  return (
    <Stack
      className={classes.totalStoreListingsInfo}
      align="center"
      spacing={4}
    >
      <Text size="xl" fw={500} data-testid="product-payment-preview">
        {formatCurrency(
          contextData.selectedStoreListingsTotalPrice,
          contextData.currency
        )}
      </Text>

      <Text data-testid="product-quantity-preview">
        {selectedStoreListingsTotalQuantity} Products
      </Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  totalStoreListingsInfo: {
    minWidth: 180,
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: theme.radius.lg,
    background: theme.colors.gray[0],
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.xl,
    boxShadow: '0px 4px 10px 0px rgba(38, 50, 56, 0.13)',
  },
}));
