import {
  ActionIcon,
  createStyles,
  Group,
  Navbar,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as ArrowRightIcon } from '@portals/icons/linear/arrow-right.svg';
import { useToggleSidebar } from '@portals/redux';

import { SidebarItem } from './SidebarItem';
import { useHubs } from '../HubsContext';

export function HubsSidebar() {
  const [isSidebarOpen, toggleSidebar] = useToggleSidebar();

  const { classes, cx } = useStyles();

  const { activeHub } = useHubs();

  return (
    <Navbar width={{ base: '100%' }} zIndex={1} py="xl">
      {isSidebarOpen && (
        <Navbar.Section className={classes.sidebarSection}>
          <Text className={classes.sidebarTitle} data-testid="sidebar-title">
            {activeHub?.sidebarTitle}
          </Text>
        </Navbar.Section>
      )}

      <Navbar.Section
        grow
        className={cx(classes.sidebarSection, classes.sidebarItemsSection)}
      >
        <Stack spacing="xs" h="100%">
          {activeHub?.sidebarItems.map((sidebarItem) => (
            <SidebarItem key={sidebarItem.id} sidebarItem={sidebarItem} />
          ))}
        </Stack>
      </Navbar.Section>

      <Navbar.Section className={classes.sidebarSection}>
        <Group position="center">
          {isSidebarOpen && (
            <Text color="blue_gray.2" mr="auto">
              Powered by Xyte
            </Text>
          )}

          <ActionIcon
            variant="light"
            color="blue_gray"
            onClick={() => toggleSidebar()}
          >
            <ArrowRightIcon
              className={cx({ [classes.rotate]: isSidebarOpen })}
            />
          </ActionIcon>
        </Group>
      </Navbar.Section>
    </Navbar>
  );
}

const useStyles = createStyles((theme) => ({
  sidebarSection: {
    paddingInline: theme.spacing.md,

    '&:not(:last-child)': {
      marginBottom: theme.spacing.xl,
    },
  },
  sidebarTitle: {
    paddingInline: theme.spacing.md,
    color: theme.colors.blue_gray[3],
  },
  sidebarItemsSection: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
}));
