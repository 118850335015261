import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { UuidType } from '@portals/types';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
  getDeviceModelApiUrl,
} from './device-models.constants';
import { HardwareKeyType } from './device-models.types';
import { useApiQuery } from '../../hooks/query';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';

function getApiUrl(modelId: string) {
  return `${getDeviceModelApiUrl(modelId)}/hardware_keys`;
}

export const useDeviceModelHardwareKeys = (modelId: UuidType) =>
  useApiQuery<HardwareKeyType[]>(
    getApiUrl(modelId),
    deviceModelsQueryKeys.hardwareKeys.details(modelId),
    {
      staleTime: 0,
      enabled: !!modelId,
    }
  );

export const useCreateDeviceModelHardwareKey = (modelId: UuidType) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: getApiUrl(modelId),
    method: 'POST',
  });

  return useMutation({
    mutationFn: ({
      friendly_name,
      expected_device_count,
    }: {
      friendly_name: string;
      expected_device_count: number;
    }): Promise<HardwareKeyType> =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          friendly_name,
          expected_device_count,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        deviceModelsQueryKeys.hardwareKeys.details(modelId)
      );

      dispatch(toastrSuccess(`Hardware key created`));
    },
    onError: ({ error }) => {
      return dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateDeviceModelHardwareKey',
      baseUrl: `${DEVICE_MODELS_API_URL}/:id/hardware_keys`,
      method: 'POST',
    },
  });
};
