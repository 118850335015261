import { LoadingOverlay } from '@mantine/core';
import React, { FC, useCallback } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { useCreateDeviceModelWidget } from '@portals/api/partners';

import { Footer, Widget } from '../../components/ModelWidgets/common';
import { WIDGETS } from '../../components/ModelWidgets/constants';
import { WidgetMetaType } from '../../components/ModelWidgets/types';

interface WidgetsListProps {
  onCancel: () => void;
  onSelect: (widget: WidgetMetaType) => void;
  modelId: string;
}

const WidgetsList: FC<WidgetsListProps> = ({ onCancel, onSelect, modelId }) => {
  const { mutateAsync, isLoading } = useCreateDeviceModelWidget(modelId);

  const onSubmit = useCallback(
    async (widget: WidgetMetaType) => {
      await mutateAsync({
        name: widget.name,
        config: {
          id: widget.id,
          width: widget.width,
        },
      });

      onCancel();
    },
    [mutateAsync, onCancel]
  );

  return (
    <>
      <Container>
        <LoadingOverlay visible={isLoading} />

        {WIDGETS.map((widget) => (
          <Widget
            key={widget.id}
            data-testid={'widget-' + widget.id}
            onClick={() =>
              widget.isNoFields ? onSubmit(widget) : onSelect(widget)
            }
            layoutId={widget.isNoFields ? null : `widget-${widget.id}`}
          >
            <div className="title">{widget.name}</div>

            <div className="icon-wrapper">
              <img src={widget.configIcon} alt={widget.name} />
            </div>
          </Widget>
        ))}
      </Container>

      <Footer>
        <Button color="light" size="lg" onClick={onCancel}>
          Cancel
        </Button>
      </Footer>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 250px;
  grid-column-gap: 25px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: space-between;
  padding: 40px;
`;

export default WidgetsList;
