import { Button, Group, Tooltip } from '@mantine/core';
import { every, forEach, keys } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { CurrencyStoreListing } from '@portals/api/partners';
import { CurrencyCode } from '@portals/types';

import { usePricingTableContext } from './context';

interface FooterProps {
  onClose: () => void;
  selectedCurrencies: Array<CurrencyCode>;
  onAddCurrency: (params: {
    currency: string;
    store_listings: Array<CurrencyStoreListing>;
  }) => Promise<any>;
}

export const Footer: FC<FooterProps> = ({
  onClose,
  selectedCurrencies,
  onAddCurrency,
}) => {
  const pricingTable = usePricingTableContext();

  const isAllPricesAssigned = useMemo(() => {
    let isAllAssigned = true;

    forEach((rowMeta) => {
      const currencies = keys(rowMeta.pricesByCurrencies);

      isAllAssigned = every(
        (currency) =>
          every(
            ({ amount_in_scu }) => !!amount_in_scu,
            rowMeta.pricesByCurrencies[currency]
          ),
        currencies
      );

      if (!isAllAssigned) return false;
    }, pricingTable.data);

    return isAllAssigned;
  }, [pricingTable.data]);

  const onSubmit = async () => {
    const addCurrencyParams = [];

    forEach((currency) => {
      const addCurrencyParamsByCurrency = [];

      forEach((storeListingId) => {
        const storeListingObject = {
          id: storeListingId,
        };

        const rowCurrencyPrices =
          pricingTable.data[storeListingId].pricesByCurrencies[currency];

        forEach(({ key, amount_in_scu }) => {
          storeListingObject[key] = amount_in_scu;
        }, rowCurrencyPrices);

        addCurrencyParamsByCurrency.push(storeListingObject);
      }, keys(pricingTable.data));

      addCurrencyParams.push({
        currency,
        store_listings: addCurrencyParamsByCurrency,
      });
    }, selectedCurrencies);

    for (let i = 0; i < addCurrencyParams.length; i++) {
      const params = addCurrencyParams[i];

      await onAddCurrency(params);
    }

    onClose();
  };

  return (
    <Group position="right">
      <Button variant="default" onClick={onClose}>
        Cancel
      </Button>

      <Tooltip
        label="All products prices must be assigned a price in all currencies"
        disabled={isAllPricesAssigned}
      >
        <Button disabled={!isAllPricesAssigned} onClick={onSubmit}>
          Save
        </Button>
      </Tooltip>
    </Group>
  );
};
