import { Box, Group, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface RadioCardLabelProps {
  title: string;
  description?: string;
  icon: ReactNode;
  children?: ReactNode;
}

export function RadioCardLabel({
  title,
  description,
  icon,
  children,
}: RadioCardLabelProps) {
  return (
    <Stack>
      <Group noWrap>
        <Box>{icon}</Box>

        <Stack spacing={4}>
          <Text
            size="md"
            data-testid="radio-card-label-title"
            weight={500}
            color="gray.8"
          >
            {title}
          </Text>

          {description && (
            <Text size="xs" weight={500} color="gray.5">
              {description}
            </Text>
          )}
        </Stack>
      </Group>

      {children}
    </Stack>
  );
}
