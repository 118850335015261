import { useInputState } from '@mantine/hooks';
import React, { useState } from 'react';

import { useApprovePurchaseOrder, useOrderById } from '@portals/api/partners';
import { ModalProps } from '@portals/framework';

import { ApprovePurchaseOrder } from './ApprovePurchaseOrder';
import { PurchaseOrderFilePreviewWrapper } from './PurchaseOrderFilePreview';

export interface ReviewPurchaseOrderModalProps
  extends ModalProps<{
    orderId: string;
  }> {}

export function ReviewPurchaseOrderModal({
  closeMe,
  data,
}: ReviewPurchaseOrderModalProps) {
  const [value, setValue] = useInputState<string>('');
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [currentStep, setCurrentStep] = useState<'filePreview' | 'poApprove'>(
    'filePreview'
  );
  const order = useOrderById(data.orderId);
  const approvePurchaseOrder = useApprovePurchaseOrder();

  const onApprove = async () => {
    try {
      await approvePurchaseOrder.mutateAsync({
        orderId: data.orderId,
        note: value,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  if (currentStep === 'filePreview') {
    return (
      <PurchaseOrderFilePreviewWrapper
        order={order.data}
        onSubmit={() => setCurrentStep('poApprove')}
        onCancel={closeMe}
        setIsDownloaded={() => setIsDownloaded(true)}
        isDownloaded={isDownloaded}
      />
    );
  } else {
    return (
      <ApprovePurchaseOrder
        isLoading={approvePurchaseOrder.isLoading}
        onCancel={() => setCurrentStep('filePreview')}
        onClose={closeMe}
        onSubmit={onApprove}
        value={value}
        setValue={setValue}
      />
    );
  }
}
