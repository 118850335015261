import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  PartnerFileResponseType,
  UsePaginatedTableApiQuery,
} from '@portals/types';

import { FILE_INFOS_API_URL, fileInfosQueryKeys } from './file-infos.constants';
import { useApiQuery } from '../../hooks/query';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export function useFileInfos(
  tableState: UsePaginatedTableApiQuery<PartnerFileResponseType>['tableState'],
  columns: UsePaginatedTableApiQuery<PartnerFileResponseType>['columns'],
  baseUrl: string = FILE_INFOS_API_URL
) {
  return usePaginatedTableApiQuery<PartnerFileResponseType>({
    baseUrl,
    columns,
    tableState,
    queryKey: [...fileInfosQueryKeys.all(), tableState],
  });
}

export function useFileInfoById(fileId: string | undefined) {
  return useApiQuery<PartnerFileResponseType>(
    `${FILE_INFOS_API_URL}/${fileId}`,
    fileInfosQueryKeys.detail(String(fileId)),
    { enabled: !!fileId }
  );
}

export function useDeleteFileInfo() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: FILE_INFOS_API_URL,
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (fileId: string) => {
      return fetchApiRequest(`${url}/${fileId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('File deleted successfully'));

      queryClient.invalidateQueries(fileInfosQueryKeys.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteFileInfo',
      baseUrl: `${FILE_INFOS_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
