import React from 'react';

import { IconPickerProps } from '@portals/core';
import { ReactComponent as Box } from '@portals/icons/linear/box.svg';
import { ReactComponent as Car } from '@portals/icons/linear/car.svg';
import { ReactComponent as Coffee } from '@portals/icons/linear/coffee.svg';
import { ReactComponent as Cpu } from '@portals/icons/linear/cpu.svg';
import { ReactComponent as Driver } from '@portals/icons/linear/driver.svg';
import { ReactComponent as Electricity } from '@portals/icons/linear/electricity.svg';
import { ReactComponent as ExternalDrive } from '@portals/icons/linear/external-drive.svg';
import { ReactComponent as Microphone2 } from '@portals/icons/linear/microphone-2.svg';
import { ReactComponent as Mobile } from '@portals/icons/linear/mobile.svg';
import { ReactComponent as Monitor } from '@portals/icons/linear/monitor.svg';
import { ReactComponent as Printer } from '@portals/icons/linear/printer.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Speaker } from '@portals/icons/linear/speaker.svg';
import { ReactComponent as Watch } from '@portals/icons/linear/watch.svg';
import { ReactComponent as Wifi } from '@portals/icons/linear/wifi.svg';

export type TaxGroupIconName =
  | 'cpu'
  | 'monitor'
  | 'driver'
  | 'watch'
  | 'microphone'
  | 'printer'
  | 'mobile'
  | 'drive'
  | 'speaker'
  | 'car'
  | 'coffee'
  | 'electricity'
  | 'box'
  | 'setting'
  | 'wifi';

export const TAX_GROUPS_ICONS: IconPickerProps<TaxGroupIconName>['icons'] = {
  cpu: {
    name: 'cpu',
    component: <Cpu />,
    color: 'teal',
  },
  monitor: {
    name: 'monitor',
    component: <Monitor />,
    color: 'deep_purple',
  },
  driver: {
    name: 'driver',
    component: <Driver />,
    color: 'blue',
  },
  watch: {
    name: 'watch',
    component: <Watch />,
    color: 'deep_orange',
  },
  microphone: {
    name: 'microphone',
    component: <Microphone2 />,
    color: 'yellow',
  },
  printer: {
    name: 'printer',
    component: <Printer />,
    color: 'pink',
  },
  mobile: {
    name: 'mobile',
    component: <Mobile />,
    color: 'indigo',
  },
  drive: {
    name: 'drive',
    component: <ExternalDrive />,
    color: 'green',
  },
  speaker: {
    name: 'speaker',
    component: <Speaker />,
    color: 'lime',
  },
  car: {
    name: 'car',
    component: <Car />,
    color: 'amber',
  },
  coffee: {
    name: 'coffee',
    component: <Coffee />,
    color: 'brown',
  },
  electricity: {
    name: 'electricity',
    component: <Electricity />,
    color: 'red',
  },
  box: {
    name: 'box',
    component: <Box />,
    color: 'light_blue',
  },
  setting: {
    name: 'setting',
    component: <Setting2 />,
    color: 'purple',
  },
  wifi: {
    name: 'wifi',
    component: <Wifi />,
    color: 'cyan',
  },
};
