import { Select } from '@mantine/core';
import { keys } from 'lodash/fp';
import React from 'react';

import { PREDICATE_MAP } from './mapping';

const TypeInput = ({ value, setValue, disabled }) => (
  <Select
    id="type"
    type="select"
    data-testid="select-field-type"
    name="type"
    label="Field Type"
    required
    disabled={disabled}
    value={value || ''}
    onChange={setValue}
    data={keys(PREDICATE_MAP)}
    withinPortal={false}
  />
);

export default TypeInput;
