import { Box, createStyles, Group, Paper, Text } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ComponentType, ReactNode } from 'react';

import { PartnerUpdateTierRibbonRight } from '../PartnerPremiumTag';

interface DomainCardProps {
  isActive: boolean;
  label: string;
  Icon: ComponentType;
  onSelect: () => void;
  isDisabled?: boolean;
  children: ReactNode;
}

function DomainCard({
  label,
  Icon,
  children,
  onSelect,
  isActive,
  isDisabled,
}: DomainCardProps) {
  const { classes, cx } = useStyles();

  return (
    <Box sx={{ position: 'relative' }}>
      {isDisabled ? (
        <PartnerUpdateTierRibbonRight source="Partners > White Branding > Custom domain" />
      ) : null}

      <Paper
        radius="md"
        p="xl"
        className={cx(classes.container, {
          active: isActive,
          disabled: isDisabled,
        })}
        onClick={() => !isDisabled && onSelect()}
      >
        <Group
          spacing="md"
          mb={isActive ? 20 : 0}
          className="domain-card-header"
        >
          <Icon />

          <Text color="blue_gray">{label}</Text>
        </Group>

        <AnimatePresence initial={false}>
          {isActive ? (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.1,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0,
                },
              }}
            >
              {children}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Paper>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    minHeight: 72,
    transition: 'all 0.15s ease-in-out',
    border: `1px solid ${theme.fn.rgba(theme.colors.blue_gray[1], 0.7)}`,
    cursor: 'pointer',
    position: 'relative',

    '&.active': {
      minHeight: 140,
      border: `1px solid ${theme.colors.blue_accent[4]}`,
      cursor: 'default',
    },

    '&.disabled': {
      cursor: 'not-allowed',

      '.domain-card-header': {
        opacity: 0.5,
      },
    },

    '&:hover': {
      '&:not(.active):not(.disabled)': {
        boxShadow: '0px 11px 18px rgba(38, 50, 56, 0.1)',
      },
    },

    svg: {
      color: theme.colors.blue_accent[4],
    },
  },
}));

export default DomainCard;
