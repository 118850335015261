import { find, keys, reduce } from 'lodash/fp';

import { StoreListingPrice } from '@portals/api/partners';
import { CurrencyCode } from '@portals/types';

import { CellPrice, StoreListingKeys } from './types';

interface GetMainCellCurrencyParams {
  prices: Array<StoreListingPrice>;
  currency: CurrencyCode;
}

export const getCellPricesList = ({
  prices,
  currency,
}: GetMainCellCurrencyParams): Array<CellPrice> => {
  const currentCurrencyPrices = find({ currency }, prices);

  return reduce(
    (acc, priceKey: StoreListingKeys) => {
      if (priceKey === 'currency' || !currentCurrencyPrices[priceKey])
        return acc;

      acc.push({
        key: priceKey,
        amount_in_scu: currentCurrencyPrices[priceKey],
      });

      return acc;
    },
    [],
    keys(currentCurrencyPrices)
  );
};
