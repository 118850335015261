import { Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { CurrencyCode } from '@portals/types';
import { formatPrice } from '@portals/utils';

import { usePublishProductsWizard } from '../hooks';
import { PublishProductsStepIds } from '../types';

interface PriceProps {
  value: number | undefined | null;
  currencyCode: CurrencyCode;
  isActive: boolean;
  children?: ReactNode;
}

export function Price({ value, currencyCode, isActive, children }: PriceProps) {
  const { currentStep } = usePublishProductsWizard();

  return (
    <Text
      size="xl"
      weight={700}
      sx={(theme) => ({
        color:
          isActive || currentStep.id === PublishProductsStepIds.ProductDetails
            ? theme.colors.blue_gray[9]
            : theme.colors.blue_gray[2],
        transition: 'color 0.15s linear',
      })}
    >
      {formatPrice({ value, currencyCode })}
      {children}
    </Text>
  );
}
