import { getOr } from 'lodash/fp';

import { EditActive } from '@portals/framework';

import CustomFields from './CustomFields';
import EditConnection from './EditConnection';
import EditDefaults from './EditDefaults';

export default [
  {
    name: 'Connect',
    enabled: (_) => true,
    component: EditConnection,
  },
  {
    name: 'Organization',
    enabled: (data) => getOr(false, 'extra.org_ids', data),
    optionsPath: () => 'extra.org_ids',
    loadParams: () => ['org_ids'],
    fields: (options) => [
      {
        name: 'org_id',
        title: 'Organization ID',
        required: true,
        type: 'select',
        options,
      },
    ],
    visible: (data) => !getOr(false, 'params.org_id', data),
  },
  {
    name: 'Fields Configuration',
    enabled: (data) => getOr(false, 'extra.departments', data),
    component: EditDefaults,
  },
  {
    name: 'Custom Fields',
    enabled: (data) => getOr(false, 'extra.departments', data),
    component: CustomFields,
  },
  {
    name: 'Enable',
    enabled: (data) => getOr(false, 'extra.departments', data),
    component: EditActive,
  },
];
