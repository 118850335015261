import { createStyles, Group, Modal, ModalProps, Stack } from '@mantine/core';
import { isEqual } from 'lodash/fp';
import React, { useRef, useState } from 'react';

import {
  SaveProductRequestPayload,
  useCreateProduct,
  useUpdateProduct,
} from '@portals/api/partners';
import { useConfirmationModal, WizardTransitionType } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { ProductPricingModel, ProductTypeEnum } from '@portals/types';

import { EditModeConfirmationModal } from './EditModeConfirmationModal';
import { NavigationColumn } from './navigation-column/NavigationColumn';
import { PreviewColumn } from './preview-column/PreviewColumn';
import { getFirstStepWithErrors } from './product-wizard.utils';
import { ProductWizardContext, StepId } from './types';
import { useProductWizard } from './use-product-wizard';
import { CreateModeFooter } from './wizard-footer/CreateModeFooter';
import { EditModeFooter } from './wizard-footer/EditModeFooter';

interface WizardContentProps {
  productId: string | undefined;
  isEdit: boolean;
  onClose: () => void;
}

export function WizardContent({
  productId,
  isEdit,
  onClose,
}: WizardContentProps) {
  const { classes } = useStyles();
  const asyncConfirm = useConfirmationModal();
  const [isEditModeConfirmationModalOpen, setIsEditModeConfirmationModalOpen] =
    useState(false);

  const updateProduct = useUpdateProduct();
  const createProduct = useCreateProduct();

  const { currentStep, contextData, skipToStep, setContextData } =
    useProductWizard();

  const openModal = useOpenModal();

  // Hold a copy of an un-changed contextData,
  // so later we can check if we need to prompt the "Save Changes" modal.
  const pristineContextData = useRef<ProductWizardContext>(contextData);

  const composeProductRequestPayload = () => {
    const product = contextData.product as SaveProductRequestPayload;
    const isUsageBasedPricing =
      product.pricing_model === ProductPricingModel.UsageBased;

    return {
      name: product.name,
      product_type: product.product_type,
      category: product.category,
      subtitle: product.subtitle,
      description: product.description,
      image_url: product.image_url,
      tax_group_id: product.tax_group_id,
      license_data: product.device_model_id ? product.license_data : null,
      device_model_id: product.device_model_id,
      post_purchase_parameters: product.post_purchase_parameters,
      pricing_model: product.pricing_model,
      sku: product.sku,
      usage_based_display_name: isUsageBasedPricing
        ? product.usage_based_display_name
        : null,
      usage_based_telemetry_key: isUsageBasedPricing
        ? product.usage_based_telemetry_key
        : null,
      usage_based_unit_name: isUsageBasedPricing
        ? product.usage_based_unit_name
        : null,
      supported_command_ids: product.supported_command_ids,
      transferable: Boolean(product.transferable),
    } satisfies SaveProductRequestPayload;
  };

  const onCreateProduct = async () => {
    try {
      const payload = composeProductRequestPayload();
      await createProduct.mutateAsync(payload);

      onClose();

      openModal('ProductCreationSuccessModal');
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateProduct = async () => {
    try {
      const productPayload = composeProductRequestPayload();

      await updateProduct.mutateAsync({
        productId: productId as string,
        productPayload,
      });

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = async () => {
    if (isEdit) {
      if (isEqual(contextData, pristineContextData.current)) {
        onClose();
        return;
      }

      // If the contextData has changed, prompt the "Save Changes" modal.
      setIsEditModeConfirmationModalOpen(true);
    } else {
      const isConfirmed = await asyncConfirm({
        title: 'Leave without saving?',
        description:
          'This product will not be created and you will lose all changes if you leave now.',
        confirmationLabel: 'Leave anyway',
        cancelLabel: 'Keep editing',
      });

      if (isConfirmed) {
        onClose();
      }
    }
  };

  const handleSave = async () => {
    // Run the transition handler of the current step to let it set errors if any.
    const isCurrentStepValid = await currentStep.onTransition?.({
      contextData,
      transitionType: WizardTransitionType.Next,
      from: currentStep.id,
      setContextData,
    });

    if (!isCurrentStepValid) {
      return;
    }

    if (isEdit) {
      // Check if there are any errors in the contextData.
      const firstStepWithErrors = getFirstStepWithErrors(contextData.errors);
      if (firstStepWithErrors) {
        await skipToStep(firstStepWithErrors, {
          forceSkip: true,
          runTransitionHandler: true,
        });

        return;
      }

      // If there are no errors, save the product.
      await onUpdateProduct();
    } else {
      await onCreateProduct();
    }
  };

  const hidePreview =
    currentStep.id === StepId.ProductDetails &&
    contextData.product.product_type === ProductTypeEnum.PlatformLicense;

  if (currentStep.id === StepId.ProductType) {
    return (
      <Modal
        opened
        onClose={onClose}
        closeButtonProps={{ size: 'lg' }}
        data-testid="wizard-modal"
        size="clamp(1200px, 100%, 1700px)"
        styles={productTypeModalStyles}
      >
        {currentStep.component}
      </Modal>
    );
  }

  return (
    <>
      <Modal
        opened
        onClose={handleClose}
        closeButtonProps={{ size: 'lg' }}
        size="clamp(1200px, 90%, 1700px)"
        padding={0}
        styles={wizardModalStyles}
      >
        <Stack spacing={0} className={classes.modalContentContainer}>
          <div className={classes.modalContent}>
            <NavigationColumn isEdit={isEdit} />

            <div className={classes.detailsColumn}>{currentStep.component}</div>

            {hidePreview ? null : <PreviewColumn />}
          </div>

          <Group position="apart" p="xl" className={classes.modalFooter}>
            {isEdit ? (
              <EditModeFooter
                onCancel={handleClose}
                onSave={handleSave}
                isLoading={updateProduct.isLoading}
              />
            ) : (
              <CreateModeFooter
                isLoading={createProduct.isLoading}
                onSave={handleSave}
              />
            )}
          </Group>
        </Stack>
      </Modal>

      <EditModeConfirmationModal
        opened={isEditModeConfirmationModalOpen}
        onConfirm={() => {
          setIsEditModeConfirmationModalOpen(false);
          handleSave();
        }}
        onDiscard={onClose}
        onCancel={() => setIsEditModeConfirmationModalOpen(false)}
      />
    </>
  );
}

const productTypeModalStyles: ModalProps['styles'] = (theme) => ({
  header: { marginBottom: 0 },
  body: {
    padding: '0 40px 40px',
    display: 'grid',
    height: '100%',
    flex: 1,
    gridTemplateRows: 'min-content 1fr',
    placeContent: 'center',
  },
  content: { display: 'flex', flexDirection: 'column', minHeight: '80%' },
});

const wizardModalStyles: ModalProps['styles'] = (theme) => ({
  inner: { padding: 0 },
  content: { display: 'flex', flexDirection: 'column', minHeight: '100%' },
  header: { margin: 0 },
  close: { position: 'absolute', top: 32, right: theme.spacing.xl },
  body: { display: 'flex', flexGrow: 1 },
});

const useStyles = createStyles((theme) => ({
  modalContentContainer: {
    flexGrow: 1,
  },
  modalContent: {
    flexGrow: 1,
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '285px 3fr',
    gridAutoColumns: '2fr',
  },
  modalFooter: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
  detailsColumn: {
    paddingTop: 40,
  },
}));
