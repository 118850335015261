import {
  CloseButton,
  createStyles,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import { isEqual, omit } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';

import { StoreListing, useStoreSettings } from '@portals/api/partners';
import {
  ModalProps,
  useConfirmationModal,
  WizardProvider,
} from '@portals/framework';

import { STEPS_MAP } from './steps-map';
import { calcIsBasePriceEnabled } from './store-listing-wizard.utils';
import { OnCloseCallback, StepId, StoreListingWizardContext } from './types';
import { WizardContent } from './WizardContent';
import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

export interface StoreListingWizardModalProps
  extends ModalProps<{
    intent: 'create' | 'edit' | 'publish';
    storeListing: StoreListing | undefined;
  }> {}

export function StoreListingWizardModal({
  closeMe,
  data: { storeListing, intent },
}: StoreListingWizardModalProps) {
  const { classes } = useStyles();
  const asyncConfirmation = useConfirmationModal();

  const storeSettings = useStoreSettings();

  const hasChanges = () => {
    const keysToOmit: Array<keyof StoreListingWizardContext> = [
      'intent',
      'storeListing',
      'paymentSettingsAccordionValues',
      'paymentSettingsGeneralError',
      'slugError',
      'slug',
      'requireCancellationReason',
      'closeModal',
    ];

    return !isEqual(
      omit(keysToOmit, pristineContextData.current),
      omit(keysToOmit, contextData)
    );
  };

  const onClose: OnCloseCallback = async ({ confirmBeforeClose }) => {
    if (!confirmBeforeClose || !hasChanges()) {
      closeMe();
      return;
    }

    const isConfirmed = await asyncConfirmation({
      title: 'Leave without saving?',
      description:
        'Closing this window will discard all changes you have made. Are you sure you want to leave?',
      confirmationLabel: 'Leave anyway',
      cancelLabel: 'Cancel',
    });

    if (isConfirmed) {
      closeMe();
    }
  };

  const [contextData, setContextData] = useState<StoreListingWizardContext>({
    intent,
    storeListing: storeListing,
    productOverrides: storeListing
      ? {
          productName: storeListing.product_name,
          productCategory: storeListing.product_category,
          productSubtitle: storeListing.product_subtitle,
          productDescription: storeListing.product_description,
          productImageUrl: storeListing.product_image_url,
        }
      : undefined,
    productNameError: '',
    productDescriptionError: '',
    selectedProduct: storeListing?.product,
    paymentSettings: mapStoreListingPricesToPaymentSettings({
      currencies: storeSettings.data?.currencies,
      storeListingPrices: storeListing?.prices,
      defaultCurrency: storeSettings.data?.default_currency,
    }),
    paymentSettingsAccordionValues: [],
    paymentSettingsGeneralError: undefined,
    isBasePriceEnabled: calcIsBasePriceEnabled(storeListing),
    requireCancellationReason:
      storeListing?.require_cancellation_reason ?? false,
    slug: storeListing?.slug,
    slugError: '',

    closeModal: onClose,
  });

  const pristineContextData = useRef(contextData);

  useEffect(
    function initContextData() {
      if (!storeSettings.isFetched || !storeSettings.data) {
        return;
      }

      setContextData((prev) => {
        const updateContextData: StoreListingWizardContext = {
          ...prev,
          paymentSettings: mapStoreListingPricesToPaymentSettings({
            currencies: storeSettings.data.currencies,
            storeListingPrices: storeListing?.prices,
            defaultCurrency: storeSettings.data.default_currency,
          }),
        };

        pristineContextData.current = updateContextData;
        return updateContextData;
      });
    },
    [storeListing?.prices, storeSettings.data, storeSettings.isFetched]
  );

  return (
    <Modal
      opened
      onClose={() => onClose({ confirmBeforeClose: true })}
      withCloseButton={false}
      size="clamp(1200px, 90%, 1700px)"
      data-testid="store-listing-wizard-modal"
      padding={0}
      styles={modalStyles}
    >
      <LoadingOverlay visible={storeSettings.isFetching} />

      <CloseButton
        size="lg"
        onClick={() => onClose({ confirmBeforeClose: true })}
        className={classes.closeButton}
        data-testid="modal-close-button"
      />
      <WizardProvider<StoreListingWizardContext, StepId>
        steps={STEPS_MAP}
        initialStep={
          contextData.intent === 'create'
            ? StepId.ChooseProduct
            : StepId.ProductInformation
        }
        contextData={contextData}
        setContextData={setContextData}
      >
        <WizardContent />
      </WizardProvider>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = () => ({
  inner: { padding: 0 },
  content: {
    display: 'flex',
    height: '90%',
    overflow: 'hidden !important',
  },
  header: { margin: 0 },
  body: { display: 'flex', flexGrow: 1 },
});

const useStyles = createStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing.md,
    right: theme.spacing.md,
    zIndex: 2,
  },
}));
