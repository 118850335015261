import { TableColumn, TableState } from '@portals/types';

import { useApiQuery } from '../../hooks';
import { usePaginatedTableApiQuery } from '../../utils';
import {
  PURCHASED_PRODUCTS_API_URL,
  purchasedProductsQueryKeys,
} from './purchased-products.constants';
import {
  PurchasedProductSummaryType,
  PurchasedProductType,
} from './purchased-products.types';

export function usePurchasedProducts(
  tableState: Pick<TableState<PurchasedProductSummaryType>, 'sortBy'>,
  columns?: Array<TableColumn>
) {
  return usePaginatedTableApiQuery<PurchasedProductSummaryType>({
    baseUrl: PURCHASED_PRODUCTS_API_URL,
    tableState,
    columns,
  });
}

export function usePurchasedProduct(purchasedProductId: string) {
  return useApiQuery<PurchasedProductType>(
    `${PURCHASED_PRODUCTS_API_URL}/${purchasedProductId}`,
    purchasedProductsQueryKeys.detail(purchasedProductId),
    {
      staleTime: 0,
    }
  );
}
