import { Modal, Text } from '@mantine/core';
import React, { useState } from 'react';

import WidgetConfig from './WidgetConfig';
import WidgetsList from './WidgetsList';
import { WidgetMetaType } from '../../components/ModelWidgets/types';

export function AddModelWidget({ closeMe, data: modelId }) {
  const [selectedWidget, setSelectedWidget] = useState<WidgetMetaType | null>(
    null
  );

  return (
    <Modal
      opened
      onClose={closeMe}
      title={<Text data-testid="connect-widget-title">Connect Widget </Text>}
      size={1000}
    >
      {selectedWidget ? (
        <WidgetConfig
          {...selectedWidget}
          modelId={modelId}
          onClose={closeMe}
          onBack={() => setSelectedWidget(null)}
        />
      ) : (
        <WidgetsList
          onSelect={setSelectedWidget}
          modelId={modelId}
          onCancel={() => {
            setSelectedWidget(null);
            closeMe();
          }}
        />
      )}
    </Modal>
  );
}
