import { Button, Group, Stack } from '@mantine/core';
import { endsWith } from 'lodash/fp';
import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import {
  DomainChangeType,
  PortalConfigType,
  useConfig,
  usePortalConfig,
  useUpdateDomain,
} from '@portals/api/partners';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps, usePortalDomain } from '@portals/framework';
import { TenantType } from '@portals/types';

import { ReactComponent as Planet } from '../assets/img/planet.svg';
import CustomUrl from '../components/ChangeDomain';
import {
  customDomainValidation,
  onXyteDomainValidation,
} from '../components/OnboardingWizard/constants';

interface ChangeDomainProps {
  onUpdate: Dispatch<SetStateAction<PortalConfigType>>;
}

const ChangeDomain: FC<ModalProps<ChangeDomainProps>> = ({ closeMe, data }) => {
  const { data: config } = useConfig();
  const { data: portalConfig } = usePortalConfig();

  const currentDomainName = usePortalDomain();
  const isCustomDomain = !endsWith('on-xyte.com', currentDomainName);

  const [domainChangeType, setDomainChangeType] = useState(
    isCustomDomain ? DomainChangeType.Custom : DomainChangeType.OnXyte
  );

  const [onXyteDomainError, setOnXyteDomainError] = useState(null);
  const [onXyteDomain, setOnXyteDomain] = useState(
    config[TenantType.Partner].name
  );
  const [customDomain, setCustomDomain] = useState(
    isCustomDomain ? currentDomainName : portalConfig.candidate_domain || ''
  );

  const { onUpdate, isLoading } = useUpdateDomain({
    onOnXyteDomainChanged: closeMe,
    onOnXyteDomainChangeError: setOnXyteDomainError,

    onCustomDomainRequested: (updatedPortalConfig) => {
      data.onUpdate(updatedPortalConfig);
      closeMe();
    },
  });

  const onChange = (event) => {
    if (domainChangeType === DomainChangeType.Custom) {
      setCustomDomain(event.target.value);
    } else {
      setOnXyteDomainError(null);

      setOnXyteDomain(event.target.value);
    }
  };

  const isDisabled = useMemo(() => {
    if (domainChangeType === DomainChangeType.Custom) {
      return !customDomainValidation(customDomain);
    } else {
      try {
        onXyteDomainValidation.validateSync(onXyteDomain);

        return false;
      } catch ({ errors }) {
        return true;
      }
    }
  }, [customDomain, domainChangeType, onXyteDomain]);

  const onSubmit = () =>
    onUpdate({
      onXyteDomain,
      customDomain,
      domainChangeType,
    });

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="What would you like to do?"
      media={<Planet />}
    >
      <Stack mih={400}>
        <CustomUrl
          withCustomDomain
          onSetDomainChangeType={setDomainChangeType}
          domainChangeType={domainChangeType}
          onChange={onChange}
          onXyteDomain={onXyteDomain}
          customDomain={customDomain}
          externalOnXyteDomainError={onXyteDomainError}
        />

        <Group grow mt="auto">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            disabled={isDisabled || onXyteDomainError}
            onClick={onSubmit}
            loading={isLoading}
          >
            Change URL
          </Button>
        </Group>
      </Stack>
    </ModalCenteredMediaLayout>
  );
};

export default ChangeDomain;
