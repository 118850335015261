import { StoreListing } from '@portals/api/partners';
import { CurrencyCode } from '@portals/types';

export enum StepIdEnum {
  SelectProducts = 'selectProducts',
  OrderDetails = 'orderDetails',
}

export interface CreateOrderWizardContextDataType {
  closeMe: () => void;
  selectedStoreListings: Array<StoreListing>;
  storeListingsQuantities: Record<string, number>;
  storeListings: Array<StoreListing>;
  selectedStoreListingsTotalPrice: number;
  orderReferenceId: string;
  orderNotes: string;
  currency: CurrencyCode;
  attachmentUrl: string;
}
