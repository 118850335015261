import React from 'react';

import { Meta, SystemIssues } from '@portals/framework';
import { useAuth } from '@portals/redux';
import { TenantType } from '@portals/types';

export function PartnersMeta() {
  const auth = useAuth();

  return (
    <>
      <Meta title="Xyte - HWaaS Platform" />

      {auth && <SystemIssues tenantType={TenantType.Partner} />}
    </>
  );
}
