import { find } from 'lodash/fp';

import { WidgetTypeEnum } from '@portals/device-widgets';

import { WIDGETS } from '../components/ModelWidgets/constants';

// TODO: Remove when old devices widgets replaced with new device widgets
export const useWidgetMeta = (widgetId: WidgetTypeEnum) => {
  return find({ id: widgetId }, WIDGETS);
};
