import {
  Anchor,
  Button,
  Checkbox,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  useNonePaginatedProducts,
  usePublishableProductsByIds,
} from '@portals/api/partners';
import { ModalFooter } from '@portals/core';
import { ProductImage } from '@portals/framework';
import {
  PaginatedFilterTypeEnum,
  SupportedCommandProductType,
  SupportedCommandType,
} from '@portals/types';

export interface LinkCommandToLicenseModalProps {
  closeMe: () => void;
  form: UseFormReturnType<Partial<SupportedCommandType>>;
  onNavigateToCatalog: () => void;
}
export function LinkCommandToLicenseModal({
  closeMe,
  form,
  onNavigateToCatalog,
}: LinkCommandToLicenseModalProps) {
  const { classes } = useStyles();

  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    form.values.products?.map((product) => product.id) ?? []
  );

  const publishableProducts = usePublishableProductsByIds(selectedProducts);

  const nonePaginatedProducts = useNonePaginatedProducts({
    filters: [
      {
        id: 'product_type',
        value: 'platform_license',
        type: PaginatedFilterTypeEnum.Eq,
      },
      {
        id: 'id',
        value: form.values.products?.map((product) => product.id) ?? [],
        type: PaginatedFilterTypeEnum.NotContains,
      },
      {
        id: 'archived',
        value: false,
        type: PaginatedFilterTypeEnum.Eq,
      },
    ],
  });

  const onLinkLicenseToCommand = (
    products: SupportedCommandProductType[] | undefined
  ) => {
    form.setFieldValue('products', products);

    closeMe();
  };

  const onClose = (productsCount: number) => {
    if (productsCount === 0) {
      form.setFieldValue('premium', false);
    }

    closeMe();
  };

  if (nonePaginatedProducts.data?.length === 0) {
    return (
      <Modal
        title={
          selectedProducts.length === 0
            ? 'No available licenses'
            : 'All licenses linked'
        }
        opened
        onClose={() => onClose(selectedProducts.length)}
        radius="lg"
        padding="xxl"
      >
        <LoadingOverlay visible={nonePaginatedProducts.isLoading} />

        <Text>
          {selectedProducts.length === 0
            ? 'There are no available publishes licenses to link to this command.'
            : 'You have linked all your published licenses to this command.'}
        </Text>

        <ModalFooter position="right" pt="xxl">
          <Button onClick={() => onClose(selectedProducts.length)}>
            Got it
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal
      title="Link this command to a license"
      opened
      onClose={() => onClose(selectedProducts.length)}
      radius="lg"
      padding="xxl"
      size="lg"
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <LoadingOverlay visible={nonePaginatedProducts.isLoading} />
      <Stack>
        <Text>Chooses which license(s) can unlock this command.</Text>

        <ScrollArea.Autosize mah={400}>
          <Stack spacing="sm">
            {nonePaginatedProducts.data?.map((product) => (
              <Paper key={product.id} withBorder radius="md" p="sm">
                <Group spacing="sm">
                  <Checkbox.Group
                    value={selectedProducts}
                    onChange={setSelectedProducts}
                    data-testid={'checkbox-' + product.name}
                  >
                    <Checkbox value={product.id} />
                  </Checkbox.Group>

                  <ProductImage
                    src={product.image_url}
                    radius="md"
                    height={40}
                    width={45}
                  />

                  <Stack spacing={4}>
                    <Text fw={500}>{product.name}</Text>

                    <Text fz="xs" color="gray.7">
                      by {product.manufacturer.display_name}
                    </Text>
                  </Stack>
                </Group>
              </Paper>
            ))}
          </Stack>
        </ScrollArea.Autosize>

        <Anchor
          component={Link}
          to="/catalog"
          color="gray.7"
          className={classes.anchor}
          onClick={onNavigateToCatalog}
          data-testid="cant-find-license-link"
        >
          Can’t find a license? Manage your licenses
        </Anchor>
      </Stack>
      <ModalFooter position="right" pt="xxl">
        <Button
          onClick={() => onClose(selectedProducts.length)}
          variant="default"
          data-testid="link-to-license-cancel-button"
        >
          Cancel
        </Button>

        <Button
          onClick={() => onLinkLicenseToCommand(publishableProducts.data)}
          disabled={selectedProducts.length === 0}
          data-testid="link-to-license-link-button"
        >
          Link
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  anchor: {
    textDecoration: 'underline',

    '&:hover': {
      color: theme.colors.gray[7],
    },
  },
}));
