import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React from 'react';

interface ApprovePurchaseOrderProps {
  onCancel: () => void;
  onClose: () => void;
  onSubmit: () => void;
  value: string;
  setValue: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isLoading: boolean;
}

export function ApprovePurchaseOrder({
  onCancel,
  onClose,
  onSubmit,
  value,
  setValue,
  isLoading,
}: ApprovePurchaseOrderProps) {
  return (
    <Modal
      opened
      onClose={onClose}
      title="Purchase Order Approval"
      pos="relative"
    >
      <LoadingOverlay visible={isLoading} />

      <Stack spacing="md" p={0}>
        <Stack>
          <Text size="sm" color="blue_gray.9">
            By continuing, I confirm that I have reviewed and approved the
            purchase order details, including the item description, quantity,
            unit price, and terms.
          </Text>

          <Textarea
            data-testid="po-approval-textarea"
            value={value}
            onChange={setValue}
            placeholder="Add comments or notes (optional) ..."
            minRows={6}
          />
        </Stack>

        <Group position="right" pt="xl">
          <Button
            variant="default"
            onClick={onCancel}
            data-testid="cancel-po-approval-button"
          >
            Back
          </Button>

          <Button
            onClick={onSubmit}
            data-testid="submit-po-approval-button"
            disabled={isLoading}
          >
            Approve
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
