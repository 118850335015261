import {
  createStyles,
  Group,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

// import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as AddSquare } from '@portals/icons/linear/add-square.svg';
import { ReactComponent as BoxIcon } from '@portals/icons/linear/box.svg';
import { ReactComponent as CloudConnection } from '@portals/icons/linear/cloud-connection.svg';
import { ReactComponent as Shop } from '@portals/icons/linear/shop.svg';
import { ReactComponent as TruckFast } from '@portals/icons/linear/truck-fast.svg';
import { ComponentRendererType } from '@portals/types';

import { useInvitePartnerWizard } from '../hooks';
import { RelationshipTypeEnum } from '../types';

export interface PartnerTypeProps {
  type: string;
  Icon: ComponentRendererType;
  isSelected: boolean;
}

function PartnerType({ type, Icon, isSelected }: PartnerTypeProps) {
  const { classes, cx } = usePartnerTypeStyles();

  return (
    <Stack
      spacing="xs"
      align="center"
      justify="center"
      data-testid={`partner-type-${type}`}
      className={cx(classes.container, { [classes.selected]: isSelected })}
    >
      <Icon />

      <Text size="xs">{type}</Text>
    </Stack>
  );
}

const usePartnerTypeStyles = createStyles((theme) => ({
  container: {
    width: 94,
    height: 94,
    cursor: 'pointer',
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,

    '&:hover': {
      boxShadow: theme.shadows.xs,
    },

    svg: {
      width: 18,
      height: 18,
      color: theme.colors.gray[4],
    },
  },
  selected: {
    border: `1px solid ${theme.fn.primaryColor()}`,
    backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.3),
    color: theme.fn.primaryColor(),

    svg: {
      color: theme.fn.primaryColor(),
    },
  },
}));

export const PARTNERS_TYPES = [
  {
    type: RelationshipTypeEnum.Manufacturer,
    Icon: BoxIcon,
  },
  {
    type: RelationshipTypeEnum.Integrator,
    Icon: CloudConnection,
  },
  {
    type: RelationshipTypeEnum.Distributor,
    Icon: TruckFast,
  },
  {
    type: RelationshipTypeEnum.Reseller,
    Icon: Shop,
  },
  {
    type: RelationshipTypeEnum.Other,
    Icon: AddSquare,
  },
] as const;

export function PartnerTypeSelection() {
  const { contextData } = useInvitePartnerWizard();
  const {
    selectedRelationshipType,
    onChangeRelationshipType,
    otherRelationshipType,
    setOtherRelationshipType,
  } = contextData;

  return (
    <motion.div layout="position">
      <Group
        noWrap
        spacing="xs"
        position="center"
        data-testid="partner-type-selection-group"
      >
        {PARTNERS_TYPES.map(({ type, Icon }) => (
          <UnstyledButton
            onClick={() => onChangeRelationshipType(type)}
            key={type}
          >
            <PartnerType
              type={type}
              Icon={Icon}
              isSelected={selectedRelationshipType === type}
            />
          </UnstyledButton>
        ))}
      </Group>

      <AnimatePresence initial={false}>
        {selectedRelationshipType === RelationshipTypeEnum.Other && (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
          >
            <TextInput
              autoFocus
              mt={30}
              placeholder="Who is this partner for you?"
              value={otherRelationshipType}
              onChange={(event) => setOtherRelationshipType(event.target.value)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
