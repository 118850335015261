import {
  Button,
  CloseButton,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  StoreListing,
  useSortableStoreListings,
  useStoreSettings,
  useUpdateStoreListingsPositions,
} from '@portals/api/partners';
import { SortableDndItem } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { StoreListingCategories } from './StoreListingCategories';
import { StoreListingDraggablePreviewProductCard } from './StoreListingDraggablePreviewProductCard';
import { StoreListingDraggableProductCard } from './StoreListingDraggableProductCard';
import { StoreListingProductCard } from '../../components/store-listings/StoreListingProductCard';
import { mapStoreListingPricesToPaymentSettings } from '../../utils/store-listings.utils';

interface StoreListingsSortingModalProps extends ModalProps {}

export function StoreListingsSortingModal({
  closeMe,
}: StoreListingsSortingModalProps) {
  const { classes } = useStyles();

  const storeSettings = useStoreSettings();

  const storeListings = useSortableStoreListings();

  const updateStoreListingsPositions = useUpdateStoreListingsPositions();

  const [values, handlers] = useListState<StoreListing>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  useEffect(
    function initListStateValues() {
      if (values.length > 0) return;

      if (!storeListings.isFetching && storeListings.data) {
        handlers.setState(storeListings.data);
      }
    },
    [handlers, storeListings.isFetching, storeListings.data, values.length]
  );

  const categories = useMemo(() => {
    if (!storeListings.isFetched || !storeListings.data) return [];

    const allCategories = storeListings.data
      .map((storeListing) => storeListing.product.category || '')
      .filter(Boolean);

    return Array.from(new Set(allCategories));
  }, [storeListings.data, storeListings.isFetched]);

  const onMove = (dragIndex: number, hoverIndex: number) => {
    handlers.reorder({ from: dragIndex, to: hoverIndex });
  };

  const positionsMap = values.reduce((acc, item, index) => {
    acc[item.id] = index + 1;
    return acc;
  }, {} as Record<string, number>);

  const filteredStoreListing = useMemo(() => {
    if (selectedCategory === null) {
      return values.filter(
        (storeListing) => storeListing.product.pricing_model !== 'personalized'
      );
    }

    return values.filter(
      (storeListing) =>
        storeListing.product.category === selectedCategory &&
        storeListing.product.pricing_model !== 'personalized'
    );
  }, [selectedCategory, values]);

  const onUpdateStoreListingsOrder = async () => {
    try {
      await updateStoreListingsPositions.mutateAsync({
        items: values.map((storeListing, index) => ({
          id: storeListing.id,
          position: index + 1,
        })),
      });

      closeMe();
    } catch (err) {
      console.error(err);
    }
  };

  const viewport = useRef<HTMLDivElement>(null);
  const scrollToProductTop = (childIndex: number) => {
    if (!viewport.current.children[childIndex]) return;

    viewport.current.children[childIndex].scrollIntoView({
      behavior: 'smooth',
    });
  };

  if (updateStoreListingsPositions.isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Modal.Root
      opened
      onClose={closeMe}
      centered
      size="clamp(1200px, 90%, 1700px)"
      padding={0}
      radius="lg"
    >
      <Modal.Overlay />

      <Modal.Content className={classes.content}>
        <Modal.Body className={classes.body}>
          <aside className={classes.sidebar}>
            <Title
              order={3}
              weight={600}
              data-testid="rearrange-store-modal-title"
              mb={44}
            >
              Rearrange Store
            </Title>

            <StoreListingCategories
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={setSelectedCategory}
            />
          </aside>

          <main className={classes.main}>
            <Group position="apart" align="flex-start" mb="lg">
              <Stack h={70}>
                <Title order={5} weight={400} mt={4} className={classes.title}>
                  {selectedCategory ?? 'All products'}
                </Title>

                <Text color="gray.7">
                  {selectedCategory
                    ? null
                    : 'Use drag and drop and numbering to easily rearrange your store'}
                </Text>
              </Stack>

              <CloseButton onClick={closeMe} size="md" />
            </Group>

            <ScrollArea.Autosize h="65vh" offsetScrollbars>
              <div ref={viewport} className={classes.storeListingsGrid}>
                {filteredStoreListing.map((storeListing, index) => {
                  const paymentSettings =
                    mapStoreListingPricesToPaymentSettings({
                      storeListingPrices: storeListing.prices,
                      currencies: storeSettings.data?.currencies,
                      defaultCurrency: storeSettings.data?.default_currency,
                    });

                  if (selectedCategory !== null) {
                    return (
                      <StoreListingProductCard
                        key={storeListing.id}
                        product={storeListing.product}
                        productImageUrl={storeListing.product_image_url}
                        productName={storeListing.product_name}
                        productCategory={storeListing.product_category}
                        productSubtitle={storeListing.product_subtitle}
                        paymentSettings={paymentSettings}
                      />
                    );
                  }

                  return (
                    <SortableDndItem
                      key={storeListing.id}
                      dndTypeIdentifier="store-listing"
                      itemIndex={index}
                      itemUniqueId={storeListing.id}
                      onMove={onMove}
                      handlerClassName={classes.handler}
                      isPreview
                    >
                      <motion.div
                        className={classes.refConatiner}
                        layout
                        transition={{
                          type: 'spring',
                          damping: 25,
                          stiffness: 120,
                        }}
                      >
                        <StoreListingDraggableProductCard
                          key={storeListing.id}
                          product={storeListing.product}
                          productImageUrl={storeListing.product_image_url}
                          productName={storeListing.product_name}
                          productCategory={storeListing.product_category}
                          productSubtitle={storeListing.product_subtitle}
                          paymentSettings={paymentSettings}
                          position={positionsMap[storeListing.id]}
                          storeListingId={storeListing.id}
                          onMove={onMove}
                          scrollToProductTop={scrollToProductTop}
                        />
                      </motion.div>

                      <StoreListingDraggablePreviewProductCard
                        storeListing={storeListing}
                        paymentSettings={paymentSettings}
                        position={positionsMap[storeListing.id]}
                      />
                    </SortableDndItem>
                  );
                })}
              </div>
            </ScrollArea.Autosize>
          </main>

          <Group position="right" className={classes.footer}>
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button
              data-testid="save-order-button"
              onClick={onUpdateStoreListingsOrder}
            >
              Save Order
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

const useStyles = createStyles((theme) => ({
  body: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '280px 1fr',
    gridAutoRows: '1fr auto',
  },

  content: {
    overflow: 'hidden',
  },

  sidebar: {
    padding: theme.spacing.xxl,
  },

  main: {
    paddingTop: theme.spacing.xxl,
    paddingRight: theme.spacing.xxl,
    paddingLeft: theme.spacing.xxl,
    paddingBottom: 0,
  },

  footer: {
    gridColumn: '1 / -1',
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    background: 'white',
    zIndex: 100,
  },

  refConatiner: {
    height: '100%',
  },

  title: {
    textTransform: 'capitalize',
  },

  storeListingsGrid: {
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridAutoRows: '1fr',
    gap: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
  },

  handler: {
    height: '100%',
  },
}));
