import React, { FC } from 'react';
import styled from 'styled-components';

import { PortalConfigType } from '@portals/api/partners';
import { getStyledThemeColor } from '@portals/utils';

import BrowserURLControls from './BrowserURLControls';

interface AddressBarProps {
  portalConfig: Partial<PortalConfigType>;
  domain: string;
}

const AddressBar: FC<AddressBarProps> = ({ portalConfig, domain }) => (
  <Container className="address-bar-container">
    <BrowserURLControls
      domain={<Domain>{domain}</Domain>}
      favicon={
        <Favicon src={portalConfig.favicon || 'favicon.ico'} alt="favicon" />
      }
      windowTitle={
        <WindowTitle>
          {portalConfig.window_title || 'Xyte - Simply Connected'}
        </WindowTitle>
      }
    />
  </Container>
);

const Container = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${getStyledThemeColor('white')};

  svg {
    foreignObject {
      pointer-events: none;
    }
  }
`;

const Domain = styled.div`
  font-size: 13px;
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${getStyledThemeColor('dark')};
`;

const Favicon = styled.img`
  width: 18px;
  height: 18px;
`;

const WindowTitle = styled.div`
  font-size: 11px;
  color: ${getStyledThemeColor('dark')};
  width: 67px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default AddressBar;
