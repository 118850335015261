import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  REFERRAL_CODES_API_URL,
  referralCodesQueryKeys,
} from './referral-codes.constants';
import { ReferralCode } from './referral-codes.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useReferralCodes() {
  return useApiQuery<ReferralCode[]>(
    REFERRAL_CODES_API_URL,
    referralCodesQueryKeys.all
  );
}

export function useCreateReferralCode() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: REFERRAL_CODES_API_URL,
    method: 'POST',
  });

  return useMutation<ReferralCode[], ServerError, { title: string }>({
    mutationFn: ({ title }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ title, organization_type: 'organization' }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Referral created successfully'));

      queryClient.invalidateQueries(referralCodesQueryKeys.all);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateReferralCode',
      baseUrl: REFERRAL_CODES_API_URL,
      method: 'POST',
    },
  });
}

export function useUpdateReferralCode() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: REFERRAL_CODES_API_URL,
    method: 'PUT',
  });

  return useMutation<
    ReferralCode[],
    ServerError,
    { referralCodeId: string; title: string }
  >({
    mutationFn: ({ referralCodeId, title }) => {
      return fetchApiRequest(`${url}/${referralCodeId}`, {
        ...options,
        body: JSON.stringify({ title }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Referral code updated successfully'));

      queryClient.invalidateQueries(referralCodesQueryKeys.all);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateReferralCode',
      baseUrl: `${REFERRAL_CODES_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useDeleteReferralCode() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: REFERRAL_CODES_API_URL,
    method: 'DELETE',
  });

  return useMutation<ReferralCode[], ServerError, { referralCodeId: string }>({
    mutationFn: ({ referralCodeId }) => {
      return fetchApiRequest(`${url}/${referralCodeId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Referral code deleted successfully'));

      queryClient.invalidateQueries(referralCodesQueryKeys.all);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteReferralCode',
      baseUrl: `${REFERRAL_CODES_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}
