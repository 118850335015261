import { Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';

import {
  useDeviceModelHardwareKeys,
  usePartnerConfig,
} from '@portals/api/partners';
import { AuthMethodType } from '@portals/types';

import CodeSnippet from '../CodeSnippet';
import CopyText from '../CopyText';

const SERIAL_NUMBER = Math.random().toString(36).slice(2);
const MAC_ADDRESS = 'XX:XX:XX:XX:XX:XX'.replace(/X/g, function () {
  return '0123456789abcdef'.charAt(Math.floor(Math.random() * 16));
});

const getCodeSnippet = (
  authMethod: string,
  hardwareKey: string,
  cloudId: string
) => {
  if (authMethod === AuthMethodType.CloudId) {
    return `curl --request POST \\
       --url ${
         process.env.NODE_ENV === 'development'
           ? process.env.NX_SERVER_URL
           : 'https://entry.xyte.io/'
       }v1/devices \\
       --header 'Accept: application/json' \\
       --header 'Content-Type: application/json' \\
       --data '{
       "firmware_version": "1.0.0",
       "test": true,
       "hardware_key": "${hardwareKey}",
       "cloud_id": "${cloudId}",
       "sn": "${SERIAL_NUMBER}"
  }'`;
  }

  return `curl --request POST \\
       --url ${
         process.env.NODE_ENV === 'development'
           ? process.env.NX_SERVER_URL
           : 'https://entry.xyte.io/'
       }v1/devices \\
       --header 'Accept: application/json' \\
       --header 'Content-Type: application/json' \\
       --data '{
       "firmware_version": "1.0.0",
       "test": true,
       "hardware_key": "${hardwareKey}",
       "mac": "${MAC_ADDRESS}",
       "sn": "${SERIAL_NUMBER}"
  }'`;
};

interface RegisterProps {
  modelId: string;
  modelName: string;
  authMethod: string;
}

export function RegisterDevice({
  modelId,
  modelName,
  authMethod,
}: RegisterProps) {
  const partnerConfig = usePartnerConfig();
  const { data: hardwareKeys } = useDeviceModelHardwareKeys(modelId);

  const cloudId = useMemo(() => {
    return partnerConfig.shortcode + nanoid(20);
  }, [partnerConfig]);

  const hardwareKey = useMemo(() => {
    if (!isEmpty(hardwareKeys)) {
      return hardwareKeys[0];
    }
  }, [hardwareKeys]);

  const codeSnippet = getCodeSnippet(authMethod, hardwareKey?.id, cloudId);

  return (
    <Stack
      sx={() => ({
        maxWidth: '588px',
      })}
    >
      <Stack mb={36}>
        <Text
          size="md"
          sx={(theme) => ({
            color: theme.colors.gray[8],
          })}
        >
          Register your first {modelName} device
        </Text>

        <Text
          size="sm"
          sx={(theme) => ({
            color: theme.colors.gray[6],
          })}
        >
          As soon as a device comes online, it should try to register itself to
          be able to get configuration and license information
        </Text>
      </Stack>

      <Text
        size="md"
        sx={(theme) => ({
          color: theme.colors.gray[8],
        })}
      >
        Please use the code sample below to register your device
      </Text>

      <CodeSnippet code={codeSnippet} />

      {authMethod === 'cloud_id' ? (
        <Group>
          <CopyText title="Cloud ID" textToCopy={cloudId} />
          <CopyText title="Hardware Key" textToCopy={hardwareKey?.id || ''} />
        </Group>
      ) : (
        <SimpleGrid cols={2}>
          <CopyText title="MAC Address" textToCopy={MAC_ADDRESS} />
          <CopyText title="Serial Number" textToCopy={SERIAL_NUMBER} />
          <CopyText title="Hardware Key" textToCopy={hardwareKey?.id || ''} />
        </SimpleGrid>
      )}
    </Stack>
  );
}
