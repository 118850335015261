import { noop } from 'lodash/fp';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Button, Modal, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

import { useUpdateDeviceModelWidget } from '@portals/api/partners';
import { AutoFormik } from '@portals/autoformik';
import { WidgetType } from '@portals/device-widgets';
import { NetworkButton } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

import { useIsWidgetFormValid } from './AddModelWidget/hooks';
import { Footer, Widget } from '../components/ModelWidgets/common';
import { useWidgetMeta } from '../hooks/model-widgets';

interface EditModelWidgetProps {
  closeMe: () => void;
  data: {
    modelId: string;
  } & WidgetType;
}

const EditModelWidget: FC<EditModelWidgetProps> = ({ closeMe, data }) => {
  const { name, id, config, modelId } = data;

  const ref = useRef(null);
  const [form, setForm] = useState<Record<string, any>>(config.fields);
  const { mutate, isLoading } = useUpdateDeviceModelWidget(modelId);
  const widgetMeta = useWidgetMeta(config.id);

  const isValid = useIsWidgetFormValid({ ref, form, widgetMeta });

  const onSubmit = useCallback(async () => {
    if (!isValid) return;

    await mutate({
      widget: {
        name: form.name,
        config: {
          ...config,
          fields: form,
        },
      },
      widgetId: id,
      withNotification: true,
    });

    closeMe();
  }, [isValid, form, mutate, config, id, closeMe]);

  return (
    <StyledModal isOpen centered>
      <ModalHeader toggle={closeMe}>Edit Widget: {name}</ModalHeader>

      <Container className="p-4">
        <div className="d-flex justify-content-center widget-preview-container">
          <Widget $isPreview>
            <div className="title">{name}</div>

            <div className="icon-wrapper">
              <img src={widgetMeta.configIcon} alt={name} />
            </div>
          </Widget>
        </div>

        <AutoFormik
          innerRef={ref}
          fields={widgetMeta.fields}
          inProgress={false}
          initialValues={config.fields}
          actionWrap={() => null}
          handleSubmit={noop}
          onChange={setForm}
        />
      </Container>

      <Footer>
        <Button size="lg" color="light" className="mr-4" onClick={closeMe}>
          Cancel
        </Button>
        <NetworkButton
          reloading={isLoading}
          size="lg"
          color="primary"
          disabled={!isValid}
          onClick={onSubmit}
        >
          Submit
        </NetworkButton>
      </Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: 1000px !important;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 25px;
  min-height: 650px;

  .widget-preview-container {
    border-right: 1px solid ${getStyledThemeColor('gray300')};
    padding-right: 25px;
  }

  form {
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 0 !important;
      flex: 1;
    }

    input::placeholder {
      color: ${getStyledThemeColor('gray500')};
    }
  }
`;

export default EditModelWidget;
