import { LoadingOverlay, Select, Stack } from '@mantine/core';
import { set } from 'lodash/fp';
import React, { useEffect } from 'react';

import { PublishableProduct } from '@portals/api/partners';
import { VerticalScrollBar } from '@portals/scrollbar';

import { CurrencyList } from './CurrencyList';
import { usePublishProductsWizard } from '../hooks';
import { ProductHeader } from '../product-header/ProductHeader';
import { PricingOptionKey } from '../types';

export function PricesDetailsStep() {
  const { contextData, setContextData } = usePublishProductsWizard();

  const product = contextData.products?.find(
    (product) => product.id === contextData.activeProductId
  );

  const options = composeOptions(product);

  useEffect(() => {
    if (!product) return;

    if (!contextData?.selectedPricingOptionMap?.[product.id]) {
      setContextData((curr) =>
        set(['selectedPricingOptionMap', product.id], options[0].value, curr)
      );
    }
  }, [contextData?.selectedPricingOptionMap, options, product, setContextData]);

  if (!contextData.selectedPricingOptionMap || !product) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack p={32}>
      <ProductHeader product={product} />

      <Select
        disabled={options.length === 1}
        data={options}
        value={contextData.selectedPricingOptionMap[product.id]}
        onChange={(value: PricingOptionKey) =>
          setContextData((curr) =>
            set(['selectedPricingOptionMap', product.id], value, curr)
          )
        }
      />

      <VerticalScrollBar>
        <CurrencyList product={product} />
      </VerticalScrollBar>
    </Stack>
  );
}

function composeOptions(product: PublishableProduct | undefined) {
  const result: Array<{ value: PricingOptionKey; label: string }> = [];

  if (!product) return result;

  if (product.allow_one_time_price) {
    result.push({ value: 'one_time_price_in_scu', label: 'One time' });
  }

  if (product.allow_monthly_price) {
    result.push({ value: 'monthly_price_in_scu', label: 'Monthly' });
  }

  if (product.allow_yearly_price) {
    result.push({ value: 'yearly_price_in_scu', label: 'Yearly' });
  }

  return result;
}
