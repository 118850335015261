import { Button, Group } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import { useCheckStoreListingSlugAvailability } from '@portals/api/partners';
import { useOpenModal } from '@portals/redux';

import { PublishButton } from './PublishButton';
import { SaveDraftButton } from './SaveDraftButton';
import { DuplicateSlugModalProps } from '../../DuplicateSlugModal';
import { useStoreListingWizard, useValidateStoreListingSlug } from '../hooks';
import { isProductInformationError } from '../store-listing-wizard.utils';
import { StepId } from '../types';

export function SteppingModeFooter() {
  const openModal = useOpenModal();
  const validateStoreListingSlug = useValidateStoreListingSlug();
  const checkStoreListingSlugAvailability =
    useCheckStoreListingSlugAvailability();

  const {
    currentStep,
    contextData,
    setContextData,
    isFinalStep,
    isNextDisabled,
    onNext,
    onPrev,
  } = useStoreListingWizard();

  const onPrevHandler = () => {
    if (
      currentStep.id === StepId.ChooseProduct ||
      (contextData.intent === 'publish' &&
        currentStep.id === StepId.ProductInformation)
    ) {
      contextData.closeModal({ confirmBeforeClose: true });
      return;
    }

    onPrev();
  };

  const onNextHandler = () => {
    // If the current step is not the product information step, just move to the next step
    // no validations are needed
    if (currentStep.id !== StepId.ProductInformation) {
      onNext();
      return;
    }

    // If the product information fields has errors, stop the progression
    if (isProductInformationError(contextData)) return;

    const trimmedSlug = contextData.slug?.trim() ?? '';

    // If the slug is empty or the same as the current store listing slug, just move to the next step
    if (
      isEmpty(trimmedSlug) ||
      trimmedSlug === contextData.storeListing?.slug
    ) {
      onNext();
      return;
    }

    if (!validateStoreListingSlug(contextData.slug)) {
      setContextData((prev) => ({
        ...prev,
        slugError:
          'Permalink is not a valid URL. Spaces and special characters are not allowed.',
      }));

      return;
    }

    checkStoreListingSlugAvailability.mutate(trimmedSlug, {
      onSuccess: (foundStoreListing) => {
        // If the slug is available, just move to the next step
        if (!foundStoreListing) {
          onNext();
          return;
        }

        openModal<DuplicateSlugModalProps['data']>('DuplicateSlugModal', {
          storeListingId: foundStoreListing.id,
          selectedStoreListingSlug: contextData.slug ?? '',
          selectedProductName: contextData.selectedProduct?.name ?? '',
          selectedProductImageUrl: contextData.selectedProduct?.image_url ?? '',
          onChangeSelectedStoreListingSlug: (slug) => {
            setContextData((prev) => ({
              ...prev,
              slug,
            }));
          },
        });
      },
    });
  };

  return (
    <Group position="apart">
      <Button variant="default" onClick={onPrevHandler}>
        {currentStep.id === StepId.ChooseProduct ||
        (contextData.intent === 'publish' &&
          currentStep.id === StepId.ProductInformation)
          ? 'Cancel'
          : 'Back'}
      </Button>

      <Group>
        <SaveDraftButton />

        {isFinalStep ? (
          <PublishButton />
        ) : (
          <Button
            onClick={onNextHandler}
            disabled={isNextDisabled}
            loading={checkStoreListingSlugAvailability.isLoading}
            data-testid="modal-continue-button"
          >
            Continue
          </Button>
        )}
      </Group>
    </Group>
  );
}
