import { Alert, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { flow, set } from 'lodash/fp';
import React from 'react';

import {
  useFeatureFlags,
  useFindCatalogProductStoreListings,
} from '@portals/api/partners';
import {
  CreditCardSVG,
  GaugeIcon,
  RoundDollarWithCursorSVG,
} from '@portals/assets';
import { isPartnerFeatureOn } from '@portals/framework';
import { ReactComponent as Lock } from '@portals/icons/linear/lock.svg';
import { ProductPricingModel, ProductTypeEnum } from '@portals/types';

import { UsageBasedFields } from './UsageBasedFields';
import { DetailsColumnContent } from '../common/DetailsColumnContent';
import { RadioCard } from '../common/RadioCard';
import { RadioCardGroup } from '../common/RadioCardGroup';
import { RadioCardLabel } from '../common/RadioCardLabel';
import { StepId } from '../types';
import { useProductWizard } from '../use-product-wizard';

export function PricingModelStep() {
  const { contextData, setContextData } = useProductWizard();
  const featureFlags = useFeatureFlags();

  const storeListings = useFindCatalogProductStoreListings(
    contextData.productId
  );

  const lockedPricingModel =
    contextData.productId && storeListings.data?.length
      ? storeListings.data[0].product.pricing_model
      : null;

  return (
    <DetailsColumnContent title="Pricing Model">
      <LoadingOverlay visible={storeListings.isFetching} />

      <DetailsColumnContent.Section title="Choose a pricing model">
        <Stack spacing="md">
          {lockedPricingModel ? (
            <Alert bg="blue_accent.0" p="xl">
              <Stack>
                <Group spacing="xs" color="blue_gray.8">
                  <Lock width={18} height={18} />
                  <Text
                    data-testid="pricing-model-modification-alert"
                    size="md"
                  >
                    Pricing model modification restricted
                  </Text>
                </Group>

                <Text size="sm" color="blue_gray.7">
                  The pricing model cannot be modified as it is currently
                  associated with
                  <b>
                    {storeListings.data.length === 1
                      ? ' 1 product listing'
                      : ` ${storeListings.data.length} product listings`}
                  </b>
                  .
                </Text>
              </Stack>
            </Alert>
          ) : null}

          <RadioCardGroup
            error={contextData.errors[StepId.PricingModel]?.general}
            value={contextData.product.pricing_model}
            onChange={(value) =>
              setContextData((curr) => {
                if (lockedPricingModel) return curr;

                return flow([
                  set(['product', 'pricing_model'], value),
                  set(['errors', StepId.PricingModel], ''),
                ])(curr);
              })
            }
          >
            <RadioCard
              disabled={
                lockedPricingModel !== null &&
                lockedPricingModel !== ProductPricingModel.Standard
              }
              value={ProductPricingModel.Standard}
              label={
                <RadioCardLabel
                  title="Standard pricing"
                  description="Charges customers a pre defined amount for a product or service, offering predictable cost."
                  icon={<CreditCardSVG />}
                />
              }
            />

            {isPartnerFeatureOn(featureFlags, 'usage_based_pricing') &&
              contextData.product?.product_type !==
                ProductTypeEnum.PlatformLicense && (
                <RadioCard
                  disabled={
                    lockedPricingModel !== null &&
                    lockedPricingModel !== ProductPricingModel.UsageBased
                  }
                  value={ProductPricingModel.UsageBased}
                  label={
                    <RadioCardLabel
                      title="Usage Based pricing"
                      description="Charges customers based on usage, offering a cost-effective alternative to fixed pricing."
                      icon={<GaugeIcon />}
                    >
                      <UsageBasedFields />
                    </RadioCardLabel>
                  }
                />
              )}

            {isPartnerFeatureOn(featureFlags, 'personalized_pricing_model') &&
              contextData.product?.product_type !==
                ProductTypeEnum.PlatformLicense && (
                <RadioCard
                  disabled={
                    lockedPricingModel !== null &&
                    lockedPricingModel !== ProductPricingModel.Personalized
                  }
                  value={ProductPricingModel.Personalized}
                  label={
                    <RadioCardLabel
                      title="Personalized pricing"
                      description="Your customers will manually enter the price for a product or service."
                      icon={<RoundDollarWithCursorSVG />}
                    />
                  }
                />
              )}
          </RadioCardGroup>
        </Stack>
      </DetailsColumnContent.Section>
    </DetailsColumnContent>
  );
}
